import {SkillId} from "./skillConstants";
import {CharacteristicDefinition, CharacteristicType} from "./characteristicConstants";

export enum VantageCostType {
  Single = '@@vantage/costType/Single',
  StaticList = '@@vantage/costType/StaticList',
  PerLevel = '@@vantage/costType/PerLevel',
}

export const VANTAGE_ID_PREFIX = '@@vantage';
export enum VantageId {
  AttractiveAppearance = '@@vantage/AttractiveAppearance',
  UglyAppearance = '@@vantage/UglyAppearance',
  Charisma = '@@vantage/Charisma',
  Ambidexterity = '@@vantage/Ambidexterity',
  Fat = '@@vantage/Fat',
  Skinny = '@@vantage/Skinny',
  OdiousPersonalHabits = '@@vantage/OdiousPersonalHabits',
  Voice = '@@vantage/Voice',
  ClericalInvestment = '@@vantage/ClericalInvestment',
  Duty = '@@vantage/Duty',
  LegalEnforcementPowers = '@@vantage/LegalEnforcementPowers',
  MilitaryRank = '@@vantage/MilitaryRank',
  GoodReputation = '@@vantage/GoodReputation',
  BadReputation = '@@vantage/BadReputation',
  SocialStigma = '@@vantage/SocialStigma',
  LowStatus = '@@vantage/LowStatus',
  HighStatus = '@@vantage/HighStatus',
  Poverty = '@@vantage/Poverty',
  Wealth = '@@vantage/Wealth',
  Dependents = '@@vantage/Dependents',
  Allies = '@@vantage/Allies',
  Patrons = '@@vantage/Patrons',
  Enemies = '@@vantage/Enemies',
  AbsoluteDirection = '@@vantage/AbsoluteDirection',
  AbsoluteTiming = '@@vantage/AbsoluteTiming',
  DangerSense = '@@vantage/DangerSense',
  Daredevil = '@@vantage/Daredevil',
  DiseaseResistantImmunityToDisease = '@@vantage/DiseaseResistantImmunityToDisease',
  DoubleJointed = '@@vantage/DoubleJointed',
  Empathy = '@@vantage/Empathy',
  Fearlessness = '@@vantage/Fearlessness',
  FitVeryFit = '@@vantage/FitVeryFit',
  HardToKill = '@@vantage/HardToKill',
  AcuteSenseS = '@@vantage/AcuteSenseS',
  Alertness = '@@vantage/Alertness',
  AnimalEmpathy = '@@vantage/AnimalEmpathy',
  CombatReflexes = '@@vantage/CombatReflexes',
  CommonSense = '@@vantage/CommonSense',
  HighPainThreshold = '@@vantage/HighPainThreshold',
  LanguageTalent = '@@vantage/LanguageTalent',
  LessSleep = '@@vantage/LessSleep',
  RapidVeryRapidHealing = '@@vantage/RapidVeryRapidHealing',
  ResistantToPoison = '@@vantage/ResistantToPoison',
  SingleMinded = '@@vantage/SingleMinded',
  StrongWill = '@@vantage/StrongWill',
  Toughness = '@@vantage/Toughness',
  Literacy = '@@vantage/Literacy',
  Luck = '@@vantage/Luck', 
  MusicalAbility = '@@vantage/MusicalAbility',
  NightVision = '@@vantage/NightVision',
// RapidVeryRapidHealing = '@@vantage/RapidVeryRapidHealing',
  AddictionTobacco = '@@vantage/AddictionTobacco',
  BadSight = '@@vantage/BadSight',
  BadTemper = '@@vantage/BadTemper',
  Bloodlust = '@@vantage/Bloodlust',
  Bully = '@@vantage/Bully',
  Callous = '@@vantage/Callous',
  Chummy = '@@vantage/Chummy',
  CodeOfHonor = '@@vantage/CodeOfHonor',
  CompulsiveBehavior = '@@vantage/CompulsiveBehavior',
  Cowardice = '@@vantage/Cowardice',
  Fanaticism = '@@vantage/Fanaticism',
  Greed = '@@vantage/Greed',
  Gullibility = '@@vantage/Gullibility',
  Jealousy = '@@vantage/Jealousy',
  LameCrippledLeg = '@@vantage/LameCrippledLeg',
  LameOneLeg = '@@vantage/LameOneLeg',
  LameLeglessOrParaplegic = '@@vantage/LameLeglessOrParaplegic',
  Laziness = '@@vantage/Laziness',
  Miserliness = '@@vantage/Miserliness',
  OneArm = '@@vantage/OneArm',
  HardOfHearing = '@@vantage/HardOfHearing',
  Hidebound = '@@vantage/Hidebound',
  Honesty = '@@vantage/Honesty',
  Impulsiveness = '@@vantage/Impulsiveness',
  Intolerance = '@@vantage/Intolerance',
  OneEye = '@@vantage/OneEye',
  Overconfidence = '@@vantage/Overconfidence',
  Pacifism = '@@vantage/Pacifism',
  Phobias = '@@vantage/Phobias',
  PostCombatShakes = '@@vantage/PostCombatShakes',
  Primitive = '@@vantage/Primitive',
  Secret = '@@vantage/Secret',
  SemiLiteracyIlliteracy = '@@vantage/SemiLiteracyIlliteracy',
  SenseOfDuty = '@@vantage/SenseOfDuty',
  Stubbornness = '@@vantage/Stubbornness',
  Truthfulness = '@@vantage/Truthfulness',
  Vow = '@@vantage/Vow',
  WeakWill = '@@vantage/WeakWill',
  Workaholic = '@@vantage/Workaholic',
  Youth = '@@vantage/Youth'
}

export interface Vantage  {
  id: VantageId;
  level: number;
}

export interface VantageDefinition extends CharacteristicDefinition {
  id: VantageId;
  costType: VantageCostType;
  cost: Array<number>;
  skillBonii: { [index in SkillId]?: number };
}

export const vantageDefinitions: Record<VantageId, VantageDefinition> = {
  [VantageId.AttractiveAppearance]: {
    id: VantageId.AttractiveAppearance,
    type: CharacteristicType.Vantage,
    name: 'Attractive Appearance',
    costType: VantageCostType.StaticList,
    cost: [5, 15, 25],
    skillBonii: {},
    description: "You are free to set the physical appearance of your character in any way you like. However, outstanding good (or bad) looks are considered an advantage (or disadvantage). Good looks cost points; bad looks give you bonus points to spend elsewhere. Reaction-roll modifiers due to Appearance apply only to people who can see you. " +
        "Attractive Appearance: You may not enter beauty contests, but you’re definitely good-looking. +1 on all reaction rolls. 5 points. Handsome (or Beautiful) Appearance: You could enter beau- ty contests! +2 on reaction rolls made by the same sex; +4 on reaction rolls made by the opposite sex. 15 points. " +
        "Very Handsome (or Beautiful) Appearance: You do enter beauty contests, and you win. +2 on reaction rolls made by the same sex; +6 (!) on reaction rolls by opposite sex. 25 points.",
  },
  [VantageId.UglyAppearance]: {
    id: VantageId.UglyAppearance,
    type: CharacteristicType.Vantage,
    name: 'Ugly Appearance',
    costType: VantageCostType.StaticList,
    cost: [-5, -10, -20],
    skillBonii: {},
    description: "You are free to set the physical appearance of your character in any way you like. However, outstanding good (or bad) looks are considered an advantage (or disadvantage). Good looks cost points; bad looks give you bonus points to spend elsewhere. Reaction-roll modifiers due to Appearance apply only to people who can see you. " +
        "Hideous Appearance: Any sort of disgusting looks you spec- ify: hunchback, severe skin disease, wall-eye . . . preferably sev- eral things at once. -4 on reaction rolls. -20 points. " +
        "Ugly Appearance: As above, but not so bad – maybe only stringy hair and snaggle teeth. -2 on reaction rolls. -10 points. " +
        "Unattractive Appearance: You just look vaguely unappealing. -1 on reaction rolls. -5 points. "
  },
  [VantageId.Charisma]: {
    id: VantageId.Charisma,
    type: CharacteristicType.Vantage,
    name: 'Charisma',
    costType: VantageCostType.PerLevel,
    cost: [5],
    skillBonii: {},
    description: "This is the natural ability to impress and lead others. Anyone can acquire a semblance of charisma by good looks, good manners, and intelligence – but real charisma works independently of these things, and you either have it or you don’t. It affects all reaction rolls made by humans (but not beasts). 5 points per +1 reaction bonus.",
  },
  [VantageId.Ambidexterity]: {
    id: VantageId.Ambidexterity,
    type: CharacteristicType.Vantage,
    name: 'Ambidexterity',
    costType: VantageCostType.Single,
    cost: [10],
    skillBonii: {},
    description: "You can use both hands with equal skill. You do not suffer the -4 penalty for using an “off hand” like most people do, and can fight (or act) with either hand (though not necessarily both at once). Should some accident befall one of your arms or hands, assume it is the left one.",
  },
  [VantageId.Fat]: {
    id: VantageId.Fat,
    type: CharacteristicType.Vantage,
    name: 'Fat',
    costType: VantageCostType.StaticList,
    cost: [-5, -10, -20],
    skillBonii: {},
    description: "You are unusually heavy. " +
        "Overweight: Determine weight normally for ST, and then increase it by 30%. Being overweight carries a reaction penalty of -1 among health-conscious societies and in areas where food is in especially short supply. -5 points. Fat: Determine weight normally for ST, and then increase it by 50%. This gives -1 on all reaction rolls; HT may not be greater than 15. -10 points. " +
        "Extremely Fat: Determine weight normally, and then double it. This gives -2 on all reaction rolls; HT may not be greater than 13. -20 points. " +
        "In all three cases, the extra weight counts as encumbrance (p. 194) that you cannot get rid of. (Exception: this does not count against you when swimming.) If you are Fat or Extremely Fat, normal clothes and body armor will not fit you, and you will be at -3 to Disguise, or to Shadowing if you are trying to follow someone in a crowd. The GM may assess additional penalties in cramped quarters (tanks, submarines, etc.). Fat people get +5 to their Swimming roll (+2 if merely Overweight).",
  },
  [VantageId.Skinny]: {
    id: VantageId.Skinny,
    type: CharacteristicType.Vantage,
    name: 'Skinny',
    costType: VantageCostType.Single,
    cost: [-5],
    skillBonii: {},
    description: "You are notably underweight. After figuring your height, take “average” weight for that height and cut it by 1/3. You may not take Handsome or Very Handsome appearance, and your HT may not be more than 14. Normal clothes and body armor will not fit you, and you will be at -2 to Disguise, or to Shadowing if you are trying to follow someone in a crowd.",
  },
  [VantageId.OdiousPersonalHabits]: {
    id: VantageId.OdiousPersonalHabits,
    type: CharacteristicType.Vantage,
    name: 'Odious Personal Habits',
    costType: VantageCostType.StaticList,
    cost: [-5, -10, -15],
    skillBonii: {},
    description: "You behave, some or all of the time, in a fashion repugnant to others. The worse your behavior, the more bonus points you receive. Specify the behavior when the character is first created, and work the bonus out with the GM. Body odor might be worth - 5 points, spitting on the floor would be worth -10 points; -15-point habits are left to the imagination of those depraved enough to want them. For each -5 points your habit is worth, subtract 1 from all reaction rolls made by someone in a position to notice it.",
  },
  [VantageId.Voice]: {
    id: VantageId.Voice,
    type: CharacteristicType.Vantage,
    name: 'Voice',
    costType: VantageCostType.Single,
    cost: [10],
    skillBonii: {},
    description: "You have a naturally clear, resonant, and attractive voice. You get a permanent +2 bonus on the Bard (or Public Speaking) skill, and on the following Influence Skills (p. 189): Diplomacy, Savoir-Faire, and Sex Appeal. You also get a +2 on any reaction roll made by someone who can hear your voice.",
  },
  [VantageId.ClericalInvestment]: {
    id: VantageId.ClericalInvestment,
    type: CharacteristicType.Vantage,
    name: 'Clerical Investment',
    costType: VantageCostType.PerLevel,
    cost: [5],
    skillBonii: {},
    description: "This is the social/political advantage of being invested as a cleric of your religion. It represents your status and influence within the church. You have a number of powers and privileges that a layman lacks, including a +1 reaction bonus per level of rank from followers of your religion and those who respect your faith. You will be addressed by a title – Father, Rabbi, Reverend – and may perform ceremonies such as marriage and last rites. Most ordinary clerics will have 5 points in this advantage.",
  },
  [VantageId.Duty]: {
    id: VantageId.Duty,
    type: CharacteristicType.Vantage,
    name: 'Duty',
    costType: VantageCostType.StaticList,
    cost: [-2, -5, -10, -15, -20],
    skillBonii: {},
    description: "You have a significant responsibility toward others, and are personally committed to that responsibility. A Duty typically reflects your obligations as a member of an intelligence agency, " +
        "a military or police force, or a similar organization. By definition, a Duty is imposed from outside. The GM rolls at the beginning of each adventure to see if each character will be “called to duty” in that adventure. The point cost of a Duty depends on the fre- quency with which it is demanded (all rolls are on three dice): " +
        "Almost all the time (roll of 15 or less): -15 points. Quite often (roll of 12 or less): -10 points. " +
        "Fairly often (roll of 9 or less): -5 points. Occasionally (roll of 6 or less): -2 points. " +
        "To be significant, a Duty should be dangerous. An ordinary job is not a Duty. If a Duty does not require you to risk your life, at least occasionally, reduce its value by 5 points, which negates those less frequent than “quite often.” " +
        "On the other hand, an Extremely Hazardous Duty, where you are “on duty” all the time and risk death or serious injury, is worth -20 points. This is typical of front-line fighters in total war. " +
        "See p. 64 for more detail on military Duties.",
  },
  [VantageId.LegalEnforcementPowers]: {
    id: VantageId.LegalEnforcementPowers,
    type: CharacteristicType.Vantage,
    name: 'Legal Enforcement Powers',
    costType: VantageCostType.StaticList,
    cost: [5, 10, 15],
    skillBonii: {},
    description: "You are an officer of the law, with all the accompanying rights, powers, and restrictions. In some jurisdictions, this amounts to a license to kill; in others, it’s little more than the right to carry a badge and write parking tickets. " +
        "The point cost is determined by the rights and privileges of the character’s branch of law enforcement. Generally, a policeman with local jurisdiction, the ability to arrest suspected criminals, the right to perform searches with an appropriate warrant, and possibly the right to carry a concealed weapon, has 5 points’ worth of Legal Enforcement Powers. " +
        "Someone with national or international jurisdiction, or not obligated to respect the civil rights of others, or free to engage in covert investigations, or able to kill with relative impunity, has 10 points’ worth of Legal Enforcement Powers. " +
        "An officer with three or more of the above abilities has 15 points of Legal Enforcement Powers. " +
        "Legal Enforcement Powers usually go hand-in-hand with an appropriate Duty disadvantage (above), and a Reputation (see below), which may be an advantage, a disadvantage, or both.",
  },
  [VantageId.MilitaryRank]: {
    id: VantageId.MilitaryRank,
    type: CharacteristicType.Vantage,
    name: 'Military Rank',
    costType: VantageCostType.PerLevel,
    cost: [5],
    skillBonii: {},
    description: "Military Rank reflects your position in a military organization. Each level of Rank gives authority over those of lower Rank – regardless of personal ability. Typically, enlisted men have Rank 0, NCOs have Rank 1-2, and officers have Rank 3+. Generals or the equivalent are Rank 7 or 8 (the maximum level of Rank). The title associated with a given level of Rank depends on the organ- ization; see Sample GURPS Military Ranks (p. 63) for examples. " +
        "Military Rank gives a Status bonus, which need not be paid for separately; see Status (p. 66) for more information. Military Rank almost always involves a significant Duty (see above).",
  },
  [VantageId.GoodReputation]: {
    id: VantageId.GoodReputation,
    type: CharacteristicType.Vantage,
    name: 'Good Reputation',
    costType: VantageCostType.StaticList,
    cost: [2, 5, 7, 10, 12, 15, 17, 20],
    skillBonii: {},
    description: "Some people are so well-known that their reputation actually becomes an advantage or a disadvantage. For game purposes, rep- utation affects the reaction rolls made by NPCs. The details of your reputation are entirely up to you; you can be known for brav- ery, ferocity, or whatever you want. If you have a reputation, your name or your face, or possibly the military decorations pinned to your uniform (see Reputation (Medals), p. 63), will be enough to trigger a “reputation roll” to see if the people you meet have heard of you. Roll once for each person or small group you meet. For a large group, the GM may roll more than once if he likes." +
      "There are three components to your reputation: Type of Repu- tation, People Affected, and Frequency of Recognition. " +
        "Type of Reputation affects the reaction modifier that you get from people who recognize you. For every +1 bonus to a reaction roll (up to +4), the cost is 5 points. For every -1 penalty (up to - 4), the cost is -5 points. " +
        "People Affected modifies the value of your reputation. The larger the “affected class” (people who might have heard of you), the more your reputation is worth, as follows: " +
        "Everyone you will meet in your campaign: use listed value. " +
        "Large class of people (e.g., all people of a particular national- ity, profession, or religion): 1/2 value (round down). " +
        "Small class of people (e.g., everyone from a specific town, every officer who fought in a particular battle): 1/3 value (round down). If the class of people affected is so small that, in the GM’s " +
        "opinion, you would not meet even one in the average adventure, your reputation doesn’t count at all. " +
        "Frequency of Recognition also modifies the value of your reputation. The more often you are recognized by members of the “affected class,” the more important that reputation is (all rolls are on three dice): " +
        "All the time: no modifier. " +
        "Sometimes (roll of 10 or less): 1/2 value, rounded down. Occasionally (roll of 7 or less): 1/3 value, rounded down.",
  },
  [VantageId.BadReputation]: {
    id: VantageId.BadReputation,
    type: CharacteristicType.Vantage,
    name: 'Bad Reputation',
    costType: VantageCostType.StaticList,
    cost: [-2, -5, -7, -10, -12, -15, -17, -20],
    skillBonii: {},
    description: "Some people are so well-known that their reputation actually becomes an advantage or a disadvantage. For game purposes, rep- utation affects the reaction rolls made by NPCs. The details of your reputation are entirely up to you; you can be known for brav- ery, ferocity, or whatever you want. If you have a reputation, your name or your face, or possibly the military decorations pinned to your uniform (see Reputation (Medals), p. 63), will be enough to trigger a “reputation roll” to see if the people you meet have heard of you. Roll once for each person or small group you meet. For a large group, the GM may roll more than once if he likes." +
        "There are three components to your reputation: Type of Repu- tation, People Affected, and Frequency of Recognition. " +
        "Type of Reputation affects the reaction modifier that you get from people who recognize you. For every +1 bonus to a reaction roll (up to +4), the cost is 5 points. For every -1 penalty (up to - 4), the cost is -5 points. " +
        "People Affected modifies the value of your reputation. The larger the “affected class” (people who might have heard of you), the more your reputation is worth, as follows: " +
        "Everyone you will meet in your campaign: use listed value. " +
        "Large class of people (e.g., all people of a particular national- ity, profession, or religion): 1/2 value (round down). " +
        "Small class of people (e.g., everyone from a specific town, every officer who fought in a particular battle): 1/3 value (round down). If the class of people affected is so small that, in the GM’s " +
        "opinion, you would not meet even one in the average adventure, your reputation doesn’t count at all. " +
        "Frequency of Recognition also modifies the value of your reputation. The more often you are recognized by members of the “affected class,” the more important that reputation is (all rolls are on three dice): " +
        "All the time: no modifier. " +
        "Sometimes (roll of 10 or less): 1/2 value, rounded down. Occasionally (roll of 7 or less): 1/3 value, rounded down.",
  },
  [VantageId.SocialStigma]: {
    id: VantageId.SocialStigma,
    type: CharacteristicType.Vantage,
    name: 'Social Stigma',
    costType: VantageCostType.StaticList,
    cost: [-5, -10, -15],
    skillBonii: {},
    description: "You are of an ethnicity or sex your culture considers inferior. The “stigma” must be obvious to anyone who sees you; otherwise, it’s a Reputation. The point bonus depends on the reaction penalty: " +
        "Second-class citizen (e.g., a woman in many societies, a non- Aryan Western European in the Third Reich): -5 points. -1 on all reaction rolls except from others of your own kind. " +
        "Valuable property (e.g., a woman in WWII-era Japan): -10 points. This usually takes the form of limited freedom or lack of intellectual respect. " +
        "Minority group (e.g., a black or Hispanic person in WWII-era U.S.A., a Slav in the Third Reich): -10 points. -2 on all reaction rolls made by anyone except your own kind, but +2 on rolls made by your own kind. " +
        "Outsider, outlaw, or barbarian (e.g., any non-Japanese in Japan during WWII): -15 points. This only applies if the “barbarian” is outside his own culture. You get -3 on all reaction rolls, but +3 from your own kind when met outside your home culture. " +
        "Anyone who takes a Social Stigma disadvantage must be bound by it . . . roleplay the difficulties it causes!",
  },
  [VantageId.LowStatus]: {
    id: VantageId.LowStatus,
    type: CharacteristicType.Vantage,
    name: 'Low Status',
    costType: VantageCostType.StaticList,
    cost: [-10, -5],
    skillBonii: {},
    description: " " +
        "Status is an indication of your class in society. Anyone can determine your Status by looking your dress and your bearing. If you have very high Status, your face may be easily recognized. " +
        "Status is measured in “social levels,” ranging from -2 (hobo, refugee) to 8 (absolute dictator, divine emperor); the meaning of specific Status levels is given on the Status/Cost of Living Table (p. 66). The point cost is 5 points per level of Status; e.g., Status 5 costs 25 points, while Status -3 is a disadvantage worth -15 points. " +
        "High Status: High Status means you are a member of the ruling class in your culture. As a result, others in your culture (only) defer to you. High Status carries various privileges; these are up to the GM. Because of the relationship between Status and Wealth (see below), a Wealth level of Wealthy or above lets you pay 5 fewer points for high Status. In effect, you get one level of Status free. Military Rank can also give a Status bonus; see p. 63 and p. 179. " +
        "Low Status: You are a servant, criminal, or slave. Note that this is not the same thing as a Social Stigma (p. 180). " +
        "Status as a Reaction Modifier: When a reaction roll is made, the difference between the Status of the characters involved can affect the reaction. Higher Status usually gives you a bonus. If you have Status 3, for instance, those of Status 1 would react to you at +2. Negative Status usually gives a penalty. If your Status is negative, those of higher Status will react badly to you. Take the difference between your Status and the NPC’s as a reaction penalty, but no worse than -4. Lower Status may give a penalty. If you are dealing with an NPC who is basically friendly, your Status won’t matter (as long as it’s positive). But if the NPC is neutral or already angry, lower Status makes it worse.",
  },
  [VantageId.HighStatus]: {
    id: VantageId.HighStatus,
    type: CharacteristicType.Vantage,
    name: 'High Status',
    costType: VantageCostType.StaticList,
    cost: [5, 10, 15, 20, 25, 30, 35, 40],
    skillBonii: {},
    description: "Status is an indication of your class in society. Anyone can determine your Status by looking your dress and your bearing. If you have very high Status, your face may be easily recognized. " +
        "Status is measured in “social levels,” ranging from -2 (hobo, refugee) to 8 (absolute dictator, divine emperor); the meaning of specific Status levels is given on the Status/Cost of Living Table (p. 66). The point cost is 5 points per level of Status; e.g., Status 5 costs 25 points, while Status -3 is a disadvantage worth -15 points. " +
        "High Status: High Status means you are a member of the ruling class in your culture. As a result, others in your culture (only) defer to you. High Status carries various privileges; these are up to the GM. Because of the relationship between Status and Wealth (see below), a Wealth level of Wealthy or above lets you pay 5 fewer points for high Status. In effect, you get one level of Status free. Military Rank can also give a Status bonus; see p. 63 and p. 179. " +
        "Low Status: You are a servant, criminal, or slave. Note that this is not the same thing as a Social Stigma (p. 180). " +
        "Status as a Reaction Modifier: When a reaction roll is made, the difference between the Status of the characters involved can affect the reaction. Higher Status usually gives you a bonus. If you have Status 3, for instance, those of Status 1 would react to you at +2. Negative Status usually gives a penalty. If your Status is negative, those of higher Status will react badly to you. Take the difference between your Status and the NPC’s as a reaction penalty, but no worse than -4. Lower Status may give a penalty. If you are dealing with an NPC who is basically friendly, your Status won’t matter (as long as it’s positive). But if the NPC is neutral or already angry, lower Status makes it worse.",
  },
  [VantageId.Wealth]: {
    id: VantageId.Wealth,
    type: CharacteristicType.Vantage,
    name: 'Wealth',
    costType: VantageCostType.StaticList,
    cost: [10, 20, 30, 50, 75, 100, 125, 150],
    skillBonii: {},
    description: "Wealth governs how much money you start play with, how much money you earn per game month (though this depends on your specific job, too), and how much time you must spend earn- ing your living. All characters get the “standard” starting wealth unless they paid extra character points for high wealth (the Wealth advantage) or took the disadvantage of low wealth (the Poverty disadvantage); “wealth levels” are explained below. Characters with a “settled” lifestyle should put 80% of their starting wealth into home, clothing, etc., leaving only 20% for “adventuring” gear. " +
        "GURPS uses a $ sign to indicate money, regardless of the spe- cific currency. Standard starting wealth is $1,500 (1,500 U.S. dol- lars) during World War 2. For more on how wealth works in this era, see Wealth (p. 63). " +
        "Average: You have the standard starting wealth. You spend 40 hours per week at your job. No point cost or bonus. " +
        "Comfortable: You work for a living, but your lifestyle is bet- ter than average. You spend 40 hours per week at your job. Your starting wealth is twice the standard starting wealth. 10 points. " +
        "Wealthy: Your starting wealth is 5 times the standard starting wealth; you live very well. Your job takes only 20 hours per week. This level of wealth, and higher ones, may not be chosen without the GM’s permission! 20 points. " +
        "Very Wealthy: Your starting wealth is 20 times the standard starting wealth. You spend only 10 hours a week looking after business (this is hardly a “job”). 30 points. " +
        "Filthy Rich: Your starting wealth is 100 times the standard start- ing wealth. You spend 10 hours a week on business. You can buy almost anything you want without considering the cost. 50 points. " +
        "Multimillionaire: Once you have purchased Filthy Rich, you may buy additional levels of Wealth. Each level increases your wealth by a factor of ten (e.g., the first level would increase total wealth to 1,000 times standard starting wealth). Each level also grants a free level of Status, to a maximum bonus of +2 over the free level already given for high Wealth. 25 points per level.",
  },
  [VantageId.Poverty]: {
    id: VantageId.Poverty,
    type: CharacteristicType.Vantage,
    name: 'Poverty',
    costType: VantageCostType.StaticList,
    cost: [-10, -15, -25],
    skillBonii: {},
    description: "Wealth governs how much money you start play with, how much money you earn per game month (though this depends on your specific job, too), and how much time you must spend earn- ing your living. All characters get the “standard” starting wealth unless they paid extra character points for high wealth (the Wealth advantage) or took the disadvantage of low wealth (the Poverty disadvantage); “wealth levels” are explained below. Characters with a “settled” lifestyle should put 80% of their starting wealth into home, clothing, etc., leaving only 20% for “adventuring” gear. " +
        "GURPS uses a $ sign to indicate money, regardless of the spe- cific currency. Standard starting wealth is $1,500 (1,500 U.S. dol- lars) during World War 2. For more on how wealth works in this era, see Wealth (p. 63). " +
        "Dead Broke: You have no job, no source of income, no money, and no property other than the clothes on your back. Either you are unable to work, or there are no jobs. -25 points. " +
        "Poor: Starting wealth is only 1/5 the standard starting wealth. You spend 50 hours per week at your job. Some jobs are not avail- able to you, and no job you find will pay you very well. -15 points. " +
        "Struggling: Your starting wealth is only 1/2 the standard start- ing wealth. You spend 40 hours per week at your job. Most jobs are open to you, but you don’t earn much. -10 points. "
  },
  [VantageId.Dependents]: {
    id: VantageId.Dependents,
    type: CharacteristicType.Vantage,
    name: 'Dependents',
    costType: VantageCostType.StaticList,
    cost: [-6, -12, -18, -24, -30, -36, -42, -48, -54],
    skillBonii: {},
    description: "An NPC for whom you are responsible is a Dependent, and is considered a disadvantage. This may be your child, sidekick, spouse, or anyone else you feel an obligation to look after. If your Dependent is in trouble and you don’t go to his aid immediately, the GM can deny you bonus character points (see p. 194) for “acting out of character.” Furthermore, you can never get any character points for a play session in which your Dependent is killed or badly hurt. " +
        "The Dependent is created just like any other character, but instead of the 100 points used to create a PC, you use 50 points or less. A Dependent built with 0 or fewer points is worth -16 points, one who is built with 1 to 25 points is worth -12 points, and one built with 26 to 50 points is worth -6 points. A Depen- dent built with 50+ points is not worth any bonus points. " +
        "The more important the Dependent is to you, the more points he is worth. For an employer or acquaintance to whom you may weigh risks in a rational fashion, halve the values above. For a friend you must always protect, unless something even more important is on the line, use the values above. For a loved one whose safety always comes first, double the values above. " +
        "Finally, pick a frequency of appearance (see above) that fits the “story” behind the Dependent. " +
        "No character may ever earn points for more than two Depen- dents at once.",
  },
  [VantageId.Allies]: {
    id: VantageId.Allies,
    type: CharacteristicType.Vantage,
    name: 'Allies',
    costType: VantageCostType.StaticList,
    cost: [2, 5, 10, 20, 30],
    skillBonii: {},
    description: "Allies are loyal comrades who are competent enough to accom- pany you on adventures. Having an Ally is an advantage. An Ally is an NPC, and should be played as such. Allies are usually agree- able to the suggestions of their PCs, but they are not puppets. As well, a PC should receive no character points for any play session in which he betrays, attacks, or unnecessarily endangers his Ally. " +
        "An Ally character is created just as though he were a PC. An Ally built on 51-75 points is worth no points, but must be protect- ed like a Dependent. An Ally built on 76-100 points costs 5 points, while one built on 101-150 points costs 10 points. An Ally built on over 50 points more than his PC is actually a Patron (see below). " +
        "An Ally who has special abilities – for instance, political power out of proportion to his point value – may cost an extra 5 to 10 points, at the GM’s discretion. " +
        "As for a Dependent, pick a frequency of appearance that fits the “story” behind the Ally. " +
        "Note that NPC Allies must all pay the points to have their PC as an Ally. For a 100-point PC, this will cost 5 points.",
  },
  [VantageId.Patrons]: {
    id: VantageId.Patrons,
    type: CharacteristicType.Vantage,
    name: 'Patrons',
    costType: VantageCostType.StaticList,
    cost: [10, 20, 30, 40, 50, 60, 70, 80, 90],
    skillBonii: {},
    description: "The most powerful NPC friends are known as Patrons. Like Allies, Patrons are NPCs, created initially by the player but con- trolled by the GM. A Patron may be an advisor, protector, or employer (but you can have a job without having a Patron; a Patron is more than an ordinary boss – see Patrons, p. 63). Unlike an Ally, a Patron does not adventure with a PC. Instead, the Patron offers advice, knowledge, equipment, influence, etc. " +
        "The point value of a Patron depends on his (or its) power. A single powerful individual (created with at least 150 points), or a group with assets of at least 1,000 times standard starting wealth, is worth 10 points. An extremely powerful individual (created with at least 200 points), or a reasonably powerful organization (assets equivalent to at least 10,000 times standard starting wealth), is worth 15 points. A very powerful organization (assets equivalent to at least a million times standard starting wealth) is worth 25 points. A national government or giant multi-national organization (net worth basically incalculable) is worth 30 points. " +
        "If a Patron supplies useful equipment, that increases the point value if you can use the equipment for your own purposes. In most cases, this adds 5 points to a Patron’s cost. If the equipment is worth more than the standard starting wealth of the campaign, it adds 10 points. " +
        "Like an Ally, a Patron who has special abilities may cost an extra 5 to 10 points, at the GM’s discretion. " +
        "Finally, pick an appropriate frequency of appearance.",
  },
  [VantageId.Enemies]: {
    id: VantageId.Enemies,
    type: CharacteristicType.Vantage,
    name: 'Enemies',
    costType: VantageCostType.StaticList,
    cost: [-5, -10, -20, -30, -40, -50, -60],
    skillBonii: {},
    description: "An NPC or organization that is working against you, or just trying to kill you, is an Enemy. You are responsible for determin- ing the nature of your Enemy when you first create your charac- ter, and must explain to the GM why this Enemy is after you. The GM always has the right to veto your choice of Enemy if it seems silly or would be too hard to fit into the campaign. " +
        "To be worth points as an Enemy, your nemesis must be per- sonal, not simply a common foe of everyone around you. If you are a soldier, the opposing forces in wartime do not qualify as Enemies: you are no more their target than anyone else fighting on your side. However, a crazed enemy sniper obsessed with killing you personally (for whatever reason) would be a legiti- mate Enemy. " +
        "The point value of an Enemy is governed by his (or its) strength: the more powerful the Enemy, the more points he (or it) is worth as a disadvantage. A single above-average individual (created with 50 points) is worth -5 points. A single very formi- dable individual (created with 100 points), or a group of 3 to 5 “average” 25-point people, is worth -10 points. A medium-sized group (6 to 20 people) is worth -20 points. A large group (20 to 1,000 people), or a medium-sized group that includes some for- midable or superhuman individuals, is worth -30 points. An entire government or some other utterly formidable group is worth -40 points. " +
        "Once you know the base point value of the Enemy, pick an appropriate frequency of appearance. " +
        "Since too many Enemies can disrupt a game, no character may take more than two Enemies, or total more than 60 points bonus from Enemies.",
  },
  [VantageId.AbsoluteDirection]: {
    id: VantageId.AbsoluteDirection,
    type: CharacteristicType.Vantage,
    name: 'Absolute direction',
    costType: VantageCostType.Single,
    cost: [5],
    skillBonii: {},
    description: "You always know which way is north, and you can always retrace a path you have followed within the past month. This ability does work underground or underwater. Gives a +3 bonus on your Navigation and Orienteering skills."
  },
  [VantageId.AbsoluteTiming]: {
    id: VantageId.AbsoluteTiming,
    type: CharacteristicType.Vantage,
    name: 'Absolute Timing',
    costType: VantageCostType.Single,
    cost: [5],
    skillBonii: {},
    description: "You have an accurate mental clock. You always know what time it is, down to the second, unless you have been knocked unconscious by drugs or injury. You can measure elapsed time with equal accuracy. Sleep does not interfere with this, and you can wake up at a predetermined time if you choose. Changes of time zone also have no effect."
  },
  [VantageId.DangerSense]: {
    id: VantageId.DangerSense,
    type: CharacteristicType.Vantage,
    name: 'Danger Sense',
    costType: VantageCostType.Single,
    cost: [15],
    skillBonii: {},
    description: "You can’t depend on it, but sometimes you get this prickly feeling right at the back of your neck, and you know something’s wrong . . . If you have Danger Sense, the GM rolls once against your IQ, secretly, in any situation involving an ambush, impending disaster, or similar hazard. A successful roll means you get a warning that something’s wrong. A roll of 3 or 4 means you get a little detail as to the nature of the danger."
  },
  [VantageId.Daredevil]: {
    id: VantageId.Daredevil,
    type: CharacteristicType.Vantage,
    name: 'Daredevil',
    costType: VantageCostType.Single,
    cost: [15],
    skillBonii: {},
    description: "Fortune seems to smile on you when you take risks. Any time you take an unnecessary risk (GM’s option) you get a +1 to all skill rolls. Furthermore, you may re-roll any critical failure that occurs while you are engaged in high-risk behavior. Example: If you’re fired upon from a window across the street, you don’t get this bonus if you crouch behind a wall and return fire from cover, but you do get it if you vault over the wall and charge, screaming!"
  },
  [VantageId.DiseaseResistantImmunityToDisease]: {
    id: VantageId.DiseaseResistantImmunityToDisease,
    type: CharacteristicType.Vantage,
    name: 'Disease Resistant\Immunity to Disease',
    costType: VantageCostType.StaticList,
    cost: [5, 10],
    skillBonii: {},
    description: "Your body naturally resists disease organisms. This protects you against bacterial, viral, and fungal infections, but not parasites (e.g., tapeworms). Disease Resistant: You get +8 to HT to avoid catching any disease. 5 points. Immunity to Disease: You never catch any infection or disease, even if you are forcibly injected with it! You must start with a HT of 12 or better to take Immunity to Disease, but the advantage will remain if your HT is later reduced below 12. 10 points."
  },
  [VantageId.DoubleJointed]: {
    id: VantageId.DoubleJointed,
    type: CharacteristicType.Vantage,
    name: 'Double-Jointed',
    costType: VantageCostType.Single,
    cost: [5],
    skillBonii: {},
    description: "Your body is unusually flexible. You get a +3 on any Climbing or Escape roll, or on any Mechanic roll (to reach into an engine, of course)!"
  },
  [VantageId.Empathy]: {
    id: VantageId.Empathy,
    type: CharacteristicType.Vantage,
    name: 'Empathy',
    costType: VantageCostType.Single,
    cost: [15],
    skillBonii: {},
    description: "You have a “feeling” for people. When you first meet someone, or when you are reunited after an absence, you may request the GM to roll against your IQ. He will then tell you what you “feel” about that person. (A failed roll means the GM may lie to you.) This talent, when it works, is excellent for spotting impostors and determining the true loyalties of NPCs. You can also use it to determine whether someone is lying . . . not what the truth really is, but just whether they are being honest with you."
  },
  [VantageId.Fearlessness]: {
    id: VantageId.Fearlessness,
    type: CharacteristicType.Vantage,
    name: 'Fearlessness',
    costType: VantageCostType.PerLevel,
    cost: [2],
    skillBonii: {},
    description: "You are extremely brave. Your level of Fearlessness adds to your Will when you roll Fright Checks (see p. 197) or resist the Intimidation skill (p. 189). Costs 2 points per +1 bonus to your roll."
  },
  [VantageId.FitVeryFit]: {
    id: VantageId.FitVeryFit,
    type: CharacteristicType.Vantage,
    name: 'Fit/Very Fit',
    costType: VantageCostType.StaticList,
    cost: [5, 15],
    skillBonii: {},
    description: "You are in peak condition for someone of your HT. Fit: You lose fatigue points (see p. 205) to exertion, heat, etc. at the normal rate, but recover them at twice the normal rate. You also get +1 on all HT rolls. 5 points. Very Fit: You lose fatigue half as quickly and regain it twice as quickly as normal. You also get +2 on all HT rolls. 15 points."
  },
  [VantageId.HardToKill]: {
    id: VantageId.HardToKill,
    type: CharacteristicType.Vantage,
    name: 'Hard to Kill',
    costType: VantageCostType.PerLevel,
    cost: [5],
    skillBonii: {},
    description: "You are incredibly difficult to kill. Your level of Hard to Kill adds to HT rolls to avoid death when you are wounded to -HT or worse (see p. 203). Costs 5 points per +1 bonus to your roll."
  },
  [VantageId.AcuteSenseS]: {
    id: VantageId.AcuteSenseS,
    type: CharacteristicType.Vantage,
    name: 'Acute Sense(s)',
    costType: VantageCostType.PerLevel,
    cost: [2],
    skillBonii: {},
    description: "You have better-than-average senses. Acute Hearing gives you a bonus to your IQ whenever you must roll to hear something, or when the GM rolls for you. Acute Taste/Smell gives you a bonus when rolling to notice a taste or smell. Acute Vision gives you a bonus when rolling to see something. Each acute sense is a separate advantage, and costs 2 points per +1 bonus to your roll. E.g., Acute Hearing +6 costs 12 points."
  },
  [VantageId.Alertness]: {
    id: VantageId.Alertness,
    type: CharacteristicType.Vantage,
    name: 'Alertness',
    costType: VantageCostType.PerLevel,
    cost: [5],
    skillBonii: {},
    description: "A general bonus to any Sense roll (pp. 196-197), or when the GM rolls against your IQ to see if you notice something. This advantage can be combined with Acute Senses. Costs 5 points per +1 bonus to your roll."
  },
  [VantageId.AnimalEmpathy]: {
    id: VantageId.AnimalEmpathy,
    type: CharacteristicType.Vantage,
    name: 'Animal Empathy',
    costType: VantageCostType.Single,
    cost: [5],
    skillBonii: {},
    description: "You get +2 on any reaction roll by a wild animal, and +4 from a tame one. You also get a +4 bonus on Animal Handling, Riding, Teamster, and other “animal” skill rolls. You may never kill an animal without a very good reason, and you should try to prevent others from doing so. Note that killing for food is perfectly acceptable, and in a hunting situation you will get a +3 bonus to find game."
  },
  [VantageId.CombatReflexes]: {
    id: VantageId.CombatReflexes,
    type: CharacteristicType.Vantage,
    name: 'Combat Reflexes',
    costType: VantageCostType.Single,
    cost: [15],
    skillBonii: {},
    description: "You have extraordinary reactions and are very rarely surprised for more than a moment. You get a +1 to any Active Defense in combat (see p. 199). You never “freeze up” in a surprise situation, and you get a +6 on any IQ roll to wake up or to recover from surprise or a mental “stun” (see p. 203)."
  },
  [VantageId.CommonSense]: {
    id: VantageId.CommonSense,
    type: CharacteristicType.Vantage,
    name: 'Common Sense',
    costType: VantageCostType.Single,
    cost: [10],
    skillBonii: {},
    description: "Any time you start to do something that the GM feels is stupid, he rolls against your IQ. A successful roll means he must warn you, “Hadn’t you better think about that?” This advantage allows an impulsive player to take the part of a thoughtful character."
  },
  [VantageId.HighPainThreshold]: {
    id: VantageId.HighPainThreshold,
    type: CharacteristicType.Vantage,
    name: 'High Pain Threshold',
    costType: VantageCostType.Single,
    cost: [10],
    skillBonii: {},
    description: "You are as susceptible to injury as anyone else, but you don’t feel it as much. If you are hurt in combat, you are not stunned and do not have a “shock” penalty (p. 203) on your next turn. (Exception: a head blow can still stun you.) If you are tortured physically, you are at a +3 to resist. The GM may let you roll at +3 to ignore pain in other situations."
  },
  [VantageId.LanguageTalent]: {
    id: VantageId.LanguageTalent,
    type: CharacteristicType.Vantage,
    name: 'Language Talent',
    costType: VantageCostType.PerLevel,
    cost: [2],
    skillBonii: {},
    description: "You pick up languages quickly. Whenever you learn any Language skill (see p. 191), add your level of Language Talent to IQ."
  },
  [VantageId.LessSleep]: {
    id: VantageId.LessSleep,
    type: CharacteristicType.Vantage,
    name: 'Less Sleep',
    costType: VantageCostType.PerLevel,
    cost: [3],
    skillBonii: {},
    description: "You can stay awake and alert for longer than most people. For you, a full night’s sleep is 8 hours minus your level of this advantage, to a maximum of five levels (3 hours’ sleep). A half-night’s sleep is half this amount. This helps you avoid the fatigue penalty for missed sleep; see Fatigue (p. 205)."
  },
  [VantageId.RapidVeryRapidHealing]: {
    id: VantageId.RapidVeryRapidHealing,
    type: CharacteristicType.Vantage,
    name: 'Rapid/Very Rapid Healing',
    costType: VantageCostType.StaticList,
    cost: [5, 15],
    skillBonii: {},
    description: "You recover rapidly from all kinds of wounds. These advantages are only available if your basic HT is 10 or above. Rapid Healing: Whenever you roll to recover lost HT (p. 204), or when you roll to see if you can get over a crippling injury (p. 203), you get +5 to your effective HT. This ability does not help you get over stunning or similar incapacities. 5 points. Very Rapid Healing: As above, but when recovering lost HT, a successful HT roll heals two hits, not one. 15 points."
  },
  [VantageId.ResistantToPoison]: {
    id: VantageId.ResistantToPoison,
    type: CharacteristicType.Vantage,
    name: 'Resistant to Poison',
    costType: VantageCostType.Single,
    cost: [5],
    skillBonii: {},
    description: "Poison affects you less. You get +3 to HT to resist its effects."
  },
  [VantageId.SingleMinded]: {
    id: VantageId.SingleMinded,
    type: CharacteristicType.Vantage,
    name: 'Single-Minded',
    costType: VantageCostType.Single,
    cost: [5],
    skillBonii: {},
    description: "When you put your mind to something, you concentrate! You get a +3 bonus when working on lengthy tasks, but you may ignore other important tasks while obsessed (make a Will roll to avoid this). Roll at -5 to notice interruptions."
  },
  [VantageId.StrongWill]: {
    id: VantageId.StrongWill,
    type: CharacteristicType.Vantage,
    name: 'Strong Will',
    costType: VantageCostType.PerLevel,
    cost: [4],
    skillBonii: {},
    description: "You have much more “willpower” than the average person. Your level of Strong Will is added to your IQ when you make a Will roll (p. 197) for any reason, including any attempt to resist brainwashing, distraction, intimidation, seduction, or torture. However, Strong Will does not help against combat shock and the like. In questionable cases, the GM’s ruling is law. Costs 4 points per +1 bonus."
  },
  [VantageId.Toughness]: {
    id: VantageId.Toughness,
    type: CharacteristicType.Vantage,
    name: 'Toughness',
    costType: VantageCostType.StaticList,
    cost: [10, 25],
    skillBonii: {},
    description: "Your skin and flesh are tougher than the average human’s. Your body itself has a “Damage Resistance” (DR) score (see p. 200). This DR is treated just like the DR from armor: you subtract it from the damage done by any attack before you multiply the damage done by a cutting or impaling weapon. Toughness does not let your skin “turn” weapons. They still break the skin, even draw blood, but you’re not hurt. If a poisoned weapon breaks your skin, the poison will do normal damage. Costs 10 points for DR 1, or 25 points for DR 2. DR 3+ is not possible."
  },
  [VantageId.Literacy]: {
    id: VantageId.Literacy,
    type: CharacteristicType.Vantage,
    name: 'Literacy',
    costType: VantageCostType.Single,
    cost: [0],
    skillBonii: {},
    description: "You can read and write any language you know (see Languages, p. 191), limited only by your skill in that language. This is the norm on 20th-century Earth, and has no point cost. Illiteracy is a disadvantage, however; see p. 186."
  },
  [VantageId.Luck]: {
    id: VantageId.Luck,
    type: CharacteristicType.Vantage,
    name: 'Luck',
    costType: VantageCostType.StaticList,
    cost: [5, 30, 60],
    skillBonii: {},
    description: "This allows creation of the kind of hero who defies the odds. Luck: Once per hour of play, you may re-roll a single bad die roll twice (this must be the last roll you made) and take the best of the three rolls! If the GM is rolling (e.g., to see if you notice something), you may tell him you are using your Luck, and he must roll three times and give you the best result. 15 points. Extraordinary Luck: Works the same way, but it is usable every 30 minutes instead of every hour. 30 points. Ridiculous Luck: Usable every 10 minutes! 60 points. Your Luck only applies on rolls for your character to try to do something, OR on outside events that affect you or your whole party, OR when you are being attacked (in which case you may make the attacker roll three times and take the worst roll!). Luck cannot be shared. If Strong Sam is trying to kick open a door, Lucky Lou can’t stand behind him and transfer his Luck. He’ll have to kick that door himself. Once you use your Luck, you must wait an hour of real time (30 minutes for Extraordinary Luck, 10 minutes for Ridiculous Luck) before using it again. You cannot use Luck at 11:58 and then again at 12:01. And Luck cannot be saved up. You cannot play for hours without using Luck and then use it several times in a row!"
  },
  [VantageId.MusicalAbility]: {
    id: VantageId.MusicalAbility,
    type: CharacteristicType.Vantage,
    name: 'Musical Ability',
    costType: VantageCostType.Single,
    cost: [1],
    skillBonii: {},
    description: "You have a natural talent with music and musical instruments. You get a permanent +1 to all Musical Instrument skills per level of Musical Ability. Costs 1 point for each +1 bonus."
  },
  [VantageId.NightVision]: {
    id: VantageId.NightVision,
    type: CharacteristicType.Vantage,
    name: 'Night Vision',
    costType: VantageCostType.Single,
    cost: [10],
    skillBonii: {},
    description: "Your eyes adapt rapidly to the darkness. You cannot see in total darkness – but if you have any light at all, you can see fairly well. Whenever the GM exacts a penalty because of darkness, except for total darkness, this penalty does not apply to you."
  },
  [VantageId.AddictionTobacco]: {
    id: VantageId.AddictionTobacco,
    type: CharacteristicType.Vantage,
    name: 'Addiction (Tobacco)',
    costType: VantageCostType.Single,
    cost: [-5],
    skillBonii: {},
    description: "If you go without tobacco for a full day, it has a negative effect on your mood. Every morning afterward, make a HT roll at -5, modified by Strong/Weak Will. If you succeed, you can function normally. If you fail, you are anxious, irritable, and restless for the day. You have -1 on tasks that require concentration (GM’s decision), and suffer a -1 on reaction rolls made by those who have to tolerate your grumbling. If you manage to get a smoke, these penalties disappear and you no longer have to make a daily HT roll – until the next time you are deprived of tobacco for a day."
  },
  [VantageId.BadSight]: {
    id: VantageId.BadSight,
    type: CharacteristicType.Vantage,
    name: 'Bad Sight',
    costType: VantageCostType.StaticList,
    cost: [-10, -25],
    skillBonii: {},
    description: "You may be either nearsighted or farsighted – your choice. If you are nearsighted, you cannot read small print more than a foot away, or signs at more than about 10 yards. When using a weapon, you are at -2 to your skill rolls. If you are farsighted, you cannot read a book except with great difficulty (triple the normal time), and you are at -3 DX on any close manual labor. Glasses will compensate totally for Bad Sight while they are worn; however, they can be lost or damaged while adventuring! For characters who start play with glasses, Bad Sight is worth only -10 points. For characters who must do without (due to shortages, lack of skilled optometrists, etc.), Bad Sight is worth -25 points."
  },
  [VantageId.BadTemper]: {
    id: VantageId.BadTemper,
    type: CharacteristicType.Vantage,
    name: 'Bad Temper',
    costType: VantageCostType.Single,
    cost: [-10],
    skillBonii: {},
    description: "In any stressful situation, you must make a Will roll. A failed roll means you lose your temper, and must insult, attack, or otherwise act against the cause of the stress."
  },
  [VantageId.Bloodlust]: {
    id: VantageId.Bloodlust,
    type: CharacteristicType.Vantage,
    name: 'Bloodlust',
    costType: VantageCostType.Single,
    cost: [-10],
    skillBonii: {},
    description: "You want to see your foes dead. This only applies to “legitimate” enemies, such as enemy troops. You must make a Will roll to accept a surrender, avoid a sentry instead of attacking him, take a prisoner, etc. If you fail, you attempt to kill your foe – even if that means compromising stealth, using up scarce ammunition, or violating orders. You will never forget that a foe is a foe."
  },
  [VantageId.Bully]: {
    id: VantageId.Bully,
    type: CharacteristicType.Vantage,
    name: 'Bully',
    costType: VantageCostType.Single,
    cost: [-10],
    skillBonii: {},
    description: "You like to push people around when you can get away with it. Depending on your personality and position, this may include physical attacks, intellectual harassment, or social “cutting.” Make a Will roll to avoid gross bullying when you know you shouldn’t – but to roleplay your character properly, you should bully anybody you can. Nobody likes a bully – others react to you at a -2."
  },
  [VantageId.Callous]: {
    id: VantageId.Callous,
    type: CharacteristicType.Vantage,
    name: 'Callous',
    costType: VantageCostType.Single,
    cost: [-6],
    skillBonii: {},
    description: "You are not necessarily cruel, but you care little about the pain of others. You ignore lost, crying children and push aside beggars. You get -2 on all rolls made for social interaction. This includes Diplomacy, Fast-Talk, Leadership, and Sex Appeal skills, and any other roll the GM feels would be affected."
  },
  [VantageId.Chummy]: {
    id: VantageId.Chummy,
    type: CharacteristicType.Vantage,
    name: 'Chummy',
    costType: VantageCostType.Single,
    cost: [-5],
    skillBonii: {},
    description: "You work well with others and seek out company. When you are alone, you are unhappy and distracted, and suffer a -1 penalty to your mental skills. NPCs with this disadvantage will always react to the PCs at +2."
  },
  [VantageId.CodeOfHonor]: {
    id: VantageId.CodeOfHonor,
    type: CharacteristicType.Vantage,
    name: 'Code of Honor',
    costType: VantageCostType.StaticList,
    cost: [-5, -10, -15],
    skillBonii: {},
    description: "You take pride in a set of principles which you follow at all times. Codes of Honor differ, but all require (by their own standards) “brave” and “honorable” behavior. A Code of Honor may also be called “pride,” “machismo,” or “face.” Under any name, it is the willingness to risk death rather than be thought dishonorable . . . whatever that means. Only one who truly follows the Code may get points for it as a disadvantage. A Code of Honor is a disadvantage because it will often require dangerous (if not reckless) behavior. Furthermore, an honorable person can often be forced into unfair situations, because his foes know he is honorable. The point value of a specific Code varies, depending on just how much trouble it gets its followers into, and how arbitrary and irrational its requirements are. As a general rule, an informal Code that applies only among one’s peers is worth -5 points; a formal Code that applies only among peers, or an informal one that applies all the time, is worth -10 points; and a formal Code of conduct that applies all the time, or which requires suicide if broken, is worth -15 points. The GM has the final word! See p. 64 for generic military Codes of Honor."
  },
  [VantageId.CompulsiveBehavior]: {
    id: VantageId.CompulsiveBehavior,
    type: CharacteristicType.Vantage,
    name: 'Compulsive Behavior',
    costType: VantageCostType.Single,
    cost: [-15],
    skillBonii: {},
    description: "You have a habit (usually a vice) that you feel compelled to indulge on a daily basis. You waste a good deal of your time indulging your habit. Examples of Compulsive Behavior include gambling, attraction to another person, arguing, or even fighting. In general, a Will roll is required if the player wants his character to avoid the compulsion in a specific instance (or for a specific day). Note that it is very bad roleplaying to attempt to avoid the compulsion often! The point value of the disadvantage depends on what the behavior is, how much money it costs, and how much trouble it is likely to get the PC into. The GM is the final judge."
  },
  [VantageId.Cowardice]: {
    id: VantageId.Cowardice,
    type: CharacteristicType.Vantage,
    name: 'Cowardice',
    costType: VantageCostType.Single,
    cost: [-10],
    skillBonii: {},
    description: "You are extremely careful about your physical well-being. Any time you are called on to risk physical danger, you must roll against Will. If there is a risk of death, the roll is at a -5. If you fail the roll, you must refuse to endanger yourself – unless you are threatened with greater danger! Soldiers, police, and the like will react to you at -2 once they know you are a coward."
  },
  [VantageId.Fanaticism]: {
    id: VantageId.Fanaticism,
    type: CharacteristicType.Vantage,
    name: 'Fanaticism',
    costType: VantageCostType.Single,
    cost: [-15],
    skillBonii: {},
    description: "You believe so strongly in a country, organization, political philosophy, or religion that you put it ahead of everything else. You might even be willing to die for it! If the object of your fanaticism demands obedience to a certain code of behavior or loyalty to a leader, you will give this willingly and unquestioningly. You must roleplay your fanaticism."
  },
  [VantageId.Greed]: {
    id: VantageId.Greed,
    type: CharacteristicType.Vantage,
    name: 'Greed',
    costType: VantageCostType.Single,
    cost: [-15],
    skillBonii: {},
    description: "You lust for wealth. Any time riches are offered – as payment for fair work, gains from adventure, spoils of crime, or just bait –you must make a Will roll to avoid temptation. The GM may modify this roll if the money involved is small relative to your own wealth. Small amounts of money will not tempt a rich character (much), but a poor character will have to roll at -5 or even more if a rich prize is in the offing. Honest characters (see below) roll at +5 to resist a shady deal and +10 to resist outright crime, but almost any greedy character will eventually do something that is illegal."
  },
  [VantageId.Gullibility]: {
    id: VantageId.Gullibility,
    type: CharacteristicType.Vantage,
    name: 'Gullibility',
    costType: VantageCostType.Single,
    cost: [-10],
    skillBonii: {},
    description: "You believe everything you hear. In order not to believe a lie – or an improbable truth – you must roll against IQ. The GM should modify this roll for the plausibility of the story; an outlandish story would give no penalty, but a believable lie would give -3 if you are familiar with the subject matter, -6 if you are not. You are also at -3 on Merchant rolls, or in any situation in which your credulity might be exploited."
  },
  [VantageId.Jealousy]: {
    id: VantageId.Jealousy,
    type: CharacteristicType.Vantage,
    name: 'Jealousy',
    costType: VantageCostType.Single,
    cost: [-10],
    skillBonii: {},
    description: "You have an automatic bad reaction toward anyone who seems smarter, more attractive, or better-off than you! You will resist any plan proposed by a “rival,” and will hate it if someone else is in the limelight. If an NPC is Jealous, the GM will apply from -2 to -4 to his reaction rolls toward the victim(s) of his jealousy."
  },
  [VantageId.LameCrippledLeg]: {
    id: VantageId.LameCrippledLeg,
    type: CharacteristicType.Vantage,
    name: 'Lame: Crippled Leg',
    costType: VantageCostType.Single,
    cost: [-15],
    skillBonii: {},
    description: "You have one bad leg; your Move and Dodge (see p. 194) are reduced by 3. You suffer a -3 penalty to use any physical skill that requires walking or running. This definitely includes all hand weapon and unarmed combat skills (missile weapon ability is unimpaired)."
  },
  [VantageId.LameOneLeg]: {
    id: VantageId.LameOneLeg,
    type: CharacteristicType.Vantage,
    name: 'Lame: One Leg',
    costType: VantageCostType.Single,
    cost: [-25],
    skillBonii: {},
    description: "You have lost a leg. You have a -6 penalty on any physical skill that requires the use of your legs. You cannot run; using crutches or a peg leg, you have a maximum Move of 2. (Otherwise, you cannot walk at all.) If you have access to prosthetics, you can reduce the effect to that of a crippled leg, but you must buy off the point difference in some way."
  },
  [VantageId.LameLeglessOrParaplegic]: {
    id: VantageId.LameLeglessOrParaplegic,
    type: CharacteristicType.Vantage,
    name: 'Lame: Legless or Paraplegic',
    costType: VantageCostType.Single,
    cost: [-35],
    skillBonii: {},
    description: "You are confined to a wheelchair or wheeled platform. If you power it with your own hands, its Speed is 1/4 your ST, rounded down. The GM should assess all reasonable penalties for this handicap; e.g., you cannot pass through narrow doors, navigate staircases or steep curbs, fight effectively (except with guns), and so on. If you have to fight with a hand weapon, you will be at -6 to skill."
  },
  [VantageId.Laziness]: {
    id: VantageId.Laziness,
    type: CharacteristicType.Vantage,
    name: 'Laziness',
    costType: VantageCostType.Single,
    cost: [-10],
    skillBonii: {},
    description: "You are violently averse to physical labor. Your chances of getting a raise or promotion in any job are halved. If you are selfemployed, your weekly income is halved. You must avoid work – especially hard work – at all costs. Roleplay it!"
  },
  [VantageId.Miserliness]: {
    id: VantageId.Miserliness,
    type: CharacteristicType.Vantage,
    name: 'Miserliness',
    costType: VantageCostType.Single,
    cost: [-10],
    skillBonii: {},
    description: "Like Greed (pp. 184-185), except that you are more concerned with holding on to what you already have. You may be both greedy and miserly! You must make a Will roll any time you are called on to spend money, and you must always hunt for the best deal possible. If the expenditure is large, the Will roll may be at a -5 (or even greater) penalty. A failed roll means you will refuse to spend the money – or, if the money absolutely must be spent, you should haggle and complain interminably."
  },
  [VantageId.OneArm]: {
    id: VantageId.OneArm,
    type: CharacteristicType.Vantage,
    name: 'One Arm',
    costType: VantageCostType.Single,
    cost: [-20],
    skillBonii: {},
    description: "You have lost an arm (or were born without it). It is assumed that you lost the left arm if you are right-handed, or vice versa. You cannot use any two-handed weapon (such as a rifle) or do anything requiring two arms. Anything requiring only one hand can be done without penalty. In borderline cases, it is best to allow the character to attempt the action at a -4 DX penalty, or try a quick reality check if possible!"
  },
  [VantageId.HardOfHearing]: {
    id: VantageId.HardOfHearing,
    type: CharacteristicType.Vantage,
    name: 'Hard of Hearing',
    costType: VantageCostType.Single,
    cost: [-10],
    skillBonii: {},
    description: "You are not deaf, but you have some hearing loss. You are at -4 to IQ on any Hearing roll (so your roll is IQ-4, rather than IQ). You are at -4 to your Language skill roll (p. 191) for any situation where you must understand someone (if you are the one talking, this disadvantage doesn’t affect you)."
  },
  [VantageId.Hidebound]: {
    id: VantageId.Hidebound,
    type: CharacteristicType.Vantage,
    name: 'Hidebound',
    costType: VantageCostType.Single,
    cost: [-5],
    skillBonii: {},
    description: "You find it very hard to come up with an original thought. You get -2 on all rolls made for tasks that require invention or creativity (GM’s decision)."
  },
  [VantageId.Honesty]: {
    id: VantageId.Honesty,
    type: CharacteristicType.Vantage,
    name: 'Honesty',
    costType: VantageCostType.Single,
    cost: [-10],
    skillBonii: {},
    description: "You must obey the law, and do your best to get others to do so as well. You are compulsive about it; this is essentially another type of Code of Honor (see p. 184). This is a disadvantage, because it will often limit your options! Faced with unreasonable laws, you must roll against IQ to see the “need” to break them, and against Will to avoid turning yourself in afterward! If you ever behave dishonestly, the GM may penalize you for bad roleplaying. You are allowed to lie if it does not involve breaking the law. Truthfulness (p. 186) is a separate disadvantage."
  },
  [VantageId.Impulsiveness]: {
    id: VantageId.Impulsiveness,
    type: CharacteristicType.Vantage,
    name: 'Impulsiveness',
    costType: VantageCostType.Single,
    cost: [-10],
    skillBonii: {},
    description: "You hate talk and debate. You prefer action! When you are alone, you will act first and think later. In a group, when your friends want to stop and discuss something, you should put in your two cents’ worth quickly – if at all – and then do something. Roleplay it! If it is absolutely necessary to wait and ponder, you must make a Will roll to do so."
  },
  [VantageId.Intolerance]: {
    id: VantageId.Intolerance,
    type: CharacteristicType.Vantage,
    name: 'Intolerance',
    costType: VantageCostType.StaticList,
    cost: [-5, -10],
    skillBonii: {},
    description: "You dislike people who are different from you, reacting to them at -3. On a “good” or better reaction, you will force yourself to tolerate them, coldly but civilly. On a “neutral” reaction, you will tolerate their presence, but you will be openly disdainful. On a worse reaction, you will walk out on them, drive them off, attack them, or something similar. Members of the disliked group will sense your intolerance and return the favor, reacting to you at -1 to -5. Point value depends on the group affected. Intolerance of anyone not of your ethnic background is worth -10 points, as is intolerance of anyone who does not share your religion. Intolerance of one particular ethnic or religious group you routinely encounter is worth -5 points. Intolerance of a group you will rarely encounter is treated as a -1-point quirk (see p. 187)."
  },
  [VantageId.OneEye]: {
    id: VantageId.OneEye,
    type: CharacteristicType.Vantage,
    name: 'One Eye',
    costType: VantageCostType.Single,
    cost: [-15],
    skillBonii: {},
    description: "You have only one good eye; you may wear a glass eye, or cover the missing eye with a patch. You suffer a -1 DX penalty on combat and anything involving hand-eye coordination, and a -3 to use ranged weapons or to drive any vehicle faster than a horse and buggy. You will also suffer a -1 on any reaction roll. Exception: If you have Charisma, or are Handsome or Very Handsome, the patch just looks romantic, and does not affect reaction rolls.185"
  },
  [VantageId.Overconfidence]: {
    id: VantageId.Overconfidence,
    type: CharacteristicType.Vantage,
    name: 'Overconfidence',
    costType: VantageCostType.Single,
    cost: [-10],
    skillBonii: {},
    description: "You think you are far more powerful, intelligent, and competent than you really are, and you should act that way. Any time (in the GM’s opinion) you show too much caution, you must roll against IQ. A failed roll means you can’t be cautious . . . you must go ahead as though able to handle the situation. An overconfident character will get +2 on all reaction rolls from the young or naive (they believe he’s as good as he says he is), but -2 from experienced NPCs. This requires roleplaying. An overconfident person may be proud and boastful, or just quietly determined – but play it up!"
  },
  [VantageId.Pacifism]: {
    id: VantageId.Pacifism,
    type: CharacteristicType.Vantage,
    name: 'Pacifism',
    costType: VantageCostType.Single,
    cost: [-15],
    skillBonii: {},
    description: "You are opposed to violence. There are two different forms: Self-Defense Only: You will only fight to defend yourself or those in your care, using only as much force as may be necessary (no pre-emptive strikes allowed!). You must do your best to discourage others from starting fights. -15 points. Cannot Kill: You may fight freely, and even start a fight, but you may never do anything that seems likely to kill. This includes abandoning a wounded foe to die. You must do your best to keep your companions from killing, too. If you kill someone (or feel responsible for a death), you suffer a nervous breakdown. Roll 3 dice and be totally morose and useless (roleplay it!) for that many days. During this time, you must make a Will roll to offer any sort of violence toward anyone, for any reason. -15 points."
  },
  [VantageId.Phobias]: {
    id: VantageId.Phobias,
    type: CharacteristicType.Vantage,
    name: 'Phobias',
    costType: VantageCostType.Single,
    cost: [0],
    skillBonii: {},
    description: "A “phobia” is a fear of a specific item or circumstance. Many fears are reasonable, but a phobia is an unreasonable, unreasoning, morbid fear. The more common an object or situation, the greater the point value of a fear of it. If you have a phobia, you may temporarily master it by making a successful Fright Check (see p. 197), but the fear persists. Even if you master a phobia, you will be at -2 IQ and -2 DX while the cause is present, and you must roll again every 10 minutes to see if the fear overcomes you. If you fail the Fright Check to overcome a phobia, you will react badly, rolling on the table on p. 197. If you are threatened with the feared object, you must immediately roll a Fright Check at +4; if enemies actually inflict the feared object on you, you must roll an unmodified Fright Check. If the roll is failed, you break down, but you do not necessarily talk. Some common phobias: blood (hemophobia; -10 points); darkness (scotophobia; -15 points); dead bodies (necrophobia; -10 points); enclosed spaces (claustrophobia; -15 points); fire (pyrophobia; -5 points); heights (acrophobia; -10 points); loud noises (brontophobia; -10 points); open spaces (agoraphobia; -10 points); strange and unknown things (xenophobia; -15 points); weapons (hoplophobia; -20 points)."
  },
  [VantageId.PostCombatShakes]: {
    id: VantageId.PostCombatShakes,
    type: CharacteristicType.Vantage,
    name: 'Post-Combat Shakes',
    costType: VantageCostType.Single,
    cost: [-5],
    skillBonii: {},
    description: "You are shaken and sickened by combat, but only after it’s over. As soon as all your enemies are dead or incapacitated, you must make a Fright Check (see p. 197), and you must roleplay the results. The GM may put penalties on your Fright Check roll if the combat was particularly dangerous or gruesome."
  },
  [VantageId.Primitive]: {
    id: VantageId.Primitive,
    type: CharacteristicType.Vantage,
    name: 'Primitive',
    costType: VantageCostType.PerLevel,
    cost: [-5],
    skillBonii: {},
    description: "You are from a culture with a lower Tech Level (see p. 205) than that of the campaign. You have no knowledge (or default skill) relating to equipment above your own TL. You can start only with skills or equipment from your own culture. You may not acquire Mental skills relating to high-tech equipment until you buy off this disadvantage. Physical skills (driving, weaponry, etc.) may be acquired at no penalty if you find a teacher. The value of this disadvantage is -5 points for each TL by which your native TL is less than that of the campaign."
  },
  [VantageId.Secret]: {
    id: VantageId.Secret,
    type: CharacteristicType.Vantage,
    name: 'Secret',
    costType: VantageCostType.StaticList,
    cost: [-5, -10, -20, -30],
    skillBonii: {},
    description: "A Secret is an aspect of your life that you must keep hidden. The GM rolls three dice before every adventure. On a 6 or less, your Secret appears. It is not automatically made public; you will have the chance to keep the Secret from being revealed, although this might mean caving in to blackmail, stealing the incriminating evidence, silencing someone who knows the Secret, etc. If you succeed, you get to keep your Secret. The solution, however, is only temporary; the Secret will appear again and again until you finally buy it off. If you fail, your Secret is made public. Immediately replace the Secret disadvantage with new disadvantages worth twice as many points as the Secret itself! The disadvantages acquired must be appropriate to the Secret, and should be determined with the GM’s assistance. Secrets usually turn into Enemies, bad Reputations, or Social Stigmas, or reduce your Status or Wealth (going from Filthy Rich to Very Wealthy is effectively a -20point disadvantage). The point value of a Secret depends on the consequences should it be revealed: serious embarrassment (-5 points), utter rejection by society (-10 points), imprisonment or exile (-20 points), or possible death (-30 points)."
  },
  [VantageId.SemiLiteracyIlliteracy]: {
    id: VantageId.SemiLiteracyIlliteracy,
    type: CharacteristicType.Vantage,
    name: 'Semi-Literacy/Illiteracy',
    costType: VantageCostType.StaticList,
    cost: [-5, -10],
    skillBonii: {},
    description: "Semi-Literacy: You can understand simple, everyday words, and may read and write slowly, but complex words, written poetry, and so on are beyond you. -5 points. Illiteracy: You cannot read or write at all. -10 points."
  },
  [VantageId.SenseOfDuty]: {
    id: VantageId.SenseOfDuty,
    type: CharacteristicType.Vantage,
    name: 'Sense of Duty',
    costType: VantageCostType.StaticList,
    cost: [-5, -10, -15, -20],
    skillBonii: {},
    description: "You suffer from a self-imposed feeling of duty. If you feel a sense of duty toward someone, you will never betray him, abandon him when he’s in trouble, or even let him suffer or go hungry if you can help. If you are known to have a sense of duty, others tend to trust you in a dangerous situation (+2 on reaction rolls in such circumstances). If you have a sense of duty, and go against it by acting against the interests of those you are supposed to feel duty toward, the GM will penalize you for bad roleplaying. The player defines the sense of duty’s group and the GM sets its point value. Examples: only toward close friends and companions (-5 points), toward a nation or other large group (-10 points), toward everyone you know personally (-10 points), toward all humanity (-15 points), toward every living being (-20 points)."
  },
  [VantageId.Stubbornness]: {
    id: VantageId.Stubbornness,
    type: CharacteristicType.Vantage,
    name: 'Stubbornness',
    costType: VantageCostType.Single,
    cost: [-5],
    skillBonii: {},
    description: "You always want your own way. Make yourself generally hard to get along with – roleplay it! Your friends may have to make a lot of Fast-Talk rolls to get you to go along with perfectly reasonable plans. Others react to you at -1."
  },
  [VantageId.Truthfulness]: {
    id: VantageId.Truthfulness,
    type: CharacteristicType.Vantage,
    name: 'Truthfulness',
    costType: VantageCostType.Single,
    cost: [-5],
    skillBonii: {},
    description: "You hate to tell a lie – or you’re just bad at it. To keep silent about an uncomfortable truth (lying by omission), you must make a Will roll. To actually tell a falsehood, you must make a Will roll at a -5 penalty! A failed roll means you blurt out the truth, or stumble so much that your lie is obvious."
  },
  [VantageId.Vow]: {
    id: VantageId.Vow,
    type: CharacteristicType.Vantage,
    name: 'Vow -1/',
    costType: VantageCostType.StaticList,
    cost: [-5, -10, -15],
    skillBonii: {},
    description: "You have sworn an oath to do (or not do) something. You take this oath seriously. If you didn’t, it would not be a disadvantage. The precise value of a Vow is up to the GM, but should be directly related to the inconvenience it causes the character. A Trivial Vow, such as “Never drink alcohol,” is a -1-point quirk (see below). A Minor Vow, such as vegetarianism, is worth -5 points. A Major Vow, such as, “Use no firearms,” is worth -10 points. A Great Vow, such as “Never refuse any request for aid,” is worth -15 points."
  },
  [VantageId.WeakWill]: {
    id: VantageId.WeakWill,
    type: CharacteristicType.Vantage,
    name: 'Weak Will',
    costType: VantageCostType.PerLevel,
    cost: [-8],
    skillBonii: {},
    description: "You are easily persuaded, frightened, bullied, coerced, tempted, etc. For every level taken, you have -1 to IQ whenever you make a Will roll (p. 197). This includes attempts to resist distraction, seduction, torture, etc. Weak Will also affects Fright Checks, and all attempts to avoid giving in to mental problems. A character cannot have both Strong Will and Weak Will."
  },
  [VantageId.Workaholic]: {
    id: VantageId.Workaholic,
    type: CharacteristicType.Vantage,
    name: 'Workaholic',
    costType: VantageCostType.Single,
    cost: [-5],
    skillBonii: {},
    description: "You tend to drive yourself past your limits. You will always work at least half again as long as a normal working day. This often results in missed sleep (see Fatigue, p. 205). Most people regard you with respect at first (+1 to reaction rolls), but you will eventually meet with a -1 or -2 reaction, especially from friends and loved ones who rarely get to spend time with you."
  },
  [VantageId.Youth]: {
    id: VantageId.Youth,
    type: CharacteristicType.Vantage,
    name: 'Youth',
    costType: VantageCostType.Single,
    cost: [-6],
    skillBonii: {},
    description: "You are 1 to 3 years “legally underage,” at -2 points per year. You suffer a -2 reaction roll whenever you try to deal with others as an adult; they may like you, but they do not fully respect you. You may also be barred from buying alcohol, joining the army, voting, etc. You must keep track of time, and “buy off” this disadvantage when you reach “legal age” (usually 18)."
  },
};

export const isDisadvantage = (id: VantageId): boolean => {
  let def = vantageDefinitions[id];
  return def.cost.every(cost => cost < 0);
};
