import {SagaIterator} from "redux-saga";
import {log} from "../lib/logger";
import {call, delay, put, select, takeLatest} from "redux-saga/effects";
import {
    DeleteCharacterSagaAction,
    deleteFile,
    ExportCharacterSagaAction,
    ImportCharacterSagaAction,
    load,
    LoadCharacterSagaAction,
    SagaDataActions,
    save,
    SaveAsCharacterSagaAction,
    SaveCharacterSagaAction,
    updateAutosaves
} from "../modules/data";
import {AppState} from "../modules";
import {
    buildCharacterFile,
    getCharacterForSaveId,
    getNextAutosaveIndex,
    isCurrentCharacterDirty
} from "../lib/gameLogic/dataLogic";
import {
    confirmSaga,
    InputResult,
    inputSaga,
    outputSaga,
    UserCloseAction,
    YesNoResult,
    yesNoSaga
} from "../modules/modal";
import {waitForClose} from "./modalSagas";
import {AUTOSAVE_INTERVAL_MILLISECONDS, DataFileType, SaveId, UUIDv1} from "../lib/constants/dataConstants";
import {
    deleteLocalStorageAutosaves,
    deleteLocalStorageFile,
    getLocalStorageFile,
    writeCurrentCharacterId,
    writeLocalStorageAutosaves,
    writeLocalStorageFile
} from "../lib/localStorage/localStorageIo";
import {loadCharacter} from "../modules/character";
import {arrayReplaceToNew} from "../lib/utils";
import v1 from "uuid/v1";
import {Character, validateCharacterJson} from "../lib/constants/characterConstants";

export function* dataSagas(): SagaIterator {
    log(`Launching data sagas`);
    yield takeLatest(SagaDataActions.Load, loadSaga);
    yield takeLatest(SagaDataActions.Save, saveSaga);
    yield takeLatest(SagaDataActions.SaveAs, saveAsSaga);
    yield takeLatest(SagaDataActions.Import, importSaga);
    yield takeLatest(SagaDataActions.Export, exportSaga);
    yield takeLatest(SagaDataActions.Delete, deleteSaga);
    yield call(autosaveSaga);
}

function* autosaveSaga(): SagaIterator {
    while (true) {
        yield delay(AUTOSAVE_INTERVAL_MILLISECONDS);
        // Don't autosave if there are no manual saves for the current character
        let state = (yield select()) as AppState;
        let characterFiles = state.dataReducer.characterFiles;
        if (characterFiles.findIndex((file => file.id === state.dataReducer.currentCharacterId)) < 0) {
            log(`Current character has no manual save, skipping autosave.`);
        } else {
            log(`Autosaving...`);
            let id = state.dataReducer.currentCharacterId;
            let autosaves = state.dataReducer.characterAutosaves[id] || [];
            let autosaveIndex = getNextAutosaveIndex(autosaves);

            let newFile = buildCharacterFile(id, state.characterReducer.character);
            autosaves = arrayReplaceToNew(autosaves, autosaveIndex, newFile);
            yield put(updateAutosaves(id, autosaves, DataFileType.Character));
            writeLocalStorageAutosaves(id, DataFileType.Character, autosaves);
        }
    }
}

function* loadSaga(action: LoadCharacterSagaAction): SagaIterator {
    log(`Load saga`);
    let state = (yield select()) as AppState;
    let idToLoad = action.id.id;
    let currentCharacterId = state.dataReducer.currentCharacterId;
    let fileToLoad = getCharacterForSaveId(action.id);
    if (!fileToLoad) {
        throw new Error(`Failed to find save with id ${action.id.id} and timestamp ${action.id.autosaveTimestamp}. Aborting load.`)
    }

    // Check if current character is unsaved
    let files = state.dataReducer.characterFiles;
    let fileIndex = files.findIndex(file => file.id === currentCharacterId);
    let isDirty = isCurrentCharacterDirty(
        state.characterReducer.character,
        fileIndex >= 0 ? files[fileIndex] : null,
        state.dataReducer.characterAutosaves[currentCharacterId] || [],
    );

    // If so, display confirm dialog
    let confirmed = true;
    if (isDirty) {
        log(`Current character dirty, prompting for overwrite`);
        yield put(yesNoSaga(`Unsaved Character`, `Your current character is unsaved. Are you sure you want ` +
            `to overwrite your current character?`));
        let closeAction = (yield* waitForClose()) as UserCloseAction;
        let result = closeAction.result as YesNoResult;
        confirmed = result.confirmed && !result.no && !result.cancelled;
    } else {
        log(`Current character not dirty, proceeding with load`);
    }

    // If not, just load without prompting
    if (confirmed) {
        log(`Load overwrite modal confirmed, proceeding with load`);
        yield* loadHelper(fileToLoad.content, action.id, 'load');
    } else {
        log(`Load overwrite modal not confirmed, cancelling load`);
    }
}

function* saveSaga(action: SaveCharacterSagaAction): SagaIterator {
    log(`Save saga`);
    // Do the save
    let state = (yield select()) as AppState;
    let id = action.id;
    let character = state.characterReducer.character;
    log(`Saving character ${character.name} with id of ${id}`);

    yield* saveHelper(character, id, `save`);
}

function* saveAsSaga(action: SaveAsCharacterSagaAction): SagaIterator {
    let state = (yield select()) as AppState;
    // Prompt for new name
    yield put(inputSaga(`Save As New`, `Enter the new character's name`, 'Save'));
    let closeAction = (yield* waitForClose()) as UserCloseAction;
    let result = closeAction.result as InputResult;
    if (result.cancelled) {
        log(`Save as cancelled`);
        return;
    }
    let newName = result.input;

    let id = v1();
    let newCharacter: Character = {
        ...state.characterReducer.character,
        name: newName,
    };
    yield* saveHelper(newCharacter, id, `save as new`);
    yield* loadHelper(newCharacter, {
        id,
        autosaveTimestamp: null,
    }, 'load');
}

function* importSaga(action: ImportCharacterSagaAction): SagaIterator {
    const errorTitle = `Invalid Import`;
    const errorMessage = `Your JSON input was not a valid Character object.` +
        ` Try exporting an existing character and inspecting the output for the right format.`;

    // Prompt for input JSON
    yield put(inputSaga(`Import`, `Enter the character's JSON`, 'Import'));
    let closeAction = (yield* waitForClose()) as UserCloseAction;
    let result = closeAction.result as InputResult;
    if (result.cancelled) {
        log(`Import cancelled`);
        return;
    }
    let inputJson: Object;
    try {
        inputJson = JSON.parse(result.input);
    } catch (e) {
        yield* errorPrompt(errorTitle, errorMessage, 'import');
        return;
    }
    if (!inputJson || !validateCharacterJson(inputJson)) {
        yield* errorPrompt(errorTitle, errorMessage, 'import');
        return;
    }

    let id = v1();
    yield* saveHelper(inputJson, id, `import`);
}

function* errorPrompt(title: string, message: string, sagaName: string) {
    log(`Displaying error prompt for ${sagaName} saga`);
    yield put(confirmSaga(title, message));
    yield* waitForClose();
    return;
}

function* exportSaga(action: ExportCharacterSagaAction): SagaIterator {
    let fileToExport = getCharacterForSaveId(action.id);
    if (!fileToExport) {
        log(`Export failed to load target file`);
        yield put(confirmSaga(`Export Failed`, `Unable to load the selected file for export.`));
        yield* waitForClose();
        return;
    }

    // Output JSON
    yield put(outputSaga(`Export`, `Character raw JSON:`, JSON.stringify(fileToExport.content), 'Ok'));
    yield* waitForClose();
}

function* deleteSaga(action: DeleteCharacterSagaAction): SagaIterator {
    let targetCharacter = getLocalStorageFile(action.id, DataFileType.Character);
    if (!targetCharacter) {
        log(`Failed to find target delete file, updating store and completing.`);
        yield put(deleteFile(action.id));
        return;
    }

    // Prompt for confirmation
    log(`Prompting for deletion confirmation`);
    yield put(yesNoSaga(`Confirm Delete`, `Are you sure you want to delete the save and all autosaves for '${targetCharacter.content.name}'?`));
    let closeAction = (yield* waitForClose()) as UserCloseAction;
    let result = closeAction.result as YesNoResult;
    let confirmed = result.confirmed && !result.no && !result.cancelled;

    if (!confirmed) {
        log(`Deletion cancelled`);
        return;
    }

    // Delete from local storage
    deleteLocalStorageFile(action.id, DataFileType.Character);
    deleteLocalStorageAutosaves(action.id, DataFileType.Character);

    // Delete from store
    yield put(deleteFile(action.id));
}

function* loadHelper(character: Character, saveId: SaveId, sagaName: string) {
    log(`Loading character for ${sagaName} saga`);
    yield put(loadCharacter(character));
    yield put(load(saveId, DataFileType.Character));
    writeCurrentCharacterId(saveId.id);
}

function* saveHelper(character: Character, id: UUIDv1, sagaName: string) {
    log(`Saving character for ${sagaName} saga`);
    writeLocalStorageFile(id, DataFileType.Character, buildCharacterFile(id, character));
    yield put(save(id, DataFileType.Character, character));
}
