import React, {ReactNode} from 'react';
import Select from 'react-select'
import {Button, Col, Row,} from 'react-bootstrap';
import {CenteredFlexCol} from "../../../../lib/components/CenteredFlexCol";
import {Character} from "../../../../lib/constants/characterConstants";
import {ValueType} from "react-select/lib/types";
import {SkillDefinition, skillDefinitions, SkillId} from "../../../../lib/constants/skillConstants";
import {Option, validateSingleReactSelectValue} from "../../../../lib/utils";
import {CenteredRow} from '../../../../lib/components/CenteredRow';
import {
  canUseSkill,
  getModifiedCharacteristicValue,
  getSkillBestDefault,
  getSkillCurrentValue
} from "../../../../lib/gameLogic/derivedCharacterStats";
import {attributeDefinitions} from "../../../../lib/constants/attributeConstants";
import {RollProps} from "../StatusPage";
import {CombatState} from "../../../../modules/combat";
import {rollSpecForSkill} from "../../../../lib/constants/rollConstants";

interface Props extends RollProps {
  combat: CombatState;
  character: Character;
}
interface State {
  selectedSkill: Option<SkillId>;
}

const getOptionFromSkillDef = (def: SkillDefinition): Option<SkillId> => {
  return { value: def.id as SkillId, label: def.name };
};

const skillOptions = Object.values(skillDefinitions).map((def) => getOptionFromSkillDef(def));

export default class SkillPanel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    let defaultSkill = Object.values(skillDefinitions)[0];
    this.state = {
      selectedSkill: getOptionFromSkillDef(defaultSkill),
    };
  }

  skillRollClick() {
    let {
      character,
      combat,
      initRoll,
    } = this.props;

    let spec = rollSpecForSkill(this.state.selectedSkill.value, character, combat, []);
    initRoll(spec);
  }

  selectChange(v: ValueType<Option<SkillId>>) {
      let selectedSkill = validateSingleReactSelectValue(v);
      this.setState({ selectedSkill })
  }

  render() {
    let skillId = this.state.selectedSkill.value as SkillId;
    let skillDef = skillDefinitions[skillId];

    return <Col sm className='content-panel'>
      <Row className='justify-content-center'>
        <h4>Skillz</h4>
      </Row>
      <CenteredRow>
        <CenteredFlexCol>
          <Select
            className='react-select'
            value={this.state.selectedSkill}
            onChange={this.selectChange.bind(this)}
            options={skillOptions}
          />
        </CenteredFlexCol>
        <CenteredFlexCol isRow={false}>
          <h6>{this.renderSkillValue()}</h6>
        </CenteredFlexCol>
        <CenteredFlexCol isRow={false}>
          <Button variant='outline-success' onClick={this.skillRollClick.bind(this)}>Roll Against Skill</Button>
        </CenteredFlexCol>
      </CenteredRow>
      <CenteredRow>
        <p>{skillDef.description}</p>
      </CenteredRow>
      <Row>
      </Row>
    </Col>
  }

  renderSkillValue(): ReactNode {
    let {
      character,
      combat,
    } = this.props;
    let skillId = this.state.selectedSkill.value as SkillId;
    let skillDef = skillDefinitions[skillId];

    if (!canUseSkill(skillId, character)) {
      return `The character doesn't have this skill and the skill has no defaults, so it can't be used.`;
    }

    let value = getSkillCurrentValue(skillId, character, combat);
    let prefixString, abbreviation, modifier;
    let skill = character.skills[skillId];

    if (skill) {
      // Use the character's skill level
      let baseAttribute = attributeDefinitions[skillDef.baseAttributeId];
      abbreviation = baseAttribute.abbreviation;
      modifier = skill.level;
      prefixString = 'Skill level';
    } else {
      // Use a default
      let bestDefault = getSkillBestDefault(skillId, character, combat)!;
      let attribute = attributeDefinitions[bestDefault.attributeId];
      abbreviation = attribute.abbreviation;
      modifier = bestDefault.modifier;
      prefixString = 'Best default';
    }
    return `${prefixString}: ${abbreviation}${modifier > 0 ? '+' : ''}${modifier} = ${getModifiedCharacteristicValue(value)}`;
  }
}

