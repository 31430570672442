import {
  getAttributeCurrentValue,
  getBestCharacteristic,
  getEncumbranceLevel,
  getModifiedCharacteristicValue
} from "../gameLogic/derivedCharacterStats";
import {attributeDefinitions, AttributeId} from "./attributeConstants";
import {skillDefinitions, SkillId} from "./skillConstants";
import {Character} from "./characterConstants";
import {CombatState} from "../../modules/combat";
import {CharacteristicRollSpec, RollType} from "./rollConstants";

export enum FatigueType {
  Normal = 'Normal',
  Starvation = 'Starvation',
  Dehydration = 'Dehydration',
  Sleep = 'Sleep',
}

export interface FatigueEvent  {
  id: string;
  name: string;
  description: string;
  callback: fatigueEventCallback;
  rollCallback?: fatigueRollCallback;
}

export interface fatigueEventCallback { (character: Character, combatState: CombatState, eventId: string, rollSucceeded: boolean): FatigueEffect }

export interface fatigueRollCallback { (character: Character, combatState: CombatState, eventId: string): CharacteristicRollSpec }

export const rollSpecForFatigueEvent = (fatigueEvent: FatigueEvent, combatState: CombatState, character: Character): CharacteristicRollSpec | null => {
  if (!fatigueEvent.hasOwnProperty('rollCallback') || !fatigueEvent.rollCallback) {
    return null;
  }
  return fatigueEvent.rollCallback(character, combatState, fatigueEvent.id);
};

export interface FatigueEffect  {
  fatigueValue: number;
  healthValue?: number;
  fatigueType: FatigueType;
  log: string;
}

export const FATIGUE_EVENT_MARCHING = 'FATIGUE_EVENT_MARCHING';
export const FATIGUE_EVENT_MARCHING_HOT = 'FATIGUE_EVENT_MARCHING_HOT';
export const FATIGUE_EVENT_RUNNING_SWIMMING = 'FATIGUE_EVENT_RUNNING_SWIMMING';
export const FATIGUE_EVENT_OVEREXERTION = 'FATIGUE_EVENT_OVEREXERTION';
export const FATIGUE_EVENT_LOST_SLEEP_NIGHT = 'FATIGUE_EVENT_LOST_SLEEP_NIGHT';
export const FATIGUE_EVENT_LOST_SLEEP_HALF_NIGHT = 'FATIGUE_EVENT_LOST_SLEEP_HALF_NIGHT';
export const FATIGUE_EVENT_STARVATION_MISSED_MEAL = 'FATIGUE_EVENT_STARVATION_MISSED_MEAL';
export const FATIGUE_EVENT_DEHYDRATION_FULL_RATIONS = 'FATIGUE_EVENT_DEHYDRATION_FULL_RATIONS';
export const FATIGUE_EVENT_DEHYDRATION_HALF_RATIONS = 'FATIGUE_EVENT_DEHYDRATION_HALF_RATIONS';
const getWeatherEventTemplate = (name: string) => {return {
  name,
  description: 'In temperatures above 80o Fahrenheit, roll vs. the better of HT or Survival (Desert) skill, at -1 ' +
    'per 5o over 90o. A failed roll costs 1 fatigue; when ST reaches 3, begin losing hit points, instead. In ' +
    'temperatures below 32o, roll vs. the better of HT or Survival (Arctic) skill, at -1 per 5o below 0o; light ' +
    'clothing imposes up to a -5 penalty while winter clothing (see p. 87) provides a bonus.',
  callback: (character: Character, combatState: CombatState, eventId: string, rollSucceeded: boolean) => {
    let fatigueType = FatigueType.Normal;
    let eventName = name;
    if (rollSucceeded) {
      return {
        fatigueType,
        fatigueValue: 0,
        log: `${eventName}; HT or Survival roll succeeded = 0 ${fatigueType} fatigue`
      };
    } else {
      let {
        fatigueValue,
        healthValue,
        text
      } = getFatigueEffectBasedOnSt(character, combatState, fatigueType);
      return {
        fatigueType,
        fatigueValue,
        healthValue,
        log: `${eventName} = ${text}`
      };
    }
  },
}};
export const FATIGUE_EVENT_WEATHER_90_DEGREES = 'FATIGUE_EVENT_WEATHER_90_DEGREES';
export const FATIGUE_EVENT_WEATHER_100_DEGREES = 'FATIGUE_EVENT_WEATHER_100_DEGREES';
export const FATIGUE_EVENT_WEATHER_110_DEGREES = 'FATIGUE_EVENT_WEATHER_110_DEGREES';
export const FATIGUE_EVENT_WEATHER_0_DEGREES = 'FATIGUE_EVENT_WEATHER_0_DEGREES';
export const FATIGUE_EVENT_WEATHER_NEGATIVE_10_DEGREES = 'FATIGUE_EVENT_WEATHER_NEGATIVE_10_DEGREES';
export const FATIGUE_EVENT_WEATHER_NEGATIVE_20_DEGREES = 'FATIGUE_EVENT_WEATHER_NEGATIVE_20_DEGREES';

export const FATIGUE_EVENT_10_MINUTES_REST = 'FATIGUE_EVENT_10_MINUTES_REST';
export const FATIGUE_EVENT_1_HOUR_REST = 'FATIGUE_EVENT_1_HOUR_REST';
export const FATIGUE_EVENT_FULL_NIGHT_SLEEP = 'FATIGUE_EVENT_FULL_NIGHT_SLEEP';
export const FATIGUE_EVENT_REST_DAY_FOOD = 'FATIGUE_EVENT_REST_DAY_FOOD';
export const FATIGUE_EVENT_REST_DAY_WATER = 'FATIGUE_EVENT_REST_DAY_WATER';

export const getFatigueEffectBasedOnSt = (character: Character, combatState: CombatState, fatigueType: string) => {
  let fatigueValue = 0;
  let healthValue = 0;
  let text: string;
  if (getModifiedCharacteristicValue(getAttributeCurrentValue(AttributeId.Strength, character, combatState)) <= 3) {
    healthValue = 1;
    text = '1 hit point';
  } else {
    fatigueValue = 1;
    text = `1 ${fatigueType} fatigue`;
  }
  return {
    fatigueValue,
    healthValue,
    text,
  }
};

const tiringEventsArray: Array<FatigueEvent> = [
  {
    id: FATIGUE_EVENT_MARCHING,
    name: 'Marching',
    description: 'Each hour of road travel costs fatigue equal to your encumbrance level +1. Add 1 more in hot climates.',
    callback: (character, combatState: CombatState) => {
      let encumbrance = getEncumbranceLevel(character, combatState);
      let fatigueValue = encumbrance.movementPenalty + 1;
      let fatigueType = FatigueType.Normal;
      return {
        fatigueType,
        fatigueValue,
        log: `One hour marching with encumbrance ${encumbrance} = ${fatigueValue} ${fatigueType} fatigue`
      };
    },
  },
  {
    id: FATIGUE_EVENT_MARCHING_HOT,
    name: 'Marching (hot climate)',
    description: 'Each hour of road travel costs fatigue equal to your encumbrance level +1. Add 1 more in hot climates.',
    callback: (character, combatState: CombatState) => {
      let encumbrance = getEncumbranceLevel(character, combatState);
      let fatigueValue = encumbrance.movementPenalty + 2;
      let fatigueType = FatigueType.Normal;
      return {
        fatigueType,
        fatigueValue,
        log: `One hour marching in a hot climate with encumbrance ${encumbrance} = ${fatigueValue} ${fatigueType} fatigue`
      };
    },
  },
  {
    id: FATIGUE_EVENT_RUNNING_SWIMMING,
    name: 'Running or swimming',
    description: 'After each 100 yards traveled, roll vs. HT. A failed roll costs 1 point of fatigue.',
    callback: (character, eventId, rollSucceeded) => {
      let fatigueType = FatigueType.Normal;
      if (rollSucceeded) {
        return {
          fatigueType,
          fatigueValue: 0,
          log: `100 yards running or swimming; HT roll succeeded = 0 ${fatigueType} fatigue`
        };
      } else {
        return {
          fatigueType,
          fatigueValue: 1,
          log: `100 yards running or swimming; HT roll failed = 1 ${fatigueType} fatigue`
        };
      }
    },
    rollCallback: () => {
      return {
        type: RollType.Success,
        skillAttribute: attributeDefinitions[AttributeId.Health],
        title: 'running or swimming fatigue',
        modifiers: [],
        unmodifiedAttributeRoll: false,
      };
    },
  },
  {
    ...getWeatherEventTemplate('Hot weather (<= 90 degrees)'),
    id: FATIGUE_EVENT_WEATHER_90_DEGREES,
    rollCallback: (character: Character, combatState: CombatState) => {
      let bestCharacteristic = getBestCharacteristic([attributeDefinitions[AttributeId.Health], skillDefinitions[SkillId.SurvivalDesert]], character, combatState);
      return {
        type: RollType.Success,
        skillAttribute: bestCharacteristic.definition,
        title: 'Hot weather (<= 90 degrees)',
        // TODO: implement interface to equipment
        modifiers: [],
        unmodifiedAttributeRoll: false,
      };
    },
  },
  {
    ...getWeatherEventTemplate('Hot weather (<= 100 degrees)'),
    id: FATIGUE_EVENT_WEATHER_100_DEGREES,
    rollCallback: (character: Character, combatState: CombatState) => {
      let bestCharacteristic = getBestCharacteristic([attributeDefinitions[AttributeId.Health], skillDefinitions[SkillId.SurvivalDesert]], character, combatState);
      return {
        type: RollType.Success,
        skillAttribute: bestCharacteristic.definition,
        title: 'Hot weather (<= 100 degrees)',
        modifiers: [{
          value: -2,
          summary: 'Very hot weather',
          description: 'In temperatures above 80o Fahrenheit, roll vs. the better of HT or Survival (Desert) skill, at -1 per 5o over 90o',
        }],
        unmodifiedAttributeRoll: false,
      };
    },
  },
  {
    ...getWeatherEventTemplate('Hot weather (<= 110 degrees)'),
    id: FATIGUE_EVENT_WEATHER_110_DEGREES,
    rollCallback: (character: Character, combatState: CombatState) => {
      let bestCharacteristic = getBestCharacteristic([attributeDefinitions[AttributeId.Health], skillDefinitions[SkillId.SurvivalDesert]], character, combatState);
      return {
        type: RollType.Success,
        skillAttribute: bestCharacteristic.definition,
        title: 'Hot weather (<= 110 degrees)',
        modifiers: [{
          value: -4,
          summary: 'Extremely hot weather',
          description: 'In temperatures above 80o Fahrenheit, roll vs. the better of HT or Survival (Desert) skill, at -1 per 5o over 90o',
        }],
        unmodifiedAttributeRoll: false,
      };
    },
  },
  {
    ...getWeatherEventTemplate('Cold weather (>= 0 degrees)'),
    id: FATIGUE_EVENT_WEATHER_0_DEGREES,
    rollCallback: (character: Character, combatState: CombatState) => {
      let bestCharacteristic = getBestCharacteristic([attributeDefinitions[AttributeId.Health], skillDefinitions[SkillId.SurvivalArctic]], character, combatState);
      return {
        type: RollType.Success,
        skillAttribute: bestCharacteristic.definition,
        title: 'Cold weather (>= 0 degrees)',
        modifiers: [],
        unmodifiedAttributeRoll: false,
      };
    },
  },
  {
    ...getWeatherEventTemplate('Cold weather (>= -10 degrees)'),
    id: FATIGUE_EVENT_WEATHER_NEGATIVE_10_DEGREES,
    rollCallback: (character: Character, combatState: CombatState) => {
      let bestCharacteristic = getBestCharacteristic([attributeDefinitions[AttributeId.Health], skillDefinitions[SkillId.SurvivalArctic]], character, combatState);
      return {
        type: RollType.Success,
        skillAttribute: bestCharacteristic.definition,
        title: 'Cold weather (>= -10 degrees)',
        modifiers: [{
          value: -2,
          summary: 'Very cold weather',
          description: 'In temperatures below 32o, roll vs. the better of HT or Survival (Arctic) skill, at -1 per 5o ' +
            'below 0o; light clothing imposes up to a -5 penalty while winter clothing (see p. 87) provides a bonus.',
        }],
        unmodifiedAttributeRoll: false,
      };
    },
  },
  {
    ...getWeatherEventTemplate('Cold weather (>= -20 degrees)'),
    id: FATIGUE_EVENT_WEATHER_NEGATIVE_20_DEGREES,
    rollCallback: (character: Character, combatState: CombatState) => {
      let bestCharacteristic = getBestCharacteristic([attributeDefinitions[AttributeId.Health], skillDefinitions[SkillId.SurvivalArctic]], character, combatState);
      return {
        type: RollType.Success,
        diceCount: 3,
        skillAttribute: bestCharacteristic.definition,
        title: 'Cold weather (>= -20 degrees)',
        modifiers: [{
          value: -4,
          summary: 'Extremely cold weather',
          description: 'In temperatures below 32o, roll vs. the better of HT or Survival (Arctic) skill, at -1 per 5o ' +
            'below 0o; light clothing imposes up to a -5 penalty while winter clothing (see p. 87) provides a bonus.',
        }],
        unmodifiedAttributeRoll: false,
      };
    },
  },
  {
    id: FATIGUE_EVENT_OVEREXERTION,
    name: 'Overexertion',
    description: 'Carrying more than 20 times ST, or pushing or pulling a very heavy load, costs 1 fatigue per second.',
    callback: () => {
      let fatigueType = FatigueType.Normal;
      return {
        fatigueType,
        fatigueValue: 1,
        log: `One second carrying more than 20 times ST, or pushing or pulling a very heavy load = 1 ${fatigueType} fatigue`
      };
    },
  },
  {
    id: FATIGUE_EVENT_LOST_SLEEP_NIGHT,
    name: 'Lost sleep (full night)',
    description: 'A night without sleep costs 5 fatigue. Losing a half-night of sleep costs 2 fatigue.',
    callback: () => {
      let fatigueType = FatigueType.Sleep;
      return {
        fatigueType,
        fatigueValue: 5,
        log: `Full night of sleep lost = 5 ${fatigueType} fatigue`
      };
    },
  },
  {
    id: FATIGUE_EVENT_LOST_SLEEP_HALF_NIGHT,
    name: 'Lost sleep (half night)',
    description: 'A night without sleep costs 5 fatigue. Losing a half-night of sleep costs 2 fatigue.',
    callback: () => {
      let fatigueType = FatigueType.Sleep;
      return {
        fatigueType,
        fatigueValue: 2,
        log: `Half night of sleep lost = 2 ${fatigueType} fatigue`
      };
    },
  },
  {
    id: FATIGUE_EVENT_STARVATION_MISSED_MEAL,
    name: 'Starvation, one missed meal',
    description: 'Active people need three meals per day. A missed meal costs 1 fatigue point until ST reaches 3, then ' +
      'lose hit points, instead. Only rest with plenty to eat will restore this; each rest day with three full meals ' +
      'restores 3 fatigue points lost to starvation.',
    callback: (character, combatState: CombatState) => {
      let fatigueType = FatigueType.Starvation;
      let {
        fatigueValue,
        healthValue,
        text
      } = getFatigueEffectBasedOnSt(character, combatState, fatigueType);
      return {
        fatigueType,
        fatigueValue,
        healthValue,
        log: `Missed meal = ${text}`
      };
    },
  },
  {
    id: FATIGUE_EVENT_DEHYDRATION_FULL_RATIONS,
    name: 'Dehydration, one day (adequate food)',
    description: 'A person need 2 quarts of water daily, 3 if it’s hot, 5 in a desert! Each day without adequate water' + 
      ' costs 1 fatigue point and 1 hit point. Double the losses if on less than half rations. If ST or HT reaches 0, ' +
      'you become delirious and die within a day if no help arrives. A day of rest with ample water will restore the fatigue.',
    callback: () => {
      let fatigueType = FatigueType.Dehydration;
      return {
        fatigueType,
        fatigueValue: 1,
        healthValue: 1,
        log: `One day without enough water but with enough food = 1 ${fatigueType} fatigue and 1 hit point`
      };
    },
  },
  {
    id: FATIGUE_EVENT_DEHYDRATION_HALF_RATIONS,
    name: 'Dehydration, one day (half rations)',
    description: 'A person need 2 quarts of water daily, 3 if it’s hot, 5 in a desert! Each day without adequate water' +
      ' costs 1 fatigue point and 1 hit point. Double the losses if on less than half rations. If ST or HT reaches 0, ' +
      'you become delirious and die within a day if no help arrives. A day of rest with ample water will restore the fatigue.',
    callback: () => {
      let fatigueType = FatigueType.Dehydration;
      return {
        fatigueType,
        fatigueValue: 2,
        healthValue: 2,
        log: `One day without enough water and food = 2 ${fatigueType} fatigue and 2 hit point`
      };
    },
  },
];

export const tiringEvents: Record<string, FatigueEvent> = tiringEventsArray.reduce((carry, current) => {
  carry[current.id] = current;
  return carry;
}, {} as { [key: string]: FatigueEvent });

const restingEventsArray: Array<FatigueEvent> = [
  {
    id: FATIGUE_EVENT_10_MINUTES_REST,
    name: '10 Minutes Rest',
    description: '"Normal" fatigue can be regained at 1 point per 10 minutes of rest, involving nothing more strenuous than talking and thinking.',
    callback: (character, eventId) => {
      let value = -1;
      let fatigueType = FatigueType.Normal;
      return {
        fatigueType,
        fatigueValue: value,
        log: `Resting 10 minutes = ${value} ${fatigueType} fatigue`
      };
    },
  },
  {
    id: FATIGUE_EVENT_1_HOUR_REST,
    name: '1 Hour Rest',
    description: '"Normal" fatigue can be regained at 1 point per 10 minutes of rest, involving nothing more strenuous than talking and thinking.',
    callback: (character, eventId) => {
      let value = -6;
      let fatigueType = FatigueType.Normal;
      return {
        fatigueType,
        fatigueValue: value,
        log: `Resting one hour = ${value} ${fatigueType} fatigue`
      };
    },
  },
  {
    id: FATIGUE_EVENT_REST_DAY_FOOD,
    name: 'Full day of rest with three meals',
    description: 'Active people need three meals per day. A missed meal costs 1 fatigue point until ST reaches 3, then ' +
      'lose hit points, instead. Only rest with plenty to eat will restore this; each rest day with three full meals ' +
      'restores 3 fatigue points lost to starvation.',
    callback: (character, eventId) => {
      let value = 0 - character.fatiguePoints.Starvation;
      let fatigueType = FatigueType.Starvation;
      return {
        fatigueType,
        fatigueValue: value,
        log: `Full day of rest with three meals = ${value} ${fatigueType} fatigue`
      };
    },
  },
  {
    id: FATIGUE_EVENT_REST_DAY_WATER,
    name: 'Full day of rest with ample water',
    description: 'A person need 2 quarts of water daily, 3 if it’s hot, 5 in a desert! Each day without adequate water' +
      ' costs 1 fatigue point and 1 hit point. Double the losses if on less than half rations. If ST or HT reaches 0, ' +
      'you become delirious and die within a day if no help arrives. A day of rest with ample water will restore the fatigue.',
    callback: (character, eventId) => {
      let value = 0 - character.fatiguePoints.Dehydration;
      let fatigueType = FatigueType.Dehydration;
      return {
        fatigueType,
        fatigueValue: value,
        log: `Full day of rest with ample water = ${value} ${fatigueType} fatigue`
      };
    },
  },
  {
    id: FATIGUE_EVENT_FULL_NIGHT_SLEEP,
    name: 'Full night\'s sleep',
    description: 'Fatigue due to lost sleep is regained only by getting a full night of sleep! This restores all fatigue from lost sleep.',
    callback: (character, eventId) => {
      let value = 0 - character.fatiguePoints.Sleep;
      let fatigueType = FatigueType.Sleep;
      return {
        fatigueType,
        fatigueValue: value,
        log: `Full night's sleep = ${value} ${fatigueType} fatigue`
      };
    },
  },
];
export const restingEvents: Record<string, FatigueEvent> = restingEventsArray.reduce((carry, current) => {
  carry[current.id] = current;
  return carry;
}, {} as { [key: string]: FatigueEvent });

export const allFatigueEvents = {...tiringEvents, ...restingEvents};
