import React from 'react';
import {RouteComponentProps} from 'react-router-dom'
import {connect} from 'react-redux';
import {AppState} from "../../modules";
import {bindActionCreators} from "redux";
import {ModalContainer} from "../../lib/components/ModalContainer";
import {userClose} from "../../modules/modal";
import GamemasterNavbar from "./components/GamemasterNavbar";
import OverviewPage from "./components/OverviewPage";
import EncounterPage from "./components/EncounterPage";

interface MatchParams {
    selectedPanel: string;
}

type Props = GamemasterProps & RouteComponentProps<MatchParams>;

class GamemasterDisplay extends React.Component<Props> {
    render() {
        let {
            modal,
        } = this.props;
        let {
            selectedPanel,
        } = this.props.match.params;

        return <>
            <GamemasterNavbar/>
            <ModalContainer
                modal={modal}
                userClose={userClose}
            />
            {this.renderSelectedPanel(selectedPanel)}
        </>;
    }

    renderSelectedPanel(selectedPanel: string) {
        if (!selectedPanel) {
            return null;
        }
        switch (selectedPanel.toLowerCase()) {
            case 'overview':
                return <OverviewPage/>;
            case 'encounter':
                return <EncounterPage/>;
            default:
                throw Error(`Undefined panel ${selectedPanel}`);
        }
    }
}

export type GamemasterProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
const mapStateToProps = (state: AppState) => {
    return {
        modal: state.modalReducer,
    };
};

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GamemasterDisplay)
