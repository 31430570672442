import {applyMiddleware, compose, createStore} from 'redux'
import {connectRouter, routerMiddleware} from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'
import {createBrowserHistory} from "history";
import rootReducer from './modules'
import {fatigueRollSaga} from "./sagas/fatigueSagas";
import {maneuverSagas} from "./sagas/maneuverSagas";
import {characterSagas} from "./sagas/characterSagas";
import {modalSagas} from "./sagas/modalSagas";
import {dataSagas} from "./sagas/dataSagas";
import {gamemasterSagas} from "./sagas/gamemasterSagas";

export const history = createBrowserHistory();

const initialState = {};
const enhancers = [];
const sagaMiddleware = createSagaMiddleware();
const middleware = [routerMiddleware(history), sagaMiddleware];

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
);
const store = createStore(
  connectRouter(history)(rootReducer),
  initialState,
  composedEnhancers
);

sagaMiddleware.run(fatigueRollSaga);
sagaMiddleware.run(maneuverSagas);
sagaMiddleware.run(characterSagas);
sagaMiddleware.run(modalSagas);
sagaMiddleware.run(dataSagas);
sagaMiddleware.run(gamemasterSagas);

export default store;
