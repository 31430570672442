import {put, select, take, takeLatest} from 'redux-saga/effects';
import {SagaIterator} from 'redux-saga';
import {ExecuteRollAction, FatigueRollSagaAction, initRoll, RollActions,} from "../modules/roll";
import {rollSpecForFatigueEvent} from "../lib/constants/fatigueConstants";
import {setFatiguePoints} from "../modules/character";
import {log, LogLevels} from "../lib/logger";
import {AppState} from "../modules";
import {CharacteristicRollResult, RollType} from "../lib/constants/rollConstants";

export function* fatigueRollSaga(): SagaIterator {
  log(`Taking latest fatigue roll`, LogLevels.Trace);
  yield takeLatest(RollActions.FatigueRollSaga, fatigueRoll);
}

function* fatigueRoll(action: FatigueRollSagaAction): SagaIterator {
  log(`Fatigue roll saga started`);
  let state = (yield select()) as AppState;
  let combat = state.combatReducer;
  let event = action.event;
  let character = action.character;

  let rollSpec = rollSpecForFatigueEvent(event, combat, character);
  let rollAction = null;
  if (rollSpec) {
    log(`Initializing roll spec for fatigue roll saga`);
    yield put(initRoll(rollSpec));
    log(`Waiting for user auto or manual roll`);
    rollAction = (yield take(RollActions.ExecuteRoll)) as ExecuteRollAction;
    log(`User auto or manual roll complete, processing fatigue result`);
  }
  if (!rollAction || rollAction.result.type !== RollType.Success) {
    throw new Error(`Fatigue roll failed: expected roll type 'success'`)
  }
  let rollResult = rollAction.result as CharacteristicRollResult;
  let {fatigueValue, healthValue, fatigueType, log: characterLog} =
    event.callback(character, combat, event.id, rollResult.success);
  yield put(setFatiguePoints(fatigueType, character.fatiguePoints[fatigueType] + fatigueValue, characterLog));
}

