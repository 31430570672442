import React from 'react';
import {connect} from 'react-redux';
import {Button, Col, Form, FormControlProps, Row,} from 'react-bootstrap';
import {CenteredFlexCol} from "../../../../lib/components/CenteredFlexCol";
import DiceSubpanel from "./subpanels/DiceSubpanel";
import ModifierSubpanel from "./subpanels/ModifierSubpanel";
import {
  executeAttackRollForSpec,
  executeDamageRollForSpec,
  executeDodgeRollForSpec,
  executeFragHitRollForSpec,
  executeGenericRollForSpec,
  executeParryRollForSpec,
  executeSuccessRollForSpec,
} from "../../../../lib/gameLogic/rolls";
import {AppState} from "../../../../modules";
import {Character} from "../../../../lib/constants/characterConstants";
import {
  getBasicSpeed,
  getCharacteristicCurrentValue,
  getCharacteristicValueForRollSpec
} from "../../../../lib/gameLogic/derivedCharacterStats";
import {getFragmentationHitThreshold} from "../../../../lib/gameLogic/derivedCombatStats";
import {CombatState} from "../../../../modules/combat";
import {bindActionCreators} from "redux";
import {weaponDefinitions} from "../../../../lib/constants/weaponConstants";
import {
  AttackRollSpec,
  CharacteristicRollSpec,
  DamageRollResult,
  DamageRollSpec,
  DodgeRollSpec,
  FragHitRollSpec,
  GenericRollSpec,
  Modifier,
  ParryRollSpec,
  RollResult,
  RollSpec,
  RollType
} from "../../../../lib/constants/rollConstants";
import {executeRoll, loadDamageRollSignal} from "../../../../modules/roll";
import {getObjectHash} from "../../../../lib/utils";
import {CenteredRow} from "../../../../lib/components/CenteredRow";

export const EFFECTIVE_SKILL_CUTOFF = 3;
const DAMAGE_ROLL_MANUAL_SEPARATOR = ';';

interface State {
  manualRollResult: string;
  specHash: string;
}

const CONTENT_PANEL_PROPS = {
  xs: 12,
  md: 8,
};
const TITLE_COLUMN_PROPS = {
  xs: 12,
  sm: 6,
  md: 4,
  lg: 3,
};
const VS_COLUMN_PROPS = {
  xs: 12,
  sm: 6,
  md: 1,
};
const MODIFIER_COLUMN_PROPS = {
  xs: 12,
  sm: 6,
  md: 3,
  lg: 2,
};
const TARGET_COLUMN_PROPS = {
  xs: 12,
  sm: 6,
  md: 3,
  lg: 2,
};
const ACTION_RESULT_COLUMN_PROPS = {
  xs: 12,
  sm: 6,
  md: 3,
  lg: 2,
};

class RollPanel extends React.Component<RollPanelProps, State> {
  constructor(props: RollPanelProps) {
    super(props);
    this.state = {
      manualRollResult: getManualRollPlaceholder(props.roll.rollSpec),
      specHash: getObjectHash(props.roll.rollSpec || {}),
    };
  }

  static getDerivedStateFromProps(props: RollPanelProps, state: State): State | null {
    let spec = props.roll.rollSpec;
    if (!spec) {
      return state;
    }
    let newHash = getObjectHash(spec);
    if (newHash !== state.specHash) {
      // Roll spec changed, reset manual roll placeholder
      return {
        ...state,
        specHash: newHash,
        manualRollResult: getManualRollPlaceholder(spec),
      }
    }
    return state;
  }

  executeAutoRoll() {
    let {
      combat,
      character: {
        character,
      },
      roll: {
        rollSpec,
      },
    } = this.props;

    this.doExecuteRoll(rollSpec, combat, character);
  }

  executeManualRoll() {
    let {
      combat,
      character: {
        character,
      },
      roll: {
        rollSpec,
      },
    } = this.props;

    this.doExecuteRoll(rollSpec, combat, character, this.state.manualRollResult);
  }

  executeLoadDamageRoll() {
    let {
      loadDamageRollSignal,
    } = this.props;

    loadDamageRollSignal();
  }

  parseSpec(rollSpec: RollSpec, character: Character, combatState: CombatState):
      {title: string; value: number; modifiers: Array<Modifier>} {
    let title: string;
    let value: number;
    let modifiers = rollSpec.modifiers;
    switch (rollSpec.type) {
      case RollType.Attack: {
        let skill = (rollSpec as AttackRollSpec).skill;
        title = skill.name;
        let skillValue = getCharacteristicCurrentValue(skill.id, character, combatState);
        value = skillValue.value;
        modifiers = modifiers.concat(skillValue.modifiers);
        break;
      }
      case RollType.Success: {
        let characteristicRollSpec = rollSpec as CharacteristicRollSpec;
        let characteristic = characteristicRollSpec.skillAttribute;
        title = characteristic.name;
        let charValue = getCharacteristicValueForRollSpec(characteristicRollSpec, character, combatState);
        value = charValue.value;
        modifiers = modifiers.concat(charValue.modifiers);
        break;
      }
      case RollType.Dodge: {
        title = 'Dodge';
        let speed = getBasicSpeed(character, combatState);
        value = speed.value;
        modifiers = modifiers.concat(speed.modifiers);
        break;
      }
      case RollType.FragHit: {
        let targetRange = (rollSpec as FragHitRollSpec).impactRangeToTarget;
        title = 'Frag Hit';
        value = getFragmentationHitThreshold(targetRange);
        break;
      }
      case RollType.Damage: {
        title = 'Damage';
        value = 0;
        break;
      }
      case RollType.Generic: {
        let spec = rollSpec as GenericRollSpec;
        title = spec.title;
        value = 0;
        break;
      }
      default:
        throw new Error(`Invalid roll type ${rollSpec.type}`);
    }
    return {
      title,
      value,
      modifiers,
    };
  }

  doExecuteRoll(rollSpec: RollSpec | null, combat: CombatState, character: Character, manualResult: string | null = null) {
    if (!rollSpec) {
      throw new Error(`Failed to execute roll: roll spec is null`);
    }
    let {
      executeRoll,
    } = this.props;
    let result: RollResult;

    switch (rollSpec.type) {
      case RollType.Attack: {
        let numberResult = manualResult ? parseInt(manualResult, 10) : null;
        result = executeAttackRollForSpec(rollSpec as AttackRollSpec, character, combat, numberResult);
        break;
      }
      case RollType.Dodge: {
        let numberResult = manualResult ? parseInt(manualResult, 10) : null;
        result = executeDodgeRollForSpec(rollSpec as DodgeRollSpec, character, combat, numberResult);
        break;
      }
      case RollType.Parry: {
        let numberResult = manualResult ? parseInt(manualResult, 10) : null;
        result = executeParryRollForSpec(rollSpec as ParryRollSpec, character, combat, numberResult);
        break;
      }
      case RollType.Success: {
        let numberResult = manualResult ? parseInt(manualResult, 10) : null;
        result = executeSuccessRollForSpec(rollSpec as CharacteristicRollSpec, character, combat, numberResult);
        break;
      }
      case RollType.FragHit: {
        let numberResult = manualResult ? parseInt(manualResult, 10) : null;
        result = executeFragHitRollForSpec(rollSpec as FragHitRollSpec, numberResult);
        break;
      }
      case RollType.Damage: {
        let spec = rollSpec as DamageRollSpec;
        let arrayResult: Array<number> | null = null;
        if (manualResult) {
          let arrayResult = manualResult.split(DAMAGE_ROLL_MANUAL_SEPARATOR).map(num => parseInt(num, 10));
          if (arrayResult.length !== spec.normalHits) {
            throw new Error(`Damage roll required ${spec.normalHits} die values for manual input; ${arrayResult?.length} found`);
          }
        }
        result = executeDamageRollForSpec(spec, character, arrayResult);
        break;
      }
      case RollType.Generic: {
        let numberResult = manualResult ? parseInt(manualResult, 10) : null;
        result = executeGenericRollForSpec(rollSpec as GenericRollSpec, character, numberResult);
        break;
      }
      default:
        throw new Error(`Invalid roll type ${rollSpec.type}`);
    }
    executeRoll(result);
  }

  changeRollResult(e: React.FormEvent<FormControlProps>) {
    this.setState({
      manualRollResult: e.currentTarget.value || '',
    });
  }

  render() {
    let {
      roll: {
        rollSpec,
        rollResult,
      },
      character: {
        character,
      },
      combat,
    } = this.props;

    if (!rollSpec || !rollSpec.title) {
      return <Col {...CONTENT_PANEL_PROPS} className='content-panel'>
        <Row className='h-100 no-gutters'>
          <CenteredFlexCol isRow={false}>
            <h4>No rolls loaded</h4>
          </CenteredFlexCol>
        </Row>
      </Col>;
    }

    let {title, value, modifiers} = this.parseSpec(rollSpec, character, combat);
    value = modifiers.reduce((carry, current) => {
      return carry + current.value;
    }, value);

    return <Col {...CONTENT_PANEL_PROPS} className='content-panel'>
      <CenteredRow className='h-100 no-gutters'>
        <CenteredFlexCol {...TITLE_COLUMN_PROPS} isRow={false}>
          <h4>{rollSpec.title}</h4>
        </CenteredFlexCol>
        {modifiers.map((modifier, index) => {
          return <ModifierSubpanel
              {...MODIFIER_COLUMN_PROPS}
              key={`modifier-${index}`}
              modifier={modifier}
          />
        })}
        {this.renderEndSection(rollSpec, title, value)}
      </CenteredRow>
    </Col>;
  }

  renderEndSection(rollSpec: RollSpec, title: string, value: number) {
    switch (rollSpec.type) {
      case RollType.Success:
      case RollType.Attack:
      case RollType.Parry:
      case RollType.Dodge:
      case RollType.FragHit:
        return <>
          <CenteredFlexCol {...VS_COLUMN_PROPS} isRow={false}>
            <h4>vs.</h4>
          </CenteredFlexCol>
          <CenteredFlexCol {...TARGET_COLUMN_PROPS} isRow={false}>
            <h4>{title}</h4>
            <h2>{value}</h2>
          </CenteredFlexCol>
          {this.renderSuccessResultColumn(value)}
        </>;
      case RollType.Damage: {
        let spec = rollSpec as DamageRollSpec;
        let weaponDef = weaponDefinitions[spec.weaponId];
        let damage = weaponDef.damage;
        let dice = damage.dice;

        let damageTextString = `${dice}d`;
        if (weaponDef.damage.diceMultiplier > 1) {
          damageTextString += `x${weaponDef.damage.diceMultiplier}`;
        }
        if (weaponDef.damage.modifier !== 0) {
          let sign = weaponDef.damage.modifier > 0 ? '+' : '';
          damageTextString += `${sign}${weaponDef.damage.modifier}`;
        }

        if (damage.fragmentation && spec.fragDamageHit) {
          damageTextString += ` [${damage.fragmentation}]`;
        }

        return <>
          <CenteredFlexCol {...TITLE_COLUMN_PROPS} isRow={false}>
            <h4>{damageTextString}</h4>
          </CenteredFlexCol>
          <CenteredFlexCol {...VS_COLUMN_PROPS} isRow={false}>
            <h4>=</h4>
          </CenteredFlexCol>
          {this.renderDamageResultColumn()}
        </>;
      }
      case RollType.Generic: {
        let spec = rollSpec as GenericRollSpec;
        let dice = spec.diceCount;
        let textString = `${dice}d`;

        return <>
          <CenteredFlexCol {...TITLE_COLUMN_PROPS} isRow={false}>
            <h4>{textString}</h4>
          </CenteredFlexCol>
          <CenteredFlexCol {...VS_COLUMN_PROPS} isRow={false}>
            <h4>=</h4>
          </CenteredFlexCol>
          {this.renderGenericResultColumn()}
        </>;
      }
    }
  }

  renderGenericResultColumn() {
    let {
      roll: {
        rollSpec,
        rollResult,
      },
    } = this.props;

    if (rollResult) {
      return <CenteredFlexCol {...TARGET_COLUMN_PROPS} isRow={false}>
        <DiceSubpanel rollResult={rollResult}/>
      </CenteredFlexCol>
    } else {
      if (!rollSpec) {
        throw new Error(`Can't render rollpanel without roll spec`);
      }
      return this.renderNormalRollInput();
    }
  }

  renderSuccessResultColumn(againstValue: number) {
    let {
      roll: {
        rollSpec,
        rollResult,
      },
    } = this.props;

    if (rollResult) {
      let spec = rollResult.rollSpec;
      return <CenteredFlexCol {...ACTION_RESULT_COLUMN_PROPS} isRow={false}>
        <DiceSubpanel rollResult={rollResult}/>
        {(spec.type === RollType.Attack || spec.type === RollType.FragHit) &&
          <Button variant='outline-danger' onClick={this.executeLoadDamageRoll.bind(this)}>Load Damage Roll</Button>
        }
      </CenteredFlexCol>
    } else {
      if (!rollSpec) {
        throw new Error(`Can't render rollpanel without roll spec`);
      }
      if (againstValue < EFFECTIVE_SKILL_CUTOFF) {
        // User sucks too hard at this skill to even roll
        return <CenteredFlexCol {...ACTION_RESULT_COLUMN_PROPS} isRow={false}>
          <h5>Can't roll skill below {EFFECTIVE_SKILL_CUTOFF}</h5>
        </CenteredFlexCol>
      } else {
        // Roll normally
        return this.renderNormalRollInput();
      }
    }
  }

  renderDamageResultColumn() {
    let {
      roll: {
        rollSpec,
        rollResult,
      },
    } = this.props;

    if (rollResult) {
      let result = rollResult as DamageRollResult;
      return <CenteredFlexCol {...ACTION_RESULT_COLUMN_PROPS} isRow={false}>
        <h2>{result.totalDamage}</h2>
      </CenteredFlexCol>
    } else if (rollSpec) {
      return this.renderNormalRollInput();
    } else {
      return null;
    }
  }

  renderNormalRollInput() {
    return <CenteredFlexCol {...ACTION_RESULT_COLUMN_PROPS} isRow={false}>
      <Button variant='outline-success' onClick={this.executeAutoRoll.bind(this)}>Auto Roll</Button>
      <Form.Control
          className='number-picker wide'
          type='text'
          value={this.state.manualRollResult}
          onChange={this.changeRollResult.bind(this)}
      />
      <Button variant='outline-secondary' onClick={this.executeManualRoll.bind(this)}>Manual Roll</Button>
    </CenteredFlexCol>
  }
}

const getManualRollPlaceholder = (rollSpec: RollSpec | null): string => {
  if (rollSpec?.type === RollType.Damage) {
    let spec = rollSpec as DamageRollSpec;
    let placeholderValues = [];
    for (let i = 0; i < spec.normalHits; i++) {
      placeholderValues.push(3);
    }
    return placeholderValues.join(DAMAGE_ROLL_MANUAL_SEPARATOR);
  } else if (rollSpec?.type === RollType.Generic) {
    let spec = rollSpec as GenericRollSpec;
    return `${3 * spec.diceCount}`;
  } else {
    return '18';
  }
};

export type RollPanelProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    roll: state.rollReducer,
    character: state.characterReducer,
    combat: state.combatReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  executeRoll,
  loadDamageRollSignal,
  // changePage: () => push('/about-us')
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RollPanel)
