import React from 'react';

interface Props {
  isRow?: boolean;
  className?: string;
  justification?: string;
  alignment?: string;
  style?: Object;
}

export const CenteredFlexDiv: React.FunctionComponent<Props> = (props) => {
  let {
    isRow = true,
    className = '',
    justification = 'center',
    alignment = 'center',
    style = {},
    children,
    ...rest
  } = props;
  return <div {...rest} style={style} className={`${className} d-flex flex-${isRow ? 'row' : 'column'} justify-content-${justification} align-items-${alignment}`}>
    {children}
  </div>;
};
