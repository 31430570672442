import {GEAR_ID_PREFIX, GearDefinition, gearDefinitions, GearId} from "./gearConstants";
import {WEAPON_ID_PREFIX, WeaponDefinition, weaponDefinitions, WeaponId} from "./weaponConstants";

export type EquipmentId = GearId | WeaponId;
export type EquipmentDefinition = GearDefinition | WeaponDefinition;

export enum EquipmentType {
  Weapon = "@@equipmentType/weapon",
  Gear = '@@equipmentType/gear',
}

export interface Equipment {
  id: EquipmentId;
  quantity: number;
  notes?: string;
  equipped: boolean;
}

export const getEquipmentType = (id: EquipmentId): EquipmentType => {
  if (id.startsWith(GEAR_ID_PREFIX)) {
    return EquipmentType.Gear;
  } else if (id.startsWith(WEAPON_ID_PREFIX)) {
    return EquipmentType.Weapon;
  } else {
    throw new Error(`Invalid equipment id ${id}`);
  }
};

export const getEquipmentDefinition = (id: EquipmentId): EquipmentDefinition => {
  switch (getEquipmentType(id)) {
    case EquipmentType.Gear:
      return gearDefinitions[id as GearId];
    case EquipmentType.Weapon:
      return weaponDefinitions[id as WeaponId];
  }
};
