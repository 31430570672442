import React from 'react';
import {connect} from 'react-redux';
import {Character} from '../../../lib/constants/characterConstants';
import {Container, Row,} from 'react-bootstrap';
import {addEquipment, addToCharacterLog, equipGear, removeEquipment, setCashMoney,} from "../../../modules/character";
import LogPanel from "./panels/LogPanel";
import RollPanel from "./panels/RollPanel";
import {AppState} from "../../../modules";
import {EquipmentSelectPanel} from "./panels/EquipmentSelectPanel";
import {EquipmentId} from "../../../lib/constants/equipmentConstants";
import {gearDefinitions} from "../../../lib/constants/gearConstants";
import {EquipmentDetailPanel} from "./panels/EquipmentDetailPanel";
import {CombatState} from "../../../modules/combat";

export interface EquipmentProps {
  combat: CombatState;
  character: Character;
  logs: Array<string>;
  addEquipment: typeof addEquipment;
  removeEquipment: typeof removeEquipment;
  setCashMoney: typeof setCashMoney;
  equipGear: typeof equipGear;
}

interface State {
  equipmentDetailId: EquipmentId;
}

class EquipmentPage extends React.Component<EquipmentProps, State> {
  constructor(props: EquipmentProps) {
    super(props);
    this.state = {
      equipmentDetailId: Object.values(gearDefinitions)[0].id,
    };
  }

  displayEquipmentDetail(id: EquipmentId) {
    this.setState({
      equipmentDetailId: id,
    });
  }

  render() {
    let {
      character,
      logs,
    } = this.props;

    if (Object.values(character).length === 0) {
      return <h2>No Character loaded</h2>;
    }

    return <Container fluid={true} className='content-container'>
      <Row className='top-row'>
        <EquipmentSelectPanel
          {...this.props}
          displayEquipmentDetail={this.displayEquipmentDetail.bind(this)}
        />
        <EquipmentDetailPanel
          equipmentDetailId={this.state.equipmentDetailId}
        />
      </Row>
      <Row className='bottom-row'>
        <RollPanel/>
        <LogPanel
          logs={logs}
        />
      </Row>
    </Container>;
  }
}

const mapStateToProps = (state: AppState) => {
  // Consider using reselect to memoize derived values here if performance becomes an issue
  return {
    ...state.characterReducer,
    combat: state.combatReducer,
  };
};

const mapDispatchToProps = {
  addEquipment,
  removeEquipment,
  addToCharacterLog,
  setCashMoney,
  equipGear,
  // changePage: () => push('/about-us')
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EquipmentPage)
