import React, {Component} from 'react';
import {CenteredFlexDiv} from "../../../../../lib/components/CenteredFlexDiv";
import {getRollResultCssClass, getRollResultDescription} from "../../../../../lib/gameLogic/rollFunctions";
import {RollResult} from "../../../../../lib/constants/rollConstants";

interface Props {
  rollResult: RollResult;
}

export default class DiceSubpanel extends Component<Props> {
  render() {
    let {
      rollResult,
    } = this.props;

    let resultClass = '';
    if (rollResult) {
      resultClass = getRollResultCssClass(rollResult);
    }

    return <>
      <CenteredFlexDiv>
        <h2>{getRollResultDescription(rollResult)}</h2>
      </CenteredFlexDiv>
      <CenteredFlexDiv>
        {getRollResultElement(resultClass, rollResult.totalRoll)}
      </CenteredFlexDiv>
    </>
  }
}

export const getRollResultElement = (resultClass: string, totalRoll: number) => {
  return <h2 className={`roll-result ${resultClass}`}>{totalRoll}</h2>
};
