import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import Home from '../home'
import Character from '../character'
import Gamemaster from '../gamemaster'
import {Container} from "react-bootstrap";

const App = () => (
  <Container fluid={true} className='top-level-container'>
      <Switch>
          <Route exact path="/" component={Home}/>

          <Route exact path="/character" render={() => (
              <Redirect to="/character/status"/>
          )}/>
          <Route path="/character/:selectedPanel" component={Character}/>

          <Route exact path="/gamemaster" render={() => (
              <Redirect to="/gamemaster/overview"/>
          )}/>
          <Route path="/gamemaster/:selectedPanel" component={Gamemaster}/>
      </Switch>
  </Container>
);

export default App
