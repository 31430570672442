import React from 'react';
import {connect} from 'react-redux';
import {Container, Row,} from 'react-bootstrap';
import {AppState} from "../../../modules";
import {bindActionCreators} from "redux";
import {loadCharacter} from "../../../modules/character";
import {DataPanel} from "./panels/DataPanel";
import {
    deleteCharacterSaga,
    exportCharacterSaga,
    importCharacterSaga,
    loadCharacterSaga,
    saveAsCharacterSaga,
    saveCharacterSaga
} from "../../../modules/data";

class DataPage extends React.Component<DataPageProps> {

    render() {
        return <Container fluid={true} className='content-container'>
            <Row className='fullscreen-row'>
                <DataPanel
                    {...this.props}
                />
            </Row>
        </Container>;
    }
}

export type DataPageProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
const mapStateToProps = (state: AppState) => {
    // Consider using reselect to memoize derived values here if performance becomes an issue
    return {
        data: state.dataReducer,
        character: state.characterReducer,
    };
};

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    loadCharacter,
    loadCharacterSaga,
    saveCharacterSaga,
    saveAsCharacterSaga,
    importCharacterSaga,
    exportCharacterSaga,
    deleteCharacterSaga,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DataPage)
