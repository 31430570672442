import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Form,} from 'react-bootstrap';
import {CenteredFlexCol} from "./CenteredFlexCol";
import {CenteredFlexDiv} from "./CenteredFlexDiv";

export const logTemplateValueToken = '%%value%%';

export default class NumberIncrementer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  render() {
    let {
      value,
      dispatchNewValue,
      logTemplate,
      title,
    } = this.props;

    return <CenteredFlexCol isRow={false}>
      {this.renderTitle(title)}
      <CenteredFlexDiv>
        <Form>
          <Form.Group>
            <Form.Control className='number-picker' type="number" readOnly value={value}/>
          </Form.Group>
        </Form>
        <div className='d-flex flex-column'>
          <Button variant='primary' onClick={() => {
            let log = logTemplate.replace(logTemplateValueToken, 1);
            dispatchNewValue(value + 1, log);
          }}>
            <i className="fas fa-arrow-up"/>
          </Button>
          <Button variant='primary' onClick={() => {
            let log = logTemplate.replace(logTemplateValueToken, -1);
            dispatchNewValue(value - 1, log);
          }}>
            <i className="fas fa-arrow-down" />
          </Button>
        </div>
      </CenteredFlexDiv>
    </CenteredFlexCol>
  }

  renderTitle(title) {
    if (!title) {
      return null;
    }
    return <h6>{title}</h6>;
  }
}

NumberIncrementer.propTypes = {
  value: PropTypes.number.isRequired,
  dispatchNewValue: PropTypes.func.isRequired,
  title: PropTypes.string,
};
