import {SagaIterator} from "redux-saga";
import {log, LogLevels} from "../lib/logger";
import {put, select, takeLatest} from "redux-saga/effects";
import {
    addInjury,
    addToCharacterLog,
    ConditionEventSagaAction,
    healDamage,
    removeInjury,
    SagaCharacterActions,
    setConsecutiveBleedCheckSuccesses
} from "../modules/character";
import {CharacterConditionEventId, InjuryId} from "../lib/constants/characterConditionConstants";
import {doBandaging, setShock, setStun, ShockStatus, StunStatus, takeDamageSaga} from "../modules/combat";
import {AppState} from "../modules";
import {waitForRoll} from "./maneuverSagas";
import {
    CharacteristicRollResult,
    GenericRollResult,
    Modifier,
    rollSpecForAttribute,
    rollSpecForGeneric,
    rollSpecForSkill
} from "../lib/constants/rollConstants";
import {SkillId} from "../lib/constants/skillConstants";
import {AttributeId} from "../lib/constants/attributeConstants";
import {VantageId} from "../lib/constants/vantageConstants";

export function* characterSagas(): SagaIterator {
    log(`Launching character sagas`);
    yield takeLatest(SagaCharacterActions.ConditionEvent, characterConditionEvent);
}

function* characterConditionEvent(action: ConditionEventSagaAction): SagaIterator {
    log(`Launching character condition saga`);
    let state = (yield select()) as AppState;

    switch (action.event) {
        case CharacterConditionEventId.SimpleBandaging: {
            // Manual: Basic, unskilled bandaging will restore 1 lost hit point per fight – but no more, no matter how
            // bad the injury. This takes 30 minutes per victim.

            if (state.combatReducer.haveBandagedSinceLastFight) {
                log(`Already bandaged once this fight; can't bandage again.`);
                return;
            }
            yield put(addToCharacterLog(`30 minutes was spent crudely bandaging your wounds. You regain 1 HT.`));
            yield put(healDamage(1));
            yield put(doBandaging());
            break;
        }
        case CharacterConditionEventId.FirstAid: {
            // Manual: A successful First Aid skill roll will restore 1d-1 lost hit points. On a critical success, the
            // victim regains the maximum: 5 hit points. On a critical failure, the victim loses 2 hits, and band-
            // aging will not help. Barring critical failure, a minimum of 1 point is always restored. First aid is not
            // cumulative with simple band- aging. This takes 20 minutes per victim.

            if (state.combatReducer.haveBandagedSinceLastFight) {
                log(`Already bandaged once this fight; can't bandage again.`, LogLevels.Error);
                return;
            }
            let firstAidRollSpec = rollSpecForSkill(SkillId.FirstAid, state.characterReducer.character, state.combatReducer, []);
            let firstAidRollAction = yield* waitForRoll(firstAidRollSpec, 'first aid bandaging roll');
            let firstAidRollResult = firstAidRollAction.result as CharacteristicRollResult;

            if (firstAidRollResult.criticalFailure) {
                yield put(addToCharacterLog(`20 minutes was spent providing first aid for your wounds. Critical failure, you lose 2 HT.`));
                yield put(takeDamageSaga(state.characterReducer.character, 2, false));
                yield put(doBandaging());
                return;
            }

            let pointsHealed: number;
            if (firstAidRollResult.criticalSuccess) {
                pointsHealed = 5;
            } else if (firstAidRollResult.success) {
                let healRollSpec = rollSpecForGeneric([{
                    summary: 'Static Modifier',
                    description: 'First aid bandaging rolls are defined as 1d-1',
                    value: -1,
                }], 1, 'Damage healed by first aid');
                let healRollAction = yield* waitForRoll(healRollSpec, 'Damage healed by first aid');
                let healRollResult = healRollAction.result as GenericRollResult;
                pointsHealed = Math.max(healRollResult.totalRoll, 1);
            } else {
                pointsHealed = 1;
            }

            yield put(addToCharacterLog(`20 minutes was spent providing first aid for your wounds. You regain ${pointsHealed} HT.`));
            yield put(healDamage(pointsHealed));
            yield put(doBandaging());
            // Ruling: the 20 minute healing First Aid appears distinct from the 1 minute stop-the-bleeding First Aid,
            // but I'm going to say successfully performing this First Aid also stops bleeding
            yield put(removeInjury(InjuryId.Bleeding));
            break;
        }
        case CharacterConditionEventId.StopBleedingCheck: {
            // Manual: A First Aid roll to stop bleeding takes 1 minute, and comes before the bleeding roll. Once first
            // aid has been successfully adminis- tered, no more bleeding rolls are made.
            let firstAidRollSpec = rollSpecForSkill(SkillId.FirstAid, state.characterReducer.character, state.combatReducer, []);
            let firstAidRollAction = yield* waitForRoll(firstAidRollSpec, 'first aid roll to stop bleeding');
            let firstAidRollResult = firstAidRollAction.result as CharacteristicRollResult;

            if (firstAidRollResult.success) {
                yield put(addToCharacterLog(`1 minute spent successfully stopping the bleeding.`));
                yield put(removeInjury(InjuryId.Bleeding));
            } else {
                yield put(addToCharacterLog(`1 minute spent unsuccessfully stopping the bleeding.`));
            }

            break;
        }
        case CharacterConditionEventId.BleedingCheck: {
            // Manual: At the end of every minute after being wounded, the victim rolls against HT, at a -1 per full 5
            // points of damage he has taken. If he fails, he bleeds for 1 point of damage. On a critical failure, he
            // bleeds for 3 points of damage. On a critical success, the bleeding stops. On an ordinary success, he
            // does not bleed this minute, but must continue to roll every minute. If he does not bleed for three
            // consecutive minutes, the bleeding stops for good.

            if (!state.characterReducer.character.injuries[InjuryId.Bleeding]) {
                log(`Can't do a bleeding check if the character is not bleeding.`, LogLevels.Error);
                return;
            }

            let modifier: Modifier = {
                value: -Math.floor(state.characterReducer.character.damageTaken / 5),
                summary: 'Damage penalty to bleeding check',
                description: 'At the end of every minute after being wounded, the victim rolls against HT, at a -1 per full 5 points of damage he has taken',
            };

            let bleedingRollSpec = rollSpecForAttribute(AttributeId.Health, state.characterReducer.character, state.combatReducer, [modifier], 'Bleeding Check', true);
            let bleedingRollAction = yield* waitForRoll(bleedingRollSpec, 'bleeding roll');
            let bleedingRollResult = bleedingRollAction.result as CharacteristicRollResult;

            if (bleedingRollResult.criticalSuccess) {
                yield put(addToCharacterLog(`Bleeding check critical success. The bleeding stops.`));
                yield put(removeInjury(InjuryId.Bleeding));
            } else if (bleedingRollResult.criticalFailure) {
                yield put(setConsecutiveBleedCheckSuccesses(0));
                yield put(addToCharacterLog(`Bleeding check critical failure. You lose 3 HT.`));
                yield put(takeDamageSaga(state.characterReducer.character, 3, false));
            } else if (!bleedingRollResult.success) {
                yield put(setConsecutiveBleedCheckSuccesses(0));
                yield put(addToCharacterLog(`Bleeding check failure. You lose 1 HT.`));
                yield put(takeDamageSaga(state.characterReducer.character, 1, false));
            } else {
                yield put(setConsecutiveBleedCheckSuccesses(state.characterReducer.character.consecutiveBleedingCheckSuccesses + 1));
                state = (yield select()) as AppState;
                if (state.characterReducer.character.consecutiveBleedingCheckSuccesses >= 3) {
                    yield put(addToCharacterLog(`3 consecutive bleeding check successes, the bleeding stops.`));
                    yield put(removeInjury(InjuryId.Bleeding));
                } else {
                    yield put(addToCharacterLog(`Bleeding check success, no further damage right now.`));
                }
            }
            break;
        }
        case CharacterConditionEventId.CollapseCheck: {
            let character = state.characterReducer.character;
            let modifiers = [];
            let weakWill = character.vantages[VantageId.WeakWill];
            let strongWill = character.vantages[VantageId.StrongWill];
            if (weakWill) {
                modifiers.push({
                    value: -weakWill.level,
                    summary: 'Weak Will',
                    description: 'Weak Will penalty to collapse check',
                });
            }
            if (strongWill) {
                modifiers.push({
                    value: strongWill.level,
                    summary: 'Strong Will',
                    description: 'Strong Will bonus to collapse check',
                });
            }

            let collapseRollSpec = rollSpecForAttribute(AttributeId.Health, state.characterReducer.character, state.combatReducer, modifiers, 'Collapse Check', true);
            let collapseRollAction = yield* waitForRoll(collapseRollSpec, 'collapse roll');
            let collapseRollResult = collapseRollAction.result as CharacteristicRollResult;

            if (collapseRollResult.success) {
                yield put(addToCharacterLog(`Collapse check succeeded, you stay on your feet for now.`));
            } else {
                yield put(addToCharacterLog(`Collapse check failed, you fall unconscious.`));
                yield put(removeInjury(InjuryId.UnconsciousnessRisk));
                yield put(addInjury(InjuryId.Unconscious));
            }
            break;
        }
        case CharacterConditionEventId.PhysicalStunRecoveryCheck:
        case CharacterConditionEventId.MentalStunRecoveryCheck: {
            // Manual: Someone will be “stunned” if he takes damage greater than half his HT in one blow. If you are
            // stunned, all your active defens- es are at -4 until your next turn. At that time, roll against basic HT
            // to see whether you recover. A successful roll means you can act normally on that turn. A failed roll
            // means you are still stunned and stand there mindlessly . . . The “stunned” state continues until you can
            // make your HT roll and snap out of it. You may act again on the turn you roll successfully and shake off
            // the daze. Mental Stun: Someone who is surprised or shocked may also be mentally “stunned.” The effects
            // of this sort of stunning are just the same, but you must make your IQ roll, rather than your HT roll, to
            // snap out of it. You’re not hurt – you’re confused.

            let attribute = action.event === CharacterConditionEventId.PhysicalStunRecoveryCheck ? AttributeId.Health : AttributeId.Intelligence;

            let stunRollSpec = rollSpecForAttribute(attribute, state.characterReducer.character, state.combatReducer, [], 'Stun Recovery Check', true);
            let stunRollAction = yield* waitForRoll(stunRollSpec, 'stun recovery roll');
            let stunRollResult = stunRollAction.result as CharacteristicRollResult;

            if (stunRollResult.success) {
                yield put(addToCharacterLog(`Stun recovery check succeeded, you are no longer stunned.`));
                yield put(setStun(StunStatus.Lucid));
            } else {
                yield put(addToCharacterLog(`Stun recovery check failed, you are still stunned and skip your turn.`));
            }
            break;
        }
        case CharacterConditionEventId.ShockRecovery:
            // Manual: When you are injured, your DX and IQ, and any skills based on DX and IQ, are reduced by that
            // amount, on your next turn only. Example: If you take 3 hits of injury, your IQ, DX, and skills will be
            // at -3 on your next turn. Active defenses are not reduced. This subtraction will most often affect weapon
            // attacks – but any use of IQ, DX, or skills is affected. Therefore, on the turn after you are badly hurt,
            // it may be a good idea to try flight, All- Out Defense, or the like, rather than counterattacking
            // instantly. This is only a temporary effect due to shock. On your follow- ing turn, your skills are back
            // to normal.

            yield put(addToCharacterLog(`Recovered from shock.`));
            yield put(setShock(ShockStatus.Lucid, 0));
            break;
        case CharacterConditionEventId.OneDayNaturalRecovery:
        case CharacterConditionEventId.OneDayNaturalRecoveryWithPhysician: {
            // Manual: Natural recovery will cure any number of hits. At the end of each day of rest and decent food,
            // the victim may roll against his basic HT. A successful roll results in the recovery of 1 hit point. The
            // GM may modify the roll downward if conditions are bad, or upward if conditions are very good. A victim
            // under the care of a competent Physician (skill level 12+) gets +1 on all healing rolls.

            let modifiers = [];
            if (action.event === CharacterConditionEventId.OneDayNaturalRecoveryWithPhysician) {
                modifiers.push({
                    value: 1,
                    summary: 'Physician\'s Care',
                    description: 'The care of a competent physician gives +1 on all healing rolls',
                });
            }

            let recoveryRollSpec = rollSpecForAttribute(AttributeId.Health, state.characterReducer.character, state.combatReducer, modifiers, 'Natural Recovery Check', true);
            let recoveryRollAction = yield* waitForRoll(recoveryRollSpec, 'natural recovery roll');
            let recoveryRollResult = recoveryRollAction.result as CharacteristicRollResult;

            if (recoveryRollResult.success) {
                yield put(addToCharacterLog(`Natural recovery check succeeded, you recover 1 HT.`));
                yield put(setStun(StunStatus.Lucid));
            } else {
                yield put(addToCharacterLog(`Natural recovery check failed, you recover no HT.`));
            }
            break;
        }
        case CharacterConditionEventId.UnconsciousWakeUpCheckFifteenMinutes:
        case CharacterConditionEventId.UnconsciousWakeUpCheckOneHour: {
            // Manual: If your HT is still positive, roll vs. HT every hour to awaken (or, if you have lost no more
            // than 2 HT, roll every 15 minutes). If your HT is negative, but not fully negative, you will become
            // conscious in as many hours as your HT is negative, or a maxi- mum of 12 hours. Example: Your HT is -8
            // after the battle. You will wake up (still with -8 HT) in 8 hours. When you awaken, you can call for help
            // or even try to drag yourself to shelter. If your HT has gone fully negative – e.g., HT of -10 or worse
            // for someone with a basic HT of 10 – you are in bad shape. If you can make a roll on basic HT, you will
            // awaken (as above) after 12 hours, and can try to help yourself. If you fail the roll, you stay in a coma
            // and die unless you are helped within HT hours.

            let awakenRollSpec = rollSpecForAttribute(AttributeId.Health, state.characterReducer.character, state.combatReducer, [], 'Regain Consciousness Check', true);
            let awakenRollAction = yield* waitForRoll(awakenRollSpec, 'regain consciousness roll');
            let awakenRollResult = awakenRollAction.result as CharacteristicRollResult;

            if (awakenRollResult.success) {
                yield put(addToCharacterLog(`Regain consciousness check succeeded, you wake up.`));
                yield put(removeInjury(InjuryId.Unconscious));
            } else {
                yield put(addToCharacterLog(`Regain consciousness check failed, you're still unconscious.`));
            }
            break;
        }
        default:
            throw new Error(`Invalid character condition event ${action.event}`);
    }
}
