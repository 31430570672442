import {ValueType} from "react-select/lib/types";
import CryptoJs from "crypto-js";
import {log, LogLevels} from "./logger";

export const ucfirst = (input: string): string => {
  return input.charAt(0).toUpperCase() + input.slice(1);
};

export interface Option<T> {
  label: string;
  value: T;
}

export function validateSingleReactSelectValue<T>(value: ValueType<T>): T {
  if (Array.isArray(value)) {
    throw new Error("react-select value unexpectedly an array");
  }
  if (!value) {
    throw new Error("react-select value unexpectedly falsey");
  }
  return value as T;
}

export function generateArrayFullOf<T>(size: number, value: T): Array<T> {
  let out: Array<T> = [];
  for (let i = 0; i < size; i++) {
    out.push(value);
  }
  return out;
}

export function arrayChunk<T>(input: Array<T>, chunkSize: number): Array<Array<T>> {
  return Array.from({ length: Math.ceil(input.length / chunkSize) }, (v, i) =>
      input.slice(i * chunkSize, i * chunkSize + chunkSize)
  );
}

export function objectKeysByCallback<T>(input: Array<T>, callback: (element: T) => string): Record<string, T> {
  return input.reduce((carry, current) => {
    carry[callback(current)] = current;
    return carry;
  }, {} as Record<string, T>);
}

/**
 * Array.splice() modifies in place and sometimes you don't want that,
 * i.e. for React/Redux immutability purposes
 */
export function arraySpliceToNew<T>(input: Array<T>, insertAfterIndex: number, elementsToSplice: Array<T>): Array<T> {
  let output = [
    ...input.slice(0, insertAfterIndex + 1),
    ...elementsToSplice,
  ];
  if (insertAfterIndex >= input.length - 1) {
    return output;
  }
  return [
    ...output,
    ...input.slice(insertAfterIndex + 1, input.length)
  ];
}

/**
 * Array.splice() modifies in place and sometimes you don't want that,
 * i.e. for React/Redux immutability purposes
 */
export function arrayReplaceToNew<T>(input: Array<T>, index: number, element: T): Array<T> {
  return [
    ...input.slice(0, index),
    element,
    ...input.slice(index + 1, input.length)
  ];
}

/**
 * Array.splice() modifies in place and sometimes you don't want that,
 * i.e. for React/Redux immutability purposes
 */
export function arrayRemoveToNew<T>(input: Array<T>, startIndex: number, endIndex: number): Array<T> {
  return [
    ...input.slice(0, startIndex),
    ...input.slice(endIndex, input.length)
  ];
}

export const getObjectHash = (spec: Object): string => {
  return CryptoJs.SHA1(JSON.stringify(spec)).toString(CryptoJs.enc.Hex);
};

export const ghettoCloneObject = <T>(object: T): T => {
  return JSON.parse(JSON.stringify(object));
};

export const dateTimeFormatter = (timestamp: number): string => {
  let date = new Date(timestamp * 1000);
  // Why is january month 0?
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
};

export const selectText = (nodeId: string) => {
  let node = document.getElementById(nodeId);
  if (!node) {
    log(`Text selection failed to find element with id ${nodeId}`, LogLevels.Warn);
    return;
  }
  let range = document.createRange();
  range.selectNodeContents(node);
  let selection = window.getSelection();
  if (selection) {
    selection.removeAllRanges();
    selection.addRange(range);
  }
};
