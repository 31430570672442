import React from 'react';
import {connect} from 'react-redux';
import {Container, Row,} from 'react-bootstrap';
import {AppState} from "../../../modules";
import {bindActionCreators} from "redux";
import EncounterPanel from "./panels/EncounterPanel";

class EncounterPage extends React.Component<EncounterPageProps> {

    render() {
        return <Container fluid={true} className='content-container'>
            <Row className='fullscreen-row'>
                <EncounterPanel
                    {...this.props}
                />
            </Row>
        </Container>;
    }
}

export type EncounterPageProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
const mapStateToProps = (state: AppState) => {
    // Consider using reselect to memoize derived values here if performance becomes an issue
    return {
        gm: state.gamemasterReducer,
    };
};

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EncounterPage)
