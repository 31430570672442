import {weaponDefinitions, WeaponId} from "./weaponConstants";
import {Character} from "./characterConstants";
import {CombatState} from "../../modules/combat";
import {getBestSkillForWeapon} from "../gameLogic/derivedCharacterStats";
import {CharacteristicDefinition} from "./characteristicConstants";
import {SkillDefinition, skillDefinitions, SkillId} from "./skillConstants";
import {attributeDefinitions, AttributeId} from "./attributeConstants";

export enum RollType {
    Success = '@@roll/success',
    Attack = '@@roll/attack',
    FragHit = '@@roll/fragHit',
    Dodge = '@@roll/dodge',
    Parry = '@@roll/parry',
    Damage = '@@roll/damage',
    Generic = '@@roll/generic',
}

/**
 * RollSpecs contain just the data needed to execute a roll and return a result.
 * Roll execution is either automatic (computed internally) or manually provided by the user.
 */
export interface RollSpec  {
    type: RollType;
    modifiers: Array<Modifier>;
    title: string;
}

export interface CharacteristicRollSpec extends RollSpec {
    type: RollType.Success;
    /** The best applicable skill or attribute to roll against  */
    skillAttribute: CharacteristicDefinition;
    unmodifiedAttributeRoll: boolean;
}

export interface AttackRollSpec extends RollSpec {
    type: RollType.Attack;
    /** The applicable attack skill */
    skill: SkillDefinition;
    weaponId: WeaponId;
}

export interface DodgeRollSpec extends RollSpec {
    type: RollType.Dodge;
}

export interface ParryRollSpec extends RollSpec {
    type: RollType.Parry;
    weaponId: WeaponId;
}

export interface DamageRollSpec extends RollSpec, AttackResultData {
    type: RollType.Damage;
    weaponId: WeaponId;
    targetDr: number;
    softTarget: boolean;
}

export interface FragHitRollSpec extends RollSpec {
    type: RollType.FragHit;
    weaponId: WeaponId;
    // Distance between weapon impact and the target
    // In yards
    impactRangeToTarget: number;
}

/**
 * Just roll a given number of dice and return the total
 */
export interface GenericRollSpec extends RollSpec {
    type: RollType.Generic;
    title: string;
    diceCount: number;
}

export interface RollResult  {
    totalRoll: number;
    type: RollType;
    rollSpec: RollSpec;
}

export interface SuccessRollResult extends RollResult {
    success: boolean;
    criticalSuccess: boolean;
    criticalFailure: boolean;
}

export interface CharacteristicRollResult extends SuccessRollResult {
    type: RollType.Success;
    rollSpec: CharacteristicRollSpec;
    characteristic: CharacteristicDefinition;
}

export interface AttackResultData {
    // These will only ever be 1 or 0 for non-automatic weapons
    normalHits: number;
    maxDamageHits: number;
    // If the weapon has fragmentation damage, do a separate roll to see if the frag damage hits.
    fragDamageHit?: boolean;
    impactDistanceToTarget?: number;
}

export interface AttackRollResult extends RollResult, AttackResultData {
    success: boolean;
    type: RollType.Attack;
    rollSpec: AttackRollSpec;
    /** The applicable attack skill */
    skill: SkillDefinition;
    // Roll margin; positive for success, negative for failure
    // Used for automatic weapons and explosives
    differential: number;
    automaticHit: boolean;
    automaticMiss: boolean;
    malfunction: boolean;
}

export interface DodgeRollResult extends SuccessRollResult {
    type: RollType.Dodge;
    rollSpec: DodgeRollSpec;
}

export interface ParryRollResult extends SuccessRollResult {
    type: RollType.Parry;
    rollSpec: ParryRollSpec;
}

export interface DamageRollResult extends RollResult {
    type: RollType.Damage;
    rollSpec: DamageRollSpec;
    totalDamage: number;
}

export interface FragHitRollResult extends SuccessRollResult {
    type: RollType.FragHit;
}

export interface GenericRollResult extends RollResult {
    type: RollType.Generic;
}

export interface Modifier  {
    value: number;
    summary: string;
    description: string;
}

export const rollSpecForAttribute = (
    attributeId: AttributeId,
    character: Character,
    combatState: CombatState,
    modifiers: Array<Modifier>,
    title: string | null,
    unmodifiedAttributeRoll: boolean = false
): CharacteristicRollSpec => {
    let def = attributeDefinitions[attributeId];
    if (def === undefined) {
        throw new Error(`Can't find attribute ${attributeId}`);
    }
    return {
        type: RollType.Success,
        skillAttribute: def,
        modifiers,
        title: title || `Roll Against ${def.name}`,
        unmodifiedAttributeRoll,
    };
};

export const rollSpecForSkill = (
    skillId: SkillId,
    character: Character,
    combatState: CombatState,
    modifiers: Array<Modifier>
): CharacteristicRollSpec => {
    let def = skillDefinitions[skillId];
    if (def === undefined) {
        throw new Error(`Can't find skill ${skillId}`);
    }
    return {
        type: RollType.Success,
        skillAttribute: def,
        modifiers,
        title: `Roll Against ${def.name}`,
        unmodifiedAttributeRoll: false,
    };
};

export const rollSpecForAttack = (weaponId: WeaponId, character: Character, combatState: CombatState, modifiers: Array<Modifier>): AttackRollSpec => {
    let weaponDef = weaponDefinitions[weaponId];
    let skillDefinition = getBestSkillForWeapon(weaponId, character, combatState);

    return {
        type: RollType.Attack,
        skill: skillDefinition,
        weaponId: weaponId,
        modifiers,
        title: `Attack with ${weaponDef.name}`,
    };
};

export const rollSpecForFragHit = (weaponId: WeaponId, modifiers: Array<Modifier>, targetRange: number): FragHitRollSpec => {
    let weaponDef = weaponDefinitions[weaponId];
    return {
        type: RollType.FragHit,
        weaponId: weaponId,
        modifiers,
        title: `Fragmentation Hit with ${weaponDef.name}`,
        impactRangeToTarget: targetRange,
    };
};

export const rollSpecForMalfunction = (weaponId: WeaponId, character: Character, combatState: CombatState, modifiers: Array<Modifier>): CharacteristicRollSpec => {
    let skillDefinition = getBestSkillForWeapon(weaponId, character, combatState);

    return {
        type: RollType.Success,
        skillAttribute: skillDefinition,
        modifiers, //: modifiers.concat(modifiedValue.value.modifiers),
        title: `Jam Clear: Roll Against ${skillDefinition.name}`,
        unmodifiedAttributeRoll: false,
    };
};

export const rollSpecForDodge = (character: Character, modifiers: Array<Modifier>): DodgeRollSpec => {
    return {
        type: RollType.Dodge,
        title: `Dodge roll`,
        modifiers,
    };
};

export const rollSpecForParry = (character: Character, modifiers: Array<Modifier>, weaponId: WeaponId): ParryRollSpec => {
    return {
        type: RollType.Parry,
        title: `Parry roll`,
        modifiers,
        weaponId,
    };
};

export const rollSpecForDamage = (
    modifiers: Array<Modifier>,
    weaponId: WeaponId,
    targetDr: number,
    softTarget: boolean,
    normalHits: number,
    maxDamageHits: number,
    fragDamageHit?: boolean,
    impactDistanceToTarget?: number,
): DamageRollSpec => {
    return {
        type: RollType.Damage,
        title: `Damage roll`,
        modifiers,
        weaponId,
        targetDr,
        softTarget,
        normalHits,
        maxDamageHits,
        fragDamageHit,
        impactDistanceToTarget,
    };
};

export const rollSpecForGeneric = (modifiers: Array<Modifier>, diceCount: number, title: string): GenericRollSpec => {
    return {
        type: RollType.Generic,
        title,
        modifiers,
        diceCount,
    };
};

