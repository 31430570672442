import {
  Attribute,
  AttributeDefinition,
  attributeDefinitions,
  AttributeId,
  AttributeModifier,
  attributePointCosts
} from "../constants/attributeConstants";
import {
  getSkillCost,
  getSkillFirstLevel,
  Skill,
  SkillDefault,
  SkillDefinition,
  skillDefinitions,
  SkillId
} from "../constants/skillConstants";
import {
  isDisadvantage,
  Vantage,
  VantageCostType,
  VantageDefinition,
  vantageDefinitions,
  VantageId
} from "../constants/vantageConstants";
import {Character,} from "../constants/characterConstants";
import {
  Characteristic,
  CharacteristicDefinition,
  CharacteristicId,
  CharacteristicType
} from "../constants/characteristicConstants";
import {Equipment, EquipmentType, getEquipmentDefinition, getEquipmentType} from "../constants/equipmentConstants";
import {positionDefinitions, PositionId} from "../constants/combatConstants";
import {Weapon, weaponCategories, weaponDefinitions, WeaponId} from "../constants/weaponConstants";
import {CombatState, initialCombatState as initialCombatState, ShockStatus, StunStatus} from "../../modules/combat";
import {CharacteristicRollSpec, Modifier} from "../constants/rollConstants";
import {
  CharacterStatusIcon,
  injuryDefinitions,
  InjuryId,
  shockIcon,
  stunIcon
} from "../constants/characterConditionConstants";
import {log} from "../logger";

export const getCharacteristicFromId = (id: CharacteristicId): CharacteristicDefinition => {
  if (id.startsWith('@@skill')) {
    return skillDefinitions[id as SkillId];
  } else if (id.startsWith('@@attribute')) {
    return attributeDefinitions[id as AttributeId];
  } else if (id.startsWith('@@vantage')) {
    return vantageDefinitions[id as VantageId];
  } else {
    throw new Error(`Invalid characteristic id ${id}`);
  }
};

export const getCharacteristicTypeLabel = (id: CharacteristicId): string => {
  let def = getCharacteristicFromId(id);
  switch (def.type) {
    case CharacteristicType.Attribute:
      return 'Attribute';
    case CharacteristicType.Skill:
      return 'Skill';
    case CharacteristicType.Vantage:
      return isDisadvantage(id as VantageId) ? 'Disadvantage' : 'Advantage';
  }
};

export interface CharacteristicLevelDetailDisplay {
  currentLevelLabel: string;
  nextLevelCost: number | null;
  previousLevelCost: number | null;
}

export const getLevelDetails = (id: CharacteristicId, character: Character): CharacteristicLevelDetailDisplay => {
  let def = getCharacteristicFromId(id);
  switch (def.type) {
    case CharacteristicType.Attribute: {
      let instance = getCharacteristicInstance(id, character) as Attribute;
      return {
        currentLevelLabel: instance.level.toString(10),
        nextLevelCost: attributePointCosts[instance.level] - attributePointCosts[instance.level - 1],
        previousLevelCost: instance.level > 1 ? attributePointCosts[instance.level - 2] - attributePointCosts[instance.level - 1] : null,
      }
    }
    case CharacteristicType.Skill: {
      let skillId = id as SkillId;
      let skillDef = def as SkillDefinition;
      let instance = getCharacteristicInstance(id, character) as Skill | null;
      let firstLevel = getSkillFirstLevel(skillId);
      let firstLevelCost = getSkillCost(skillId, firstLevel);

      // Character doesn't have the skill
      if (!instance) {
        // A bit cheesy
        let bestDefault = getSkillBestDefault(skillId, character, initialCombatState);
        if (bestDefault) {
          let attributeDef = attributeDefinitions[bestDefault.attributeId];
          return {
            currentLevelLabel: `Not Taken; Default ${attributeDef.abbreviation} ${bestDefault.modifier}`,
            nextLevelCost: firstLevelCost,
            previousLevelCost: null,
          };
        } else {
          return {
            currentLevelLabel: `Not Taken; No Default`,
            nextLevelCost: firstLevelCost,
            previousLevelCost: null,
          };
        }
      }

      let baseAttribute = attributeDefinitions[skillDef.baseAttributeId];
      // Character does have the skill
      let operator = instance.level >= 0 ? '+' : '';
      let previousCost = getSkillCost(skillId, instance.level);
      return {
        currentLevelLabel: `${baseAttribute.abbreviation} ${operator} ${instance.level}`,
        nextLevelCost: getSkillCost(skillId, instance.level + 1),
        previousLevelCost: previousCost ? -previousCost : null,
      };
    }
    case CharacteristicType.Vantage: {
      let vantageDef = def as VantageDefinition;
      let instance = getCharacteristicInstance(id, character) as Vantage | null;
      if (!instance) {
        return {
          currentLevelLabel: 'Not Taken',
          nextLevelCost: vantageDef.cost[0],
          previousLevelCost: null,
        };
      }
      switch (vantageDef.costType) {
        case VantageCostType.Single: {
          return {
            currentLevelLabel: 'Taken',
            nextLevelCost: null,
            previousLevelCost: -vantageDef.cost[0],
          }
        }
        case VantageCostType.PerLevel: {
          return {
            currentLevelLabel: `Level ${instance.level}`,
            nextLevelCost: vantageDef.cost[0],
            previousLevelCost: -vantageDef.cost[0],
          }
        }
        case VantageCostType.StaticList: {
          let costs = vantageDef.cost;
          let previousLevelCost = instance.level > 1 ? costs[instance.level - 2] : 0;
          let thisLevelCost = costs[instance.level - 1];
          let nextLevelCost = costs[instance.level];
          return {
            currentLevelLabel: `Level ${instance.level}`,
            nextLevelCost: costs.length > instance.level ? nextLevelCost - thisLevelCost : null,
            previousLevelCost: instance.level > 0 ? -(thisLevelCost - previousLevelCost) : null,
          }
        }
      }
    }
  }
};

export const getAttributeTotalCost = (character: Character): number => {
  return Object.values(character.attributes).reduce((carry, attribute) => {
    return carry + attributePointCosts[attribute.level - 1];
  }, 0);
};

export const getSkillTotalCost = (character: Character): number => {
  return Object.values(character.skills).reduce((carry, skill) => {
    if (!skill) {
      return carry;
    }
    let firstLevel = getSkillFirstLevel(skill.id);
    let levelArrayLength = skill.level - firstLevel + 1;
    if (levelArrayLength < 0) {
      log('wat');
    }
    let levelArray = Array.from(new Array(levelArrayLength), (x,i) => i + firstLevel);
    
    return carry + levelArray.reduce((carry, level) => {
      return carry + (getSkillCost(skill.id, level) || 0)
    }, 0);
  }, 0);
};

export const getVantageTotalCost = (character: Character): number => {
  return Object.values(character.vantages).reduce((carry, vantage) => {
    if (!vantage) {
      return carry;
    }
    let def = vantageDefinitions[vantage.id];
    switch (def.costType) {
      case VantageCostType.Single:
        return carry + def.cost[0];
      case VantageCostType.PerLevel:
        return carry + (def.cost[0] * vantage.level);
      case VantageCostType.StaticList:
        return carry + def.cost[vantage.level - 1];
      default:
        throw new Error(`Unsupported vantage cost type ${def.costType}`);
    }
  }, 0)
};

export const getCharacterTotalPoints = (character: Character): number => {
  let attCost = getAttributeTotalCost(character);
  let skillCost = getSkillTotalCost(character);
  let vantageCost = getVantageTotalCost(character);

  return attCost + skillCost + vantageCost;
};

export const editCharacteristic = (id: CharacteristicId, character: Character, differential: number): Character => {
  let def = getCharacteristicFromId(id);
  switch (def.type) {
    case CharacteristicType.Attribute: {
      let instance = getCharacteristicInstance(id, character) as Attribute;
      instance.level = instance.level + differential;
      return {
        ...character,
        attributes: {
          ...character.attributes,
        }
      };
    }
    case CharacteristicType.Skill: {
      let skillId = id as SkillId;
      let skillDef = def as SkillDefinition;
      let instance = getCharacteristicInstance(id, character) as Skill | null;
      let firstLevel = getSkillFirstLevel(skillId);
      let firstLevelCost = getSkillCost(skillId, firstLevel);

      // Character doesn't have the skill
      if (!instance) {
        return {
          ...character,
          skills: {
            ...character.skills,
            [id]: {
              id: skillId,
              level: firstLevel,
            }
          }
        };
      }
      // Character does have the skill
      let skills = {...character.skills};
      let newLevel = instance.level + differential;
      if (newLevel < firstLevel) {
        delete skills[skillId];
      } else {
        skills[skillId] = {
          id: skillId,
          level: newLevel,
        };
      }
      return {
        ...character,
        skills,
      };
    }
    case CharacteristicType.Vantage: {
      let vantageDef = def as VantageDefinition;
      let vantageId = id as VantageId;
      let instance = getCharacteristicInstance(id, character) as Vantage | null;
      if (instance) {

        let vantages = {...character.vantages};
        let newLevel = instance.level + differential;
        if (newLevel === 0) {
          delete vantages[vantageId];
        } else {
          vantages[vantageId] = {
            id: vantageId,
            level: newLevel,
          };
        }

        return {
          ...character,
          vantages,
        };
      } else {
        return {
          ...character,
          vantages: {
            ...character.vantages,
            [id]: {
              id: id as VantageId,
              level: 1,
            }
          }
        };
      }
    }
  }
};

export const getCharacteristicInstance = (id: CharacteristicId, character: Character): Characteristic | null => {
  let def = getCharacteristicFromId(id);
  switch (def.type) {
    case CharacteristicType.Attribute:
      return character.attributes[id as AttributeId];
    case CharacteristicType.Skill:
      return character.skills[id as SkillId] || null;
    case CharacteristicType.Vantage:
      return character.vantages[id as VantageId] || null;
  }
};

export const getCharacteristicCurrentValue = (id: CharacteristicId, character: Character, combatState: CombatState): CharacteristicValueWithModifiers => {
  let def = getCharacteristicFromId(id);
  switch (def.type) {
    case CharacteristicType.Attribute:
      return getAttributeCurrentValue(id as AttributeId, character, combatState);
    case CharacteristicType.Skill:
      return getSkillCurrentValue(id as SkillId, character, combatState);
    case CharacteristicType.Vantage:
      throw Error(`Advantages/disadvantages don't have values, only modifiers. Tried to get value for id ${id}`);
    default:
      throw Error(`Invalid characteristic type ${def.type} for id ${id}`);
  }
};

export const getCharacteristicValueForRollSpec = (spec: CharacteristicRollSpec, character: Character, combatState: CombatState): CharacteristicValueWithModifiers => {
  let characteristic = spec.skillAttribute;
  switch (characteristic.type) {
    case CharacteristicType.Attribute:
      let value = getAttributeCurrentValue(characteristic.id as AttributeId, character, combatState);
      if (spec.unmodifiedAttributeRoll) {
        value.modifiers = [];
      }
      return value;
    case CharacteristicType.Skill:
      // While skills' underlying attributes can be rolled against unmodified, I don't know of any cases currently
      //  where skills themselves are rolled against unmodified
      return getSkillCurrentValue(characteristic.id as SkillId, character, combatState);
    default:
      throw new Error(`Invalid characteristic roll for id ${characteristic.id}`);
  }
};

export const getAttributeUnmodifiedValue = (attributeId: AttributeId, character: Character): number => {
  return character.attributes[attributeId].level;
};

export const getModifiedCharacteristicValue = (value: CharacteristicValueWithModifiers): number => {
  return value.modifiers.reduce((carry, modifier) => carry + modifier.value, value.value);
};

/**
 * Represents a skill/attribute value that's been modified by something affecting the (underlying) attributes
 * For instance, if a character is shocked, their IQ, DX, and all skills relying on them, will be reduced.
 * This interface consists of the unmodified value of the skill/attribute as well as the modifying values to be applied.
 */
export interface CharacteristicValueWithModifiers {
  value: number;
  modifiers: Array<Modifier>;
}

/**
 * @param attributeId
 * @param character
 * @param combatState
 * @param isActiveDefense Active defense rolls follow slightly different rules for what injuries affect them
 */
export const getAttributeCurrentValue = (attributeId: AttributeId, character: Character, combatState: CombatState, isActiveDefense: boolean = false): CharacteristicValueWithModifiers => {
  let modifiers = [];
  switch (attributeId) {
    case AttributeId.Intelligence:
    case AttributeId.Dexterity: {
      if (combatState.shockStatus === ShockStatus.Shocked) {
        let shockModifier = {
          value: -combatState.shockDamage,
          summary: 'Shock',
          description: 'Lost DX/IQ due to shock',
        };
        modifiers.push(shockModifier);
      }
      return {
        value: character.attributes[attributeId].level,
        modifiers,
      };
    }
    case AttributeId.Strength: {
      let fatigue = Object.values(character.fatiguePoints).reduce((carry, value) => carry + value, 0);
      if (fatigue > 0) {
        let fatigueModifier = {
          value: -fatigue,
          summary: 'Fatigue',
          description: 'Lost strength due to fatigue',
        };
        modifiers.push(fatigueModifier);
      }
      return {
        value: Math.max(character.attributes[AttributeId.Strength].level, 0),
        modifiers,
      };
    }
    case AttributeId.Health: {
      if (character.damageTaken > 0) {
        let damageModifier = {
          value: -character.damageTaken,
          summary: 'Damage',
          description: 'Lost health due to damage',
        };
        modifiers.push(damageModifier);
      }
      return {
        value: character.attributes[AttributeId.Health].level,
        modifiers,
      };
    }
    default:
      throw Error(`Undefined attribute id ${attributeId}`);
  }
};

export const canUseSkill = (skillId: SkillId, character: Character): boolean => {
  let skillDef = skillDefinitions[skillId];
  return skillId in character.skills || skillDef.defaults.length > 0;
};

export const getSkillBestApplicableAttribute = (skillId: SkillId, character: Character, combatState: CombatState): AttributeModifier => {
  let skillDef = skillDefinitions[skillId];

  let attributeId: AttributeId;
  let modifier;
  let skill = character.skills[skillId];
  if (skill) {
    // Character has this skill, use their skill level
    attributeId = skillDef.baseAttributeId;
    modifier = skill.level;
  } else {
    // Character doesn't have this skill
    // Find the best default to use
    let bestDefault = getSkillBestDefault(skillId, character, combatState);
    if (!bestDefault) {
      throw Error(`Couldn't find a valid skill value for character ${character.name} skill ${skillDef.id}.`);
    }
    attributeId = bestDefault.attributeId;
    modifier = bestDefault.modifier;
  }
  return {
    id: attributeId,
    modifier,
  };
};

export const getSkillBestDefault = (skillId: SkillId, character: Character, combatState: CombatState): SkillDefault | null => {
  let skillDef = skillDefinitions[skillId];
  let defaults = skillDef.defaults;
  if (defaults.length === 0) {
    return null;
  }

  return defaults.reduce((lastMax: SkillDefault | null, currentValue: SkillDefault) => {
    if (!lastMax) {
      return currentValue;
    }
    let lastModifiedValue = getModifiedCharacteristicValue(getAttributeCurrentValue(lastMax.attributeId, character, combatState)) + lastMax.modifier;
    let currentModifiedValue = getModifiedCharacteristicValue(getAttributeCurrentValue(currentValue.attributeId, character, combatState)) + currentValue.modifier;
    if (currentModifiedValue > lastModifiedValue) {
      return currentValue;
    }
    return lastMax;
  }, null);
};

export const getSkillCurrentValue = (skillId: SkillId, character: Character, combatState: CombatState): CharacteristicValueWithModifiers => {
  let skillDef = skillDefinitions[skillId];
  let modifiers = [];
  let attributeModifier = getSkillBestApplicableAttribute(skillId, character, combatState);
  let attributeDef = attributeDefinitions[attributeModifier.id];
  if (attributeModifier.modifier !== 0) {
    let skillModifier = getSkillModifier(attributeModifier.modifier, skillDef, attributeDef);
    modifiers.push(skillModifier);
  }
  let attrValue = getAttributeCurrentValue(attributeModifier.id, character, combatState);
  return {
    value: attrValue.value,
    modifiers: attrValue.modifiers.concat(modifiers),
  };
};

export const getSkillModifier = (modifierValue: number, skillDef: SkillDefinition, attributeDef: AttributeDefinition): Modifier => {
  return {
    value: modifierValue,
    summary: `Skill Modifier`,
    description: `${skillDef.name} modifier of ${attributeDef.abbreviation}`,
  };
};

export interface CharacteristicValue  {
  type: CharacteristicType;
  definition: CharacteristicDefinition;
  value: CharacteristicValueWithModifiers;
}

export const getCharacteristicValue = (characteristic: CharacteristicDefinition, character: Character, combatState: CombatState): CharacteristicValue => {
  let currentDef = getCharacteristicFromId(characteristic.id);
  let currentValue = getCharacteristicCurrentValue(characteristic.id, character, combatState);
  return {
    definition: currentDef,
    type: characteristic.type,
    value: currentValue,
  };
};

export const getBestCharacteristic = (characteristics: Array<CharacteristicDefinition>, character: Character, combatState: CombatState): CharacteristicValue => {
  return characteristics.reduce((lastMax: CharacteristicValue, characteristic: CharacteristicDefinition) => {
    let currentReturn = getCharacteristicValue(characteristic, character, combatState);
    if (!lastMax) {
      return currentReturn;
    }
    let lastValue = getModifiedCharacteristicValue(lastMax.value);
    if (getModifiedCharacteristicValue(currentReturn.value) > lastValue) {
      return currentReturn;
    }
    return lastMax;
  }, getCharacteristicValue(characteristics[0], character, combatState));
};

export const getBestSkillForWeapon = (weaponId: WeaponId, character: Character, combatState: CombatState): SkillDefinition => {
  let categoryId = weaponDefinitions[weaponId].category;
  let category = weaponCategories[categoryId];
  let possibleSkills = category.skills;

  return getBestCharacteristic(possibleSkills.map(skillId => skillDefinitions[skillId]), character, combatState).definition as SkillDefinition;
};

/**
 * Speed value without encumbrance, position, and injury factored in.
 *
 * @param character
 * @param combatState
 */
export const getBasicSpeed = (character: Character, combatState: CombatState): CharacteristicValueWithModifiers => {
  let modifiers: Array<Modifier> = [];
  let health = getAttributeCurrentValue(AttributeId.Health, character, combatState);
  let dex = getAttributeCurrentValue(AttributeId.Dexterity, character, combatState);
  let strength = getAttributeCurrentValue(AttributeId.Strength, character, combatState);
  let baseSpeed  = (health.value + dex.value) / 4;
  let runningSpeedValue = baseSpeed;
  // Manual: Likewise, your Move score is not affected by fatigue until your ST reaches 3. At that point, cut your
  //  Move in half, rounding down."
  if (strength.value <= 3) {
    let fatigueSpeedReduction = runningSpeedValue / 2;
    modifiers.push({
      value: -(runningSpeedValue / 2),
      summary: 'Fatigue speed reduction',
      description: 'Speed is halved when ST is at 3 or below',
    });
    runningSpeedValue -= fatigueSpeedReduction;
  }
  // Manual: 3 or less hit points left: Your Move and Dodge are cut in half; you are reeling from your wounds.
  if (health.value <= 3) {
    let healthSpeedReduction = runningSpeedValue / 2;
    modifiers.push({
      value: -(runningSpeedValue / 2),
      summary: 'Wounded speed reduction',
      description: 'Speed is halved when HT is at 3 or below as you are reeling from your wounds',
    });
    runningSpeedValue -= healthSpeedReduction;
  }
  return {
    value: baseSpeed,
    modifiers,
  };
};

export enum EncumbranceType {
  No = 'No',
  Light = 'Light',
  Medium = 'Medium',
  Heavy = 'Heavy',
  ExtraHeavy = 'ExtraHeavy',
  Hernia = 'Hernia',
}

export interface EncumbranceDefinition {
  type: EncumbranceType;
  strengthRatioThreshold: number;
  movementPenalty: number;
  description: string;
}

export const encumbranceLevels: Record<EncumbranceType, EncumbranceDefinition> = {
  [EncumbranceType.No]: {
    type: EncumbranceType.No,
    strengthRatioThreshold: 2,
    movementPenalty: 0,
    description: 'Unencumbered',
  },
  [EncumbranceType.Light]: {
    type: EncumbranceType.Light,
    strengthRatioThreshold: 4,
    movementPenalty: 1,
    description: 'Light encumbrance',
  },
  [EncumbranceType.Medium]: {
    type: EncumbranceType.Medium,
    strengthRatioThreshold: 6,
    movementPenalty: 2,
    description: 'Medium encumbrance',
  },
  [EncumbranceType.Heavy]: {
    type: EncumbranceType.Heavy,
    strengthRatioThreshold: 12,
    movementPenalty: 3,
    description: 'Heavy encumbrance',
  },
  [EncumbranceType.ExtraHeavy]: {
    type: EncumbranceType.ExtraHeavy,
    strengthRatioThreshold: 20,
    movementPenalty: 4,
    description: 'Extra heavy encumbrance',
  },
  [EncumbranceType.Hernia]: {
    type: EncumbranceType.Hernia,
    strengthRatioThreshold: 30,
    movementPenalty: 10,
    description: 'You\'re actively having a hernia',
  },
};

/**
 * Get lbs carried by character
 * @param character
 */
export const getWeightCarried = (character: Character): number => {
  return Object.values(character.inventory).reduce((carry, current) => {
    if (current) {
      return carry + (current.quantity * getEquipmentDefinition(current.id).weight);
    } else {
      return carry;
    }
  }, 0);
};

export const getEncumbranceLevel = (character: Character, combatState: CombatState): EncumbranceDefinition => {
  let strength = getModifiedCharacteristicValue(getAttributeCurrentValue(AttributeId.Strength, character, combatState));
  let ratio = getWeightCarried(character) / strength;
  if (ratio > 30) {
    throw Error(`Your character is carrying ${getWeightCarried(character)} pounds and can't carry more than ${30*strength} pounds.`)
  }
  let closestEncumbrance = encumbranceLevels[EncumbranceType.No];
  Object.values(encumbranceLevels).forEach((def) => {
    if ((ratio <= def.strengthRatioThreshold) && (ratio > closestEncumbrance.strengthRatioThreshold)) {
      closestEncumbrance = def;
    }
  });
  return closestEncumbrance;
};

/**
 * Speed value with encumbrance, position, and injury factored in.
 * @param character
 * @param combatState
 * @param position
 */
export const getSpeed = (character: Character, combatState: CombatState, position: PositionId = PositionId.Standing): CharacteristicValueWithModifiers => {
  let modifiers: Array<Modifier> = [];
  let basicSpeed = getBasicSpeed(character, combatState);
  let speedValue = getModifiedCharacteristicValue(getBasicSpeed(character, combatState));

  let encumbranceLevel = getEncumbranceLevel(character, combatState);
  if (encumbranceLevel.movementPenalty !== 0) {
    modifiers.push({
      value: -encumbranceLevel.movementPenalty,
      summary: 'Encumbrance speed reduction',
      description: `${encumbranceLevel.description} reduces speed by ${encumbranceLevel.movementPenalty}`,
    });
    speedValue -= encumbranceLevel.movementPenalty;
  }

  let positionDef = positionDefinitions[position];
  let positionMultiplier = positionDef.movementMultiplier;

  if (positionMultiplier !== 1) {
    let positionMovementPenalty = (1 - positionMultiplier) * speedValue;
    modifiers.push({
      value: -positionMovementPenalty,
      summary: 'Position speed reduction',
      description: `${positionDef.name} reduces speed by ${positionMovementPenalty}`,
    });
    speedValue -= positionMovementPenalty;
  }

  return {
    value: basicSpeed.value,
    modifiers: basicSpeed.modifiers.concat(modifiers),
  };
};

export const doesCharacterHaveAWeapon = (character: Character): boolean => {
  return Object.values(character.inventory).some((equipment) => {
    if (equipment) {
      return getEquipmentType((equipment as Equipment).id) === EquipmentType.Weapon;
    }
    return false;
  });
};

export const getWeapons = (character: Character): Array<Weapon> => {
  return (Object.values(character.inventory) as Array<Weapon>).filter((equipment) => {
    if (equipment) {
      return getEquipmentType((equipment as Equipment).id) === EquipmentType.Weapon;
    }
    return false;
  });
};

export const getEquippedWeapons = (character: Character): Array<Weapon> => {
  return (getWeapons(character)).filter((weapon) => weapon.equipped);
};

const getRiskOfDeathThreshold = (character: Character): number => {
  return -getAttributeUnmodifiedValue(AttributeId.Health, character);
};

export const getIsInstantlyDead = (character: Character, hitPoints: number): boolean => {
  return hitPoints < getRiskOfDeathThreshold(character) * 5;
};

export const getIsRiskingDeath = (character: Character, hitPoints: number): boolean => {
  return hitPoints < getRiskOfDeathThreshold(character);
};

export const getIsRiskingUnconsciousness = (character: Character, hitPoints: number): boolean => {
  return hitPoints <= 0;
};

export const getIsReeling = (character: Character, hitPoints: number): boolean => {
  return hitPoints <= 3;
};

/**
 * Manual: -HT hit points: You must make your HT roll (use basic HT) or die. Another roll is required after each
 *  further loss of 5 hit points.
 *
 * @param oldHitPoints
 * @param newHitPoints
 * @param character
 */
export const getNeedsToMakeDeathRoll = (oldHitPoints: number, newHitPoints: number, character: Character): boolean => {
  let damage = oldHitPoints - newHitPoints;
  if (!getIsRiskingDeath(character, newHitPoints)) {
    return false;
  }
  if (!getIsRiskingDeath(character, oldHitPoints) && getIsRiskingDeath(character, newHitPoints)) {
    return true;
  }

  // Manual: Another roll is required after each further loss of 5 hit points.
  if ((oldHitPoints - getRiskOfDeathThreshold(character)) % 5 - damage <= -5) {
    return true;
  }

  return false;
};

/**
 * Manual: Anyone who takes damage greater than than half his HT in one blow must immediately roll against his basic
 *  HT. If he fails the roll, he falls and is stunned (see below). If he makes his HT roll, he keeps his footing, but
 * he is still stunned.
 *
 * @param oldHitPoints
 * @param newHitPoints
 * @param character
 */
export const getNeedsToMakeKnockdownRoll = (oldHitPoints: number, newHitPoints: number, character: Character): boolean => {
  let damage = oldHitPoints - newHitPoints;
  let baseHp = getAttributeUnmodifiedValue(AttributeId.Health, character);
  return damage > baseHp / 2;
};

export const getCharacterStatusIcons = (character: Character, combatState: CombatState): Array<CharacterStatusIcon> => {
  let icons: Array<CharacterStatusIcon> = [];

  for (let injuryId in character.injuries) {
    let def = injuryDefinitions[injuryId as InjuryId];
    icons.push(def);
  }

  if (combatState.shockStatus === ShockStatus.Shocked) {
    icons.push(shockIcon);
  }

  if (combatState.stunStatus !== StunStatus.Lucid) {
    icons.push(stunIcon);
  }

  return icons;
};
