import {Option} from "../utils";

export enum CombatActionTypes {
  Maneuver = '@@combatActionType/Maneuver',
  FreeAction = '@@combatActionType/FreeAction',
}

export enum ManeuverId {
  Move = '@@maneuver/Move',
  ChangePosition = '@@maneuver/ChangePosition',
  Ready = '@@maneuver/Ready',
  Aim = '@@maneuver/Aim',
  Attack = '@@maneuver/Attack',
  AllOutAttack = '@@maneuver/AllOutAttack',
  AllOutDefense = '@@maneuver/AllOutDefense',
  LongAction = '@@maneuver/LongAction',
}

export enum FreeActionId {
  DefendDodge = '@@freeAction/DefendDodge',
  DefendParry = '@@freeAction/DefendParry',
  RollForDamage = '@@freeAction/RollForDamage',
  TakeDamage = '@@freeAction/TakeDamage',
}

export type CombatActionId = ManeuverId | FreeActionId;

export interface CombatActionDefinition {
  id: CombatActionId;
  name: string;
  type: CombatActionTypes;
}

export interface ManeuverDefinition extends CombatActionDefinition {
  id: ManeuverId;
}

export interface FreeActionDefinition extends CombatActionDefinition {
  id: FreeActionId;
}

export const getCombatActionSelectLabel = (id: CombatActionId): string => {
  let def = combatActionDefinitions[id];
  let suffix: string;
  switch (def.type) {
    case CombatActionTypes.Maneuver:
      suffix = '(T)';
      break;
    case CombatActionTypes.FreeAction:
      suffix = '(F)';
      break;
    default:
      throw new Error(`Unsupported combat action type ${def.type}`);
  }
  return `${def.name} ${suffix}`;
};

export const combatActionDefinitions: Record<CombatActionId, CombatActionDefinition> = {
  [ManeuverId.Move]: {
    id: ManeuverId.Move,
    name: 'Move',
    type: CombatActionTypes.Maneuver,
  },
  [ManeuverId.ChangePosition]: {
    id: ManeuverId.ChangePosition,
    name: 'Change Position',
    type: CombatActionTypes.Maneuver,
  },
  [ManeuverId.Ready]: {
    id: ManeuverId.Ready,
    name: 'Ready',
    type: CombatActionTypes.Maneuver,
  },
  [ManeuverId.Aim]: {
    id: ManeuverId.Aim,
    name: 'Aim',
    type: CombatActionTypes.Maneuver,
  },
  [ManeuverId.Attack]: {
    id: ManeuverId.Attack,
    name: 'Attack',
    type: CombatActionTypes.Maneuver,
  },
  [ManeuverId.AllOutAttack]: {
    id: ManeuverId.AllOutAttack,
    name: 'All Out Attack',
    type: CombatActionTypes.Maneuver,
  },
  [ManeuverId.AllOutDefense]: {
    id: ManeuverId.AllOutDefense,
    name: 'All Out Defense',
    type: CombatActionTypes.Maneuver,
  },
  [ManeuverId.LongAction]: {
    id: ManeuverId.LongAction,
    name: 'Long Action',
    type: CombatActionTypes.Maneuver,
  },
  [FreeActionId.DefendDodge]: {
    id: FreeActionId.DefendDodge,
    name: 'Active Defense: Dodge',
    type: CombatActionTypes.FreeAction,
  },
  [FreeActionId.DefendParry]: {
    id: FreeActionId.DefendParry,
    name: 'Active Defense: Parry',
    type: CombatActionTypes.FreeAction,
  },
  [FreeActionId.RollForDamage]: {
    id: FreeActionId.RollForDamage,
    name: 'Roll for Damage',
    type: CombatActionTypes.FreeAction,
  },
  [FreeActionId.TakeDamage]: {
    id: FreeActionId.TakeDamage,
    name: 'Take Damage',
    type: CombatActionTypes.FreeAction,
  },
};

export enum AllOutAttackId {
  TwoAttacks = '@@allOutAttack/twoAttacks',
  SkillBonus = '@@allOutAttack/skillBonus',
  DamageBonus = '@@allOutAttack/damageBonus',
}

export interface AllOutAttackDefinition extends Option<AllOutAttackId> {
}

export const allOutAttackDefinitions: Record<AllOutAttackId, AllOutAttackDefinition> = {
  [AllOutAttackId.TwoAttacks]: {
    value: AllOutAttackId.TwoAttacks,
    label: "Attack Twice",
  },
  [AllOutAttackId.SkillBonus]: {
    value: AllOutAttackId.SkillBonus,
    label: "+4 Skill Bonus",
  },
  [AllOutAttackId.DamageBonus]: {
    value: AllOutAttackId.DamageBonus,
    label: "+2 Damage Bonus",
  },
};

export enum PositionId {
  Standing = '@@position/standing',
  Crouching = '@@position/crouching',
  Kneeling = '@@position/kneeling',
  Crawling = '@@position/crawling',
  Sitting = '@@position/sitting',
  Prone = '@@position/prone',
}

export interface PositionDefinition {
  id: PositionId;
  name: string;
  rangedAttackModifier: number | null;
  meleeAttackModifier: number | null;
  rangedDefenseModifier: number;
  activeDefenseModifier: number;
  movementMultiplier: number;
}

export const positionDefinitions: Record<PositionId, PositionDefinition> = {
  [PositionId.Standing]: {
    id: PositionId.Standing,
    name: 'Standing',
    rangedAttackModifier: 0,
    meleeAttackModifier: 0,
    rangedDefenseModifier: 0,
    activeDefenseModifier: 0,
    movementMultiplier: 1,
  },
  [PositionId.Crouching]: {
    id: PositionId.Crouching,
    name: 'Crouching',
    rangedAttackModifier: -2,
    meleeAttackModifier: -2,
    rangedDefenseModifier: -2,
    activeDefenseModifier: 0,
    movementMultiplier: .6,
  },
  [PositionId.Kneeling]: {
    id: PositionId.Kneeling,
    name: 'Kneeling',
    rangedAttackModifier: -2,
    meleeAttackModifier: -2,
    rangedDefenseModifier: -2,
    activeDefenseModifier: -2,
    movementMultiplier: .3,
  },
  [PositionId.Crawling]: {
    id: PositionId.Crawling,
    name: 'Crawling',
    rangedAttackModifier: null,
    meleeAttackModifier: null,
    rangedDefenseModifier: -4,
    activeDefenseModifier: -3,
    movementMultiplier: .3,
  },
  [PositionId.Sitting]: {
    id: PositionId.Sitting,
    name: 'Sitting',
    rangedAttackModifier: -2,
    meleeAttackModifier: -2,
    rangedDefenseModifier: -2,
    activeDefenseModifier: -2,
    movementMultiplier: 0,
  },
  [PositionId.Prone]: {
    id: PositionId.Prone,
    name: 'Prone',
    rangedAttackModifier: 0,
    meleeAttackModifier: -4,
    rangedDefenseModifier: -4,
    activeDefenseModifier: -3,
    movementMultiplier: .1,
  },
};

export enum CoverId {
  None = '@@cover/None',
  Light = '@@cover/Light',
  Medium = '@@cover/Medium',
  Heavy = '@@cover/Heavy',
  EvasiveMovement = '@@cover/EvasiveMovement',
  DedicatedEvasiveMovement = '@@cover/DedicatedEvasiveMovement',
}

export interface CoverDefinition {
  id: CoverId;
  description: string;
  attackModifier: number;
}

export const coverDefinitions: Record<CoverId, CoverDefinition> = {
  [CoverId.None]: {
    id: CoverId.None,
    description: 'None',
    attackModifier: 0,
  },
  [CoverId.Light]: {
    id: CoverId.Light,
    description: 'Light',
    attackModifier: -2,
  },
  [CoverId.Medium]: {
    id: CoverId.Medium,
    description: 'Medium',
    attackModifier: -3,
  },
  [CoverId.Heavy]: {
    id: CoverId.Heavy,
    description: 'Heavy',
    attackModifier: -4,
  },
  [CoverId.EvasiveMovement]: {
    id: CoverId.EvasiveMovement,
    description: 'Evasive Movement',
    attackModifier: -1,
  },
  [CoverId.DedicatedEvasiveMovement]: {
    id: CoverId.DedicatedEvasiveMovement,
    description: 'Evasive Movement at the Cost of Forward Progress',
    attackModifier: -2,
  },
};

export enum AttackerConditionId {
  StrangePosition = '@@attackerCondition/StrangePosition',
  Walking = '@@attackerCondition/Walking',
  Running = '@@attackerCondition/Running',
  BadFooting = '@@attackerCondition/BadFooting',
  BadLight = '@@attackerCondition/BadLight',
  VeryBadLight = '@@attackerCondition/VeryBadLight',
  TotalDarkness = '@@attackerCondition/TotalDarkness',
  Blind = '@@attackerCondition/Blind',
  BlindedSuddenly = '@@attackerCondition/BlindedSuddenly',
  OffhandAttack = '@@attackerCondition/OffhandAttack',
  OneEye = '@@attackerCondition/OneEye',
}

export interface AttackerConditionDefinition {
  id: AttackerConditionId;
  description: string;
  attackModifier: number;
}

export const attackerConditionDefinitions: Record<AttackerConditionId, AttackerConditionDefinition> = {
  [AttackerConditionId.StrangePosition]: {
    id: AttackerConditionId.StrangePosition,
    description: 'Strange Position',
    attackModifier: -2,
  },
  [AttackerConditionId.Walking]: {
    id: AttackerConditionId.Walking,
    description: 'Walking',
    attackModifier: -1,
  },
  [AttackerConditionId.Running]: {
    id: AttackerConditionId.Running,
    description: 'Running',
    attackModifier: -2,
  },
  [AttackerConditionId.BadFooting]: {
    id: AttackerConditionId.BadFooting,
    description: 'Bad Footing',
    attackModifier: -1,
  },
  [AttackerConditionId.BadLight]: {
    id: AttackerConditionId.BadLight,
    description: 'Bad Light',
    attackModifier: -3,
  },
  [AttackerConditionId.VeryBadLight]: {
    id: AttackerConditionId.VeryBadLight,
    description: 'Very Bad Light',
    attackModifier: -6,
  },
  [AttackerConditionId.TotalDarkness]: {
    id: AttackerConditionId.TotalDarkness,
    description: 'Total Darkness',
    attackModifier: -10,
  },
  [AttackerConditionId.Blind]: {
    id: AttackerConditionId.Blind,
    description: 'Blind',
    attackModifier: -6,
  },
  [AttackerConditionId.BlindedSuddenly]: {
    id: AttackerConditionId.BlindedSuddenly,
    description: 'Blinded Suddenly',
    attackModifier: -10,
  },
  [AttackerConditionId.OffhandAttack]: {
    id: AttackerConditionId.OffhandAttack,
    description: 'Offhand Attack',
    attackModifier: -4,
  },
  [AttackerConditionId.OneEye]: {
    id: AttackerConditionId.OneEye,
    description: 'One Eye',
    attackModifier: -2,
  },
};

/**
 * @param speed in y/s
 * @return number Accuracy modifier
 */
export const getSpeedAccuracyModifier = (speed: number): number => {
  return 0 - getRangeSpeedSizeModifier(speed);
};

/**
 * @param range in y
 */
export const getRangeAccuracyModifier = (range: number): number => {
  return 0 - getRangeSpeedSizeModifier(range);
};

/**
 * @param size in y
 */
export const getSizeAccuracyModifier = (size: number): number => {
  return getRangeSpeedSizeModifier(size)
};

/**
 * @param input Input should be in y (size or range) or y/s (speed)
 * @return number Output behaves as the "size" modifier (positive scale), invert for range or speed.
 */
const getRangeSpeedSizeModifier = (input: number): number => {
  // Derived from GURPS Lite "Speed/Range and Size Table"
  // Logarithmic curve fit to the data in that table
  // a*ln(b*x) = y
  // With offset to avoid negative numbers: a*ln(b*x) - 3 = y
  // Optimal coefficients
  let a = 2.60887825;
  let b = 1.48951407;
  // Had to offset up to avoid negative numbers, so offset back down the output
  return Math.round(a * Math.log(b * input) - 3);
};

export const getIndirectFireMissMargin = (rollResultDifferential: number): number => {
  return getRangeSpeedSizeFromModifier(rollResultDifferential);
};

/**
 * Inverse of getRangeSpeedSizeModifier. Returns size or range/speed from a speed/range modifier input.
 * @param modifier
 */
const getRangeSpeedSizeFromModifier = (modifier: number): number => {
  // Derived from GURPS Lite "Speed/Range and Size Table"
  // Logarithmic curve fit to the data in that table, inverted for this function.
  // e^(y/A)/b = x
  // With offset to avoid negative numbers: e^((y+3)/A)/b = x
  // Optimal coefficients
  let a = 2.60887825;
  let b = 1.48951407;
  // Had to offset up to avoid negative numbers, so offset back down the output
  return Math.round(Math.exp((modifier + 3) / a) / b);
};
