import React from 'react';
import {Col, Table} from 'react-bootstrap';
import {
  EquipmentDefinition,
  EquipmentId,
  EquipmentType,
  getEquipmentDefinition,
  getEquipmentType
} from "../../../../lib/constants/equipmentConstants";
import {CenteredFlexDiv} from "../../../../lib/components/CenteredFlexDiv";
import {gearCategories, GearDefinition} from "../../../../lib/constants/gearConstants";
import {weaponCategories, WeaponDefinition} from "../../../../lib/constants/weaponConstants";

interface Props {
  equipmentDetailId: EquipmentId;
}

export class EquipmentDetailPanel extends React.Component<Props> {
  render() {
    let {
      equipmentDetailId,
    } = this.props;
    let equipDef = getEquipmentDefinition(equipmentDetailId);

    return <Col sm className='content-panel scrollable full-height'>
      <Table responsive={'sm'} className='detail-table'>
        <thead>
        <tr>
          <th/>
          <th/>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>Name:</td>
          <td>{equipDef.name}</td>
        </tr>
        <tr>
          <td>Cost:</td>
          <td>${equipDef.cost}</td>
        </tr>
        <tr>
          <td>Weight:</td>
          <td>{equipDef.weight} lbs</td>
        </tr>
        {this.renderTypeSpecificRows(equipDef)}
        </tbody>
      </Table>
      <CenteredFlexDiv>
        {equipDef.description}
      </CenteredFlexDiv>
    </Col>
  }

  renderTypeSpecificRows(def: EquipmentDefinition) {
    let type = getEquipmentType(def.id);
    switch (type) {
      case EquipmentType.Gear: {
        let typedDef = def as GearDefinition;
        return <tr>
          <td>Category:</td>
          <td>{gearCategories[typedDef.category].name}</td>
        </tr>;
      }
      case EquipmentType.Weapon: {
        let typedDef = def as WeaponDefinition;
        return <tr>
          <td>Category:</td>
          <td>{weaponCategories[typedDef.category].name}</td>
        </tr>;
      }
    }
  }
}

