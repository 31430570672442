import {AttributeId} from "./attributeConstants";
import {CharacteristicDefinition, CharacteristicType} from "./characteristicConstants";

export enum SkillType {
  Mental = 'Mental',
  Physical = 'Physical',
}

export enum SkillDifficulty {
  Easy = 'Easy',
  Average = 'Average',
  Hard = 'Hard',
  VeryHard = 'VeryHard',
}

const SKILL_PROGRESSION_PHYSICAL = [.5, 1, 2, 4, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80, 88];
const SKILL_PROGRESSION_MENTAL = [.5, 1, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30];
const SKILL_PROGRESSION_MENTAL_VERY_HARD = [.5, 1, 2, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52];

export const SKILL_PROGRESSION_STARTING_LEVEL: Record<string, number> = {
  [SkillDifficulty.Easy]: -1,
  [SkillDifficulty.Average]: -2,
  [SkillDifficulty.Hard]: -3,
  [SkillDifficulty.VeryHard]: -4,
};
export const getSkillFirstLevel = (skillId: SkillId): number => {
  let skillDef = skillDefinitions[skillId];
  let difficulty = skillDef.difficulty;
  return SKILL_PROGRESSION_STARTING_LEVEL[difficulty];
};

export const getSkillCost = (skillId: SkillId, level: number): number | null => {
  let skillDef = skillDefinitions[skillId];
  if (skillDef === undefined) {
    throw new Error(`Can't find skill ${skillId}`);
  }
  let type = skillDef.skillType;
  let difficulty = skillDef.difficulty;
  let startingLevel = getSkillFirstLevel(skillId);

  // If the requested level is below the starting level, it's invalid
  if (level < startingLevel) {
    return null;
  }
  let difference = level - startingLevel;
  let progression;
  if (type === SkillType.Physical) {
    progression = SKILL_PROGRESSION_PHYSICAL;
  } else if (type === SkillType.Mental && difficulty === SkillDifficulty.VeryHard) {
    progression = SKILL_PROGRESSION_MENTAL_VERY_HARD;
  } else if (type === SkillType.Mental) {
    progression = SKILL_PROGRESSION_MENTAL;
  } else {
    throw Error(`Unhandled skill type ${type} and difficulty ${difficulty}`);
  }

  return progression[difference];
};

// get skill level options

export interface Skill  {
  id: SkillId;
  /** Level expressed as an attributeId modifier  */
  level: number;
}

export interface SkillDefinition extends CharacteristicDefinition {
  skillType: SkillType;
  difficulty: SkillDifficulty;
  baseAttributeId: AttributeId;
  defaults: Array<SkillDefault>;
}

export interface SkillDefault  {
  attributeId: AttributeId;
  modifier: number;
}

export const GUNNER_DEFAULT_DEFINITION = {
  type: CharacteristicType.Skill,
  skillType: SkillType.Physical,
  difficulty: SkillDifficulty.Average,
  baseAttributeId: AttributeId.Dexterity,
  description: "The ability to fire emplaced, vehicular, or tripod-mounted heavy weapons. Each type of weapon requires its own Gunner skill. Options are bombs (aircraft bombs), cannon (artillery, naval guns, and tank guns used for direct fire), machine gun (heavy automatic weapons), mortar (artillery or naval guns used for indirect fire), rocket launcher (ground and aerial rocket tubes), and torpedo (shipor submarine-launched torpedoes). Add 1 to Gunner skill for an IQ of 10-11, and 2 for an IQ of 12+.",
  defaults: [{
    attributeId: AttributeId.Dexterity,
    modifier: -5
  }],
};

export const GUNS_DEFAULT_DEFINITION = {
  type: CharacteristicType.Skill,
  skillType: SkillType.Physical,
  difficulty: SkillDifficulty.Easy,
  baseAttributeId: AttributeId.Dexterity,
  description: "The ability to fire 20th-century small arms. Each type of weapon requires its own Guns skill. Options are pistol (revolvers and semi-automatic handguns), rifle (semi-automatic rifles), light automatic weapon (automatic rifles and submachine guns), shotgun (unrifled long arms), flamethrower (flaming-liquid projectors), and grenade launcher (rifle grenades and actual grenade launchers). Add 1 to Guns skill for an IQ of 10-11, or 2 for an IQ of 12+.",
  defaults: [{
    attributeId: AttributeId.Dexterity,
    modifier: -4
  }],
};

export const SURVIVAL_DEFAULT_DEFINITION = {
  type: CharacteristicType.Skill,
  skillType: SkillType.Mental,
  difficulty: SkillDifficulty.Average,
  baseAttributeId: AttributeId.Intelligence,
  description: "The ability to “live off the land,” find food and water, avoid hazards, build shelter, etc. A different Survival skill is required for each type of terrain. Roll once per day in a wilderness situation.",
  defaults: [{
    attributeId: AttributeId.Intelligence,
    modifier: -5
  }],
};

export const SKILL_ID_PREFIX = '@@skill';

export enum SkillId {
  Acrobatics = '@@skill/Acrobatics',
  Acting = '@@skill/Acting',
  Administration = '@@skill/Administration',
  AnimalHandling = '@@skill/AnimalHandling',
  AreaKnowledge = '@@skill/AreaKnowledge',
  Armoury = '@@skill/Armoury',
  Aviation = '@@skill/Aviation',
  Bard = '@@skill/Bard',
  Boxing = '@@skill/Boxing',
  Brawling = '@@skill/Brawling',
  Camouflage = '@@skill/Camouflage',
  Carousing = '@@skill/Carousing',
  Carpentry = '@@skill/Carpentry',
  Climbing = '@@skill/Climbing',
  Cooking = '@@skill/Cooking',
  Criminology = '@@skill/Criminology',
  Demolition = '@@skill/Demolition',
  Diagnosis = '@@skill/Diagnosis',
  Disguise = '@@skill/Disguise',
  Electronics = '@@skill/Electronics',
  ElectronicsOperation = '@@skill/ElectronicsOperation',
  Escape = '@@skill/Escape',
  ExplosiveOrdnanceDisposal = '@@skill/ExplosiveOrdnanceDisposal',
  FirstAid = '@@skill/FirstAid',
  Forensics = '@@skill/Forensics',
  Forgery = '@@skill/Forgery',
  ForwardObserver = '@@skill/ForwardObserver',
  FreightHandling = '@@skill/FreightHandling',
  Gambling = '@@skill/Gambling',


  GunnerBomb = '@@skill/GunnerBomb',
  GunnerCannon = '@@skill/GunnerCannon',
  GunnerHeavyMachineGun = '@@skill/GunnerHeavyAuto',
  GunnerArtillery = '@@skill/GunnerArtillery',
  GunnerRocketTube = '@@skill/GunnerRocketTube',
  GunnerTorpedo = '@@skill/GunnerTorpedo',

  GunsPistol = '@@skill/GunsPistol',
  GunsRifle = '@@skill/GunsRifle',
  GunsLightAuto = '@@skill/GunsLightAuto',
  GunsShotgun = '@@skill/GunsShotgun',
  GunsFlamethrower = '@@skill/GunsFlamethrower',
  GunsGrenadeLauncher = '@@skill/GunsGrenadeLauncher',
  GunsLightAntitank = '@@skill/GunsLightAntitank',

  // HandWeapon = '@@skill/HandWeapon',
  Hiking = '@@skill/Hiking',
  Holdout = '@@skill/Holdout',
  Humanities = '@@skill/Humanities',
  // InfluenceSkills = '@@skill/InfluenceSkills',
  IntelligenceAnalysis = '@@skill/IntelligenceAnalysis',
  Interrogation = '@@skill/Interrogation',
  Judo = '@@skill/Judo',
  Jumping = '@@skill/Jumping',
  Karate = '@@skill/Karate',
  Law = '@@skill/Law',
  Leadership = '@@skill/Leadership',
  Lockpicking = '@@skill/Lockpicking',
  Masonry = '@@skill/Masonry',
  Mathematics = '@@skill/Mathematics',
  Mechanic = '@@skill/Mechanic',
  Merchant = '@@skill/Merchant',
  Meteorology = '@@skill/Meteorology',
  MusicalInstrument = '@@skill/MusicalInstrument',
  NaturalSciences = '@@skill/NaturalSciences',
  Naturalist = '@@skill/Naturalist',
  Navigation = '@@skill/Navigation',
  NbcWarfare = '@@skill/NbcWarfare',
  Orienteering = '@@skill/Orienteering',
  Parachuting = '@@skill/Parachuting',
  Photography = '@@skill/Photography',
  Physician = '@@skill/Physician',
  Pickpocket = '@@skill/Pickpocket',
  Research = '@@skill/Research',
  Running = '@@skill/Running',
  Sailor = '@@skill/Sailor',
  Scrounging = '@@skill/Scrounging',
  Seamanship = '@@skill/Seamanship',
  Shadowing = '@@skill/Shadowing',
  Shiphandling = '@@skill/Shiphandling',
  Skiing = '@@skill/Skiing',
  SocialSciences = '@@skill/SocialSciences',
  Stealth = '@@skill/Stealth',

  SurvivalWoodlands = '@@skill/SurvivalWoodlands',
  SurvivalPlains = '@@skill/SurvivalPlains',
  SurvivalDesert = '@@skill/SurvivalDesert',
  SurvivalMountains = '@@skill/SurvivalMountains',
  SurvivalJungle = '@@skill/SurvivalJungle',
  SurvivalSwampland = '@@skill/SurvivalSwampland',
  SurvivalUrban = '@@skill/SurvivalUrban',
  SurvivalIslandBeach = '@@skill/SurvivalIslandBeach',
  SurvivalArctic = '@@skill/SurvivalArctic',
  SurvivalRadioactive = '@@skill/SurvivalRadioactive',
  Swimming = '@@skill/Swimming',
  Teaching = '@@skill/Teaching',
  Teamster = '@@skill/Teamster',
  Telegraphy = '@@skill/Telegraphy',
  Throwing = '@@skill/Throwing',
  ThrownWeapon = '@@skill/ThrownWeapon',
  Tracking = '@@skill/Tracking',
  Traps = '@@skill/Traps',
  // VehicleSkills = '@@skill/VehicleSkills',
}

export const skillDefinitions: Record<SkillId, SkillDefinition> = {
  [SkillId.Acrobatics]: {
    id: SkillId.Acrobatics,
    type: CharacteristicType.Skill,
    name: "Acrobatics",
    skillType: SkillType.Physical,
    difficulty: SkillDifficulty.Hard,
    baseAttributeId: AttributeId.Dexterity,
    description: "The ability to perform acrobatic and gymnastic stunts, roll, take falls, etc. A separate skill roll is required for each trick you attempt.",
    defaults: [{
      attributeId: AttributeId.Dexterity,
      modifier: -6
    }],
  },
  [SkillId.Acting]: {
    id: SkillId.Acting,
    type: CharacteristicType.Skill,
    name: "Acting",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Average,
    baseAttributeId: AttributeId.Intelligence,
    description: "The ability to counterfeit moods, emotions, and voices, and to lie convincingly over a period of time. Roll a Quick Contest versus the IQ of each person you wish to fool.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -5
    }],
  },
  [SkillId.Administration]: {
    id: SkillId.Administration,
    type: CharacteristicType.Skill,
    name: "Administration",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Average,
    baseAttributeId: AttributeId.Intelligence,
    description: "The skill of running a large organization, be it a business or a military unit. A skill roll will let you deal with a bureaucracy quickly and efficiently.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -6
    }],
  },
  [SkillId.AnimalHandling]: {
    id: SkillId.AnimalHandling,
    type: CharacteristicType.Skill,
    name: "Animal Handling",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Hard,
    baseAttributeId: AttributeId.Intelligence,
    description: "The ability to train and work with all types of animals. Daily rolls are required when training an animal.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -6
    }],
  },
  [SkillId.AreaKnowledge]: {
    id: SkillId.AreaKnowledge,
    type: CharacteristicType.Skill,
    name: "Area Knowledge",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Easy,
    baseAttributeId: AttributeId.Intelligence,
    description: "Default for area residents only. The skill of familiarity with the people, politics, and geography of a given area. Roll for each piece of knowledge required.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -4
    }],
  },
  [SkillId.Armoury]: {
    id: SkillId.Armoury,
    type: CharacteristicType.Skill,
    name: "Armoury",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Average,
    baseAttributeId: AttributeId.Intelligence,
    description: "The ability to build and repair weapons. Each class of weapons – e.g., artillery, small arms, and vehicular weapons – requires its own Armoury skill.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -5
    }],
  },
  [SkillId.Aviation]: {
    id: SkillId.Aviation,
    type: CharacteristicType.Skill,
    name: "Aviation",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Average,
    baseAttributeId: AttributeId.Intelligence,
    description: "The skill of familiarity with aircraft procedures (but not flying the plane; use Piloting for that). A skill roll will let you file a flight plan, refuel a plane, understand the lingo used by airmen, etc.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -5
    }],
  },
  [SkillId.Bard]: {
    id: SkillId.Bard,
    type: CharacteristicType.Skill,
    name: "Bard",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Average,
    baseAttributeId: AttributeId.Intelligence,
    description: "The ability to speak extemporaneously and to tell stories. Also called Public Speaking. Roll once per speech or story.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -5
    }],
  },
  [SkillId.Boxing]: {
    id: SkillId.Boxing,
    type: CharacteristicType.Skill,
    name: "Boxing",
    skillType: SkillType.Physical,
    difficulty: SkillDifficulty.Average,
    baseAttributeId: AttributeId.Dexterity,
    description: "Formal training in fisticuffs. When you punch, roll against Boxing skill to hit, and add 1/5 your skill (round down) to damage. You may use your bare hands to parry attacks at 2/3 your Boxing skill; you parry kicks at -2, non-thrusting weapons at -3.",
    defaults: [],
  },
  [SkillId.Brawling]: {
    id: SkillId.Brawling,
    type: CharacteristicType.Skill,
    name: "Brawling",
    skillType: SkillType.Physical,
    difficulty: SkillDifficulty.Easy,
    baseAttributeId: AttributeId.Dexterity,
    description: "The skill of unscientific unarmed combat. Roll against Brawling to hit with a punch, or Brawling-2 to hit with a kick, and add 1/10 your skill (round down) to damage. You may parry barehanded attacks at 2/3 skill.",
    defaults: [],
  },
  [SkillId.Camouflage]: {
    id: SkillId.Camouflage,
    type: CharacteristicType.Skill,
    name: "Camouflage",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Easy,
    baseAttributeId: AttributeId.Intelligence,
    description: "The ability to use natural material or paints to disguise yourself, your equipment, your position, etc. Roll once per person, vehicle, or position hidden.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -4
    }],
  },
  [SkillId.Carousing]: {
    id: SkillId.Carousing,
    type: CharacteristicType.Skill,
    name: "Carousing",
    skillType: SkillType.Physical,
    difficulty: SkillDifficulty.Average,
    baseAttributeId: AttributeId.Health,
    description: "The skill of drinking, partying, etc. A successful skill roll gives you +2 reaction rolls in such circumstances; a failed roll gives you -2 instead. This skill is based on HT, not DX.",
    defaults: [{
      attributeId: AttributeId.Health,
      modifier: -4
    }],
  },
  [SkillId.Carpentry]: {
    id: SkillId.Carpentry,
    type: CharacteristicType.Skill,
    name: "Carpentry",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Easy,
    baseAttributeId: AttributeId.Intelligence,
    description: "The ability to build things out of wood, given the right tools. Roll once per hour of work.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -4
    }, {
      attributeId: AttributeId.Dexterity,
      modifier: -4
    }],
  },
  [SkillId.Climbing]: {
    id: SkillId.Climbing,
    type: CharacteristicType.Skill,
    name: "Climbing",
    skillType: SkillType.Physical,
    difficulty: SkillDifficulty.Average,
    baseAttributeId: AttributeId.Dexterity,
    description: "The ability to climb mountains, ropes, the sides of buildings, trees, etc. Roll once to start a climb; long climbs may require more rolls. See p. 195.",
    defaults: [{
      attributeId: AttributeId.Dexterity,
      modifier: -5
    }, {
      attributeId: AttributeId.Strength,
      modifier: -5
    }],
  },
  [SkillId.Cooking]: {
    id: SkillId.Cooking,
    type: CharacteristicType.Skill,
    name: "Cooking",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Easy,
    baseAttributeId: AttributeId.Intelligence,
    description: "The ability to prepare a pleasing meal from basic ingredients. Roll once per meal.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -4
    }],
  },
  [SkillId.Criminology]: {
    id: SkillId.Criminology,
    type: CharacteristicType.Skill,
    name: "Criminology",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Average,
    baseAttributeId: AttributeId.Intelligence,
    description: "The study of crime and the criminal mind. Roll to find and interpret clues, guess how criminals might behave, etc.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -4
    }],
  },
  [SkillId.Demolition]: {
    id: SkillId.Demolition,
    type: CharacteristicType.Skill,
    name: "Demolition",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Average,
    baseAttributeId: AttributeId.Intelligence,
    description: "The ability to blow things up with explosives. A Demolition roll is necessary whenever you use explosives.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -5
    }],
  },
  [SkillId.Diagnosis]: {
    id: SkillId.Diagnosis,
    type: CharacteristicType.Skill,
    name: "Diagnosis",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Hard,
    baseAttributeId: AttributeId.Intelligence,
    description: "The ability to tell what is wrong with a sick or injured person, or what killed a dead person. Roll once per diagnosis.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -6
    }],
  },
  [SkillId.Disguise]: {
    id: SkillId.Disguise,
    type: CharacteristicType.Skill,
    name: "Disguise",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Average,
    baseAttributeId: AttributeId.Intelligence,
    description: "The ability to make yourself look like someone else. Roll a Quick Contest of Skills (Disguise vs. IQ) for each person (or group) that your disguise must fool.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -5
    }],
  },
  [SkillId.Electronics]: {
    id: SkillId.Electronics,
    type: CharacteristicType.Skill,
    name: "Electronics",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Hard,
    baseAttributeId: AttributeId.Intelligence,
    description: "The ability to design and build electronic apparatus. A successful roll will let you identify the purpose of a strange device, diagnose a glitch, perform a repair, or design a new system. A separate Electronics skill is needed for each class of equipment (communications, sensors, etc.).",
    defaults: [],
  },
  [SkillId.ElectronicsOperation]: {
    id: SkillId.ElectronicsOperation,
    type: CharacteristicType.Skill,
    name: "Electronics Operation",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Average,
    baseAttributeId: AttributeId.Intelligence,
    description: "The ability to use electronic gear. No skill roll is required for normal, everyday use of equipment – only for emergency situations. Each type of equipment (communications, sensors, etc.) requires its own Electronics Operation skill.Engineer/TL (Mental/Hard) No default",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -5
    }],
  },
  [SkillId.Escape]: {
    id: SkillId.Escape,
    type: CharacteristicType.Skill,
    name: "Escape",
    skillType: SkillType.Physical,
    difficulty: SkillDifficulty.Hard,
    baseAttributeId: AttributeId.Dexterity,
    description: "The ability to get free from ropes, handcuffs, and similar bonds. The first attempt to escape takes one minute; each subsequent attempt takes 10 minutes.",
    defaults: [{
      attributeId: AttributeId.Dexterity,
      modifier: -6
    }],
  },
  [SkillId.ExplosiveOrdnanceDisposal]: {
    id: SkillId.ExplosiveOrdnanceDisposal,
    type: CharacteristicType.Skill,
    name: "Explosive Ordnance Disposal",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Hard,
    baseAttributeId: AttributeId.Intelligence,
    description: "The ability to defuse and dispose of unexploded grenades, mines, bombs, etc. The GM should assign significant penalties for distractions. Critical failure does not automatically mean an explosion; the GM should be creative.",
    defaults: [],
  },
  [SkillId.FirstAid]: {
    id: SkillId.FirstAid,
    type: CharacteristicType.Skill,
    name: "First Aid",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Easy,
    baseAttributeId: AttributeId.Intelligence,
    description: "The ability to patch up an injury in the field (see p. 204). Roll once per injury.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -5
    }],
  },
  [SkillId.Forensics]: {
    id: SkillId.Forensics,
    type: CharacteristicType.Skill,
    name: "Forensics",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Hard,
    baseAttributeId: AttributeId.Intelligence,
    description: "The general science of “laboratory” criminology. Roll to analyze each piece of physical evidence.",
    defaults: [],
  },
  [SkillId.Forgery]: {
    id: SkillId.Forgery,
    type: CharacteristicType.Skill,
    name: "Forgery",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Hard,
    baseAttributeId: AttributeId.Intelligence,
    description: "The ability to produce fake passports, identity papers, or similar documents. Roll once per forgery.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -6
    }, {
      attributeId: AttributeId.Dexterity,
      modifier: -8
    }],
  },
  [SkillId.ForwardObserver]: {
    id: SkillId.ForwardObserver,
    type: CharacteristicType.Skill,
    name: "Forward Observer",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Average,
    baseAttributeId: AttributeId.Intelligence,
    description: "The skill of directing artillery fire onto a target and matching ordnance to target for best effect. See Indirect-Fire Weapons (p. 202) for game effects.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -5
    }],
  },
  [SkillId.FreightHandling]: {
    id: SkillId.FreightHandling,
    type: CharacteristicType.Skill,
    name: "Freight Handling",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Average,
    baseAttributeId: AttributeId.Intelligence,
    description: "The ability to load and unload cargo efficiently. A successful skill roll will reduce the time required for such tasks by 25%.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -5
    }],
  },
  [SkillId.Gambling]: {
    id: SkillId.Gambling,
    type: CharacteristicType.Skill,
    name: "Gambling",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Average,
    baseAttributeId: AttributeId.Intelligence,
    description: "The skill of playing games of chance. A successful Gambling roll can tell you if a game is rigged, identify a fellow gambler in a group of strangers, or “estimate the odds” in a tricky situation.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -5
    }],
  },
  [SkillId.GunnerBomb]: {
    id: SkillId.GunnerBomb,
    ...GUNNER_DEFAULT_DEFINITION,
    type: CharacteristicType.Skill,
    name: "Gunner (Bomb)",
  }, [SkillId.GunnerCannon]: {
    id: SkillId.GunnerCannon,
    ...GUNNER_DEFAULT_DEFINITION,
    type: CharacteristicType.Skill,
    name: "Gunner (Cannon)",
  }, [SkillId.GunnerHeavyMachineGun]: {
    id: SkillId.GunnerHeavyMachineGun,
    ...GUNNER_DEFAULT_DEFINITION,
    type: CharacteristicType.Skill,
    name: "Gunner (HMG)",
  }, [SkillId.GunnerArtillery]: {
    id: SkillId.GunnerArtillery,
    ...GUNNER_DEFAULT_DEFINITION,
    type: CharacteristicType.Skill,
    name: "Gunner (Artillery)",
  }, [SkillId.GunnerRocketTube]: {
    id: SkillId.GunnerRocketTube,
    ...GUNNER_DEFAULT_DEFINITION,
    type: CharacteristicType.Skill,
    name: "Gunner (Rocket tube)",
  }, [SkillId.GunnerTorpedo]: {
    id: SkillId.GunnerTorpedo,
    ...GUNNER_DEFAULT_DEFINITION,
    type: CharacteristicType.Skill,
    name: "Gunner (Torpedo)",
  },
  [SkillId.GunsPistol]: {
    id: SkillId.GunsPistol,
    ...GUNS_DEFAULT_DEFINITION,
    type: CharacteristicType.Skill,
    name: "Guns (Pistol)",
  }, [SkillId.GunsRifle]: {
    id: SkillId.GunsRifle,
    ...GUNS_DEFAULT_DEFINITION,
    type: CharacteristicType.Skill,
    name: "Guns (Rifle)",
  }, [SkillId.GunsLightAuto]: {
    id: SkillId.GunsLightAuto,
    ...GUNS_DEFAULT_DEFINITION,
    type: CharacteristicType.Skill,
    name: "Guns (Light auto)",
  }, [SkillId.GunsShotgun]: {
    id: SkillId.GunsShotgun,
    ...GUNS_DEFAULT_DEFINITION,
    type: CharacteristicType.Skill,
    name: "Guns (Shotgun)",
  }, [SkillId.GunsFlamethrower]: {
    id: SkillId.GunsFlamethrower,
    ...GUNS_DEFAULT_DEFINITION,
    type: CharacteristicType.Skill,
    name: "Guns (Flamethrower)",
  }, [SkillId.GunsGrenadeLauncher]: {
    id: SkillId.GunsGrenadeLauncher,
    ...GUNS_DEFAULT_DEFINITION,
    type: CharacteristicType.Skill,
    name: "Guns (Grenade launcher)",
  }, [SkillId.GunsLightAntitank]: {
    id: SkillId.GunsLightAntitank,
    ...GUNS_DEFAULT_DEFINITION,
    type: CharacteristicType.Skill,
    name: "Guns (Light anti tank)",
  },
  // [SkillId.HandWeapon]: {
  // id: SkillId.HandWeapon,
  //   name: "Hand Weapon",
  //   type: SkillType.Physical,
  //   difficulty: SkillId.DifficultyVaries,
  //   baseAttributeId: AttributeId.Dexterity,
  //   description: "Each class of hand weapons requires a separate physical skill; roll against this skill when attacking. Most hand weapons may also parry (p. 200), done at 1/2 skill unless noted. Assume P/E weapon skills default to DX-4 and P/A ones default to DX-5. Skills include: Axe/Mace (P/A): Any short or middle-sized, unbalanced, onehanded weapon, such as an entrenching tool or a hatchet. Blackjack (P/E): A blackjack or sap. May not parry. Broadsword (P/A): Any 2to 4-foot, balanced, one-handed weapon, such as the cavalry saber carried by some American and European officers. Fencing (P/A): Smallswords and “hangers.” Parry is 2/3 Fencing skill, not 1/2. Your encumbrance must be light or less to use Fencing. Katana (P/A): The longsword carried by Japanese officers. Increase damage by 1 when swinging it two-handed. Parry is 2/3 Katana skill when using it in two hands and at no more than light encumbrance, 1/2 skill otherwise. Knife (P/E): Any fighting knife or short, unfixed bayonet. Shortsword (P/A): Any 1’ to 2’ balanced, one-handed weapon, such as a billy club, a machete, or an unfixed Japanese bayonet. Spear (P/A): Any sort of fixed bayonet. Two-Handed Axe/Mace (P/A): Any long, unbalanced, twohanded weapon, such as a shovel, or a heavy rifle or machine gun gripped by the barrel.",
  //   defaults: [{
  //     // TODO: fill this in
  //   }
  //   ],
  // },
  [SkillId.Hiking]: {
    id: SkillId.Hiking,
    type: CharacteristicType.Skill,
    name: "Hiking",
    skillType: SkillType.Physical,
    difficulty: SkillDifficulty.Average,
    baseAttributeId: AttributeId.Health,
    description: "This skill is training for endurance walking, hiking, marching, etc., and includes knowledge of how best to carry a pack. See Hiking (p. 195) for game effects. This skill is based on HT, not DX.",
    defaults: [],
  },
  [SkillId.Holdout]: {
    id: SkillId.Holdout,
    type: CharacteristicType.Skill,
    name: "Holdout",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Average,
    baseAttributeId: AttributeId.Intelligence,
    description: "The skill of concealing items on your person or the persons of others, or finding such hidden items. Roll once per item.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -5
    }],
  },
  [SkillId.Humanities]: {
    id: SkillId.Humanities,
    type: CharacteristicType.Skill,
    name: "Humanities",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Hard,
    baseAttributeId: AttributeId.Intelligence,
    description: "Each academic “humanities” or “arts” subject (such as History, Literature, Philosophy, or Theology) is a separate Mental/Hard skill that defaults to IQ-6. Roll versus skill to recall references, perform critical analysis, etc.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -6
    }],
  },
  // [SkillId.InfluenceSkills]: {
  // id: SkillId.InfluenceSkills,
  //   name: "Influence Skills",
  //   type: SkillType.Mental,
  //   difficulty: SkillId.DifficultyVaries,
  //   baseAttributeId: AttributeId.Health,
  //   description: "There are several ways to influence others; each is a separate influence skill. A successful roll will result in a “good” reaction from an NPC. Failure results in a “bad” reaction (except for Diplomacy, which is always safe). To actually coerce or manipulate an NPC, you must win a Quick Contest of your skill versus his Will. Methods of influencing others include: Diplomacy (M/H): Negotiation and compromise. Defaults to IQ-6. Fast-Talk (M/A): Lies and deception. Defaults to IQ-5. Intimidation (M/A): Threats and violence. Defaults to ST-5. Savoir-Faire (M/E): Manners and etiquette. Mainly useful in “high society” situations. Defaults to IQ-4. Savoir-Faire (Military) (M/E): Finer points of military protocol and how to work the “old boys” network. Defaults to IQ-4. Sex Appeal (M/A; based on HT, not IQ): Vamping and seduction, usually of the opposite sex. Defaults to HT-3. Streetwise (M/A): Contacts and (usually) subtle intimidation. Only useful in “street” and criminal situations. Defaults to IQ-5.",
  //   defaults: [{
  //     // TODO: fill this in
  //   }
  //   ],
  // },
  [SkillId.IntelligenceAnalysis]: {
    id: SkillId.IntelligenceAnalysis,
    type: CharacteristicType.Skill,
    name: "Intelligence Analysis",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Hard,
    baseAttributeId: AttributeId.Intelligence,
    description: "The skill of interpreting intelligence reports and analyzing raw reconnaissance data. On successful roll, the GM might grant you additional information about enemy actions, provided the original data was reliable.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -6
    }],
  },
  [SkillId.Interrogation]: {
    id: SkillId.Interrogation,
    type: CharacteristicType.Skill,
    name: "Interrogation",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Average,
    baseAttributeId: AttributeId.Intelligence,
    description: "The ability to question a prisoner. To do so, you must win a Contest of Skills: your Interrogation skill vs. the prisoner’s Will.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -5
    }],
  },
  [SkillId.Judo]: {
    id: SkillId.Judo,
    type: CharacteristicType.Skill,
    name: "Judo",
    skillType: SkillType.Physical,
    difficulty: SkillDifficulty.Hard,
    baseAttributeId: AttributeId.Dexterity,
    description: "Formal training in locks and throws. If you have empty hands and no more than light encumbrance, you may parry attacks (even weapons) at 2/3 skill. On the turn after a successful parry, you may attempt to throw your opponent. This counts as an attack, and is rolled against Judo skill. If your foe does not dodge or parry, he is thrown to the ground.",
    defaults: [],
  },
  [SkillId.Jumping]: {
    id: SkillId.Jumping,
    type: CharacteristicType.Skill,
    name: "Jumping",
    skillType: SkillType.Physical,
    difficulty: SkillDifficulty.Easy,
    baseAttributeId: AttributeId.Dexterity,
    description: "The trained ability to use your strength to its best advantage when you jump (see p. 195). Roll once per jump.",
    defaults: [],
  },
  [SkillId.Karate]: {
    id: SkillId.Karate,
    type: CharacteristicType.Skill,
    name: "Karate",
    skillType: SkillType.Physical,
    difficulty: SkillDifficulty.Hard,
    baseAttributeId: AttributeId.Dexterity,
    description: "The skill of trained punching and kicking. When you punch or kick, use Karate skill rather than DX to determine the odds of hitting, and add 1/5 of your skill level (round down) to damage. You may also parry attacks (even weapons) at 2/3 skill. Your encumbrance must be light or less to use Karate.",
    defaults: [],
  },
  [SkillId.Law]: {
    id: SkillId.Law,
    type: CharacteristicType.Skill,
    name: "Law",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Hard,
    baseAttributeId: AttributeId.Intelligence,
    description: "A successful Law roll lets you remember, deduce, or figure out the answer to a question about the law. An actual trial is handled as a Quick Contest of Law skills.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -6
    }],
  },
  [SkillId.Leadership]: {
    id: SkillId.Leadership,
    type: CharacteristicType.Skill,
    name: "Leadership",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Average,
    baseAttributeId: AttributeId.Intelligence,
    description: "The ability to coordinate a group in a dangerous or stressful situation. Roll to lead NPCs into a dangerous situation (e.g., combat).",
    defaults: [{
      attributeId: AttributeId.Strength,
      modifier: -5
    }],
  },
  [SkillId.Lockpicking]: {
    id: SkillId.Lockpicking,
    type: CharacteristicType.Skill,
    name: "Lockpicking",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Average,
    baseAttributeId: AttributeId.Intelligence,
    description: "The ability to open locks without the key or combination. Each attempt to open a lock requires one minute and a skill roll; cracking a safe may take considerably longer!",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -5
    }],
  },
  [SkillId.Masonry]: {
    id: SkillId.Masonry,
    type: CharacteristicType.Skill,
    name: "Masonry",
    skillType: SkillType.Physical,
    difficulty: SkillDifficulty.Easy,
    baseAttributeId: AttributeId.Dexterity,
    description: "The ability to build things out of brick or stone, given the right tools. Roll once per hour of work.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -3
    }],
  },
  [SkillId.Mathematics]: {
    id: SkillId.Mathematics,
    type: CharacteristicType.Skill,
    name: "Mathematics",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Hard,
    baseAttributeId: AttributeId.Intelligence,
    description: "Formal training in higher mathematics. A successful skill roll will let you answer just about any mathematical question.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -6
    }],
  },
  [SkillId.Mechanic]: {
    id: SkillId.Mechanic,
    type: CharacteristicType.Skill,
    name: "Mechanic",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Average,
    baseAttributeId: AttributeId.Intelligence,
    description: "The ability to diagnose and fix ordinary mechanical problems. Roll once per diagnosis or repair. Each type of machine (gasoline engine, ocean-going vessel, etc.) requires its own Mechanic skill.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -5
    }],
  },
  [SkillId.Merchant]: {
    id: SkillId.Merchant,
    type: CharacteristicType.Skill,
    name: "Merchant",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Average,
    baseAttributeId: AttributeId.Intelligence,
    description: "The ability to act as a “trader,” buying and selling merchandise. A successful skill roll lets you judge the value of common goods, locate markets, and so on.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -5
    }],
  },
  [SkillId.Meteorology]: {
    id: SkillId.Meteorology,
    type: CharacteristicType.Skill,
    name: "Meteorology",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Average,
    baseAttributeId: AttributeId.Intelligence,
    description: "The study of the weather and the ability to predict it, given the appropriate instruments. Roll once per prediction.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -5
    }],
  },
  [SkillId.MusicalInstrument]: {
    id: SkillId.MusicalInstrument,
    type: CharacteristicType.Skill,
    name: "Musical Instrument",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Hard,
    baseAttributeId: AttributeId.Intelligence,
    description: "The ability to play a musical instrument. Each instrument is a separate version of this skill. Roll once per performance.",
    defaults: [],
  },
  [SkillId.NaturalSciences]: {
    id: SkillId.NaturalSciences,
    type: CharacteristicType.Skill,
    name: "Natural Sciences",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Hard,
    baseAttributeId: AttributeId.Intelligence,
    description: "Each specialty (such as Botany, Chemistry, Geology, Physics, or Zoology) is a separate Mental/Hard skill that defaults to IQ-6. Roll versus skill to recall general knowledge within the field, analyze data, perform lab work, and so on.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -6
    }],
  },
  [SkillId.Naturalist]: {
    id: SkillId.Naturalist,
    type: CharacteristicType.Skill,
    name: "Naturalist",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Hard,
    baseAttributeId: AttributeId.Intelligence,
    description: "A general knowledge of animals and plants, and of nature in its various forms. Roll to identify plants, animals, etc.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -6
    }],
  },
  [SkillId.Navigation]: {
    id: SkillId.Navigation,
    type: CharacteristicType.Skill,
    name: "Navigation",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Hard,
    baseAttributeId: AttributeId.Intelligence,
    description: "The ability to find position by the stars, ocean currents, etc. Whether you are on land, at sea, or in the air, a successful roll will tell you where you are.",
    defaults: [],
  },
  [SkillId.NbcWarfare]: {
    id: SkillId.NbcWarfare,
    type: CharacteristicType.Skill,
    name: "NBC Warfare",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Average,
    baseAttributeId: AttributeId.Intelligence,
    description: "Expertise with the protective gear used in nuclear, biological, or chemical (NBC) warfare environments. At TL6, a skill roll is required to check, repair, or quickly don the gas masks and ponchos used to protect against poison gas.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -5
    }],
  },
  [SkillId.Orienteering]: {
    id: SkillId.Orienteering,
    type: CharacteristicType.Skill,
    name: "Orienteering",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Average,
    baseAttributeId: AttributeId.Intelligence,
    description: "The ability to find position on the ground using landmarks, a compass, and a map. Orienteering rolls are -1 to -10 (GM’s discretion) in an unfamiliar area.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -5
    }],
  },
  [SkillId.Parachuting]: {
    id: SkillId.Parachuting,
    type: CharacteristicType.Skill,
    name: "Parachuting",
    skillType: SkillType.Physical,
    difficulty: SkillDifficulty.Easy,
    baseAttributeId: AttributeId.Dexterity,
    description: "The ability to survive a parachute jump. Failure means a slight drift off course or some dropped gear. A critical failure is potentially fatal! A second roll is required to dodge trees, avoid injury, etc. in a rough landing zone.",
    defaults: [{
      attributeId: AttributeId.Dexterity,
      modifier: -4
    }, {
      attributeId: AttributeId.Intelligence,
      modifier: -6
    }],
  },
  [SkillId.Photography]: {
    id: SkillId.Photography,
    type: CharacteristicType.Skill,
    name: "Photography",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Average,
    baseAttributeId: AttributeId.Intelligence,
    description: "The ability to use a camera competently, use a darkroom, and so on. Roll once per roll of film shot or developed.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -5
    }],
  },
  [SkillId.Physician]: {
    id: SkillId.Physician,
    type: CharacteristicType.Skill,
    name: "Physician",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Hard,
    baseAttributeId: AttributeId.Intelligence,
    description: "The general professional ability to aid the sick, prescribe drugs and care, etc. This is the skill to use if the GM requires a single roll to test general medical competence or knowledge.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -7
    }],
  },
  [SkillId.Pickpocket]: {
    id: SkillId.Pickpocket,
    type: CharacteristicType.Skill,
    name: "Pickpocket",
    skillType: SkillType.Physical,
    difficulty: SkillDifficulty.Hard,
    baseAttributeId: AttributeId.Dexterity,
    description: "The ability to steal a small object (purse, knife, etc.) from someone’s person. Roll once per theft; if the target is alert, treat this as a Quick Contest vs. the target’s IQ.",
    defaults: [{
      attributeId: AttributeId.Dexterity,
      modifier: -6
    }],
  },
  [SkillId.Research]: {
    id: SkillId.Research,
    type: CharacteristicType.Skill,
    name: "Research",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Average,
    baseAttributeId: AttributeId.Intelligence,
    description: "A successful roll in an appropriate place of research will let you find a useful piece of data, if that information is to be found.Riding (Physical/Average) DX-5",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -5
    }],
  },
  [SkillId.Running]: {
    id: SkillId.Running,
    type: CharacteristicType.Skill,
    name: "Running",
    skillType: SkillType.Physical,
    difficulty: SkillDifficulty.Hard,
    baseAttributeId: AttributeId.Health,
    description: "This skill is based on HT, not DX. It represents training in sprints and long-distance running. If you have studied this skill, divide your skill level by 8 (don’t round down) and add the result to your Speed for the purpose of calculating your Move score (this affects land movement only). See p. 194 for details.",
    defaults: [],
  },
  [SkillId.Sailor]: {
    id: SkillId.Sailor,
    type: CharacteristicType.Skill,
    name: "Sailor",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Average,
    baseAttributeId: AttributeId.Intelligence,
    description: "The skill of familiarity with shipboard procedures (but not steering the ship; use Shiphandling for that). A skill roll will let you avoid the danger zones aboard a ship, identify different types of buoys and beacons, understand nautical terminology, etc.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -5
    }],
  },
  [SkillId.Scrounging]: {
    id: SkillId.Scrounging,
    type: CharacteristicType.Skill,
    name: "Scrounging",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Easy,
    baseAttributeId: AttributeId.Intelligence,
    description: "The ability to find or salvage useful items. A successful skill roll will locate the item desired, if the GM rules one exists to be found.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -4
    }],
  },
  [SkillId.Seamanship]: {
    id: SkillId.Seamanship,
    type: CharacteristicType.Skill,
    name: "Seamanship",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Easy,
    baseAttributeId: AttributeId.Intelligence,
    description: "The ability to crew a large seagoing vessel. It covers putting out fires, swabbing decks, operating pumps and cranes, and other menial tasks aboard ship.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -4
    }],
  },
  [SkillId.Shadowing]: {
    id: SkillId.Shadowing,
    type: CharacteristicType.Skill,
    name: "Shadowing",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Average,
    baseAttributeId: AttributeId.Intelligence,
    description: "The ability to follow another person through a crowd without being noticed. Roll a Quick Contest of Skill every 10 minutes: your Shadowing vs. the subject’s Vision roll. If you lose, you lose the target – or he spots you!",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -6
    }],
  },
  [SkillId.Shiphandling]: {
    id: SkillId.Shiphandling,
    type: CharacteristicType.Skill,
    name: "Shiphandling",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Hard,
    baseAttributeId: AttributeId.Intelligence,
    description: "The ability to manage the operation of a large ship, directing the crew in the tasks necessary to control its speed and direction. On naval vessels, at least one person with Shiphandling skill (the “watch officer”) is duty at all times.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -6
    }],
  },
  [SkillId.Skiing]: {
    id: SkillId.Skiing,
    type: CharacteristicType.Skill,
    name: "Skiing",
    skillType: SkillType.Physical,
    difficulty: SkillDifficulty.Hard,
    baseAttributeId: AttributeId.Dexterity,
    description: "The ability to ski. This skill replaces Hiking skill (p. 189) when traveling cross-country on skis.",
    defaults: [{
      attributeId: AttributeId.Dexterity,
      modifier: -6
    }],
  },
  [SkillId.SocialSciences]: {
    id: SkillId.SocialSciences,
    type: CharacteristicType.Skill,
    name: "Social Sciences",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Hard,
    baseAttributeId: AttributeId.Intelligence,
    description: "Each “social science” (e.g., Anthropology, Archaeology, Psychology, or Sociology) is a separate Mental/Hard skill that defaults to IQ-6. Roll versus skill to recall general knowledge within the field, identify traits that characterize an individual, culture, or society (as applicable), and so on.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -6
    }],
  },
  [SkillId.Stealth]: {
    id: SkillId.Stealth,
    type: CharacteristicType.Skill,
    name: "Stealth",
    skillType: SkillType.Physical,
    difficulty: SkillDifficulty.Average,
    baseAttributeId: AttributeId.Dexterity,
    description: "The ability to hide and to move silently. Roll a Quick Contest of Skills between your Stealth and the Hearing roll of anyone you’re trying to hide from.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -5
    }, {
      attributeId: AttributeId.Dexterity,
      modifier: -5
    }],
  },
  [SkillId.SurvivalWoodlands]: {
    id: SkillId.SurvivalWoodlands,
    ...SURVIVAL_DEFAULT_DEFINITION,
    type: CharacteristicType.Skill,
    name: "Survival (Woodlands)",
  },
  [SkillId.SurvivalPlains]: {
    id: SkillId.SurvivalPlains,
    ...SURVIVAL_DEFAULT_DEFINITION,
    type: CharacteristicType.Skill,
    name: "Survival (Plains)",
  },
  [SkillId.SurvivalDesert]: {
    id: SkillId.SurvivalDesert,
    ...SURVIVAL_DEFAULT_DEFINITION,
    type: CharacteristicType.Skill,
    name: "Survival (Desert)",
  },
  [SkillId.SurvivalMountains]: {
    id: SkillId.SurvivalMountains,
    ...SURVIVAL_DEFAULT_DEFINITION,
    type: CharacteristicType.Skill,
    name: "Survival (Mountains)",
  },
  [SkillId.SurvivalJungle]: {
    id: SkillId.SurvivalJungle,
    ...SURVIVAL_DEFAULT_DEFINITION,
    type: CharacteristicType.Skill,
    name: "Survival (Jungle)",
  },
  [SkillId.SurvivalSwampland]: {
    id: SkillId.SurvivalSwampland,
    ...SURVIVAL_DEFAULT_DEFINITION,
    type: CharacteristicType.Skill,
    name: "Survival (Swampland)",
  },
  [SkillId.SurvivalUrban]: {
    id: SkillId.SurvivalUrban,
    ...SURVIVAL_DEFAULT_DEFINITION,
    type: CharacteristicType.Skill,
    name: "Survival (Urban)",
  },
  [SkillId.SurvivalIslandBeach]: {
    id: SkillId.SurvivalIslandBeach,
    ...SURVIVAL_DEFAULT_DEFINITION,
    type: CharacteristicType.Skill,
    name: "Survival (Island/beach)",
  },
  [SkillId.SurvivalArctic]: {
    id: SkillId.SurvivalArctic,
    ...SURVIVAL_DEFAULT_DEFINITION,
    type: CharacteristicType.Skill,
    name: "Survival (Arctic)",
  },
  [SkillId.SurvivalRadioactive]: {
    id: SkillId.SurvivalRadioactive,
    ...SURVIVAL_DEFAULT_DEFINITION,
    type: CharacteristicType.Skill,
    name: "Survival (Radioactive)",
  },
  [SkillId.Swimming]: {
    id: SkillId.Swimming,
    type: CharacteristicType.Skill,
    name: "Swimming",
    skillType: SkillType.Physical,
    difficulty: SkillDifficulty.Easy,
    baseAttributeId: AttributeId.Dexterity,
    description: "This skill is used both for swimming and for saving a drowning victim. Roll once per swim, dive, or lifesaving attempt. See p. 196.",
    defaults: [{
      attributeId: AttributeId.Strength,
      modifier: -5
    }, {
      attributeId: AttributeId.Dexterity,
      modifier: -4
    }],
  },
  [SkillId.Teaching]: {
    id: SkillId.Teaching,
    type: CharacteristicType.Skill,
    name: "Teaching",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Average,
    baseAttributeId: AttributeId.Intelligence,
    description: "The ability to instruct others. The GM may require one or more skill rolls to teach another character a skill.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -5
    }],
  },
  [SkillId.Teamster]: {
    id: SkillId.Teamster,
    type: CharacteristicType.Skill,
    name: "Teamster",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Average,
    baseAttributeId: AttributeId.Intelligence,
    description: "The skill of driving teams of animals, such gun teams. The GM may require regular Teamster rolls to move quickly or over broken terrain without injury to man or beast.",
    defaults: [],
  },
  [SkillId.Telegraphy]: {
    id: SkillId.Telegraphy,
    type: CharacteristicType.Skill,
    name: "Telegraphy",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Easy,
    baseAttributeId: AttributeId.Intelligence,
    description: "The ability to send and receive Morse code at (3 × Telegraphy) words per minute, to a maximum of 75 wpm.",
    defaults: [],
  },
  [SkillId.Throwing]: {
    id: SkillId.Throwing,
    type: CharacteristicType.Skill,
    name: "Throwing",
    skillType: SkillType.Physical,
    difficulty: SkillDifficulty.Hard,
    baseAttributeId: AttributeId.Dexterity,
    description: "The ability to throw whatever you can pick up. It helps both accuracy (roll against Throwing skill to throw anything you can lift) and distance (add 1/6 of Throwing skill to ST when determining distance). Roll once per throw. See pp. 200-201.",
    defaults: [{
      // Ruling: I added this in because I thought it made sense to have a default.
      //  Modifier is copied from ThrownWeapon below.
      attributeId: AttributeId.Dexterity,
      modifier: -4
    }],
  },
  [SkillId.ThrownWeapon]: {
    id: SkillId.ThrownWeapon,
    type: CharacteristicType.Skill,
    name: "Thrown Weapon",
    skillType: SkillType.Physical,
    difficulty: SkillDifficulty.Easy,
    baseAttributeId: AttributeId.Dexterity,
    description: "The ability to throw any one type of throwable weapon. This skill is different for each type of weapon: Axe Throwing, Knife Throwing, etc.",
    defaults: [{
      attributeId: AttributeId.Dexterity,
      modifier: -4
    }],
  },
  [SkillId.Tracking]: {
    id: SkillId.Tracking,
    type: CharacteristicType.Skill,
    name: "Tracking",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Average,
    baseAttributeId: AttributeId.Intelligence,
    description: "The ability to follow a man or animal by its tracks. Make one Tracking roll to pick up the trail, and one further roll for every 5 minutes of travel.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -5
    }],
  },
  [SkillId.Traps]: {
    id: SkillId.Traps,
    type: CharacteristicType.Skill,
    name: "Traps",
    skillType: SkillType.Mental,
    difficulty: SkillDifficulty.Average,
    baseAttributeId: AttributeId.Intelligence,
    description: "The skill of building and avoiding traps and detection devices. Roll to build, detect, disarm, or reset a trap.",
    defaults: [{
      attributeId: AttributeId.Intelligence,
      modifier: -5
    }, {
      attributeId: AttributeId.Dexterity,
      modifier: -5
    }],
  },
  // [SkillId.VehicleSkills]: {
  // id: SkillId.VehicleSkills,
  //   name: "Vehicle Skills",
  //   type: SkillType.Physical,
  //   difficulty: SkillId.DifficultyVaries,
  //   baseAttributeId: AttributeId.Dexterity,
  //   description: "Each class of vehicle requires its own operation skill. Roll once to get under way and again each time a hazard is encountered; failure indicates lost time, or an accident. Vehicle skills default to DX at -4 (Easy), -5 (Average), or -6 (Hard); motor vehicles also default to IQ, at similar penalties. Available types include Bicycling (P/E), Boating (P/A) (for rowboats and sailboats), Driving (P/A) (separate versions for cars, tanks, trucks, etc.), Motorcycle (P/E), Piloting (P/A) (separate versions for light and heavy propeller craft, jets, and gliders), and Powerboat (P/A) (for motorboats).",
  //   defaults: [{
  //     // TODO: fill this in
  //   }
  //   ],
  // },
};
