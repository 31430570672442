import {Character} from "../lib/constants/characterConstants";
import {UUIDv1} from "../lib/constants/dataConstants";
import {CombatState, initialCombatState} from "./combat";
import v1 from "uuid/v1";
import {LOCAL_STORAGE_PREFIX} from "../lib/constants/constants";

// ------------------------------------
// Constants
// ------------------------------------
export const GAMEMASTER_LOCAL_STORAGE_KEY = `${LOCAL_STORAGE_PREFIX}_gamemaster_state`;

export enum GamemasterActions {
    LoadNew = '@@gamemaster/LoadNew',
    UpdateCharacter = '@@gamemaster/UpdateCharacter',
    DeleteCharacter = '@@gamemaster/DeleteCharacter',
    ClearCharacters = '@@gamemaster/ClearCharacters',
    SetGmEditing = '@@gamemaster/SetGmEditing',
    FinishGmEditing = '@@gamemaster/FinishGmEditing',
}

export enum SagaGamemasterActions {
    EditCharacterSaga = '@@gamemaster/EditCharacterSaga',
}

interface GamemasterAction {
    type: GamemasterActions;
}

interface GamemasterSagaAction {
    type: SagaGamemasterActions;
}

export enum GamemasterCharacterTypeId {
    PC = '@@gamemasterCharacter/PC',
    NPC = '@@gamemasterCharacter/NPC',
}

export interface GamemasterCharacterTypeDefinition {
    id: GamemasterCharacterTypeId;
    description: string;
}

export const gamemasterCharacterTypes: Record<GamemasterCharacterTypeId, GamemasterCharacterTypeDefinition> = {
    [GamemasterCharacterTypeId.NPC]: {
        id: GamemasterCharacterTypeId.NPC,
        description: 'Non-Player Character',
    },
    [GamemasterCharacterTypeId.PC]: {
        id: GamemasterCharacterTypeId.PC,
        description: 'Player Character',
    },
};

// ------------------------------------
// Actions
// ------------------------------------
interface LoadNewAction extends GamemasterAction {
    type: GamemasterActions.LoadNew;
    character: Character;
    alias: string;
    characterType: GamemasterCharacterTypeId;
}
export const loadNew = (alias: string, character: Character, characterType: GamemasterCharacterTypeId): LoadNewAction => {
    return {
        type: GamemasterActions.LoadNew,
        alias,
        character,
        characterType
    };
};

interface UpdateCharacterAction extends GamemasterAction {
    type: GamemasterActions.UpdateCharacter;
    newCharacter: Character;
    combatState: CombatState;
    id: UUIDv1;
}
export const updateCharacter = (id: UUIDv1, newCharacter: Character, combatState: CombatState): UpdateCharacterAction => {
    return {
        type: GamemasterActions.UpdateCharacter,
        newCharacter,
        combatState,
        id,
    };
};

export interface DeleteCharacterAction extends GamemasterAction {
    type: GamemasterActions.DeleteCharacter;
    id: UUIDv1;
}
export const deleteCharacter = (id: UUIDv1): DeleteCharacterAction => {
    return {
        type: GamemasterActions.DeleteCharacter,
        id,
    };
};

export interface ClearCharactersAction extends GamemasterAction {
    type: GamemasterActions.ClearCharacters;
    characterType: GamemasterCharacterTypeId;
}
export const clearCharacters = (characterType: GamemasterCharacterTypeId): ClearCharactersAction => {
    return {
        type: GamemasterActions.ClearCharacters,
        characterType,
    };
};

export interface SetGmEditingAction extends GamemasterAction {
    type: GamemasterActions.SetGmEditing;
    isEditing: boolean;
}
export const setGmEditing = (isEditing: boolean): SetGmEditingAction => {
    return {
        type: GamemasterActions.SetGmEditing,
        isEditing,
    };
};

export interface FinishGmEditingAction extends GamemasterAction {
    type: GamemasterActions.FinishGmEditing;
}
export const finishGmEditing = (): FinishGmEditingAction => {
    return {
        type: GamemasterActions.FinishGmEditing,
    };
};

export interface EditCharacterSagaAction extends GamemasterSagaAction {
    type: SagaGamemasterActions.EditCharacterSaga;
    id: UUIDv1;
}
export const editCharacterSaga = (id: UUIDv1): EditCharacterSagaAction => {
    return {
        type: SagaGamemasterActions.EditCharacterSaga,
        id,
    };
};

export type GamemasterActionTypes = LoadNewAction | UpdateCharacterAction | DeleteCharacterAction | ClearCharactersAction |
    SetGmEditingAction;

export interface GamemasterCharacter {
    id: UUIDv1;
    character: Character;
    characterType: GamemasterCharacterTypeId;
    alias: string;
}

export interface GamemasterState {
    characters: Record<UUIDv1, GamemasterCharacter>;
    combatStates: Record<UUIDv1, CombatState>;
    isInGmEditingSession: boolean;
}

const initialGamemasterLoad = JSON.parse(window.localStorage.getItem(GAMEMASTER_LOCAL_STORAGE_KEY) as string) || {};
const initialState: GamemasterState = Object.values(initialGamemasterLoad).length > 0 ? initialGamemasterLoad : {
    characters: {},
    combatStates: {},
    isInGmEditingSession: false,
};

export function gamemasterReducer(state = initialState, action: GamemasterActionTypes): GamemasterState {
    switch (action.type) {
        case GamemasterActions.LoadNew: {
            let id = v1();
            return {
                ...state,
                characters: {
                    ...state.characters,
                    [id]: {
                        id,
                        alias: action.alias,
                        character: action.character,
                        characterType: action.characterType,
                    },
                },
                combatStates: {
                    ...state.combatStates,
                    [id]: initialCombatState,
                }
            };
        }
        case GamemasterActions.UpdateCharacter: {
            let id = action.id;
            return {
                ...state,
                characters: {
                    ...state.characters,
                    [id]: {
                        ...state.characters[id],
                        character: action.newCharacter,
                    },
                },
                combatStates: {
                    ...state.combatStates,
                    [id]: action.combatState,
                },
            };
        }
        case GamemasterActions.DeleteCharacter: {
            let newCharacters = state.characters;
            delete newCharacters[action.id];
            let newCombat = state.combatStates;
            delete newCombat[action.id];
            return {
                ...state,
                characters: newCharacters,
                combatStates: newCombat,
            };
        }
        case GamemasterActions.ClearCharacters: {
            let newCharacters = state.characters;
            let newCombats = state.combatStates;
            Object.keys(newCharacters).forEach(characterId => {
                let gmCharacter = newCharacters[characterId];
                if (gmCharacter.characterType === action.characterType) {
                    delete newCharacters[characterId];
                    delete newCombats[characterId];
                }
            });
            return {
                ...state,
                characters: newCharacters,
                combatStates: newCombats,
            }
        }
        case GamemasterActions.SetGmEditing: {
            return {
                ...state,
                isInGmEditingSession: action.isEditing,
            }
        }
        default:
            return state
    }
}
