import React, {Component} from 'react';
import {Card} from 'react-bootstrap';
import {Character} from "../../../../../lib/constants/characterConstants";
import {CharacteristicId} from "../../../../../lib/constants/characteristicConstants";
import {
    getCharacteristicFromId,
    getCharacteristicInstance,
    getCharacteristicTypeLabel
} from "../../../../../lib/gameLogic/derivedCharacterStats";

const DESCRIPTION_TRUNCATE_LENGTH = 120;

interface Props {
    workingCharacter: Character;
    characteristicId: CharacteristicId;
    active: boolean;
}

export class CharacteristicListItem extends Component<Props> {
    render() {
        let {
            workingCharacter,
            characteristicId,
            active,
        } = this.props;
        let def = getCharacteristicFromId(characteristicId);

        let characteristicInstance = getCharacteristicInstance(characteristicId, workingCharacter);
        let levelText: string;
        if (characteristicInstance) {
            levelText = `Level ${characteristicInstance.level}`;
        } else {
            levelText = `Not taken`;
        }
        let descriptionSlug = def.description.length > DESCRIPTION_TRUNCATE_LENGTH ?
            def.description.substring(0, DESCRIPTION_TRUNCATE_LENGTH) + '...' :
            def.description;
        return <Card className={`${active ? 'selected' : ''}`}>
            <Card.Body>
                <Card.Title>{def.name}</Card.Title>
                <Card.Subtitle>{`${getCharacteristicTypeLabel(characteristicId)}: ${levelText}`}</Card.Subtitle>
                <Card.Text>{descriptionSlug}</Card.Text>
            </Card.Body>
        </Card>;
    }
}
