import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import {connect} from 'react-redux';
import {Container, Row,} from 'react-bootstrap';
import {CenteredFlexCol} from "../../lib/components/CenteredFlexCol";
import {CenteredFlexDiv} from "../../lib/components/CenteredFlexDiv";

class Home extends Component {
  render() {
    let {
    } = this.props;

    return <Container fluid={true} className='landing-page'>
      <Row className='fullscreen-row'>
        <CenteredFlexCol md={3} />
        <CenteredFlexCol isRow={false} md={6}>
          <CenteredFlexDiv style={{minHeight: '80vh'}} isRow={false} justification='between'>
            <div>
              <h1 className='text-center'>GURPS WW2</h1>
              <Link to='/character'><h2 className='text-center'>Character</h2></Link>
              <Link to='/gamemaster'><h2 className='text-center'>GM Tools</h2></Link>
            </div>
            <div>
              <p className='disclaimer'><em>GURPS</em> is a trademark of Steve Jackson Games, and its rules and art are copyrighted by Steve Jackson Games.
                All rights are reserved by Steve Jackson Games. This game aid is the original creation of Dan van Kley and is
                released for free distribution, and not for resale, under the permissions granted in the <a href="http://www.sjgames.com/general/online_policy.html">Steve Jackson Games Online Policy</a>.
              </p>
              <p className='disclaimer'>If you're using this, you should buy a copy of <a href='http://www.sjgames.com/gurps/ww2/'>GURPS WW2</a>.
              </p>
            </div>
          </CenteredFlexDiv>
        </CenteredFlexCol>
        <CenteredFlexCol md={3} />
      </Row>
    </Container>;
  }
}

interface State {
}

const mapStateToProps = (state: State) => {
  return {
  };
};

const mapDispatchToProps = {
  // changePage: () => push('/about-us')
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home)
