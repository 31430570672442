import React, {Component} from 'react';
import {Col, Row,} from 'react-bootstrap';
import {
  getAttributeCurrentValue,
  getModifiedCharacteristicValue
} from "../../../../../lib/gameLogic/derivedCharacterStats";
import {attributeDefinitions, AttributeId} from "../../../../../lib/constants/attributeConstants";
import {Character} from "../../../../../lib/constants/characterConstants";
import {CombatState} from "../../../../../modules/combat";

interface Props {
  combat: CombatState;
  character: Character;
  attributeId: AttributeId;
}

export default class AttributeCell extends Component<Props> {
  render() {
    let {
      character,
      attributeId,
      combat,
    } = this.props;

    let abbreviation = attributeDefinitions[attributeId].abbreviation;
    let level = character.attributes[attributeId].level;
    let currentValue = getModifiedCharacteristicValue(getAttributeCurrentValue(attributeId, character, combat));

    return <Row className='no-gutters'>
      <Col>
        <h4>{abbreviation}:</h4>
      </Col>
      <Col>
        <h4>{currentValue}/{level}</h4>
      </Col>
    </Row>
  }
}
