import React, {Component} from 'react';
import {GunReadyStatus, MAX_AIM} from "../../../../../../modules/combat";
import {CenteredFlexDiv} from "../../../../../../lib/components/CenteredFlexDiv";
import {Button, Form} from "react-bootstrap";
import {CombatPageProps} from "../../../CombatPage";
import {weaponDefinitions} from "../../../../../../lib/constants/weaponConstants";

interface State {
  braced: boolean;
}

export class Aim extends Component<CombatPageProps, State> {
  constructor(props: CombatPageProps) {
    super(props);
    this.state = {
      braced: false,
    };
  }

  executeManeuver() {
    let {
      character,
      combat,
      aimManeuver,
      addToCharacterLog,
    } = this.props;
    if (!combat.weaponReadyStatus) {
      throw new Error(`Can't aim an unreadied weapon`)
    }
    aimManeuver(character, this.state.braced);
    addToCharacterLog(`Aimed ${weaponDefinitions[combat.weaponReadyStatus.weapon].name}`);
  }

  render() {
    let {
      character,
      combat
    } = this.props;
    if (!combat.weaponReadyStatus) {
      throw new Error(`Can't aim an unreadied weapon`)
    }
    let readyStatus = combat.weaponReadyStatus as GunReadyStatus;
    return <CenteredFlexDiv isRow={false}>
      <Form>
        <Form.Group>
          <Form.Check
            type="checkbox"
            label='Weapon Braced?'
            checked={this.state.braced}
            onChange={() => {
              this.setState({
                braced: !this.state.braced,
              });
            }}
          />
        </Form.Group>
      </Form>
      <Button variant='primary' disabled={readyStatus.aim >= MAX_AIM} onClick={this.executeManeuver.bind(this)}>Aim</Button>
    </CenteredFlexDiv>;
  }
}
