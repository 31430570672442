import {AttackRollResult, CharacteristicRollResult, RollResult, RollType} from "../constants/rollConstants";

export const getRollResultCssClass = (rollResult: RollResult): string => {
  let resultClass: string;
  switch (rollResult.type) {
    case RollType.Dodge:
    case RollType.FragHit:
    case RollType.Success: {
      let successResult = rollResult as CharacteristicRollResult;
      // total = values.reduce((carry, value) => carry + value, 0);
      resultClass = successResult.success ? 'success' : 'failure';
      if (successResult.criticalFailure || successResult.criticalSuccess) {
        resultClass += ' critical';
      }
      return resultClass;
    }
    case RollType.Attack: {
      let attackResult = rollResult as AttackRollResult;
      resultClass = attackResult.automaticHit || attackResult.success ? 'success' : 'failure';
      if (attackResult.automaticHit || attackResult.automaticMiss || attackResult.maxDamageHits > 0) {
        resultClass += ' critical';
      }
      return resultClass;
    }
    case RollType.Generic:
    case RollType.Damage: {
      return '';
    }
    default:
      throw Error(`Invalid roll type ${rollResult.type}`);
  }
};

export const getRollResultDescription = (rollResult: RollResult): string => {
  let resultDescription: string;
  switch (rollResult.type) {
    case RollType.Dodge:
    case RollType.FragHit:
    case RollType.Success:
      let successResult = rollResult as CharacteristicRollResult;
      resultDescription = successResult.success ? 'Success' : 'Failure';
      if (successResult.criticalFailure || successResult.criticalSuccess) {
        resultDescription = 'Critical ' + resultDescription;
      }
      return resultDescription;
    case RollType.Attack:
      let attackResult = rollResult as AttackRollResult;
      if (attackResult.maxDamageHits > 0) {
        return 'Critical: Max Damage';
      }
      if (attackResult.automaticHit) {
        return 'Critical: Automatic Hit';
      }
      if (attackResult.malfunction) {
        return 'Weapon Malfunction';
      }
      if (attackResult.automaticMiss) {
        return 'Critical: Automatic Miss';
      }
      return attackResult.success ? 'Success' : 'Failure';
    case RollType.Damage:
      return 'Damage';
    case RollType.Generic:
      return '';
    default:
      throw Error(`Invalid roll type ${rollResult.type}`);
  }
};