import React, {Component} from 'react';
import {CenteredFlexDiv} from "../../../../../../lib/components/CenteredFlexDiv";
import {Button} from "react-bootstrap";
import {CombatPageProps} from "../../../CombatPage";
import {
    getActiveDefenseEligibility,
    getActiveDefenseReadiness,
} from "../../../../../../lib/gameLogic/derivedCombatStats";

interface State {
    parryingBarehandWithWeapon: boolean;
    parryingThreeTimesHeavierWeapon: boolean;
}

export class Parry extends Component<CombatPageProps, State> {
    constructor(props: CombatPageProps) {
        super(props);
        this.state = {
            parryingBarehandWithWeapon: false,
            parryingThreeTimesHeavierWeapon: false,
        };
    }

    executeManeuver() {
        let {
            character,
            defendParryManeuverSaga,
        } = this.props;
        let {
            parryingBarehandWithWeapon,
            parryingThreeTimesHeavierWeapon,
        } = this.state;
        defendParryManeuverSaga(character, parryingBarehandWithWeapon, parryingThreeTimesHeavierWeapon);
    }

    render() {
        let {
            combat
        } = this.props;
        if (!combat.weaponReadyStatus) {
            return <h4>You need a ready weapon to parry</h4>;
        }
        let {isReady, isReloading} = getActiveDefenseReadiness(combat.weaponReadyStatus);

        if (isReloading) {
            return <h4>You can't parry while reloading a gun</h4>;
        }
        if (!isReady) {
            return <h4>Weapon must be unslung and ready to parry</h4>;
        }

        let isEligible = getActiveDefenseEligibility(combat);
        if (!isEligible) {
            return <h4>You have already defended as many times as you can this turn</h4>;
        }

        return <CenteredFlexDiv isRow={false}>
            <Button variant='primary' onClick={this.executeManeuver.bind(this)}>Roll for Parry</Button>
        </CenteredFlexDiv>;
    }
}
