import {
  getBasicSpeed,
  getCharacteristicCurrentValue,
  getCharacteristicValueForRollSpec,
  getModifiedCharacteristicValue,
  getSkillCurrentValue
} from "./derivedCharacterStats";
import {Character} from "../constants/characterConstants";
import {SkillId} from "../constants/skillConstants";
import {weaponDefinitions, WeaponId} from "../constants/weaponConstants";
import {
  getArmorDivisor,
  getConcussiveDamageMultiplierByRange,
  getFragmentationHitThreshold,
  isArmorPiercingWeapon,
  isAutomaticWeapon,
  isExplosiveWeapon,
  isShotgunWeapon,
  parseAttackHits
} from "./derivedCombatStats";
import {CombatState} from "../../modules/combat";
import {arrayChunk, generateArrayFullOf} from "../utils";
import {
  AttackRollResult,
  AttackRollSpec,
  CharacteristicRollResult,
  CharacteristicRollSpec,
  DamageRollResult,
  DamageRollSpec,
  DodgeRollResult,
  DodgeRollSpec,
  FragHitRollResult,
  FragHitRollSpec,
  GenericRollResult,
  GenericRollSpec,
  ParryRollResult,
  ParryRollSpec,
  RollType
} from "../constants/rollConstants";
import {DamageTypeId, getDamageModifiedByType, getWeaponDamageModifiedByType} from "../constants/damageConstants";

const MAX_STANDARD_DIE_ROLL = 6;

const roll = (sides: number = 6): number => {
  return Math.round(Math.random() * (sides - 1)) + 1;
};

export const rollMany = (count: number, sides: number = 6): Array<number> => {
  let out = [];
  for (let die = 0; die < count; die++) {
    out.push(roll(sides));
  }
  return out;
};

export const standardRoll = (): Array<number> => {
  return [roll(6), roll(6), roll(6)];
};

export const getRollTotal = (values: Array<number>) => {
  return values.reduce((carry, value) => carry + value, 0);
};

const isSkillOrAttackCriticalSuccess = (rollTotal: number, effectiveSkill: number): boolean => {
  if ([3,4].includes(rollTotal)) {
    return true;
  }
  if (effectiveSkill >= 15 && rollTotal <= 5) {
    return true;
  }
  if (effectiveSkill >= 16 && rollTotal <= 6) {
    return true;
  }
  return false;
};

const isSkillCriticalFailure = (rollTotal: number, effectiveSkill: number): boolean => {
  if (rollTotal === 18) {
    return true;
  }
  if (effectiveSkill < 16 && rollTotal >= 17) {
    return true;
  }
  if (rollTotal >= (effectiveSkill + 10)) {
    return true;
  }
  return false;
};

const isAttackCriticalFailure = (rollTotal: number, weaponId: WeaponId): boolean => {
  let def = weaponDefinitions[weaponId];
  let cutoff = Math.min(17, def.malfunction.roll || 18);

  return rollTotal >= cutoff;
};

const isGenericCriticalFailure = (rollTotal: number): boolean => {
  return rollTotal >= 17;
};

const isGenericCriticalSuccess = (rollTotal: number): boolean => {
  return rollTotal <= 4;
};

/**
 * @param spec
 * @param character
 * @param combatState
 * @param totalRoll Null for an automatic roll, provide the manual result otherwise.
 */
export const executeSuccessRollForSpec = (spec: CharacteristicRollSpec, character: Character, combatState: CombatState, totalRoll: number | null = null): CharacteristicRollResult => {
  let bestCharacteristic = spec.skillAttribute;
  let rollAgainst = getModifiedCharacteristicValue(getCharacteristicValueForRollSpec(spec, character, combatState));
  if (!totalRoll) {
    let rollValues = standardRoll();
    totalRoll = getRollTotal(rollValues);
  }
  let criticalSuccess = isSkillOrAttackCriticalSuccess(totalRoll, rollAgainst);
  let criticalFailure = isSkillCriticalFailure(totalRoll, rollAgainst);
  return {
    type: RollType.Success,
    totalRoll,
    success: criticalSuccess || (!criticalFailure && ![17,18].includes(totalRoll) && totalRoll <= rollAgainst),
    criticalSuccess,
    criticalFailure,
    characteristic: bestCharacteristic,
    rollSpec: spec,
  };
};

/**
 * @param spec
 * @param character
 * @param combat
 * @param totalRoll Null for an automatic roll, provide the manual result otherwise.
 */
export const executeAttackRollForSpec = (spec: AttackRollSpec, character: Character, combat: CombatState, totalRoll: number | null = null): AttackRollResult => {
  let rollAgainst = getModifiedCharacteristicValue(getCharacteristicCurrentValue(spec.skill.id, character, combat));
  if (!totalRoll) {
    let rollValues = standardRoll();
    totalRoll = getRollTotal(rollValues);
  }
  let criticalSuccess = isSkillOrAttackCriticalSuccess(totalRoll, rollAgainst);
  let criticalFailure = isAttackCriticalFailure(totalRoll, spec.weaponId);
  let weaponMalf = weaponDefinitions[spec.weaponId].malfunction;
  let malfunction: boolean;
  if (weaponMalf.crit) {
    malfunction = criticalFailure;
  } else if (weaponMalf.roll) {
    malfunction = totalRoll >= weaponMalf.roll;
  } else {
    throw new Error(`Weapon ${spec.weaponId} has an invalid malfunction definition`);
  }
  let automaticMiss = criticalFailure || malfunction;
  let success = criticalSuccess || (!automaticMiss && ![17,18].includes(totalRoll) && totalRoll <= rollAgainst);
  let maxDamageCritical = totalRoll === 3;
  let differential = rollAgainst - totalRoll;

  let {normalHits, maxDamageHits} = parseAttackHits(spec.weaponId, combat, differential, success, criticalSuccess, maxDamageCritical);
  return {
    totalRoll,
    differential,
    skill: spec.skill,
    type: RollType.Attack,
    rollSpec: spec,
    success,
    automaticHit: criticalSuccess,
    automaticMiss,
    normalHits,
    maxDamageHits,
    malfunction,
  };
};

/**
 * @param spec
 * @param character
 * @param combatState
 * @param totalRoll Null for an automatic roll, provide the manual result otherwise.
 */
export const executeDodgeRollForSpec = (spec: DodgeRollSpec, character: Character, combatState: CombatState, totalRoll: number | null = null): DodgeRollResult => {
  let rollAgainst = getModifiedCharacteristicValue(getBasicSpeed(character, combatState));
  if (!totalRoll) {
    let rollValues = standardRoll();
    totalRoll = getRollTotal(rollValues);
  }
  let criticalSuccess = isGenericCriticalSuccess(totalRoll);
  let criticalFailure = isGenericCriticalFailure(totalRoll);
  return {
    totalRoll,
    type: RollType.Dodge,
    rollSpec: spec,
    success: criticalSuccess || (!criticalFailure && totalRoll <= rollAgainst),
    criticalSuccess,
    criticalFailure,
  };
};

/**
 * @param spec
 * @param character
 * @param combatState
 * @param totalRoll Null for an automatic roll, provide the manual result otherwise.
 */
export const executeParryRollForSpec = (spec: ParryRollSpec, character: Character, combatState: CombatState, totalRoll: number | null = null): ParryRollResult => {
  // TODO: implement this
  let rollAgainst = getModifiedCharacteristicValue(getSkillCurrentValue(SkillId.Karate, character, combatState));
  if (!totalRoll) {
    let rollValues = standardRoll();
    totalRoll = getRollTotal(rollValues);
  }
  let criticalSuccess = isGenericCriticalSuccess(totalRoll);
  let criticalFailure = isGenericCriticalFailure(totalRoll);
  return {
    totalRoll,
    type: RollType.Parry,
    rollSpec: spec,
    success: criticalSuccess || (!criticalFailure && totalRoll <= rollAgainst),
    criticalSuccess,
    criticalFailure,
  };
};

const getDrForDamageRoll = (spec: DamageRollSpec): number => {
  let isExplosive = isExplosiveWeapon(spec.weaponId);
  let isArmorPiercing = isArmorPiercingWeapon(spec.weaponId);
  let impactDistance = spec.impactDistanceToTarget;

  let dr = spec.targetDr;
  // Manual: "Shaped Charges: These special explosives have an “armor divisor” in parentheses after their damage.
  //  On a direct hit, divide DR by this number; e.g., 6d×2 (10) means “apply 6d×2 to 1/10 DR.”
  // I kind of wonder if this shouldn't apply to soft targets, but I doubt it matters much
  if (isArmorPiercing && (impactDistance === undefined || impactDistance === 0)) {
    dr /= getArmorDivisor(spec.weaponId);
  }

  // Manual: Explosives do concussion damage and fragmentation damage. Both types of damage are doubled for anyone
  //  in contact with the explosive when it goes off. PD has no effect on either type of damage, and no active defense
  //  is possible against an explosion.
  if (isExplosive) {
    if (!impactDistance && impactDistance !== 0) {
      throw new Error(`Damage roll spec for explosive weapon missing impact distance.`);
    }
    // Manual: A vehicle or structure’s DR, but not that of WWII personal armor, is squared vs. (the concussive damage of)
    //  explosions that aren’t a direct hit. Toughness protects normally.
    if (!spec.softTarget && impactDistance > 0) {
      dr = dr * dr;
    }
  }
  return dr;
};

const getModifiedPostDrDamage = (spec: DamageRollSpec, postDrDamage: number): number => {
  let weaponDef = weaponDefinitions[spec.weaponId];
  if (weaponDef.damage.halvePenetratedDamage) {
    postDrDamage /= 2;
  } else if (weaponDef.damage.multiplyPenetratedDamage) {
    postDrDamage *= 1.5;
  }
  // Manual: "armor-piercing rounds with a modifier of (2) halve damage to living things that penetrates DR."
  // There are some weapons with a (10) notation, which appears to denote "shaped charges" (see above). They're
  //  explosive weapons and in a different category than the (2) anti-material ballistic rounds.
  if (getArmorDivisor(spec.weaponId) === 2 && spec.softTarget) {
    postDrDamage /= 2;
  }
  return getWeaponDamageModifiedByType(spec.weaponId, postDrDamage);
};

const getFragDamage = (spec: DamageRollSpec, manualFragRoll: Array<number> | null = null): number => {
  if (!spec.fragDamageHit) {
    return 0;
  }
  let weaponDef = weaponDefinitions[spec.weaponId];
  let directHitMultiplier = spec.impactDistanceToTarget === 0 ? 2 : 1;
  let fragDice = weaponDef.damage.fragmentation;
  if (!fragDice) {
    return 0;
  }
  if (manualFragRoll && manualFragRoll.length !== fragDice) {
    throw new Error(`Need to enter ${fragDice} fragmentation dice rolls for ${weaponDef.name}; found ${manualFragRoll.length}`);
  }
  let rolls = manualFragRoll || rollMany(fragDice);

  let postDrDamage = (getRollTotal(rolls) * directHitMultiplier) - spec.targetDr;
  return getDamageModifiedByType(DamageTypeId.Cutting, postDrDamage);
};

/**
 * Manual: Fragmentation: Most explosive munitions are designed to produce lots of metal fragments. Fragmentation
 * damage is given in square brackets after concussion damage; e.g., 2d [2d] means “2d concussion, 2d fragmentation.”
 * An explosion projects fragments to a distance of 5 yards times the dice of concussion damage. A hit is automatic at
 * “ground zero.” At 1 yard from the blast, a hit occurs on a roll of 17 or less. At 2 yards, the roll is 16 or less,
 * and so on. When this roll reaches 3, it stays at 3 to the limit of fragment range. Apply the target Situational
 * Modifers from p. 201 to this roll – but against explosives bursting in the air any cover must be overhead and lying
 * prone doesn’t help! DR (including any from cover) protects normally against frag- mentation. Fragmentation damage is
 * considered cutting damage.
 *
 * It's not really clear what roll the manual is referring to: the original attack roll or a new roll made specifically
 *  for frag damage? For now I'm going to treat it as a separate roll.
 * @param spec
 * @param manualRoll
 */
export const executeFragHitRollForSpec = (spec: FragHitRollSpec, manualRoll: number | null = null): FragHitRollResult => {
  let hitThreshold = getFragmentationHitThreshold(spec.impactRangeToTarget);

  let totalRoll: number;
  if (manualRoll) {
    totalRoll = manualRoll;
  } else {
    let rollValues = standardRoll();
    totalRoll = getRollTotal(rollValues);
  }
  return {
    totalRoll,
    type: RollType.FragHit,
    rollSpec: spec,
    success: totalRoll <= hitThreshold,
    criticalSuccess: false,
    criticalFailure: false,
  };
};

/**
 * @param spec
 * @param character
 * @param manualRoll Null for an automatic roll, provide the manual result otherwise.
 * @param manualFragRoll
 */
export const executeDamageRollForSpec = (
    spec: DamageRollSpec,
    character: Character,
    manualRoll: Array<number> | null = null,
    manualFragRoll: Array<number> | null = null
): DamageRollResult => {
  let weaponDef = weaponDefinitions[spec.weaponId];
  let diceToRoll = weaponDef.damage.dice * spec.normalHits;
  if (manualRoll && manualRoll.length !== diceToRoll) {
    throw new Error(`Need to enter ${diceToRoll} dice rolls for ${weaponDef.name}; found ${manualRoll.length}`);
  }
  let isShotgun = isShotgunWeapon(spec.weaponId);
  let isExplosive = isExplosiveWeapon(spec.weaponId);
  let isAutomatic = isAutomaticWeapon(spec.weaponId);

  if (isShotgun && isExplosive || isShotgun && isAutomatic) {
    throw new Error(`Automatic and/or explosive shotguns are not supported.`);
  }

  let dr = getDrForDamageRoll(spec);

  let concussiveDamageMultiplier = 1;
  if (isExplosive) {
    let impactDistance = spec.impactDistanceToTarget;
    if (!impactDistance && impactDistance !== 0) {
      throw new Error(`Damage roll spec for explosive weapon missing impact distance.`);
    }
    concussiveDamageMultiplier = getConcussiveDamageMultiplierByRange(spec.weaponId, impactDistance);
  }

  let modifier = spec.modifiers.reduce((carry, current) => {
    return carry + current.value;
  }, 0);

  let rollValues = (manualRoll || rollMany(diceToRoll)).concat(generateArrayFullOf(weaponDef.damage.dice * spec.maxDamageHits, MAX_STANDARD_DIE_ROLL));
  // let totalRoll = (getRollTotal(rollValues) + (spec.maxDamageHits * weaponDef.damage.dice * MAX_STANDARD_DIE_ROLL)) * diceMultiplier;
  
  let postDrDamage: number;
  if (isShotgun) {
    // Manual: "The wide spread of shot gives +1 to hit, but roll each die of damage individually and apply it to DR separately."
    let shotgunModifiedRollValues = rollValues.map(rollValue => {
      return Math.max(rollValue - dr, 0);
    });
    postDrDamage = getRollTotal(shotgunModifiedRollValues) + modifier;
  } else if (isAutomatic) {
    // Automatic weapons can hit multiple times per burst
    // Manual: Roll damage separately for each round that hits and apply DR separately against each round.
    let automaticModifiedRollValues = arrayChunk(rollValues, weaponDef.damage.dice).map(groupRolls => {
      return Math.max((getRollTotal(groupRolls) + modifier) * concussiveDamageMultiplier - dr, 0);
    });
    postDrDamage = getRollTotal(automaticModifiedRollValues);
  } else {
    postDrDamage = (getRollTotal(rollValues) + modifier) * concussiveDamageMultiplier - dr;
  }
  let modifiedPostDrDamage = getModifiedPostDrDamage(spec, postDrDamage * weaponDef.damage.diceMultiplier);

  let fragDamage = Math.max(Math.round(getFragDamage(spec, manualFragRoll)), 0);

  return {
    totalRoll: getRollTotal(rollValues),
    type: RollType.Damage,
    rollSpec: spec,
    totalDamage: Math.max(Math.round(modifiedPostDrDamage) + fragDamage, 0),
  };
};

export const executeGenericRollForSpec = (
    spec: GenericRollSpec,
    character: Character,
    manualRoll: number | null = null,
): GenericRollResult => {
  let diceToRoll = spec.diceCount;
  let modifier = spec.modifiers.reduce((carry, current) => {
    return carry + current.value;
  }, 0);

  let totalRoll = (manualRoll || getRollTotal(rollMany(diceToRoll))) + modifier;

  return {
    totalRoll: totalRoll,
    type: RollType.Generic,
    rollSpec: spec,
  };
};
