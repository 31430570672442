import {
    CharacterFile,
    CURRENT_CHARACTER_ID_LOCAL_STORAGE_KEY,
    DataFileType,
    getFilePrefix,
    getLocalStorageAutosavesKey,
    getLocalStorageFileKey,
    UUIDv1
} from "../constants/dataConstants";

export const getLocalStorageAutosaves = (id: UUIDv1, type: DataFileType): Array<CharacterFile> => {
    let rawString = window.localStorage.getItem(getLocalStorageAutosavesKey(id, type));
    if (!rawString) {
        return [];
    }
    return JSON.parse(rawString);
};

export const writeLocalStorageAutosaves = (id: UUIDv1, type: DataFileType, autosaves: Array<CharacterFile>): void => {
    let key = getLocalStorageAutosavesKey(id, type);
    window.localStorage.setItem(key, JSON.stringify(autosaves));
};

export const getAllLocalStorageIds = (type: DataFileType): Array<UUIDv1> => {
    let out = {} as Record<string, boolean>;
    let prefix = getFilePrefix(type);
    for (let key in window.localStorage) {
        if (key.startsWith(prefix)) {
            // + 1 for period delimiter
            let id = key.substring(prefix.length + 1);
            out[id] = true;
        }
    }
    return Object.keys(out);
};

export const getLocalStorageFile = (id: UUIDv1, type: DataFileType): CharacterFile | null => {
    let rawString = window.localStorage.getItem(getLocalStorageFileKey(id, type));
    if (!rawString) {
        return null;
    }
    return JSON.parse(rawString);
};

export const writeLocalStorageFile = (id: UUIDv1, type: DataFileType, file: CharacterFile): void => {
    window.localStorage.setItem(getLocalStorageFileKey(id, type), JSON.stringify(file));
};

export const deleteLocalStorageFile = (id: UUIDv1, type: DataFileType): void => {
    window.localStorage.removeItem(getLocalStorageFileKey(id, type));
}
;
export const deleteLocalStorageAutosaves = (id: UUIDv1, type: DataFileType): void => {
    window.localStorage.removeItem(getLocalStorageAutosavesKey(id, type));
};

export const getCurrentCharacterId = (): UUIDv1 | null => {
    return window.localStorage.getItem(CURRENT_CHARACTER_ID_LOCAL_STORAGE_KEY);
};

export const writeCurrentCharacterId = (id: UUIDv1): void => {
    return window.localStorage.setItem(CURRENT_CHARACTER_ID_LOCAL_STORAGE_KEY, id);
};
