import React, {Component} from 'react';
import {Col, Form, ListGroup} from 'react-bootstrap';
import {
  Equipment,
  EquipmentType,
  getEquipmentDefinition,
  getEquipmentType
} from "../../../../../lib/constants/equipmentConstants";
import {equipGear} from '../../../../../modules/character';
import {GearId} from "../../../../../lib/constants/gearConstants";
import {CenteredRow} from "../../../../../lib/components/CenteredRow";
import {CenteredFlexCol} from "../../../../../lib/components/CenteredFlexCol";

interface Props {
  equipment: Equipment;
  active: boolean;
  equipGear: typeof equipGear;
}

const WEAPON_LABEL_PROPS = {
  xs: 12,
};
const GEAR_LABEL_PROPS = {
  md: 6,
};

const WEAPON_CHECK_PROPS = {
  xs: 0,
};
const GEAR_CHECK_PROPS = {
  xs: 1,
};

const WEAPON_EQUIPPED_PROPS = {
  xs: 0,
};
const GEAR_EQUIPPED_PROPS = {
  md: 5,
};

export class EquipmentListItem extends Component<Props> {
  render() {
    let {
      equipment,
    } = this.props;
    let def = getEquipmentDefinition(equipment.id);
    let isGear = getEquipmentType(this.props.equipment.id) == EquipmentType.Gear;
    let labelProps = isGear ? GEAR_LABEL_PROPS : WEAPON_LABEL_PROPS;
    let checkProps = isGear ? GEAR_CHECK_PROPS : WEAPON_CHECK_PROPS;
    let equippedProps = isGear ? GEAR_EQUIPPED_PROPS : WEAPON_EQUIPPED_PROPS;

    return <ListGroup.Item active={this.props.active} action>
      <Form>
        <Form.Group style={{marginBottom: 0}}>
          <Form.Check type="checkbox" style={{paddingLeft: 0}}>
            <CenteredRow>
              <Col {...labelProps}>
                <Form.Check.Label>{`${def.name}: ${equipment.quantity}`}</Form.Check.Label>
              </Col>
              <Col {...checkProps} />
              <CenteredFlexCol justify='flex-start' {...equippedProps}>
                {this.renderEquippedCheckbox()}
              </CenteredFlexCol>
            </CenteredRow>
          </Form.Check>
        </Form.Group>
      </Form>
    </ListGroup.Item>;
  }

  renderEquippedCheckbox() {
    if (getEquipmentType(this.props.equipment.id) !== EquipmentType.Gear) {
      return null;
    }
    return <>
      <Form.Check.Label>Equipped</Form.Check.Label>
      <Form.Check.Input
          style={{marginTop:0}}
          type="checkbox"
          checked={this.props.equipment.equipped}
          onChange={() => {
            let gearId = this.props.equipment.id as GearId;
            this.props.equipGear(gearId, !this.props.equipment.equipped);
          }}
      />
    </>
  }
}
