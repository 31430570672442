// ------------------------------------
// Constants
// ------------------------------------

// ------------------------------------
// Actions
// ------------------------------------
export enum CharacterEditorActions {
  SetConditionEventResult = '@@characterEditor/SetConditionEventResult',
}

interface CharacterEditorAction {
  type: CharacterEditorActions;
}


interface SetConditionEventResultAction extends CharacterEditorAction {
  type: CharacterEditorActions.SetConditionEventResult,
  resultText: string;
}
export const setConditionEventResult = (resultText: string): SetConditionEventResultAction => {
  return {
    type: CharacterEditorActions.SetConditionEventResult,
    resultText
  };
};

export type CharacterEditorActionTypes = SetConditionEventResultAction;

export interface CharacterEditorState {
  readonly conditionEventResult: string | null;
}

const initialState: CharacterEditorState = {
  conditionEventResult: null,
};

export function characterEditorReducer(state = initialState, action: CharacterEditorActionTypes): CharacterEditorState {
  switch (action.type) {
    case CharacterEditorActions.SetConditionEventResult: {
      return {
        ...state,
        conditionEventResult: action.resultText,
      };
    }
    default:
      return state
  }
}
