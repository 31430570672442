import {Skill, SkillId} from "./skillConstants";
import {Attribute, AttributeId} from "./attributeConstants";
import {Vantage, VantageId} from "./vantageConstants";

export enum CharacteristicType {
  Skill = '@@characteristic/typeSkill',
  Attribute = '@@characteristic/typeAttribute',
  Vantage = '@@characteristic/typeVantage',
}

export type CharacteristicId = SkillId | AttributeId | VantageId;
export type Characteristic = Skill | Attribute | Vantage;

export interface CharacteristicDefinition  {
  id: CharacteristicId;
  type: CharacteristicType;
  name: string;
  description: string;
}
