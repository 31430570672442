import React from 'react';
import {Link} from 'react-router-dom'
import {connect} from 'react-redux';
import {Button, Nav, Navbar,} from 'react-bootstrap';
import {loadCharacter} from "../../../modules/character";
import {rifleman} from "../../../lib/constants/fixtures/rifleman";
import {AppState} from "../../../modules";
import {bindActionCreators} from "redux";
import {confirmSaga} from "../../../modules/modal";
import {COMBAT_LOCAL_STORAGE_KEY} from "../../../modules/combat";
import {AppDispatch} from "../../../lib/constants/appConstants";
import {finishGmEditing} from "../../../modules/gamemaster";

class CharacterNavbar extends React.Component<Props> {
  render() {
    let {
      gm: {
        isInGmEditingSession,
      },
    } = this.props;

    return <Navbar bg='light' expand='lg' className='character-nav'>
      <Link to='/'>
        <Navbar.Brand className='stencil-font header'>GURPS WW2</Navbar.Brand>
      </Link>
      <Navbar.Toggle/>
      <Navbar.Collapse>
        <Nav className='mr-auto' variant='tabs' id='character-navbar'>
          <Link className='stencil-font' to='/character/status'>Status</Link>
          <Link className='stencil-font' to='/character/skill'>Skills</Link>
          <Link className='stencil-font' to='/character/combat'>Combat</Link>
          <Link className='stencil-font' to='/character/equipment'>Equipment</Link>
          <Link className='stencil-font' to='/character/editor'>Editor</Link>
          {!isInGmEditingSession &&
            <Link className='stencil-font' to='/character/data'>Data</Link>
          }
          {isInGmEditingSession &&
            <Link
                className='stencil-font'
                to='/gamemaster/overview'
                onClick={() => {
                  let {
                    finishGmEditing,
                  } = this.props;
                  finishGmEditing();
                }}
            >Return to GM</Link>
          }
          {/*<Nav.Link style={{height:'24px'}}><Button onClick={this.loadFixtureCharacter.bind(this)}>Load Fixture</Button></Nav.Link>*/}
          <Nav.Item style={{height:'24px'}}><Button onClick={() => {
            let {
              combat,
            } = this.props;

            window.localStorage.setItem(COMBAT_LOCAL_STORAGE_KEY, JSON.stringify(combat));
          }}>Save State</Button></Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>;
  }

  loadFixtureCharacter() {
    let {
      loadCharacter,
    } = this.props;
    console.log('Loading fixture character');

    loadCharacter(rifleman);
  }
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    character: state.characterReducer,
    combat: state.combatReducer,
    gm: state.gamemasterReducer,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  loadCharacter,
  confirmSaga,
  finishGmEditing,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CharacterNavbar)
