import {combineReducers} from 'redux'
import {characterReducer} from './character'
import {combatReducer} from './combat'
import {characterUiReducer} from "./characterUi";
import {characterEditorReducer} from './characterEditor'
import {rollReducer} from './roll'
import {modalReducer} from './modal'
import {dataReducer} from "./data";
import {gamemasterReducer} from "./gamemaster";

const rootReducer = combineReducers({
  characterReducer,
  combatReducer,
  characterEditorReducer,
  characterUiReducer,
  rollReducer,
  modalReducer,
  dataReducer,
  gamemasterReducer,
});

export default rootReducer;
export type AppState = ReturnType<typeof rootReducer>
