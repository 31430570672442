import {CharacteristicDefinition, CharacteristicType} from "./characteristicConstants";

export interface Attribute  {
  id: AttributeId;
  level: number;
}

export interface AttributeModifier {
  id: AttributeId;
  modifier: number;
}

export interface AttributeDefinition extends CharacteristicDefinition {
  abbreviation: string;
}

export const ATTRIBUTE_ID_PREFIX = '@@attribute';

export enum AttributeId {
  Strength = '@@attribute/Strength',
  Dexterity = '@@attribute/Dexterity',
  Intelligence = '@@attribute/Intelligence',
  Health = '@@attribute/Health',
}

export const attributeDefinitions: { [index in AttributeId]: AttributeDefinition } = {
  [AttributeId.Strength]: {
    id: AttributeId.Strength,
    type: CharacteristicType.Attribute,
    name: 'Strength',
    description: 'A measure of “brawn” or physical muscle',
    abbreviation: 'ST',
  },
  [AttributeId.Dexterity]: {
    id: AttributeId.Dexterity,
    type: CharacteristicType.Attribute,
    name: 'Dexterity',
    description: 'A measure of agility and coordination',
    abbreviation: 'DX',
  },
  [AttributeId.Intelligence]: {
    id: AttributeId.Intelligence,
    type: CharacteristicType.Attribute,
    name: 'Intelligence',
    description: 'A measure of brainpower, alertness, adaptability, and general background experience',
    abbreviation: 'IQ',
  },
  [AttributeId.Health]: {
    id: AttributeId.Health,
    type: CharacteristicType.Attribute,
    name: 'Health',
    description: 'A measure of energy and vitality. HT also stands for “hits” – the amount of physical damage a character can take. When you have taken “hits” equal to your Health score, you soon fall unconscious. Further injury can kill you.',
    abbreviation: 'HT',
  },
};

export const attributeDefinitionsByAbbreviation: Record<string, AttributeDefinition> =
  Object.values(attributeDefinitions).reduce((carry: Record<string, AttributeDefinition>, attribute: AttributeDefinition | undefined) => {
    if (attribute !== undefined) {
      carry[attribute.abbreviation] = attribute;
    }
  return carry;
}, {});

export const attributePointCosts: Array<number> = [-80, -70, -60, -50, -40, -30, -20, -15, -10, 0, 10, 20, 30, 45, 60,
  80, 100, 125, 150, 175, 200, 225, 250, 275, 300, 325, 350, 375, 400];