import React, {Component} from 'react';
import {OverlayTrigger, Popover,} from 'react-bootstrap';
import {CenteredFlexCol} from "../../../../../lib/components/CenteredFlexCol";
import {CenteredFlexDiv} from "../../../../../lib/components/CenteredFlexDiv";
import {Modifier} from "../../../../../lib/constants/rollConstants";

interface Props {
  modifier: Modifier;
}

export default class ModifierSubpanel extends Component<Props> {
  infoPopover(props: Props) {
    let {
      /** @type {Modifier} */
      modifier,
    } = this.props;
    return <Popover {...props} id={`modifier-popover-${modifier.summary}`} title={modifier.summary}>{modifier.description}</Popover>;
  };

  render() {
    let {
      /** @type {Modifier} */
      modifier,
    } = this.props;

    let sign = modifier.value > 0 ? '+' : '';

    return <CenteredFlexCol {...this.props} isRow={false}>
      <h2>{sign}{modifier.value}</h2>
      <CenteredFlexDiv>
        <OverlayTrigger trigger="click" placement='top' rootClose={true} overlay={this.infoPopover.bind(this)}>
          <div>
            <h4>{modifier.summary}</h4>
          </div>
        </OverlayTrigger>
      </CenteredFlexDiv>
    </CenteredFlexCol>
  }
}
