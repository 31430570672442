import React from 'react';
import {connect} from 'react-redux';
import {Character} from '../../../lib/constants/characterConstants';
import {Container, Row,} from 'react-bootstrap';
import {setFatiguePoints} from "../../../modules/character";
import LogPanel from "./panels/LogPanel";
import RollPanel from "./panels/RollPanel";
import {executeRoll, fatigueRollSaga, initRoll} from "../../../modules/roll";
import {AppState} from "../../../modules";
import SkillPanel from "./panels/SkillPanel";
import {RollProps} from "./StatusPage";
import {CombatState} from "../../../modules/combat";

interface Props extends RollProps {
  combat: CombatState;
  character: Character;
  logs: Array<string>;
}

class SkillPage extends React.Component<Props> {
  render() {
    let {
      character,
      logs,
      initRoll,
      executeRoll,
    } = this.props;

    if (Object.values(character).length === 0) {
      return <h2>No Character loaded</h2>;
    }

    return <Container fluid={true} className='content-container'>
      <Row className='top-row'>
        <SkillPanel
          {...this.props}
        />
        {/*<FeatPanel*/}
        {/*  character={character}*/}
        {/*  initRoll={initRoll}*/}
        {/*  executeRoll={executeRoll}*/}
        {/*/>*/}
      </Row>
      <Row className='bottom-row'>
        <RollPanel/>
        <LogPanel
          logs={logs}
        />
      </Row>
    </Container>;
  }
}

const mapStateToProps = (state: AppState) => {
  // Consider using reselect to memoize derived values here if performance becomes an issue
  return {
    ...state.characterReducer,
    combat: state.combatReducer,
  };
};

const mapDispatchToProps = {
  setFatiguePoints,
  initRoll,
  executeRoll,
  fatigueRollSaga,
  // changePage: () => push('/about-us')
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SkillPage)
