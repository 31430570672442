import {SkillId} from "./skillConstants";
import {Equipment} from "./equipmentConstants";
import {GunReadyStatus, HandWeaponReadyStatus, ReadyStatusUnion} from "../../modules/combat";
import {Character} from "./characterConstants";
import {DamageTypeId} from "./damageConstants";

export interface WeaponCategoryDefinition {
  name: string;
  id: WeaponCategoryId;
  type: WeaponType,
  damageType: DamageTypeId,
  skills: Array<SkillId>;
  description: string;
  defaultReadyThreshold: ReadyThresholdUnion;
}

export interface WeaponDefinition {
  name: string;
  description: string;
  id: WeaponId,
  category: WeaponCategoryId;
  cost: number;
  weight: number;
  ammoWeight?: number;
  ammoType: string;
  malfunction: MalfunctionType;
  damage: WeaponDamage;
  rateOfFire: number;
  selectiveFire: boolean;
  snapShotThreshold?: number;
  shotsPerReload: number;
  recoilModifier?: number;
  accuracy?: number;
  halfDamageRange?: number;
  maxRange?: number;
  minimumRange?: number;
  indirectRange?: number;
  fuse?: number;
  strengthRequirement?: StrengthRequirement;
  holdoutModifier?: number;
  overrideReadyThreshold?: ReadyThresholdUnion;
}

export enum WeaponType {
  Guns = '@@weaponType/Guns',
  HandWeapons = '@@weaponType/HandWeapons',
}

export interface ReadyThreshold {
  type: WeaponType;
  unsling: number;
}

export interface GunReadyThreshold extends ReadyThreshold {
  reloadPerRound: boolean;
  /** If reloadPerRound is true, this is a number indicating how many actions are required above and beyond one action per round.
   *  Otherwise it's an absolute number indicating how many ready actions it takes to reload the weapon.
   */
  reload: number;
  cock: number;
  /** If true, the weapon must be cocked every time it attacks. Otherwise it only needs to be cocked every time it's reloaded.
   */
  cockPerAttack: boolean;
}

export interface HandWeaponReadyThreshold extends ReadyThreshold {
  backswing: number;
}

export type ReadyThresholdUnion = GunReadyThreshold | HandWeaponReadyThreshold;

export interface Weapon extends Equipment {
  id: WeaponId;
  shotsLoaded: number;
  malfunctionTurns: number;
  needsMalfunctionRoll: boolean;
}

export interface MalfunctionType {
  roll?: number;
  crit: boolean;
}

export interface WeaponDamage {
  dice: number;
  diceMultiplier: number;
  modifier: number;
  halvePenetratedDamage: boolean;
  multiplyPenetratedDamage: boolean;
  armorDivisor?: number;
  fragmentation?: number;
}

export interface StrengthRequirement {
  strengthThreshold: number;
  bipodRequired: boolean;
  tripodRequired: boolean;
}

export enum WeaponCategoryId {
  SemiautomaticPistols = '@@weaponCategory/SemiautomaticPistols',
  Revolvers = '@@weaponCategory/Revolvers',
  Shotguns = '@@weaponCategory/Shotguns',
  Rifles = '@@weaponCategory/Rifles',
  SubmachineGuns = '@@weaponCategory/SubmachineGuns',
  LightMachineGuns = '@@weaponCategory/LightMachineGuns',
  HeavyMachineGuns = '@@weaponCategory/HeavyMachineGuns',
  Mortars = '@@weaponCategory/Mortars',
  LightAntitankWeapons = '@@weaponCategory/LightAntitankWeapons',
  HandGrenades = '@@weaponCategory/HandGrenades',
  Mines = '@@weaponCategory/Mines',
  RifleGrenades = '@@weaponCategory/RifleGrenades',
  Flamethrowers = '@@weaponCategory/Flamethrowers',
}

export const weaponCategories: Record<WeaponCategoryId, WeaponCategoryDefinition> = {
  [WeaponCategoryId.SemiautomaticPistols]: {
    id: WeaponCategoryId.SemiautomaticPistols,
    name: 'Semiautomatic Pistols',
    type: WeaponType.Guns,
    damageType: DamageTypeId.Crushing,
    skills: [SkillId.GunsPistol],
    description: 'Officers and troops other than regular infantry often carried a pistol for self-defense.',
    defaultReadyThreshold: {
      type: WeaponType.Guns,
      unsling: 0,
      reloadPerRound: false,
      reload: 3,
      cock: 1,
      cockPerAttack: false,
    },
  },
  [WeaponCategoryId.Revolvers]: {
    id: WeaponCategoryId.Revolvers,
    name: 'Revolvers',
    damageType: DamageTypeId.Crushing,
    skills: [SkillId.GunsPistol],
    type: WeaponType.Guns,
    description: 'Despite the popularity of semiautomatics, revolvers still played a prominent role as a defensive weapon in WWII.',
    defaultReadyThreshold: {
      type: WeaponType.Guns,
      unsling: 0,
      reloadPerRound: true,
      reload: 3,
      cock: 1,
      cockPerAttack: false,
    },
  },
  [WeaponCategoryId.Shotguns]: {
    id: WeaponCategoryId.Shotguns,
    name: 'Shotguns',
    damageType: DamageTypeId.Crushing,
    skills: [SkillId.GunsShotgun],
    type: WeaponType.Guns,
    description: 'The wide spread of shot gives +1 to hit, but roll each die of damage individually and apply it to DR separately.',
    defaultReadyThreshold: {
      type: WeaponType.Guns,
      unsling: 1,
      reloadPerRound: true,
      reload: 3,
      cock: 1,
      cockPerAttack: true,
    },
  },
  [WeaponCategoryId.Rifles]: {
    id: WeaponCategoryId.Rifles,
    name: 'Rifles',
    damageType: DamageTypeId.Crushing,
    skills: [SkillId.GunsRifle, SkillId.GunsLightAuto],
    type: WeaponType.Guns,
    description: 'Rifles served as the basic infantry weapon of WWII. Everyone in uniform had at least passing familiarity with them.',
    defaultReadyThreshold: {
      type: WeaponType.Guns,
      unsling: 1,
      reloadPerRound: false,
      reload: 3,
      cock: 1,
      cockPerAttack: false,
    },
  },
  [WeaponCategoryId.SubmachineGuns]: {
    id: WeaponCategoryId.SubmachineGuns,
    name: 'Submachine Guns',
    damageType: DamageTypeId.Crushing,
    skills: [SkillId.GunsRifle, SkillId.GunsLightAuto],
    type: WeaponType.Guns,
    description: 'SMGs enjoyed a rising popularity for close-quarters fighting, where quality of fire took a back seat to quantity. Most SMGs fire from an open bolt, meaning that an extra round cannot be placed in the chamber as with rifles. Some used drum magazines, which imposed a -2 to the user’s Stealth skill because their ammo load rattled when jostled. Many SMGs, particularly those with older designs, had the unfortunate habit of firing if struck hard while cocked.',
    defaultReadyThreshold: {
      type: WeaponType.Guns,
      unsling: 1,
      reloadPerRound: false,
      reload: 3,
      cock: 1,
      cockPerAttack: false,
    },
  },
  [WeaponCategoryId.LightMachineGuns]: {
    id: WeaponCategoryId.LightMachineGuns,
    name: 'Light Machine Guns',
    damageType: DamageTypeId.Crushing,
    skills: [SkillId.GunsLightAuto],
    type: WeaponType.Guns,
    description: 'The MGs in this book usually use a metallic belt, though HMGs often use cloth belts. Almost all MGs can use a belt of the other type if that’s what is available.',
    defaultReadyThreshold: {
      type: WeaponType.Guns,
      unsling: 3,
      reloadPerRound: false,
      reload: 0,
      cock: 1,
      cockPerAttack: false,
    },
  },
  [WeaponCategoryId.HeavyMachineGuns]: {
    id: WeaponCategoryId.HeavyMachineGuns,
    name: 'Heavy Machine Guns',
    damageType: DamageTypeId.Crushing,
    skills: [SkillId.GunnerHeavyMachineGun],
    type: WeaponType.Guns,
    description: 'The MGs in this book usually use a metallic belt, though HMGs often use cloth belts. Almost all MGs can use a belt of the other type if that’s what is available.',
    defaultReadyThreshold: {
      type: WeaponType.Guns,
      unsling: 5,
      reloadPerRound: false,
      reload: 0,
      cock: 1,
      cockPerAttack: false,
    },
  },
  [WeaponCategoryId.Mortars]: {
    id: WeaponCategoryId.Mortars,
    name: 'Mortars',
    damageType: DamageTypeId.Crushing,
    skills: [SkillId.GunnerArtillery],
    type: WeaponType.Guns,
    description: 'Most mortars can break down for easier transport. For simplicity, assume this becomes three loads (tube, bipod, and baseplate), each at one-third of the weapon’s weight. As an exception to the general rule, the listed weight is empty weight.',
    defaultReadyThreshold: {
      type: WeaponType.Guns,
      unsling: 5,
      reloadPerRound: true,
      reload: 5,
      cock: 0,
      cockPerAttack: false,
    },
  },
  [WeaponCategoryId.LightAntitankWeapons]: {
    id: WeaponCategoryId.LightAntitankWeapons,
    name: 'Light Anti-tank Weapons',
    damageType: DamageTypeId.Crushing,
    skills: [SkillId.GunsLightAntitank],
    type: WeaponType.Guns,
    description: 'Anti-tank weapons that could be carried and used by a single soldier.',
    defaultReadyThreshold: {
      type: WeaponType.Guns,
      unsling: 2,
      reloadPerRound: true,
      reload: 5,
      cock: 0,
      cockPerAttack: false,
    },
  },
  [WeaponCategoryId.HandGrenades]: {
    id: WeaponCategoryId.HandGrenades,
    name: 'Hand Grenades',
    damageType: DamageTypeId.Crushing,
    skills: [SkillId.Throwing],
    type: WeaponType.Guns,
    description: 'Most grenades were high explosive, with or without a fragmentation component; see pp. 202-203 for effects. Many WWII fragmentation grenades used a serrated fragmentation body, giving the famous "pineapple" appearance. This improved the grip (providing a roll vs. DX to avoid any "dropped weapon" critical miss), but actually degraded fragmentation. A serrated grenade should do no more than 1d-1 fragmentation attacks outside the hex in which it explodes. Roll for the closest targets first, and quit rolling if the maximum number of attacks is reached. Grenade fuses were cut by hand in the factory. Hangovers, the need for a restroom break, or plain boredom could affect delay time. When it matters, roll 3d for each grenade with a delay fuse. On a 3 subtract 3 seconds from delay time, on a 4 subtract 2, on a 5 subtract 1, on a 16 add 1, on a 17 add 2, and on an 18 add 3. Veterans took the risk of their target having time to pick up the grenade and throw it back, rather than hold one with a burning fuse. Shaped-charge grenades were thrown in a special fashion, at -2 to Throwing skill. Those who didn’t know this took an additional -4 when trying to use one.',
    defaultReadyThreshold: {
      type: WeaponType.Guns,
      unsling: 1,
      reloadPerRound: true,
      reload: 0,
      cock: 1,
      cockPerAttack: false,
    },
  },
  [WeaponCategoryId.Mines]: {
    id: WeaponCategoryId.Mines,
    name: 'Mines',
    damageType: DamageTypeId.Crushing,
    skills: [],
    type: WeaponType.Guns,
    description: 'Mines often posed more of a threat than all other enemy arms. WWII’s combatants laid millions of them. Every soldier in Europe could expect to encounter them. The Pacific combatants used them less frequently, but didn’t ignore them. Planting a mine can take as little as 5 seconds to arm one and drop it in a weed-filled ditch, to 2 minutes to gouge a hole in loose earth then fill it again after laying the mine, to an hour to rig an intricate booby trap with multiple trip and pull wires attached to objects near the mine. The GM should apply a modifier of -5 to +5 to the minelayer’s Traps roll, improving as he puts more time into placing the weapon. The amount by which he makes the roll serves as a penalty to first detect, then disarm, the mine. Advancing very slowly (1 yard every 2 seconds) and probing with a bayonet will give a +1 to Traps rolls to detect the mine. A thin, hollow metal tube with a sharpened point ($1, 0.7 lbs.) can serve as an effective mine detector – the noise it makes after dropping it from a few inches above the earth is telling. Apply a +2 to detect mines. Metal detectors provide powerful but limited aid; see p. 89. These generic mines make up a very limited sample; following books in the WWII line will provide more examples.',
    defaultReadyThreshold: {
      type: WeaponType.Guns,
      unsling: 2,
      reloadPerRound: true,
      reload: 0,
      cock: 1,
      cockPerAttack: false,
    },
  },
  [WeaponCategoryId.RifleGrenades]: {
    id: WeaponCategoryId.RifleGrenades,
    name: 'Rifle Grenades',
    damageType: DamageTypeId.Crushing,
    skills: [SkillId.GunsGrenadeLauncher],
    type: WeaponType.Guns,
    description: 'All major forces issued rifle grenades to their infantry. The U.S. military took to them in particular. Using a rifle grenade required attaching a special launcher to the muzzle of one’s rifle, LMG, or antitank rifle. This took 5 seconds. Most varieties then required that a blank or otherwise special round be loaded into the rifle. The grenade was then placed on the launcher and fired using Guns (Grenade Launcher), usually at Acc 0. Launchers usually fit a specific weapon. Cup launchers, usually rifled, used bullet-shaped grenades while spigot launchers inserted into the tail of a rocket-shaped grenade ($5, 0.8 lbs. if spigot or 1.7 lbs. if cup). Spigot launchers usually had range bands; the farther down the grenade was seated, the farther it would fly.',
    defaultReadyThreshold: {
      type: WeaponType.Guns,
      unsling: 1,
      reloadPerRound: true,
      reload: 4,
      cock: 1,
      cockPerAttack: true,
    },
  },
  [WeaponCategoryId.Flamethrowers]: {
    id: WeaponCategoryId.Flamethrowers,
    name: 'Flamethrowers',
    damageType: DamageTypeId.Crushing,
    skills: [SkillId.GunsFlamethrower],
    type: WeaponType.Guns,
    description: 'Flamethrowers strike fear in their intended targets; the GM may require a Fright Check (see p. 197) or apply a penalty to an existing check for troops facing one. On the other hand, soldiers using flamethrowers will attract a huge percentage of enemy fire, on top of the hazard they face by needing to get very close to their targets... Double PD vs. flamethrowers. All flamethrowers reduce target DR to one-fifth normal unless it’s sealed (see p. 130). A struck target continues to burn, taking 1d each for 10d seconds, with DR protecting as above. Only complete immersion in water or similar measures will put out the flame. Flamethrowers usually fire "hot shots" of lit fuel. Increase the burn damage to 1d+1 per turn if the target was hit with a "cold shot" of unlit fuel, which was then allowed to soak in at least 3 seconds prior to being ignited by a hot shot or another flame source.',
    defaultReadyThreshold: {
      type: WeaponType.Guns,
      unsling: 2,
      reloadPerRound: false,
      reload: 20,
      cock: 1,
      cockPerAttack: false,
    },
  },
};

export const explosiveWeaponCategories = [
  WeaponCategoryId.HandGrenades,
  WeaponCategoryId.Mines,
  WeaponCategoryId.Mortars,
];

export const WEAPON_ID_PREFIX = '@@weapon';

export enum WeaponId {
  BrowningModel1910 = '@@weapon/BrowningModel1910',
  BrowningHP35 = '@@weapon/BrowningHP35',
  ColtModel1911A1 = '@@weapon/ColtModel1911A1',
  TokarevTT33 = '@@weapon/TokarevTT33',
  BroomhandleMauser = '@@weapon/BroomhandleMauser',
  LugerPistole08 = '@@weapon/LugerPistole08',
  WaltherPPK = '@@weapon/WaltherPPK',
  WaltherP38 = '@@weapon/WaltherP38',
  Nambu14Shiki = '@@weapon/Nambu14Shiki',
  Nambu94Shiki = '@@weapon/Nambu94Shiki',
  BerettaModello1934 = '@@weapon/BerettaModello1934',
  EnfieldNo2MkI = '@@weapon/EnfieldNo2MkI',
  SmithWessonM1917 = '@@weapon/SmithWessonM1917',
  NagantM1895 = '@@weapon/NagantM1895',
  BrowningAutomatic = '@@weapon/BrowningAutomatic',
  WinchesterModel1897 = '@@weapon/WinchesterModel1897',
  LeeEnfieldNo4MkI = '@@weapon/LeeEnfieldNo4MkI',
  BoysMkI = '@@weapon/BoysMkI',
  MosinNagantModel189130 = '@@weapon/MosinNagantModel189130',
  Kokura97Shiki = '@@weapon/Kokura97Shiki',
  PTRS41 = '@@weapon/PTRS41',
  SpringfieldM1903A1 = '@@weapon/SpringfieldM1903A1',
  M1Garand = '@@weapon/M1Garand',
  M1Carbine = '@@weapon/M1Carbine',
  Karabiner98k = '@@weapon/Karabiner98k',
  Gewehr43 = '@@weapon/Gewehr43',
  Fallschirmjagergewehr42 = '@@weapon/Fallschirmjagergewehr42',
  Sturmgewehr44 = '@@weapon/Sturmgewehr44',
  Panzerbuchse39 = '@@weapon/Panzerbuchse39',
  ArisakaandNambu99Shiki = '@@weapon/ArisakaandNambu99Shiki',
  FusilMAS36 = '@@weapon/FusilMAS36',
  LanchesterMkI = '@@weapon/LanchesterMkI',
  StenGunMkII = '@@weapon/StenGunMkII',
  OwenMkI = '@@weapon/OwenMkI',
  M1A1Thompson = '@@weapon/M1A1Thompson',
  M3Greasegun = '@@weapon/M3Greasegun',
  PPS43 = '@@weapon/PPS43',
  PPSh41 = '@@weapon/PPSh41',
  MP38and40 = '@@weapon/MP38and40',
  KP31Suomi = '@@weapon/KP31Suomi',
  Kokura100Shiki = '@@weapon/Kokura100Shiki',
  BerettaModello1938A = '@@weapon/BerettaModello1938A',
  LewisMkI = '@@weapon/LewisMkI',
  BrenMkII = '@@weapon/BrenMkII',
  VickersMkI = '@@weapon/VickersMkI',
  M1918A2BAR = '@@weapon/M1918A2BAR',
  BrowningM1917 = '@@weapon/BrowningM1917',
  BrowningM1919A4 = '@@weapon/BrowningM1919A4',
  BrowningM2HB = '@@weapon/BrowningM2HB',
  DP = '@@weapon/DP',
  SG43 = '@@weapon/SG43',
  DShK38 = '@@weapon/DShK38',
  MG34 = '@@weapon/MG34',
  MG42 = '@@weapon/MG42',
  Nambu11Shiki = '@@weapon/Nambu11Shiki',
  Nambu99Shiki = '@@weapon/Nambu99Shiki',
  ZBvz26or30 = '@@weapon/ZBvz26or30',
  FusilMitrailleurModele192429 = '@@weapon/FusilMitrailleurModele192429',
  M2 = '@@weapon/M2',
  Type107GVPM38 = '@@weapon/Type107GVPM38',
  Type120PM38 = '@@weapon/Type120PM38',
  leGrW36 = '@@weapon/leGrW36',
  sGrW34 = '@@weapon/sGrW34',
  Type89Shiki = '@@weapon/Type89Shiki',
  PIAT = '@@weapon/PIAT',
  M1Bazooka = '@@weapon/M1Bazooka',
  Panzerfaust30 = '@@weapon/Panzerfaust30',
  No36MillsBomb = '@@weapon/No36MillsBomb',
  MkIIPineapple = '@@weapon/MkIIPineapple',
  MkIIIA2 = '@@weapon/MkIIIA2',
  ANM8HCSmoke = '@@weapon/ANM8HCSmoke',
  M15WP = '@@weapon/M15WP',
  F1 = '@@weapon/F1',
  RG42 = '@@weapon/RG42',
  RPG43 = '@@weapon/RPG43',
  RPG6 = '@@weapon/RPG6',
  MolotovCocktail = '@@weapon/MolotovCocktail',
  Stielhandgranate24PotatoMasher = '@@weapon/Stielhandgranate24PotatoMasher',
  GeballteLadung = '@@weapon/GeballteLadung',
  Panzerwurfmine = '@@weapon/Panzerwurfmine',
  Type91Shiki = '@@weapon/Type91Shiki',
  RG34 = '@@weapon/RG34',
  BouncingAntipersonnel = '@@weapon/BouncingAntipersonnel',
  Vehicular = '@@weapon/Vehicular',
  Wooden = '@@weapon/Wooden',
  No68 = '@@weapon/No68',
  M17 = '@@weapon/M17',
  M9A1 = '@@weapon/M9A1',
  M19A1 = '@@weapon/M19A1',
  M191 = '@@weapon/M191',
  M22 = '@@weapon/M22',
  Gewehrsprenggranate30mm = '@@weapon/Gewehrsprenggranate30mm',
  GrosseGewehrpanzergranate = '@@weapon/GrosseGewehrpanzergranate',
  Type99Shiki = '@@weapon/Type99Shiki',
  No2MkILifebuoy = '@@weapon/No2MkILifebuoy',
  M1A1 = '@@weapon/M1A1',
  ROKS2 = '@@weapon/ROKS2',
  Flammenwerfer41 = '@@weapon/Flammenwerfer41',
  Type100Shiki = '@@weapon/Type100Shiki',
}

export const weaponDefinitions: Record<WeaponId, WeaponDefinition> = {
  [WeaponId.BrowningModel1910]: {
    id: WeaponId.BrowningModel1910,
    name: 'Browning Model 1910',
    category: WeaponCategoryId.SemiautomaticPistols,
    description: 'Though never officially distributed, this compact pistol saw widespread service. A .32 ACP version did 2d-1 damage with 1/2 Dam 100. The even smaller (1 lb., +2 to Holdout) Model 1906 fired .25 ACPs doing 1ddamage, 1/2D 50, and Max 1,000.',
    cost: 30.0,
    weight: 1.3,
    ammoType: '.380 ACP',
    damage: {
      dice: 2,
      diceMultiplier: 1,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 3.0,
    selectiveFire: false,
    shotsPerReload: 7,
    malfunction: {
      crit: true,
    },
    ammoWeight: 0.3,
    snapShotThreshold: 10,
    recoilModifier: -1,
    accuracy: 1,
    halfDamageRange: 125,
    maxRange: 1500,
    strengthRequirement: {
      strengthThreshold: 8,
      bipodRequired: false,
      tripodRequired: false,
    },
    holdoutModifier: 1,
  },
  [WeaponId.BrowningHP35]: {
    id: WeaponId.BrowningHP35,
    name: 'Browning HP 35',
    category: WeaponCategoryId.SemiautomaticPistols,
    description: 'The British upgraded some troops to the High Power during the war.',
    cost: 80.0,
    weight: 2.4,
    ammoType: '9mm P.',
    damage: {
      dice: 2,
      diceMultiplier: 1,
      modifier: 2,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 3.0,
    selectiveFire: false,
    shotsPerReload: 13,
    malfunction: {
      crit: true,
    },
    ammoWeight: 0.5,
    snapShotThreshold: 10,
    recoilModifier: -1,
    accuracy: 3,
    halfDamageRange: 150,
    maxRange: 1900,
    strengthRequirement: {
      strengthThreshold: 9,
      bipodRequired: false,
      tripodRequired: false,
    },
    holdoutModifier: -1,
  },
  [WeaponId.ColtModel1911A1]: {
    id: WeaponId.ColtModel1911A1,
    name: 'Colt Model 1911A1',
    category: WeaponCategoryId.SemiautomaticPistols,
    description: 'The standard U.S. service pistol, the M-1911A1 upgrade debuted in 1926.',
    cost: 30.0,
    weight: 3.0,
    ammoType: '.45 ACP',
    damage: {
      dice: 2,
      diceMultiplier: 1,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 3.0,
    selectiveFire: false,
    shotsPerReload: 7,
    malfunction: {
      crit: true,
    },
    ammoWeight: 0.6,
    snapShotThreshold: 10,
    recoilModifier: -2,
    accuracy: 2,
    halfDamageRange: 175,
    maxRange: 1700,
    strengthRequirement: {
      strengthThreshold: 10,
      bipodRequired: false,
      tripodRequired: false,
    },
    holdoutModifier: -1,
  },
  [WeaponId.TokarevTT33]: {
    id: WeaponId.TokarevTT33,
    name: 'Tokarev TT-33',
    category: WeaponCategoryId.SemiautomaticPistols,
    description: 'The Soviets upgraded to this pistol during the war, though the transition wasn’t complete by 1945.',
    cost: 25.0,
    weight: 2.1,
    ammoType: '7.62mm Tokarev',
    damage: {
      dice: 2,
      diceMultiplier: 1,
      modifier: 1,
      halvePenetratedDamage: true,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 3.0,
    selectiveFire: false,
    shotsPerReload: 8,
    malfunction: {
      crit: true,
    },
    ammoWeight: 0.4,
    snapShotThreshold: 10,
    recoilModifier: -1,
    accuracy: 2,
    halfDamageRange: 140,
    maxRange: 1800,
    strengthRequirement: {
      strengthThreshold: 10,
      bipodRequired: false,
      tripodRequired: false,
    },
    holdoutModifier: -1,
  },
  [WeaponId.BroomhandleMauser]: {
    id: WeaponId.BroomhandleMauser,
    name: '"Broomhandle" Mauser',
    category: WeaponCategoryId.SemiautomaticPistols,
    description: 'Though never an official sidearm, this German weapon showed up in the hands of German, Spanish, and Chinese troops. A special holster could attach to it for use as a shoulder stock. With the 1-lb. stock, use Guns (Rifle) and both hands to add 5 to Acc. The Mauser also came in a full-auto version; use Guns (Machine Pistol) without the stock, or Guns (Light Auto) with it. RoF was 14*; Rcl was -5 with the stock or -7 without it. Older Mausers use clips; some built after 1930 use magazines, which can also be fed with the older Mausers’ clips. Only magazine Mausers may have an extra round in the chamber.',
    cost: 40.0,
    weight: 2.8,
    ammoType: '7.62mm T.',
    damage: {
      dice: 2,
      diceMultiplier: 1,
      modifier: 1,
      halvePenetratedDamage: true,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 3.0,
    selectiveFire: false,
    shotsPerReload: 10,
    malfunction: {
      crit: true,
    },
    ammoWeight: 0.3,
    snapShotThreshold: 11,
    recoilModifier: -1,
    accuracy: 3,
    halfDamageRange: 140,
    maxRange: 1800,
    strengthRequirement: {
      strengthThreshold: 10,
      bipodRequired: false,
      tripodRequired: false,
    },
    holdoutModifier: -2,
  },
  [WeaponId.LugerPistole08]: {
    id: WeaponId.LugerPistole08,
    name: 'Luger Pistole 08',
    category: WeaponCategoryId.SemiautomaticPistols,
    description: 'Many Germans still carried the sleek Luger after the P38 replaced it as their official sidearm in 1939. Allied troops greatly prized it as war booty.',
    cost: 50.0,
    weight: 2.4,
    ammoType: '9mm Parabellum',
    damage: {
      dice: 2,
      diceMultiplier: 1,
      modifier: 2,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 3.0,
    selectiveFire: false,
    shotsPerReload: 8,
    malfunction: {
      roll: 16,
      crit: false,
    },
    ammoWeight: 0.5,
    snapShotThreshold: 9,
    recoilModifier: -1,
    accuracy: 4,
    halfDamageRange: 150,
    maxRange: 1900,
    strengthRequirement: {
      strengthThreshold: 9,
      bipodRequired: false,
      tripodRequired: false,
    },
    holdoutModifier: -1,
  },
  [WeaponId.WaltherPPK]: {
    id: WeaponId.WaltherPPK,
    name: 'Walther PPK',
    category: WeaponCategoryId.SemiautomaticPistols,
    description: 'A common sidearm for German officers, this compact weapon also would nicely serve secret agents. An uncommon .380 APC version did 2d damage, with 1/2D 125 and a 6round magazine.',
    cost: 75.0,
    weight: 1.5,
    ammoType: '.32 ACP',
    damage: {
      dice: 2,
      diceMultiplier: 1,
      modifier: -1,
      halvePenetratedDamage: true,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 3.0,
    selectiveFire: false,
    shotsPerReload: 7,
    malfunction: {
      crit: true,
    },
    ammoWeight: 0.2,
    snapShotThreshold: 10,
    recoilModifier: -1,
    accuracy: 2,
    halfDamageRange: 100,
    maxRange: 1300,
    strengthRequirement: {
      strengthThreshold: 7,
      bipodRequired: false,
      tripodRequired: false,
    },
    holdoutModifier: 1,
  },
  [WeaponId.WaltherP38]: {
    id: WeaponId.WaltherP38,
    name: 'Walther P38',
    category: WeaponCategoryId.SemiautomaticPistols,
    description: 'The official German sidearm, this quality pistol and its PPK cousin had a pin to remind the user whether a round was chambered without jacking open the slide.',
    cost: 35.0,
    weight: 2.4,
    ammoType: '9mm Parabellum',
    damage: {
      dice: 2,
      diceMultiplier: 1,
      modifier: 2,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 3.0,
    selectiveFire: false,
    shotsPerReload: 8,
    malfunction: {
      crit: true,
    },
    ammoWeight: 0.4,
    snapShotThreshold: 10,
    recoilModifier: -1,
    accuracy: 3,
    halfDamageRange: 150,
    maxRange: 1900,
    strengthRequirement: {
      strengthThreshold: 9,
      bipodRequired: false,
      tripodRequired: false,
    },
    holdoutModifier: -1,
  },
  [WeaponId.Nambu14Shiki]: {
    id: WeaponId.Nambu14Shiki,
    name: 'Nambu 14 Shiki',
    category: WeaponCategoryId.SemiautomaticPistols,
    description: 'This crude imitation of the Luger was the most common Japanese sidearm.',
    cost: 15.0,
    weight: 2.2,
    ammoType: '8mm Nambu',
    damage: {
      dice: 2,
      diceMultiplier: 1,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 3.0,
    selectiveFire: false,
    shotsPerReload: 8,
    malfunction: {
      roll: 16,
      crit: false,
    },
    ammoWeight: 0.2,
    snapShotThreshold: 10,
    recoilModifier: -1,
    accuracy: 2,
    halfDamageRange: 130,
    maxRange: 1600,
    strengthRequirement: {
      strengthThreshold: 8,
      bipodRequired: false,
      tripodRequired: false,
    },
    holdoutModifier: -1,
  },
  [WeaponId.Nambu94Shiki]: {
    id: WeaponId.Nambu94Shiki,
    name: 'Nambu 94 Shiki',
    category: WeaponCategoryId.SemiautomaticPistols,
    description: 'One of the worst pistol designs of the war, this weapon could discharge any time that it was bumped hard. It also shot poorly when the user actually intended to fire it. Early on, only tank and air crews carried the Type 94, but usage spread to many other troops by 1945.',
    cost: 5.0,
    weight: 1.6,
    ammoType: '8mm Nambu',
    damage: {
      dice: 2,
      diceMultiplier: 1,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 3.0,
    selectiveFire: false,
    shotsPerReload: 6,
    malfunction: {
      roll: 15,
      crit: false,
    },
    ammoWeight: 0.2,
    snapShotThreshold: 11,
    recoilModifier: -1,
    accuracy: 1,
    halfDamageRange: 130,
    maxRange: 1600,
    strengthRequirement: {
      strengthThreshold: 8,
      bipodRequired: false,
      tripodRequired: false,
    },
    holdoutModifier: 0,
  },
  [WeaponId.BerettaModello1934]: {
    id: WeaponId.BerettaModello1934,
    name: 'Beretta Modello 1934',
    category: WeaponCategoryId.SemiautomaticPistols,
    description: 'Despite critical weapon shortages, the Italians rarely reduced the quality of their pistol production. This weapon’s fine finish and excellent design made it a popular war trophy. The Italian air force and navy carried the Modello 35 in .32 ACP (see Walther PPK).',
    cost: 40.0,
    weight: 1.5,
    ammoType: '.380 ACP',
    damage: {
      dice: 2,
      diceMultiplier: 1,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 3.0,
    selectiveFire: false,
    shotsPerReload: 7,
    malfunction: {
      crit: true,
    },
    ammoWeight: 0.3,
    snapShotThreshold: 10,
    recoilModifier: -1,
    accuracy: 2,
    halfDamageRange: 125,
    maxRange: 1500,
    strengthRequirement: {
      strengthThreshold: 8,
      bipodRequired: false,
      tripodRequired: false,
    },
    holdoutModifier: 1,
  },
  [WeaponId.EnfieldNo2MkI]: {
    id: WeaponId.EnfieldNo2MkI,
    name: 'Enfield No. 2 Mk I',
    category: WeaponCategoryId.Revolvers,
    description: 'A scaled-down version of the classic Webley 0.455-caliber service pistol, this served as the primary British sidearm under this name or as the Webley Mk IV. Usually worn on a lanyard, most versions also had the hammer filed off to prevent snagging, so manual cocking took 3 seconds. Firing this, or any other double-action revolver, without first cocking it imposes a -2. The stats also serve for the Smith & Wesson .38, which saw widespread British and U.S. usage.',
    cost: 20.0,
    weight: 1.8,
    ammoType: '.38 S&W',
    damage: {
      dice: 2,
      diceMultiplier: 1,
      modifier: -1,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 3.0,
    selectiveFire: false,
    shotsPerReload: 6,
    malfunction: {
      crit: true,
    },
    ammoWeight: 0.2,
    snapShotThreshold: 10,
    recoilModifier: -1,
    accuracy: 2,
    halfDamageRange: 120,
    maxRange: 1600,
    strengthRequirement: {
      strengthThreshold: 8,
      bipodRequired: false,
      tripodRequired: false,
    },
    holdoutModifier: -1,
  },
  [WeaponId.SmithWessonM1917]: {
    id: WeaponId.SmithWessonM1917,
    name: 'Smith & Wesson M-1917',
    category: WeaponCategoryId.Revolvers,
    description: 'Also made by Colt, this fired the rimless .45 ACP round. It employed two three-round clips to hold the rounds in the chamber. These speeded up reloading; use normal revolver rules, but the clips count as only two "rounds." British Home Guard and Royal Navy units often used this weapon, as did U.S. military police.',
    cost: 30.0,
    weight: 2.6,
    ammoType: '.45 ACP',
    damage: {
      dice: 2,
      diceMultiplier: 1,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 3.0,
    selectiveFire: false,
    shotsPerReload: 6,
    malfunction: {
      crit: true,
    },
    ammoWeight: 0.4,
    snapShotThreshold: 10,
    recoilModifier: -2,
    accuracy: 2,
    halfDamageRange: 175,
    maxRange: 1700,
    strengthRequirement: {
      strengthThreshold: 10,
      bipodRequired: false,
      tripodRequired: false,
    },
    holdoutModifier: -1,
  },
  [WeaponId.NagantM1895]: {
    id: WeaponId.NagantM1895,
    name: 'Nagant M-1895',
    category: WeaponCategoryId.Revolvers,
    description: 'The "Gas-Seal" featured a seven-shot cylinder that loaded through a side-gate, meaning that ejecting the empties took 1 second per round, not total. The strange cartridges and sliding cylinder mated during firing, allowing the pistol to use a suppressor. (No other revolver can.) Soviets and Eastern European forces often carried this weapon.',
    cost: 20.0,
    weight: 2.0,
    ammoType: '7.62mm Nagant',
    damage: {
      dice: 2,
      diceMultiplier: 1,
      modifier: -1,
      halvePenetratedDamage: true,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 3.0,
    selectiveFire: false,
    shotsPerReload: 7,
    malfunction: {
      crit: true,
    },
    ammoWeight: 0.25,
    snapShotThreshold: 10,
    recoilModifier: -1,
    accuracy: 2,
    halfDamageRange: 150,
    maxRange: 1900,
    strengthRequirement: {
      strengthThreshold: 8,
      bipodRequired: false,
      tripodRequired: false,
    },
    holdoutModifier: -1,
  },
  [WeaponId.BrowningAutomatic]: {
    id: WeaponId.BrowningAutomatic,
    name: 'Browning Automatic',
    category: WeaponCategoryId.Shotguns,
    description: 'The British preferred this semiautomatic for jungle fighting in the Pacific. Unlike its pump counterpart, the semiauto Browning could be repeatedly fired with one hand, though at high recoil penalties (p. CII67). If fired from the hip, the Browning would not chamber another shell properly on a 16+; the firer would have to take 1 second to chamber the next round manually.',
    cost: 50.0,
    weight: 9.0,
    ammoType: '12g',
    damage: {
      dice: 4,
      diceMultiplier: 1,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 3.0,
    selectiveFire: false,
    shotsPerReload: 5,
    malfunction: {
      crit: true,
    },
    ammoWeight: 0.9,
    snapShotThreshold: 11,
    recoilModifier: -2,
    accuracy: 5,
    halfDamageRange: 25,
    maxRange: 150,
    strengthRequirement: {
      strengthThreshold: 12,
      bipodRequired: false,
      tripodRequired: false,
    },
    holdoutModifier: -7,
  },
  [WeaponId.WinchesterModel1897]: {
    id: WeaponId.WinchesterModel1897,
    name: 'Winchester Model 1897',
    category: WeaponCategoryId.Shotguns,
    description: 'The U.S. Marines preferred their classic pump shotgun to semiautomatics.',
    cost: 45.0,
    weight: 7.8,
    ammoType: '12g',
    damage: {
      dice: 4,
      diceMultiplier: 1,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 3.0,
    selectiveFire: false,
    shotsPerReload: 5,
    malfunction: {
      crit: true,
    },
    ammoWeight: 0.9,
    snapShotThreshold: 11,
    recoilModifier: -4,
    accuracy: 5,
    halfDamageRange: 25,
    maxRange: 150,
    strengthRequirement: {
      strengthThreshold: 13,
      bipodRequired: false,
      tripodRequired: false,
    },
    holdoutModifier: -6,
  },
  [WeaponId.LeeEnfieldNo4MkI]: {
    id: WeaponId.LeeEnfieldNo4MkI,
    name: 'Lee-Enfield No. 4 Mk I',
    category: WeaponCategoryId.Rifles,
    description: 'The primary British and Commonwealth weapon, this bolt action was very easy to operate, doubling the usual RoF. It used box magazines, but also could be fed from 5-round stripper clips (0.4 lbs. each). A shortened version, the No. 5 Mk I (1945), weighed 7.2 lbs. but suffered reduced performance (6d damage, Acc 9, 1/2 Dam 700, Max 3,000, Recoil -3). Its sights also wandered. Firing the weapon under controlled conditions to fine-tune the sights (called zeroing) made them Acc 9, but after every 5d shots Acc dropped by -1 (to a minimum of 5) until the sights were zeroed again. Other versions of the Lee-Enfield existed, but they all basically emulated the No. 4 or No. 5.',
    cost: 30.0,
    weight: 10.0,
    ammoType: '.303 Br.',
    damage: {
      dice: 6,
      diceMultiplier: 1,
      modifier: 2,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 1.0,
    selectiveFire: false,
    shotsPerReload: 10,
    malfunction: {
      crit: true,
    },
    ammoWeight: 1.0,
    snapShotThreshold: 14,
    recoilModifier: -2,
    accuracy: 10,
    halfDamageRange: 1000,
    maxRange: 3800,
    strengthRequirement: {
      strengthThreshold: 12,
      bipodRequired: false,
      tripodRequired: false,
    },
    holdoutModifier: -6,
  },
  [WeaponId.BoysMkI]: {
    id: WeaponId.BoysMkI,
    name: 'Boys Mk I',
    category: WeaponCategoryId.Rifles,
    description: 'Thickening tank armor quickly outmoded this British antitank rifle after its debut in the late 1930s. The recoil punished all but the strongest gunners when fired from the bipod (which was actually a monopod on early versions). The British often mounted the Boys on vehicles, giving it an Acc of 13. The Germans used some captured samples and somehow the U.S. Marines ended up with a batch of them.',
    cost: 75.0,
    weight: 38.0,
    ammoType: '.55 Boys',
    damage: {
      dice: 12,
      diceMultiplier: 1,
      modifier: 2,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
      armorDivisor: 2
    },
    rateOfFire: 0.5,
    selectiveFire: false,
    shotsPerReload: 5,
    malfunction: {
      crit: true,
    },
    ammoWeight: 2.0,
    snapShotThreshold: 20,
    recoilModifier: -2,
    accuracy: 11,
    halfDamageRange: 1300,
    maxRange: 5500,
    strengthRequirement: {
      strengthThreshold: 16,
      bipodRequired: true,
      tripodRequired: false,
    },
    holdoutModifier: -13,
  },
  [WeaponId.MosinNagantModel189130]: {
    id: WeaponId.MosinNagantModel189130,
    name: 'Mosin-Nagant Model 1891/30',
    category: WeaponCategoryId.Rifles,
    description: 'This served as the primary Soviet firearm. The bolt action was a bit complicated. The bayonet usually was left fixed to the rifle, despite the resulting -1 to Guns skill. The bayonet’s point also served as a sort of screwdriver for field-cleaning the rifle.',
    cost: 25.0,
    weight: 9.8,
    ammoType: '7.62mm R.',
    damage: {
      dice: 7,
      diceMultiplier: 1,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 0.5,
    selectiveFire: false,
    shotsPerReload: 5,
    malfunction: {
      crit: true,
    },
    ammoWeight: 0.4,
    snapShotThreshold: 15,
    recoilModifier: -2,
    accuracy: 11,
    halfDamageRange: 800,
    maxRange: 3900,
    strengthRequirement: {
      strengthThreshold: 11,
      bipodRequired: false,
      tripodRequired: false,
    },
    holdoutModifier: -6,
  },
  [WeaponId.Kokura97Shiki]: {
    id: WeaponId.Kokura97Shiki,
    name: 'Kokura 97 Shiki',
    category: WeaponCategoryId.Rifles,
    description: 'Two men could serve this heavy antitank rifle, but the Japanese usually assigned four to carry it – and the gear just to carry it added some 20-40 lbs. to weight, depending on which portions the troops discarded. Though never common, the Type 97 was used throughout the war. Its bipod and monopod effectively performed as a tripod, greatly reducing the considerable recoil. An optional 18-lb., 15 DR shield could be installed, protecting the gunner from frontal fire. A few German units used a similar weapon, the 128-lb. Swiss-made PzB 785 (RoF 1) with a 10-shot, 17.5-lb. magazine.',
    cost: 80.0,
    weight: 9.3,
    ammoType: '7.62mm R.',
    damage: {
      dice: 7,
      diceMultiplier: 1,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 3.0,
    selectiveFire: false,
    shotsPerReload: 10,
    malfunction: {
      crit: true,
    },
    ammoWeight: 0.7,
    snapShotThreshold: 14,
    recoilModifier: -2,
    accuracy: 11,
    halfDamageRange: 800,
    maxRange: 3900,
    strengthRequirement: {
      strengthThreshold: 11,
      bipodRequired: false,
      tripodRequired: false,
    },
    holdoutModifier: -6,
  },
  [WeaponId.PTRS41]: {
    id: WeaponId.PTRS41,
    name: 'PTRS-41',
    category: WeaponCategoryId.Rifles,
    description: 'Though obsolete as an antitank weapon, this rifle stayed in Soviet service throughout the war for use against light vehicles, troops defending buildings, and even aircraft. The earlier PTRD-41 was identical except that it fired single shots (0.5 lbs. each) and had Acc 11. The Germans captured thousands of PTRD-41s, and put a few to use themselves.',
    cost: 180.0,
    weight: 49.0,
    ammoType: '14.5mm Russian',
    damage: {
      dice: 15,
      diceMultiplier: 1,
      modifier: 1,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
      armorDivisor: 2
    },
    rateOfFire: 1.0,
    selectiveFire: false,
    shotsPerReload: 5,
    malfunction: {
      crit: true,
    },
    ammoWeight: 2.7,
    snapShotThreshold: 20,
    recoilModifier: -2,
    accuracy: 10,
    halfDamageRange: 1400,
    maxRange: 6000,
    strengthRequirement: {
      strengthThreshold: 17,
      bipodRequired: true,
      tripodRequired: false,
    },
    holdoutModifier: -18,
  },
  [WeaponId.SpringfieldM1903A1]: {
    id: WeaponId.SpringfieldM1903A1,
    name: 'Springfield M-1903A1',
    category: WeaponCategoryId.Rifles,
    description: 'A well-made 1929 upgrade to a 1906 Mauser variant, this served the U.S. military until the Garand began replacing it in quantity as the war began. It was found everywhere as a civilian arm or U.S. military assistance. The Army retained it as their sniper weapon, usually fitted with a scope for specialist snipers, but without one for squad snipers.',
    cost: 35.0,
    weight: 9.3,
    ammoType: '.30-06',
    damage: {
      dice: 7,
      diceMultiplier: 1,
      modifier: 1,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 0.5,
    selectiveFire: false,
    shotsPerReload: 5,
    malfunction: {
      crit: true,
    },
    ammoWeight: 0.3,
    snapShotThreshold: 14,
    recoilModifier: -3,
    accuracy: 11,
    halfDamageRange: 1000,
    maxRange: 4600,
    strengthRequirement: {
      strengthThreshold: 12,
      bipodRequired: false,
      tripodRequired: false,
    },
    holdoutModifier: -6,
  },
  [WeaponId.M1Garand]: {
    id: WeaponId.M1Garand,
    name: 'M-1 Garand',
    category: WeaponCategoryId.Rifles,
    description: 'First issued in 1936, the Garand didn’t become the predominant U.S. rifle until 1942. It then gave the average U.S. Army squad an unmatched firepower. The Garand clip was loaded along with the ammo, then sprang out of the rifle as the last round was fired. A recent trend has been to criticize the distinct sound that the clip made while ejecting, arguing that it informed nearby opponents that the firer had just emptied his weapon. Historically, the noise and engagement ranges typical of combat made this risk incalculably slight.',
    cost: 100.0,
    weight: 10.0,
    ammoType: '.30-06',
    damage: {
      dice: 7,
      diceMultiplier: 1,
      modifier: 1,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 3.0,
    selectiveFire: false,
    shotsPerReload: 8,
    malfunction: {
      crit: true,
    },
    ammoWeight: 0.5,
    snapShotThreshold: 14,
    recoilModifier: -3,
    accuracy: 11,
    halfDamageRange: 1000,
    maxRange: 4600,
    strengthRequirement: {
      strengthThreshold: 12,
      bipodRequired: false,
      tripodRequired: false,
    },
    holdoutModifier: -6,
  },
  [WeaponId.M1Carbine]: {
    id: WeaponId.M1Carbine,
    name: 'M-1 Carbine',
    category: WeaponCategoryId.Rifles,
    description: 'The U.S. military intended for this carbine to replace pistols as sidearms. Instead, millions of G.I.s and Marines with perpetually Garand-bruised shoulders adopted it as their primary firearm. In 1945, the selective fire M2 (RoF 12*) and 30-round magazine were introduced. In full auto, the M2’s Malf dropped to 16. Most troops who had the option upgraded to it.',
    cost: 50.0,
    weight: 5.5,
    ammoType: '.30 Carbine',
    damage: {
      dice: 3,
      diceMultiplier: 1,
      modifier: 2,
      halvePenetratedDamage: true,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 3.0,
    selectiveFire: false,
    shotsPerReload: 15,
    malfunction: {
      crit: true,
    },
    ammoWeight: 0.5,
    snapShotThreshold: 12,
    recoilModifier: -1,
    accuracy: 8,
    halfDamageRange: 300,
    maxRange: 2100,
    strengthRequirement: {
      strengthThreshold: 9,
      bipodRequired: false,
      tripodRequired: false,
    },
    holdoutModifier: -5,
  },
  [WeaponId.Karabiner98k]: {
    id: WeaponId.Karabiner98k,
    name: 'Karabiner 98k',
    category: WeaponCategoryId.Rifles,
    description: 'This principal German infantry weapon was an unspectacular but well-designed bolt-action Mauser. China, Czechoslovakia, Iran, Poland, Rumania, Spain, Turkey, and Yugoslavia used the 98k or minor variants, and the rifle was chambered in many alternate calibers.',
    cost: 35.0,
    weight: 8.9,
    ammoType: '7.92mm Mauser',
    damage: {
      dice: 7,
      diceMultiplier: 1,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 0.5,
    selectiveFire: false,
    shotsPerReload: 5,
    malfunction: {
      crit: true,
    },
    ammoWeight: 0.3,
    snapShotThreshold: 14,
    recoilModifier: -3,
    accuracy: 11,
    halfDamageRange: 1000,
    maxRange: 4000,
    strengthRequirement: {
      strengthThreshold: 12,
      bipodRequired: false,
      tripodRequired: false,
    },
    holdoutModifier: -6,
  },
  [WeaponId.Gewehr43]: {
    id: WeaponId.Gewehr43,
    name: 'Gewehr 43',
    category: WeaponCategoryId.Rifles,
    description: 'This rare German weapon sometimes was fitted with 4× telescopic sights and used as a sniper rifle.',
    cost: 90.0,
    weight: 10.3,
    ammoType: '7.92mm Mauser',
    damage: {
      dice: 7,
      diceMultiplier: 1,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 3.0,
    selectiveFire: false,
    shotsPerReload: 10,
    malfunction: {
      crit: true,
    },
    ammoWeight: 0.6,
    snapShotThreshold: 14,
    recoilModifier: -3,
    accuracy: 11,
    halfDamageRange: 1000,
    maxRange: 4000,
    strengthRequirement: {
      strengthThreshold: 12,
      bipodRequired: false,
      tripodRequired: false,
    },
    holdoutModifier: -6,
  },
  [WeaponId.Fallschirmjagergewehr42]: {
    id: WeaponId.Fallschirmjagergewehr42,
    name: 'Fallschirmjagergewehr 42',
    category: WeaponCategoryId.Rifles,
    description: 'This unique weapon compressed an LMG’s performance into a rifle-size weapon. This made it too light to effectively fire rifle rounds in full auto; Recoil is -4 if the bipod is not used for full-auto, or -3 for semiautomatic fire. The large muzzle flash would merit a +1 to spot the firer. On early models, the puny bipod folds the wrong way, giving it 1 chance in 6 of collapsing during each turn of full-auto fire. (It takes 2 seconds to redeploy.) German paratroops received a few of these in the middle of the war, but production was discontinued when the manufacturing expense to make them came to light.',
    cost: 300.0,
    weight: 12.4,
    ammoType: '7.92mm Mauser',
    damage: {
      dice: 6,
      diceMultiplier: 1,
      modifier: 1,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 15.0,
    selectiveFire: true,
    shotsPerReload: 20,
    malfunction: {
      crit: true,
    },
    ammoWeight: 1.8,
    snapShotThreshold: 14,
    recoilModifier: -3,
    accuracy: 10,
    halfDamageRange: 800,
    maxRange: 4000,
    strengthRequirement: {
      strengthThreshold: 13,
      bipodRequired: true,
      tripodRequired: false,
    },
    holdoutModifier: -6,
  },
  [WeaponId.Sturmgewehr44]: {
    id: WeaponId.Sturmgewehr44,
    name: 'Sturmgewehr 44',
    category: WeaponCategoryId.Rifles,
    description: 'The first assault rifle, this German innovation was rushed into troopers’ hands initially as the Maschinenpistole 43. At first, only elite forces on the Eastern Front received them, but by 1945 any Germans might be using them.',
    cost: 70.0,
    weight: 13.4,
    ammoType: '7.92mm Kurz',
    damage: {
      dice: 5,
      diceMultiplier: 1,
      modifier: 1,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 8.0,
    selectiveFire: true,
    shotsPerReload: 30,
    malfunction: {
      crit: true,
    },
    ammoWeight: 2.0,
    snapShotThreshold: 12,
    recoilModifier: -2,
    accuracy: 8,
    halfDamageRange: 600,
    maxRange: 3100,
    strengthRequirement: {
      strengthThreshold: 10,
      bipodRequired: false,
      tripodRequired: false,
    },
    holdoutModifier: -6,
  },
  [WeaponId.Panzerbuchse39]: {
    id: WeaponId.Panzerbuchse39,
    name: 'Panzerbuchse 39',
    category: WeaponCategoryId.Rifles,
    description: 'This antitank rifle used an oversized cartridge to propel a tungsten-cored bullet of normal rifle size that contained a tiny amount of tear gas (too little to worry about in game terms). The PzB39 soon became obsolete, but didn’t completely phase out until about 1943.',
    cost: 135.0,
    weight: 28.0,
    ammoType: '7.92mm Pz39',
    damage: {
      dice: 13,
      diceMultiplier: 1,
      modifier: -1,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
      armorDivisor: 2
    },
    rateOfFire: 0.3333333333333333,
    selectiveFire: false,
    shotsPerReload: 1,
    malfunction: {
      crit: true,
    },
    ammoWeight: 0.3,
    snapShotThreshold: 17,
    recoilModifier: -2,
    accuracy: 12,
    halfDamageRange: 1200,
    maxRange: 5400,
    strengthRequirement: {
      strengthThreshold: 12,
      bipodRequired: true,
      tripodRequired: false,
    },
    holdoutModifier: -11,
  },
  [WeaponId.ArisakaandNambu99Shiki]: {
    id: WeaponId.ArisakaandNambu99Shiki,
    name: 'Arisaka (and Nambu) 99 Shiki',
    category: WeaponCategoryId.Rifles,
    description: 'Early versions of this slender weapon were very well made; late-war production devolved to low-quality materials, then single-shot design, then black powder. (After that, bows firing explosive arrows were being considered as front-line weapons . . .) An explosive round was issued, doing 6d-1 but adding a 1d-4 HE effect, multiplied by 5 if within flesh, and taking an armor divisor of 0.5. The round gave the rifle Malf 14 and would detonate if dropped on a hard surface. Many units still carried the prewar Arisaka 38 Shiki in 6.5mm, which was Dam 6d, Acc 8, 1/2D 600, Max 3,000.',
    cost: 30.0,
    weight: 9.1,
    ammoType: '7.7mm A.',
    damage: {
      dice: 6,
      diceMultiplier: 1,
      modifier: 2,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 0.5,
    selectiveFire: false,
    shotsPerReload: 5,
    malfunction: {
      crit: true,
    },
    ammoWeight: 0.3,
    snapShotThreshold: 13,
    recoilModifier: -2,
    accuracy: 10,
    halfDamageRange: 1000,
    maxRange: 3900,
    strengthRequirement: {
      strengthThreshold: 11,
      bipodRequired: false,
      tripodRequired: false,
    },
    holdoutModifier: -6,
  },
  [WeaponId.FusilMAS36]: {
    id: WeaponId.FusilMAS36,
    name: 'Fusil MAS36',
    category: WeaponCategoryId.Rifles,
    description: 'Some front-line French units carried this rifle in 1940, and some Free French continued to favor it over Anglo-American arms. Overall, the French army used dozens of models and makes, most of them obsolete.',
    cost: 30.0,
    weight: 8.1,
    ammoType: '7.5mm MAS',
    damage: {
      dice: 6,
      diceMultiplier: 1,
      modifier: 2,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 0.5,
    selectiveFire: false,
    shotsPerReload: 5,
    malfunction: {
      crit: true,
    },
    ammoWeight: 0.5,
    snapShotThreshold: 13,
    recoilModifier: -2,
    accuracy: 10,
    halfDamageRange: 1000,
    maxRange: 3900,
    strengthRequirement: {
      strengthThreshold: 12,
      bipodRequired: false,
      tripodRequired: false,
    },
    holdoutModifier: -6,
  },
  [WeaponId.LanchesterMkI]: {
    id: WeaponId.LanchesterMkI,
    name: 'Lanchester Mk I',
    category: WeaponCategoryId.SubmachineGuns,
    description: 'A beautiful weapon of gleaming brass and burnished walnut, the Lanchester served exclusively with the British Royal Navy. Using Guns (Rifle) for semiautomatic fire, Acc improved to 8; however, many Lanchesters fired only in full automatic. The world’s first SMG, the German WWI-era Bergmann MP18, and its successor the MP28 had virtually identical statistics and could still be found in use.',
    cost: 100.0,
    weight: 11.9,
    ammoType: '9mm P.',
    damage: {
      dice: 3,
      diceMultiplier: 1,
      modifier: -1,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 9.0,
    selectiveFire: true,
    shotsPerReload: 50,
    malfunction: {
      crit: true,
    },
    ammoWeight: 2.3,
    snapShotThreshold: 10,
    recoilModifier: -1,
    accuracy: 7,
    halfDamageRange: 160,
    maxRange: 1900,
    strengthRequirement: {
      strengthThreshold: 10,
      bipodRequired: false,
      tripodRequired: false,
    },
    holdoutModifier: -5,
  },
  [WeaponId.StenGunMkII]: {
    id: WeaponId.StenGunMkII,
    name: 'Sten Gun Mk II',
    category: WeaponCategoryId.SubmachineGuns,
    description: 'The "stench gun" looked like a novice welder tossed it together from spare plumbing parts. The crude British design was cheap and easy to manufacture, so many got made. The poorly designed and manufactured magazine caused the low Malf rating. Veteran Sten gunners who could pick through a supply of magazines for the best ones could raise their weapon’s Malf to Crit. The 9.2-lb. Mk IIS incorporated a good suppressor on the barrel, but it quickly lost function during full-auto fire. It also got very hot, but a protective sleeve protected the firer.',
    cost: 10.0,
    weight: 7.6,
    ammoType: '9mm Parabellum',
    damage: {
      dice: 3,
      diceMultiplier: 1,
      modifier: -1,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 9.0,
    selectiveFire: true,
    shotsPerReload: 32,
    malfunction: {
      roll: 16,
      crit: false,
    },
    ammoWeight: 1.4,
    snapShotThreshold: 10,
    recoilModifier: -1,
    accuracy: 6,
    halfDamageRange: 160,
    maxRange: 1900,
    strengthRequirement: {
      strengthThreshold: 10,
      bipodRequired: false,
      tripodRequired: false,
    },
    holdoutModifier: -4,
  },
  [WeaponId.OwenMkI]: {
    id: WeaponId.OwenMkI,
    name: 'Owen Mk I',
    category: WeaponCategoryId.SubmachineGuns,
    description: 'The Australians kept this distinctive SMG with its top-mounted magazine for their own use in the Pacific jungles. It very rarely jammed.',
    cost: 40.0,
    weight: 10.7,
    ammoType: '9mm Parabellum',
    damage: {
      dice: 3,
      diceMultiplier: 1,
      modifier: -1,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 10.0,
    selectiveFire: true,
    shotsPerReload: 33,
    malfunction: {
      crit: true,
    },
    ammoWeight: 1.4,
    snapShotThreshold: 10,
    recoilModifier: -1,
    accuracy: 6,
    halfDamageRange: 160,
    maxRange: 1900,
    strengthRequirement: {
      strengthThreshold: 10,
      bipodRequired: false,
      tripodRequired: false,
    },
    holdoutModifier: -6,
  },
  [WeaponId.M1A1Thompson]: {
    id: WeaponId.M1A1Thompson,
    name: 'M-1A1 Thompson',
    category: WeaponCategoryId.SubmachineGuns,
    description: 'The "Tommy gun" already had gained fame as a favorite gangster and FBI weapon in the United States, as well as with IRA guerrillas in Ireland. This military model was greatly simplified from those early civilian models. It could only use 20or 30-round (1.6-lb.) box magazines. The British issued the earlier M-1928A1 Thompson with its distinctive vertical foregrip and drum magazine. The Cutts compensator on the barrel reduced Rcl to -2. The M-1928A1 weighed 15.7 lbs. with a 50-round drum or 19.3 lbs. with the 8.5-lb. 100-round drum, but could use the box magazines, instead. In semiautomatic fire, Acc increased to 8.',
    cost: 70.0,
    weight: 12.2,
    ammoType: '.45 ACP',
    damage: {
      dice: 2,
      diceMultiplier: 1,
      modifier: 1,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: true,
    },
    rateOfFire: 11.0,
    selectiveFire: true,
    shotsPerReload: 30,
    malfunction: {
      crit: true,
    },
    ammoWeight: 1.6,
    snapShotThreshold: 11,
    recoilModifier: -3,
    accuracy: 7,
    halfDamageRange: 190,
    maxRange: 1750,
    strengthRequirement: {
      strengthThreshold: 11,
      bipodRequired: false,
      tripodRequired: false,
    },
    holdoutModifier: -5,
  },
  [WeaponId.M3Greasegun]: {
    id: WeaponId.M3Greasegun,
    name: 'M-3 "Greasegun"',
    category: WeaponCategoryId.SubmachineGuns,
    description: 'Even the simplified military Thompson cost too much to mass-produce. Tank crews and Marines often used this simple but rugged alternative.',
    cost: 45.0,
    weight: 10.2,
    ammoType: '.45 ACP',
    damage: {
      dice: 2,
      diceMultiplier: 1,
      modifier: 1,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: true,
    },
    rateOfFire: 8.0,
    selectiveFire: false,
    shotsPerReload: 30,
    malfunction: {
      crit: true,
    },
    ammoWeight: 2.2,
    snapShotThreshold: 10,
    recoilModifier: -3,
    accuracy: 6,
    halfDamageRange: 190,
    maxRange: 1750,
    strengthRequirement: {
      strengthThreshold: 11,
      bipodRequired: false,
      tripodRequired: false,
    },
    holdoutModifier: -4,
  },
  [WeaponId.PPS43]: {
    id: WeaponId.PPS43,
    name: 'PPS-43',
    category: WeaponCategoryId.SubmachineGuns,
    description: 'The PPS-43 was an upgraded variant of the PPS42, an exceedingly simple weapon born during the siege of Stalingrad. It featured a folding stock. It was adopted by the Finns as the KP44 in 9mm P; Damage 3d-1, weight with a 70-round, 3.1-lb. drum was 9.5 lbs.',
    cost: 50.0,
    weight: 8.6,
    ammoType: '7.62mm Tokarev',
    damage: {
      dice: 3,
      diceMultiplier: 1,
      modifier: -1,
      halvePenetratedDamage: true,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 11.0,
    selectiveFire: false,
    shotsPerReload: 35,
    malfunction: {
      crit: true,
    },
    ammoWeight: 1.3,
    snapShotThreshold: 10,
    recoilModifier: -1,
    accuracy: 6,
    halfDamageRange: 160,
    maxRange: 1900,
    strengthRequirement: {
      strengthThreshold: 10,
      bipodRequired: false,
      tripodRequired: false,
    },
    holdoutModifier: -4,
  },
  [WeaponId.PPSh41]: {
    id: WeaponId.PPSh41,
    name: 'PPSh-41',
    category: WeaponCategoryId.SubmachineGuns,
    description: 'The "burp gun" symbolized the aggressive, close-quarters spirit that the Red Army tried to maintain, so the Soviets cranked out millions of PPShs and often armed whole regiments with little more than it and hand grenades. The PPSh-41 was of famously crude design and required only the most basic maintenance. It also could use a 1.4-lb. 35-round box magazine.',
    cost: 65.0,
    weight: 11.7,
    ammoType: '7.62mm Tokarev',
    damage: {
      dice: 3,
      diceMultiplier: 1,
      modifier: -1,
      halvePenetratedDamage: true,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 15.0,
    selectiveFire: true,
    shotsPerReload: 71,
    malfunction: {
      crit: true,
    },
    ammoWeight: 4.0,
    snapShotThreshold: 10,
    recoilModifier: -1,
    accuracy: 6,
    halfDamageRange: 160,
    maxRange: 1900,
    strengthRequirement: {
      strengthThreshold: 10,
      bipodRequired: false,
      tripodRequired: false,
    },
    holdoutModifier: -5,
  },
  [WeaponId.MP38and40]: {
    id: WeaponId.MP38and40,
    name: 'MP38 and 40',
    category: WeaponCategoryId.SubmachineGuns,
    description: 'The fact that Hugo Schmeisser never worked on the "Schmeisser" is almost as well known as the weapon itself. A no-frills stamped-metal design, the Maschinenpistole 38 introduced weapon-making to mass production. The later MP38/40 and MP40 were virtually identical to it. Guerrillas and Allied troops employed every MP38/40 they could obtain, because it was reliable, low-maintenance, and looked deadly. Proving that familiarity breeds contempt, Germans on the Eastern Front were the only soldiers likely to discard one. When and if they could acquire a PPSh-41, many of them preferred the Soviet SMG. The Soviets, meanwhile, were tossing aside their PPShs when an MP40 turned up . . .',
    cost: 70.0,
    weight: 10.4,
    ammoType: '9mm Parabellum',
    damage: {
      dice: 3,
      diceMultiplier: 1,
      modifier: -1,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 8.0,
    selectiveFire: false,
    shotsPerReload: 32,
    malfunction: {
      crit: true,
    },
    ammoWeight: 1.5,
    snapShotThreshold: 10,
    recoilModifier: -1,
    accuracy: 6,
    halfDamageRange: 160,
    maxRange: 1900,
    strengthRequirement: {
      strengthThreshold: 10,
      bipodRequired: false,
      tripodRequired: false,
    },
    holdoutModifier: -4,
  },
  [WeaponId.KP31Suomi]: {
    id: WeaponId.KP31Suomi,
    name: 'KP31 "Suomi"',
    category: WeaponCategoryId.SubmachineGuns,
    description: 'The Finns produced what may have been the finest 9mm SMG ever made. Machined from solid metal and uncannily accurate, the Suomi served in the Scandinavian countries, Poland, and Switzerland, and appeared in some quantities in the Spanish Civil War, Polish campaign, and especially the Winter War. The weapon also used 1.3-lb., 30-round and 2.3-lb., 50-round box magazines.',
    cost: 150.0,
    weight: 15.6,
    ammoType: '9mm Parabellum',
    damage: {
      dice: 3,
      diceMultiplier: 1,
      modifier: -1,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 15.0,
    selectiveFire: true,
    shotsPerReload: 70,
    malfunction: {
      crit: true,
    },
    ammoWeight: 4.5,
    snapShotThreshold: 11,
    recoilModifier: -1,
    accuracy: 7,
    halfDamageRange: 200,
    maxRange: 1900,
    strengthRequirement: {
      strengthThreshold: 10,
      bipodRequired: false,
      tripodRequired: false,
    },
    holdoutModifier: -5,
  },
  [WeaponId.Kokura100Shiki]: {
    id: WeaponId.Kokura100Shiki,
    name: 'Kokura 100 Shiki',
    category: WeaponCategoryId.SubmachineGuns,
    description: 'The Japanese knew they needed an SMG, but simply could not produce enough of this weapon. Only paratroops were likely to carry it.',
    cost: 45.0,
    weight: 9.7,
    ammoType: '8mm Nambu',
    damage: {
      dice: 2,
      diceMultiplier: 1,
      modifier: 1,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 8.0,
    selectiveFire: false,
    shotsPerReload: 30,
    malfunction: {
      crit: true,
    },
    ammoWeight: 1.1,
    snapShotThreshold: 10,
    recoilModifier: -1,
    accuracy: 6,
    halfDamageRange: 140,
    maxRange: 1600,
    strengthRequirement: {
      strengthThreshold: 9,
      bipodRequired: false,
      tripodRequired: false,
    },
    holdoutModifier: -5,
  },
  [WeaponId.BerettaModello1938A]: {
    id: WeaponId.BerettaModello1938A,
    name: 'Beretta Modello 1938 A',
    category: WeaponCategoryId.SubmachineGuns,
    description: 'This Italian SMG was of modest, even outdated, design, but the masterful craftsmanship invested in it caused it to become highly sought after.',
    cost: 80.0,
    weight: 11.0,
    ammoType: '9mm P.',
    damage: {
      dice: 3,
      diceMultiplier: 1,
      modifier: -1,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 10.0,
    selectiveFire: true,
    shotsPerReload: 40,
    malfunction: {
      crit: true,
    },
    ammoWeight: 1.7,
    snapShotThreshold: 10,
    recoilModifier: -1,
    accuracy: 6,
    halfDamageRange: 160,
    maxRange: 1900,
    strengthRequirement: {
      strengthThreshold: 10,
      bipodRequired: false,
      tripodRequired: false,
    },
    holdoutModifier: -5,
  },
  [WeaponId.LewisMkI]: {
    id: WeaponId.LewisMkI,
    name: 'Lewis Mk I',
    category: WeaponCategoryId.LightMachineGuns,
    description: 'Of WWI fame, this distinctive gun with its fat barrel shroud and pan magazine continued to arm some second-line British units. Unloading it requires a roll against Guns (Light Auto) +2 to avoid accidental discharge.',
    cost: 100.0,
    weight: 31.0,
    ammoType: '.303 British',
    damage: {
      dice: 7,
      diceMultiplier: 1,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 9.0,
    selectiveFire: false,
    shotsPerReload: 47,
    malfunction: {
      roll: 16,
      crit: false,
    },
    ammoWeight: 4.2,
    snapShotThreshold: 18,
    recoilModifier: -1,
    accuracy: 10,
    halfDamageRange: 500,
    maxRange: 3800,
    strengthRequirement: {
      strengthThreshold: 13,
      bipodRequired: true,
      tripodRequired: false,
    },
  },
  [WeaponId.BrenMkII]: {
    id: WeaponId.BrenMkII,
    name: 'Bren Mk II',
    category: WeaponCategoryId.LightMachineGuns,
    description: 'The primary LMG of British and Commonwealth troops, the Bren was a simple and rugged weapon (+2 to Armoury rolls for repairs). Indian troops used the Vickers-Berthier Mk III, an LMG that resembled the Bren, but had many differences internally (no Armoury bonus, perhaps a penalty if the gunsmith thinks it’s a Bren clone). Use the same statistics, except Wt 27.2.',
    cost: 120.0,
    weight: 25.9,
    ammoType: '.303 British',
    damage: {
      dice: 7,
      diceMultiplier: 1,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 8.0,
    selectiveFire: true,
    shotsPerReload: 30,
    malfunction: {
      crit: true,
    },
    ammoWeight: 2.8,
    snapShotThreshold: 17,
    recoilModifier: -1,
    accuracy: 11,
    halfDamageRange: 1000,
    maxRange: 3800,
    strengthRequirement: {
      strengthThreshold: 13,
      bipodRequired: true,
      tripodRequired: false,
    },
  },
  [WeaponId.VickersMkI]: {
    id: WeaponId.VickersMkI,
    name: 'Vickers Mk I',
    category: WeaponCategoryId.HeavyMachineGuns,
    description: 'A classic water-cooled MMG, the Vickers continued to serve British forces throughout WWII. The weight includes 7 lbs. (about 3.5 quarts) of water. All water-cooled MMGs could fire for long, uninterrupted periods without overheating, but the Vickers displayed remarkable endurance, with some having fired 120,000 rounds nonstop. The Germans still had some MG08 guns in reserve service, basically identical except chambered in 7.92mm Mauser (see MG34 for Dam, Acc, 1/2D, and Max) with Wt 44/62, RoF 7. The Soviets used Maxims chambered in 7.62mm Russian.',
    cost: 300.0,
    weight: 103.0,
    ammoType: '.303 British',
    damage: {
      dice: 7,
      diceMultiplier: 1,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 8.0,
    selectiveFire: false,
    shotsPerReload: 250,
    malfunction: {
      crit: true,
    },
    ammoWeight: 13.5,
    snapShotThreshold: 20,
    recoilModifier: -1,
    accuracy: 11,
    halfDamageRange: 1000,
    maxRange: 3800,
    strengthRequirement: {
      strengthThreshold: 23,
      bipodRequired: false,
      tripodRequired: true,
    },
  },
  [WeaponId.M1918A2BAR]: {
    id: WeaponId.M1918A2BAR,
    name: 'M-1918A2 BAR',
    category: WeaponCategoryId.LightMachineGuns,
    description: 'Technically, the BAR was an automatic rifle, meant to only partially fill the LMG role but allow shooting from the hip, on the move. Theorists disliked the weapon; the troops used all of them they could find. The BAR had no semiautomatic setting, but could fire full-auto at two rates: slow and slower. Regardless, the average BAR gunner would spend more time reloading than shooting, thanks to the inadequate capacity of the weapon’s 20-round magazines. The M-1918A2 was issued to U.S. troops in 1940. The 16-lb. M-1918, sent to England, had no bipod. The Poles used the BAR in 7.92mm Mauser (7d, Max 4,000).',
    cost: 50.0,
    weight: 21.0,
    ammoType: '.30-06',
    damage: {
      dice: 7,
      diceMultiplier: 1,
      modifier: 1,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 0.6666666666666666,
    selectiveFire: false,
    shotsPerReload: 20,
    malfunction: {
      crit: true,
    },
    ammoWeight: 1.6,
    snapShotThreshold: 17,
    recoilModifier: -2,
    accuracy: 11,
    halfDamageRange: 1000,
    maxRange: 4600,
    strengthRequirement: {
      strengthThreshold: 13,
      bipodRequired: true,
      tripodRequired: false,
    },
  },
  [WeaponId.BrowningM1917]: {
    id: WeaponId.BrowningM1917,
    name: 'Browning M-1917',
    category: WeaponCategoryId.HeavyMachineGuns,
    description: 'This U.S. MMG used 8.4 lbs. of water to cool the barrel for uninterrupted fire.',
    cost: 300.0,
    weight: 109.0,
    ammoType: '.30-06',
    damage: {
      dice: 7,
      diceMultiplier: 1,
      modifier: 1,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 10.0,
    selectiveFire: false,
    shotsPerReload: 250,
    malfunction: {
      crit: true,
    },
    ammoWeight: 15.0,
    snapShotThreshold: 20,
    recoilModifier: -1,
    accuracy: 14,
    halfDamageRange: 1000,
    maxRange: 4600,
    strengthRequirement: {
      strengthThreshold: 26,
      bipodRequired: false,
      tripodRequired: true,
    },
  },
  [WeaponId.BrowningM1919A4]: {
    id: WeaponId.BrowningM1919A4,
    name: 'Browning M-1919A4',
    category: WeaponCategoryId.HeavyMachineGuns,
    description: 'Using a shrouded, shorter barrel instead of water-cooling, this became the primary U.S. MMG. In 1943, a shoulder stock and bipod were added to create an ungainly LMG dubbed the M-1919A6; use the same statistics except Acc 11, weight 47.5 lbs. Few served in WWII.',
    cost: 250.0,
    weight: 60.0,
    ammoType: '.30-06',
    damage: {
      dice: 7,
      diceMultiplier: 1,
      modifier: 1,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 8.0,
    selectiveFire: false,
    shotsPerReload: 250,
    malfunction: {
      crit: true,
    },
    ammoWeight: 15.0,
    snapShotThreshold: 20,
    recoilModifier: -1,
    accuracy: 12,
    halfDamageRange: 1000,
    maxRange: 4600,
    strengthRequirement: {
      strengthThreshold: 19,
      bipodRequired: false,
      tripodRequired: true,
    },
  },
  [WeaponId.BrowningM2HB]: {
    id: WeaponId.BrowningM2HB,
    name: 'Browning M-2HB',
    category: WeaponCategoryId.HeavyMachineGuns,
    description: 'Designed as an antitank and antiaircraft weapon, this large-bore 1938 upgrade of the M-2 was a favorite of U.S. soldiers. It had become obsolete as an antitank round and at best underpowered for AA duties. This didn’t keep G.I.s from mounting M2s on anything that would take the recoil’s abuse – and many things that would not . . .',
    cost: 1000.0,
    weight: 161.0,
    ammoType: '.50 BMG',
    damage: {
      dice: 13,
      diceMultiplier: 1,
      modifier: 1,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: true,
    },
    rateOfFire: 8.0,
    selectiveFire: true,
    shotsPerReload: 105,
    malfunction: {
      crit: true,
    },
    ammoWeight: 32.0,
    snapShotThreshold: 20,
    recoilModifier: -1,
    accuracy: 16,
    halfDamageRange: 1500,
    maxRange: 7400,
    strengthRequirement: {
      strengthThreshold: 39,
      bipodRequired: false,
      tripodRequired: true,
    },
  },
  [WeaponId.DP]: {
    id: WeaponId.DP,
    name: 'DP',
    category: WeaponCategoryId.LightMachineGuns,
    description: 'The Degtyarev Pakhotny obrazets 1928g served as the basic Soviet LMG, found anywhere the Red Army had been. It used a pan magazine mounted on its top.',
    cost: 90.0,
    weight: 26.3,
    ammoType: '7.62mm Russian',
    damage: {
      dice: 7,
      diceMultiplier: 1,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 10.0,
    selectiveFire: false,
    shotsPerReload: 47,
    malfunction: {
      crit: true,
    },
    ammoWeight: 6.2,
    snapShotThreshold: 17,
    recoilModifier: -1,
    accuracy: 10,
    halfDamageRange: 800,
    maxRange: 3900,
    strengthRequirement: {
      strengthThreshold: 13,
      bipodRequired: true,
      tripodRequired: false,
    },
  },
  [WeaponId.SG43]: {
    id: WeaponId.SG43,
    name: 'SG-43',
    category: WeaponCategoryId.HeavyMachineGuns,
    description: 'After losing many older arms in the German invasion, the Soviets introduced this MMG in 1943, using a very heavy barrel rather than water jacket to allow uninterrupted fire. The mount was a two-wheeled cart rather than tripod; treat as a tripod for firing, but it usually was easier to relocate.',
    cost: 250.0,
    weight: 80.0,
    ammoType: '7.62mm Russian',
    damage: {
      dice: 7,
      diceMultiplier: 1,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 11.0,
    selectiveFire: false,
    shotsPerReload: 250,
    malfunction: {
      crit: true,
    },
    ammoWeight: 20.0,
    snapShotThreshold: 20,
    recoilModifier: -1,
    accuracy: 10,
    halfDamageRange: 800,
    maxRange: 3900,
    strengthRequirement: {
      strengthThreshold: 18,
      bipodRequired: false,
      tripodRequired: true,
    },
  },
  [WeaponId.DShK38]: {
    id: WeaponId.DShK38,
    name: 'DShK-38',
    category: WeaponCategoryId.HeavyMachineGuns,
    description: 'The Soviets primarily used this as a light antiaircraft gun. Like the SG-43, it mounted on a cart.',
    cost: 800.0,
    weight: 361.0,
    ammoType: '12.7mm Russian',
    damage: {
      dice: 13,
      diceMultiplier: 1,
      modifier: 1,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: true,
    },
    rateOfFire: 10.0,
    selectiveFire: false,
    shotsPerReload: 50,
    malfunction: {
      crit: true,
    },
    ammoWeight: 24.2,
    snapShotThreshold: 20,
    recoilModifier: -1,
    accuracy: 15,
    halfDamageRange: 1500,
    maxRange: 7400,
    strengthRequirement: {
      strengthThreshold: 38,
      bipodRequired: false,
      tripodRequired: true,
    },
  },
  [WeaponId.MG34]: {
    id: WeaponId.MG34,
    name: 'MG34',
    category: WeaponCategoryId.HeavyMachineGuns,
    description: 'The Germans used this as their primary LMG on its integral bipod, or as their primary MMG on its tripod. The 50-round belt could be placed in a drum (+2 lbs.) for ease of handling, or linked to additional belts, but not both at once. The gun also could use a beltless, 7-lb. drum carrying 75 rounds; it took 30 seconds to switch from using the drum to belts or back.',
    cost: 400.0,
    weight: 81.0,
    ammoType: '7.92mm Mauser',
    damage: {
      dice: 7,
      diceMultiplier: 1,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 15.0,
    selectiveFire: true,
    shotsPerReload: 50,
    malfunction: {
      crit: true,
    },
    ammoWeight: 3.0,
    snapShotThreshold: 19,
    recoilModifier: -1,
    accuracy: 10,
    halfDamageRange: 1000,
    maxRange: 4000,
    strengthRequirement: {
      strengthThreshold: 13,
      bipodRequired: false,
      tripodRequired: true,
    },
  },
  [WeaponId.MG42]: {
    id: WeaponId.MG42,
    name: 'MG42',
    category: WeaponCategoryId.HeavyMachineGuns,
    description: 'The Maschinengewehr 34 was an expensive weapon. In 1942, the Germans debuted the MG42, using cheap manufacturing while improving on the MG34. An Armoury roll in a gunsmith’s workshop could convert it to use the 34’s 75round drums. The 34 and 42 used nearly identical tripods that could be raised for antiaircraft fire and included a +1 Acc scope.',
    cost: 300.0,
    weight: 74.0,
    ammoType: '7.92mm Mauser',
    damage: {
      dice: 7,
      diceMultiplier: 1,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 20.0,
    selectiveFire: false,
    shotsPerReload: 50,
    malfunction: {
      crit: true,
    },
    ammoWeight: 3.0,
    snapShotThreshold: 19,
    recoilModifier: -2,
    accuracy: 10,
    halfDamageRange: 1000,
    maxRange: 4000,
    strengthRequirement: {
      strengthThreshold: 13,
      bipodRequired: false,
      tripodRequired: true,
    },
  },
  [WeaponId.Nambu11Shiki]: {
    id: WeaponId.Nambu11Shiki,
    name: 'Nambu 11 Shiki',
    category: WeaponCategoryId.LightMachineGuns,
    description: 'This LMG served the Japanese throughout their Chinese campaigns and WWII. Its unique hopper magazine employed the stripper clips for the infantry rifle then in use, but often broke down in action. In 1936, the Japanese introduced the 96 Shiki, basically the same weapon using a 30-round, top-mounted magazine, instead. Treat as the Type 11, except that Malf was Crit. The Italians also depended on a 6.5mm, hopper-fed LMG, the Breda Modello 30, which didn’t work any better than the Type 11. The Breda had the same statistics as the Type 11, except it weighed 23.8 lbs., held 20 rounds, and fed the hopper with its own 20-round clip rather than rifle clips. The two 6.5mm rounds did not interchange between weapons.',
    cost: 100.0,
    weight: 20.0,
    ammoType: '6.5mm Arisaka',
    damage: {
      dice: 6,
      diceMultiplier: 1,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 8.0,
    selectiveFire: false,
    shotsPerReload: 30,
    malfunction: {
      roll: 16,
      crit: false,
    },
    ammoWeight: 1.5,
    snapShotThreshold: 19,
    recoilModifier: -1,
    accuracy: 6,
    halfDamageRange: 600,
    maxRange: 3000,
    strengthRequirement: {
      strengthThreshold: 11,
      bipodRequired: true,
      tripodRequired: false,
    },
  },
  [WeaponId.Nambu99Shiki]: {
    id: WeaponId.Nambu99Shiki,
    name: 'Nambu 99 Shiki',
    category: WeaponCategoryId.LightMachineGuns,
    description: 'This basically upgraded the 96 Shiki (see above) to the larger rifle caliber used most often in the war. The Types 11, 96, and 99 could mount the shortswordsized bayonet designed for Japanese service rifles. They often mounted a 2.5× scope, as well.',
    cost: 125.0,
    weight: 26.2,
    ammoType: '7.7mm Arisaka',
    damage: {
      dice: 6,
      diceMultiplier: 1,
      modifier: 2,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 13.0,
    selectiveFire: false,
    shotsPerReload: 30,
    malfunction: {
      crit: true,
    },
    ammoWeight: 3.0,
    snapShotThreshold: 19,
    recoilModifier: -1,
    accuracy: 8,
    halfDamageRange: 1000,
    maxRange: 3900,
    strengthRequirement: {
      strengthThreshold: 11,
      bipodRequired: true,
      tripodRequired: false,
    },
  },
  [WeaponId.ZBvz26or30]: {
    id: WeaponId.ZBvz26or30,
    name: 'ZB vz. 26 or 30',
    category: WeaponCategoryId.LightMachineGuns,
    description: 'An innovative Czech design that spawned the Bren and many other period LMGs, the ZB vz. 26 and nearly identical vz. 30 could be encountered throughout Eastern Europe, in China, and in German hands.',
    cost: 120.0,
    weight: 23.1,
    ammoType: '7.92mm Mauser',
    damage: {
      dice: 7,
      diceMultiplier: 1,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 9.0,
    selectiveFire: true,
    shotsPerReload: 20,
    malfunction: {
      crit: true,
    },
    ammoWeight: 1.8,
    snapShotThreshold: 17,
    recoilModifier: -2,
    accuracy: 10,
    halfDamageRange: 1000,
    maxRange: 4000,
    strengthRequirement: {
      strengthThreshold: 12,
      bipodRequired: true,
      tripodRequired: false,
    },
  },
  [WeaponId.FusilMitrailleurModele192429]: {
    id: WeaponId.FusilMitrailleurModele192429,
    name: 'Fusil Mitrailleur Modele 1924/29',
    category: WeaponCategoryId.LightMachineGuns,
    description: 'The French patched together component designs from successful weapons to create this, their primary LMG of WWII. True to French armsmaking, early versions often blew up in action, but the weapon worked reliably by the time that the war began. It also was called the Châtellerault Mle 24/29.',
    cost: 150.0,
    weight: 24.0,
    ammoType: '7.5mm MAS',
    damage: {
      dice: 6,
      diceMultiplier: 1,
      modifier: 2,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 8.0,
    selectiveFire: true,
    shotsPerReload: 25,
    malfunction: {
      crit: true,
    },
    ammoWeight: 3.6,
    snapShotThreshold: 17,
    recoilModifier: -2,
    accuracy: 10,
    halfDamageRange: 1000,
    maxRange: 3900,
    strengthRequirement: {
      strengthThreshold: 13,
      bipodRequired: true,
      tripodRequired: false,
    },
  },
  [WeaponId.M2]: {
    id: WeaponId.M2,
    name: 'M-2',
    category: WeaponCategoryId.Mortars,
    description: 'Available rounds for this U.S. mainstay included HE (as listed), WP (as HE, plus continuing burn damage per the M-15 WP, p. 98), and illuminating (eliminates darkness penalties in 400-yard radius for 32 seconds).',
    cost: 800.0,
    weight: 42.0,
    ammoType: '60mm',
    damage: {
      dice: 5,
      diceMultiplier: 1,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
      fragmentation: 6
    },
    rateOfFire: 0.3333333333333333,
    selectiveFire: false,
    shotsPerReload: 1,
    malfunction: {
      crit: true,
    },
    ammoWeight: 3.0,
    snapShotThreshold: 20,
    accuracy: 5,
    indirectRange: 2000,
  },
  [WeaponId.Type107GVPM38]: {
    id: WeaponId.Type107GVPM38,
    name: '107-GVPM-38',
    category: WeaponCategoryId.Mortars,
    description: 'The Soviets designed this to support mountain troops. It had a wheeled carriage for towing by a mule or could be broken down into pack loads. The British version, the 4.2-inch Mortar, had Ind 4,100, Wt 1,320, and AWt 20. The U.S. designed the rifled 4.2-inch Chemical Mortar for its namesake purpose (21-yard radius with smoke rounds), but also fielded HE rounds for it (same statistics except Ind 4,400, Wt 330, AWt 32).',
    cost: 1500.0,
    weight: 376.0,
    ammoType: '107mm',
    damage: {
      dice: 6,
      diceMultiplier: 7,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
      fragmentation: 10
    },
    rateOfFire: 0.2,
    selectiveFire: false,
    shotsPerReload: 1,
    malfunction: {
      crit: true,
    },
    ammoWeight: 17.7,
    snapShotThreshold: 20,
    accuracy: 6,
    indirectRange: 6900,
  },
  [WeaponId.Type120PM38]: {
    id: WeaponId.Type120PM38,
    name: '120-PM-38',
    category: WeaponCategoryId.Mortars,
    description: 'The Germans simply copied this Soviet design and fielded it themselves as the GrW 42, but never in the numbers that the Soviets enjoyed. Smoke rounds (27-yard radius) also were available.',
    cost: 2000.0,
    weight: 617.0,
    ammoType: '120mm',
    damage: {
      dice: 6,
      diceMultiplier: 12,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
      fragmentation: 10
    },
    rateOfFire: 0.16666666666666666,
    selectiveFire: false,
    shotsPerReload: 1,
    malfunction: {
      crit: true,
    },
    ammoWeight: 35.3,
    snapShotThreshold: 20,
    accuracy: 6,
    indirectRange: 6600,
  },
  [WeaponId.leGrW36]: {
    id: WeaponId.leGrW36,
    name: 'leGrW 36',
    category: WeaponCategoryId.Mortars,
    description: 'The Germans only fielded an HE round for this 50mm weapon. Encumbered with a great number of aiming aids of marginal utility, the mortar weighed far more than it should have. The Soviet version, the PM 40 (same stats except Ind 875, Wt 20.5, AWt 1.9), ended up in frequent German usage.',
    cost: 850.0,
    weight: 31.0,
    ammoType: '50mm',
    damage: {
      dice: 3,
      diceMultiplier: 1,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
      fragmentation: 4
    },
    rateOfFire: 0.3333333333333333,
    selectiveFire: false,
    shotsPerReload: 1,
    malfunction: {
      crit: true,
    },
    ammoWeight: 2.0,
    snapShotThreshold: 17,
    accuracy: 5,
    indirectRange: 570,
  },
  [WeaponId.sGrW34]: {
    id: WeaponId.sGrW34,
    name: 'sGrW 34',
    category: WeaponCategoryId.Mortars,
    description: 'Many nations fielded an 81mm mortar as the backbone of their infantry heavy weapons. This German model was particularly dependable. The usual ammo included HE (as listed) and smoke (12-yard radius) rounds. A special airburst (pp. 202-203) HEround sometimes was available, but it was expensive and rare. The U.S. counterpart, the M-1, had Ind 3,300 and AWt 6.9. The French Brandt Mle 27/31 had Ind 2,100 and AWt 7.2. The Soviets fielded an 82mm mortar, the 82-BM-41 (same statistics except Ind 3,400, Wt 99.2, AWt 7.5), that could use captured 81mm ammo (at Acc 4) but could not have its own ammo turned upon itself.',
    cost: 400.0,
    weight: 137.0,
    ammoType: '81mm',
    damage: {
      dice: 6,
      diceMultiplier: 2,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
      fragmentation: 6
    },
    rateOfFire: 0.25,
    selectiveFire: false,
    shotsPerReload: 1,
    malfunction: {
      crit: true,
    },
    ammoWeight: 7.8,
    snapShotThreshold: 20,
    accuracy: 6,
    indirectRange: 2650,
  },
  [WeaponId.Type89Shiki]: {
    id: WeaponId.Type89Shiki,
    name: '89 Shiki',
    category: WeaponCategoryId.Mortars,
    description: 'The Japanese fielded this unusual 50mm mortar in great numbers. It consisted of nothing but a short tube, on an arm, with a small curved buttplate on the other end. U.S. troops came to call it the "knee mortar" because the buttplate appeared as if designed to be braced against a kneeling man’s thigh. Firing the mortar from that position would shatter the thighbone . . . The 89 Shiki was rifled, giving it an impressive range with the HE round listed for it. It also could fire the 91 Shiki hand grenade (see p. 98), but only with Acc 1 out to 190 yards. Also issued were smoke rounds (5-yard radius), signal rounds, and WP rounds (damage as for the 91 Shiki but doing continuing burn damage as the M-15 WP, below). The British fielded the smoothbore 2" Mortar Mk II (same stats except Ind 500, Wt 9, AWt 2.3), with HE, smoke (5-hex radius), and flare (lights up 150-yard radius for 30 seconds) rounds.',
    cost: 100.0,
    weight: 10.3,
    ammoType: '50mm',
    damage: {
      dice: 2,
      diceMultiplier: 1,
      modifier: 1,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
      fragmentation: 4
    },
    rateOfFire: 0.3333333333333333,
    selectiveFire: false,
    shotsPerReload: 1,
    malfunction: {
      roll: 16,
      crit: false,
    },
    ammoWeight: 1.8,
    snapShotThreshold: 17,
    accuracy: 4,
    indirectRange: 700,
  },
  [WeaponId.PIAT]: {
    id: WeaponId.PIAT,
    name: 'PIAT',
    category: WeaponCategoryId.LightAntitankWeapons,
    description: 'The British used this spring-driven launcher to fire 89mm antitank rounds throughout the war. The massive spring was supposed to cock itself after each shot, but unless well braced it failed to do so. That required the firer to manually cock its 200-lb. resistance, thus the low RoF.',
    cost: 80.0,
    weight: 34.6,
    ammoType: 'LAW Rocket',
    damage: {
      dice: 4,
      diceMultiplier: 2,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
      armorDivisor: 10
    },
    rateOfFire: 0.1,
    selectiveFire: false,
    shotsPerReload: 1,
    malfunction: {
      crit: true,
    },
    ammoWeight: 3.0,
    snapShotThreshold: 18,
    accuracy: 6,
    halfDamageRange: 110,
    maxRange: 370,
    minimumRange: 10,
  },
  [WeaponId.M1Bazooka]: {
    id: WeaponId.M1Bazooka,
    name: 'M-1 "Bazooka"',
    category: WeaponCategoryId.LightAntitankWeapons,
    description: 'This introduced an effective new approach to man-portable tank-killing technology. Basically a 4.5’-long tube, it fired a rocket-propelled 60mm HEAT grenade. The later M9 had the same statistics, but broke into two sections for easier carrying. Raketenpanzerbüchse 43: The Germans wasted no time in copying the bazooka concept, fielding their version by 1944, popular called the Panzerschreck. It had an 88mm bore, doing more damage. The rocket left the tube still burning – 1 point of damage to each of firer’s left arm, left hand, and face; a gas mask can serve as face protection while any DR 1+ clothing protects the hand and arm. The later RPzB 54 featured a shield to prevent this damage: total Wt 31.5.',
    cost: 100.0,
    weight: 16.7,
    ammoType: 'LAW Rocket',
    damage: {
      dice: 6,
      diceMultiplier: 2,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
      armorDivisor: 10
    },
    rateOfFire: 0.2,
    selectiveFire: false,
    shotsPerReload: 1,
    malfunction: {
      crit: true,
    },
    ammoWeight: 3.4,
    snapShotThreshold: 16,
    accuracy: 9,
    halfDamageRange: 100,
    maxRange: 650,
    minimumRange: 8,
  },
  [WeaponId.Panzerfaust30]: {
    id: WeaponId.Panzerfaust30,
    name: 'Panzerfaust 30',
    category: WeaponCategoryId.LightAntitankWeapons,
    description: 'This one-shot weapon consisted of a rocket-propelled HEAT grenade on a simple launch tube. The first version debuted in late 1942, the Faustpatrone 30 (klein), doing 6d×2 (10) and weighing 7 lbs. Later on the Panzerfaust 60 (1/2D 60, 15 lbs.) improved effective range.',
    cost: 20.0,
    weight: 11.2,
    ammoType: 'LAW Rocket',
    damage: {
      dice: 6,
      diceMultiplier: 3,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
      armorDivisor: 10
    },
    rateOfFire: 1.0,
    selectiveFire: false,
    shotsPerReload: 1,
    malfunction: {
      crit: true,
    },
    ammoWeight: 0.0,
    snapShotThreshold: 14,
    accuracy: 4,
    halfDamageRange: 30,
    maxRange: 400,
    minimumRange: 10,
  },
  [WeaponId.No36MillsBomb]: {
    id: WeaponId.No36MillsBomb,
    name: 'No. 36 "Mills Bomb"',
    category: WeaponCategoryId.HandGrenades,
    description: 'The British used this serrated grenade throughout the war. Unlike most grenades, these were issued unfused. The troopers had to unscrew the bottom plug and insert one of two different fuses. A non-Commonwealth soldier flinging Mills Bombs from a freshly opened crate should be amazed by the frequency of duds . . .',
    cost: 10.0,
    weight: 1.7,
    ammoType: 'Hand Grenade',
    damage: {
      dice: 2,
      diceMultiplier: 1,
      modifier: -1,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
      fragmentation: 2
    },
    rateOfFire: 0.3333333333333333,
    selectiveFire: false,
    shotsPerReload: 1,
    malfunction: {
      crit: true,
    },
    fuse: 0,
    holdoutModifier: 4,
  },
  [WeaponId.MkIIPineapple]: {
    id: WeaponId.MkIIPineapple,
    name: 'Mk II "Pineapple"',
    category: WeaponCategoryId.HandGrenades,
    description: 'Though better grenades were issued, this remained the U.S. mainstay throughout the war.',
    cost: 10.0,
    weight: 1.3,
    ammoType: 'Hand Grenade',
    damage: {
      dice: 2,
      diceMultiplier: 1,
      modifier: -2,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
      fragmentation: 2
    },
    rateOfFire: 0.3333333333333333,
    selectiveFire: false,
    shotsPerReload: 1,
    malfunction: {
      crit: true,
    },
    fuse: 0,
    holdoutModifier: 4,
  },
  [WeaponId.MkIIIA2]: {
    id: WeaponId.MkIIIA2,
    name: 'Mk IIIA2',
    category: WeaponCategoryId.HandGrenades,
    description: 'This was a chunk of TNT in a cardboard case with the standard pull-ring and lever fuse.',
    cost: 10.0,
    weight: 1.0,
    ammoType: 'Hand Grenade',
    damage: {
      dice: 6,
      diceMultiplier: 1,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 0.3333333333333333,
    selectiveFire: false,
    shotsPerReload: 1,
    malfunction: {
      crit: true,
    },
    fuse: 0,
    holdoutModifier: 4,
  },
  [WeaponId.ANM8HCSmoke]: {
    id: WeaponId.ANM8HCSmoke,
    name: 'AN-M-8 HC Smoke',
    category: WeaponCategoryId.HandGrenades,
    description: 'This doesn’t explode, but burns for 100-150 seconds, filling a 3-yard radius with dense white smoke. The grenade gets hot as it burns and might ignite easily flammable materials.',
    cost: 10.0,
    weight: 1.5,
    ammoType: 'Hand Grenade',
    damage: {
      dice: 0,
      diceMultiplier: 1,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 0.3333333333333333,
    selectiveFire: false,
    shotsPerReload: 1,
    malfunction: {
      crit: true,
    },
    fuse: -1,
    holdoutModifier: 2,
  },
  [WeaponId.M15WP]: {
    id: WeaponId.M15WP,
    name: 'M-15 WP',
    category: WeaponCategoryId.HandGrenades,
    description: 'This fragmentation grenade spews chunks of white phosphorus rather than metal. Anyone hit by a fragment will take a further 1d damage each second for 20 seconds, but only until the affected body part is crippled if the fragment hit a limb. A bystander can remove the fragment with a DX roll; the victim may try himself but subtracts damage taken to that point from DX unless he has High Pain Threshold. The grenade also creates smoke as for the AN-M-8, above, and ignites flammables in the same radius. Immersing WP does not extinguish it; burying it works.',
    cost: 10.0,
    weight: 2.0,
    ammoType: 'Hand Grenade',
    damage: {
      dice: 2,
      diceMultiplier: 1,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
      fragmentation: 2
    },
    rateOfFire: 0.3333333333333333,
    selectiveFire: false,
    shotsPerReload: 1,
    malfunction: {
      crit: true,
    },
    fuse: -2,
    holdoutModifier: 4,
  },
  [WeaponId.F1]: {
    id: WeaponId.F1,
    name: 'F-1',
    category: WeaponCategoryId.HandGrenades,
    description: 'The Soviets used this serrated grenade.',
    cost: 10.0,
    weight: 1.3,
    ammoType: 'Hand Grenade',
    damage: {
      dice: 2,
      diceMultiplier: 1,
      modifier: -3,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
      fragmentation: 2
    },
    rateOfFire: 0.3333333333333333,
    selectiveFire: false,
    shotsPerReload: 1,
    malfunction: {
      crit: true,
    },
    fuse: 0,
    holdoutModifier: 4,
  },
  [WeaponId.RG42]: {
    id: WeaponId.RG42,
    name: 'RG-42',
    category: WeaponCategoryId.HandGrenades,
    description: 'This next-generation Soviet grenade was not serrated, but was not always available after its 1942 debut.',
    cost: 10.0,
    weight: 1.0,
    ammoType: 'Hand Grenade',
    damage: {
      dice: 2,
      diceMultiplier: 1,
      modifier: 1,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
      fragmentation: 2
    },
    rateOfFire: 0.3333333333333333,
    selectiveFire: false,
    shotsPerReload: 1,
    malfunction: {
      crit: true,
    },
    fuse: 0,
    holdoutModifier: 4,
  },
  [WeaponId.RPG43]: {
    id: WeaponId.RPG43,
    name: 'RPG-43',
    category: WeaponCategoryId.HandGrenades,
    description: 'This shaped-charge antitank grenade debuted in Soviet service in 1943.',
    cost: 10.0,
    weight: 2.7,
    ammoType: 'Hand Grenade',
    damage: {
      dice: 6,
      diceMultiplier: 1,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
      armorDivisor: 10
    },
    rateOfFire: 0.3333333333333333,
    selectiveFire: false,
    shotsPerReload: 1,
    malfunction: {
      crit: true,
    },
    fuse: -4,
    holdoutModifier: 0,
  },
  [WeaponId.RPG6]: {
    id: WeaponId.RPG6,
    name: 'RPG-6',
    category: WeaponCategoryId.HandGrenades,
    description: 'This improved on the RPG-43, and the new sleeve even made it the rare shaped-charge round that also had a useful fragmentation effect.',
    cost: 10.0,
    weight: 2.5,
    ammoType: 'Hand Grenade',
    damage: {
      dice: 8,
      diceMultiplier: 1,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
      armorDivisor: 10,
      fragmentation: 2,
    },
    rateOfFire: 0.3333333333333333,
    selectiveFire: false,
    shotsPerReload: 1,
    malfunction: {
      crit: true,
    },
    fuse: -4,
    holdoutModifier: 0,
  },
  [WeaponId.MolotovCocktail]: {
    id: WeaponId.MolotovCocktail,
    name: 'Molotov Cocktail',
    category: WeaponCategoryId.HandGrenades,
    description: 'These homemade devices consisted of a bottle filled with gasoline (thickened or not) with a rag tied around it. The rag was lit, the bottle thrown, and it cracked open, igniting the gas upon contact. Treat as one shot from a flamethrower (see p. 99).',
    cost: 10.0,
    weight: 3.0,
    ammoType: 'Hand Grenade',
    damage: {
      dice: 0,
      diceMultiplier: 1,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 0.3333333333333333,
    selectiveFire: false,
    shotsPerReload: 1,
    malfunction: {
      crit: true,
    },
    fuse: -4,
    holdoutModifier: 0,
  },
  [WeaponId.Stielhandgranate24PotatoMasher]: {
    id: WeaponId.Stielhandgranate24PotatoMasher,
    name: 'Stielhandgranate 24 "Potato Masher',
    category: WeaponCategoryId.HandGrenades,
    description: '" The classic German grenade, this took 2 seconds to ignite the fuse by unscrewing the base of the handle and pulling on the cord inside. The bulky handle gave a +2 to Throwing skill. A 0.3-lb. fragmentation sleeve could be added, giving [2d] fragmentation damage.',
    cost: 10.0,
    weight: 1.3,
    ammoType: 'Hand Grenade',
    damage: {
      dice: 4,
      diceMultiplier: 1,
      modifier: 1,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 0.3333333333333333,
    selectiveFire: false,
    shotsPerReload: 1,
    malfunction: {
      crit: true,
    },
    fuse: -3,
    holdoutModifier: 4,
  },
  [WeaponId.GeballteLadung]: {
    id: WeaponId.GeballteLadung,
    name: 'Geballte Ladung',
    category: WeaponCategoryId.HandGrenades,
    description: 'An improvised antitank weapon, this consisted of six Stielhandgranate heads tied around a complete grenade. The bundle was then tossed or dropped onto the relatively thin armor of a tank’s upper deck.',
    cost: 10.0,
    weight: 4.7,
    ammoType: 'Hand Grenade',
    damage: {
      dice: 6,
      diceMultiplier: 5,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 0.3333333333333333,
    selectiveFire: false,
    shotsPerReload: 1,
    malfunction: {
      crit: true,
    },
    fuse: -5,
    holdoutModifier: 4,
  },
  [WeaponId.Panzerwurfmine]: {
    id: WeaponId.Panzerwurfmine,
    name: 'Panzerwurfmine',
    category: WeaponCategoryId.HandGrenades,
    description: 'Some Wehrmacht veterans preferred this shaped-charge grenade even after HEAT rocket launchers became their standard antitank weapon in late 1943.',
    cost: 10.0,
    weight: 3.0,
    ammoType: 'Hand Grenade',
    damage: {
      dice: 6,
      diceMultiplier: 2,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
      armorDivisor: 10
    },
    rateOfFire: 0.3333333333333333,
    selectiveFire: false,
    shotsPerReload: 1,
    malfunction: {
      crit: true,
    },
    fuse: -4,
    holdoutModifier: 0,
  },
  [WeaponId.Type91Shiki]: {
    id: WeaponId.Type91Shiki,
    name: '91 Shiki',
    category: WeaponCategoryId.HandGrenades,
    description: 'The Japanese used this serrated grenade. The fuse was started not by releasing a lever, but by slamming it against something hard, like a helmet or stone wall.',
    cost: 10.0,
    weight: 1.2,
    ammoType: 'Hand Grenade',
    damage: {
      dice: 2,
      diceMultiplier: 1,
      modifier: -2,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
      fragmentation: 2
    },
    rateOfFire: 0.3333333333333333,
    selectiveFire: false,
    shotsPerReload: 1,
    malfunction: {
      crit: true,
    },
    fuse: 0,
    holdoutModifier: 8,
  },
  [WeaponId.RG34]: {
    id: WeaponId.RG34,
    name: 'RG-34',
    category: WeaponCategoryId.HandGrenades,
    description: 'The Czechs designed this compact grenade about 1934, but it was not in common use.',
    cost: 10.0,
    weight: 0.8,
    ammoType: 'Hand Grenade',
    damage: {
      dice: 2,
      diceMultiplier: 1,
      modifier: 2,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 0.3333333333333333,
    selectiveFire: false,
    shotsPerReload: 1,
    malfunction: {
      crit: true,
    },
    fuse: 0,
    holdoutModifier: 0,
  },
  [WeaponId.BouncingAntipersonnel]: {
    id: WeaponId.BouncingAntipersonnel,
    name: 'Bouncing Antipersonnel',
    category: WeaponCategoryId.Mines,
    description: 'Takes 1d seconds to spring up after being triggered, then bursts in the air; see pp. 202-203.',
    cost: 10.0,
    weight: 9.0,
    ammoType: 'Mine',
    damage: {
      dice: 4,
      diceMultiplier: 4,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
      fragmentation: 6
    },
    rateOfFire: 0.25,
    selectiveFire: false,
    shotsPerReload: 1,
    malfunction: {
      crit: true,
    },
  },
  [WeaponId.Vehicular]: {
    id: WeaponId.Vehicular,
    name: 'Vehicular',
    category: WeaponCategoryId.Mines,
    description: 'Designed to knock out armored vehicles, these could be set to ignore up to 400 lbs.',
    cost: 10.0,
    weight: 20.0,
    ammoType: 'Mine',
    damage: {
      dice: 6,
      diceMultiplier: 22,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 0.25,
    selectiveFire: false,
    shotsPerReload: 1,
    malfunction: {
      crit: true,
    },
  },
  [WeaponId.Wooden]: {
    id: WeaponId.Wooden,
    name: 'Wooden',
    category: WeaponCategoryId.Mines,
    description: 'Once metal detectors came into common use, these nonmetallic antipersonnel mines began appearing in large numbers. The wood casing somewhat limits fragmentation.',
    cost: 10.0,
    weight: 9.0,
    ammoType: 'Mine',
    damage: {
      dice: 6,
      diceMultiplier: 6,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
      fragmentation: 6
    },
    rateOfFire: 0.25,
    selectiveFire: false,
    shotsPerReload: 1,
    malfunction: {
      crit: true,
    },
  },
  [WeaponId.No68]: {
    id: WeaponId.No68,
    name: 'No. 68',
    category: WeaponCategoryId.RifleGrenades,
    description: 'The first shaped-charged projectile ever issued, the British fired this using a blank cartridge from a cup discharger. An ineffective round, the No. 68 was discontinued in 1941.',
    cost: 20.0,
    weight: 1.8,
    ammoType: 'Rifle Grenade',
    damage: {
      dice: 1,
      diceMultiplier: 1,
      modifier: 2,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
      armorDivisor: 10
    },
    rateOfFire: 0.25,
    selectiveFire: false,
    shotsPerReload: 1,
    malfunction: {
      crit: true,
    },
    snapShotThreshold: 16,
    accuracy: 0,
    halfDamageRange: 75,
    maxRange: 200,
    fuse: 0,
    holdoutModifier: -4,
  },
  [WeaponId.M17]: {
    id: WeaponId.M17,
    name: 'M-17',
    category: WeaponCategoryId.RifleGrenades,
    description: 'This was the Mk II "Pineapple" on a stick. U.S. forces fired this and all their rifle grenades from a spigot launcher using blank rounds.',
    cost: 20.0,
    weight: 1.6,
    ammoType: 'Rifle Grenade',
    damage: {
      dice: 2,
      diceMultiplier: 1,
      modifier: -2,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
      fragmentation: 2
    },
    rateOfFire: 0.25,
    selectiveFire: false,
    shotsPerReload: 1,
    malfunction: {
      crit: true,
    },
    snapShotThreshold: 16,
    accuracy: 0,
    halfDamageRange: 75,
    maxRange: 180,
    fuse: 0,
    holdoutModifier: -4,
  },
  [WeaponId.M9A1]: {
    id: WeaponId.M9A1,
    name: 'M-9A1',
    category: WeaponCategoryId.RifleGrenades,
    description: 'This performed fairly well against tanks.',
    cost: 20.0,
    weight: 1.3,
    ammoType: 'Rifle Grenade',
    damage: {
      dice: 8,
      diceMultiplier: 1,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
      armorDivisor: 10
    },
    rateOfFire: 0.25,
    selectiveFire: false,
    shotsPerReload: 1,
    malfunction: {
      crit: true,
    },
    snapShotThreshold: 16,
    accuracy: 0,
    halfDamageRange: 75,
    maxRange: 240,
    fuse: 0,
    holdoutModifier: -4,
  },
  [WeaponId.M19A1]: {
    id: WeaponId.M19A1,
    name: 'M-19A1',
    category: WeaponCategoryId.RifleGrenades,
    description: 'Treat damage from this U.S. white phosphorus round as for the M-15 hand grenade (p. 98).',
    cost: 20.0,
    weight: 1.5,
    ammoType: 'Rifle Grenade',
    damage: {
      dice: 2,
      diceMultiplier: 1,
      modifier: -2,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
      fragmentation: 2
    },
    rateOfFire: 0.25,
    selectiveFire: false,
    shotsPerReload: 1,
    malfunction: {
      crit: true,
    },
    snapShotThreshold: 16,
    accuracy: 0,
    halfDamageRange: 75,
    maxRange: 200,
    fuse: 0,
    holdoutModifier: -4,
  },
  [WeaponId.M191]: {
    id: WeaponId.M191,
    name: 'M-191',
    category: WeaponCategoryId.RifleGrenades,
    description: 'These U.S. illumination rounds came in yellow (M-191), green (M-192), or red (M193). The range is how far up they would fly.',
    cost: 20.0,
    weight: 1.3,
    ammoType: 'Rifle Grenade',
    damage: {
      dice: 0,
      diceMultiplier: 1,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 0.25,
    selectiveFire: false,
    shotsPerReload: 1,
    malfunction: {
      crit: true,
    },
    snapShotThreshold: 16,
    accuracy: 0,
    halfDamageRange: 75,
    maxRange: 50,
    fuse: 4,
    holdoutModifier: -4,
  },
  [WeaponId.M22]: {
    id: WeaponId.M22,
    name: 'M-22',
    category: WeaponCategoryId.RifleGrenades,
    description: 'Treat as the AN-M-8 smoke grenade (p. 98) except that available colors are red, violet, green, or yellow.',
    cost: 20.0,
    weight: 1.3,
    ammoType: 'Rifle Grenade',
    damage: {
      dice: 0,
      diceMultiplier: 1,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 0.25,
    selectiveFire: false,
    shotsPerReload: 1,
    malfunction: {
      crit: true,
    },
    snapShotThreshold: 16,
    accuracy: 0,
    halfDamageRange: 75,
    maxRange: 200,
    fuse: 0,
    holdoutModifier: -4,
  },
  [WeaponId.Gewehrsprenggranate30mm]: {
    id: WeaponId.Gewehrsprenggranate30mm,
    name: 'Gewehrsprenggranate 30mm',
    category: WeaponCategoryId.RifleGrenades,
    description: 'The German Schiessbecher was a cup discharger that usually used a rifle round with a wooden bullet to propel grenades such as this HE version. A bubble sight ($35, 0.4 lbs.) could be attached to the rifle to give the grenade Acc 1, but was not issued often.',
    cost: 20.0,
    weight: 0.7,
    ammoType: 'Rifle Grenade',
    damage: {
      dice: 1,
      diceMultiplier: 1,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
      fragmentation: 2
    },
    rateOfFire: 0.25,
    selectiveFire: false,
    shotsPerReload: 1,
    malfunction: {
      crit: true,
    },
    snapShotThreshold: 16,
    accuracy: 0,
    halfDamageRange: 75,
    maxRange: 330,
    fuse: 0,
    holdoutModifier: -4,
  },
  [WeaponId.GrosseGewehrpanzergranate]: {
    id: WeaponId.GrosseGewehrpanzergranate,
    name: 'Grosse Gewehrpanzergranate',
    category: WeaponCategoryId.RifleGrenades,
    description: 'German units with nothing better on hand kept using this inadequate antitank round throughout the war.',
    cost: 20.0,
    weight: 0.9,
    ammoType: 'Rifle Grenade',
    damage: {
      dice: 6,
      diceMultiplier: 1,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
      armorDivisor: 10
    },
    rateOfFire: 0.25,
    selectiveFire: false,
    shotsPerReload: 1,
    malfunction: {
      crit: true,
    },
    snapShotThreshold: 16,
    accuracy: 0,
    halfDamageRange: 75,
    maxRange: 330,
    fuse: 0,
    holdoutModifier: -4,
  },
  [WeaponId.Type99Shiki]: {
    id: WeaponId.Type99Shiki,
    name: '99 Shiki',
    category: WeaponCategoryId.RifleGrenades,
    description: 'The Japanese used a distinct discharger for almost all of their rifle grenades, both in spigot and cup styles. This modified hand grenade used a spigot, as did their smoke round (treat as the M22, above, except only white smoke was available). Their shaped-charge round used a cup launcher and had the German statistics, above. Rather than use blanks, they propelled the grenades using regular rifle rounds or special rounds with wooden bullets. (When U.S. troops came across these wooden bullets, they decided that they were a fiendish device to inflict particularly grievous wounds. The legend persists to this day.)',
    cost: 20.0,
    weight: 1.7,
    ammoType: 'Rifle Grenade',
    damage: {
      dice: 2,
      diceMultiplier: 1,
      modifier: -2,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
      fragmentation: 2
    },
    rateOfFire: 0.25,
    selectiveFire: false,
    shotsPerReload: 1,
    malfunction: {
      crit: true,
    },
    snapShotThreshold: 16,
    accuracy: 0,
    halfDamageRange: 75,
    maxRange: 100,
    fuse: 8,
    holdoutModifier: -4,
  },
  [WeaponId.No2MkILifebuoy]: {
    id: WeaponId.No2MkILifebuoy,
    name: 'No. 2 Mk I Lifebuoy',
    category: WeaponCategoryId.Flamethrowers,
    description: 'The British produced these with a distinctive donut-shaped tank. The Lifebuoy suffered from the same electrical-ignition problems as the M1A1, below.',
    cost: 200.0,
    weight: 64.0,
    ammoType: 'Flamethrower Fuel',
    damage: {
      dice: 3,
      diceMultiplier: 1,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 1.0,
    selectiveFire: false,
    shotsPerReload: 10,
    malfunction: {
      crit: true,
    },
    snapShotThreshold: 14,
    recoilModifier: -3,
    maxRange: 40,
  },
  [WeaponId.M1A1]: {
    id: WeaponId.M1A1,
    name: 'M-1A1',
    category: WeaponCategoryId.Flamethrowers,
    description: 'This used thickened fuel to improve on the range of the M1 (Max 30). Both used an electric-ignition system that often failed, requiring users to ignite the fuel stream with matches or similar makeshift fare.',
    cost: 180.0,
    weight: 70.0,
    ammoType: 'Flamethrower Fuel',
    damage: {
      dice: 3,
      diceMultiplier: 1,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 1.0,
    selectiveFire: false,
    shotsPerReload: 10,
    malfunction: {
      crit: true,
    },
    snapShotThreshold: 14,
    recoilModifier: -3,
    maxRange: 50,
  },
  [WeaponId.ROKS2]: {
    id: WeaponId.ROKS2,
    name: 'ROKS-2',
    category: WeaponCategoryId.Flamethrowers,
    description: 'The Soviets camouflaged the tanks of this weapon to appear like a backpack, and the flame gun to appear like a rifle; opponents must make a Vision roll to identify the flamethrower under combat conditions. The ROKS-3 functioned the same but used uncamouflaged tanks. Max becomes 40 if thickened fuel is not available.',
    cost: 220.0,
    weight: 50.0,
    ammoType: 'Flamethrower Fuel',
    damage: {
      dice: 3,
      diceMultiplier: 1,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 1.0,
    selectiveFire: false,
    shotsPerReload: 6,
    malfunction: {
      crit: true,
    },
    snapShotThreshold: 14,
    recoilModifier: -3,
    maxRange: 50,
  },
  [WeaponId.Flammenwerfer41]: {
    id: WeaponId.Flammenwerfer41,
    name: 'Flammenwerfer 41',
    category: WeaponCategoryId.Flamethrowers,
    description: 'This German weapon used 10 9mm blanks to ignite the fuel, firing one with each pull on the fuel lever. It fired cold shots only if the blanks were all spent.',
    cost: 200.0,
    weight: 40.0,
    ammoType: 'Flamethrower Fuel',
    damage: {
      dice: 3,
      diceMultiplier: 1,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 1.0,
    selectiveFire: false,
    shotsPerReload: 10,
    malfunction: {
      crit: true,
    },
    snapShotThreshold: 14,
    recoilModifier: -3,
    maxRange: 35,
  },
  [WeaponId.Type100Shiki]: {
    id: WeaponId.Type100Shiki,
    name: '100 Shiki',
    category: WeaponCategoryId.Flamethrowers,
    description: 'The ignition system performed identically to the German system.',
    cost: 200.0,
    weight: 55.0,
    ammoType: 'Flamethrower Fuel',
    damage: {
      dice: 3,
      diceMultiplier: 1,
      modifier: 0,
      halvePenetratedDamage: false,
      multiplyPenetratedDamage: false,
    },
    rateOfFire: 1.0,
    selectiveFire: false,
    shotsPerReload: 10,
    malfunction: {
      crit: true,
    },
    snapShotThreshold: 14,
    recoilModifier: -3,
    maxRange: 30,
  },
};

export const getWeaponReadyThreshold = (def: WeaponDefinition): ReadyThresholdUnion => {
  if ('overrideReadyThreshold' in def && def.overrideReadyThreshold) {
    return def.overrideReadyThreshold;
  }
  return weaponCategories[def.category].defaultReadyThreshold;
};

export const getWeaponTotalReloadReadyRequirement = (weaponId: WeaponId): number => {
  let def = weaponDefinitions[weaponId];
  let readyThreshold = getWeaponReadyThreshold(def);

  switch (readyThreshold.type) {
    case WeaponType.Guns:
      let gunThreshold = readyThreshold as GunReadyThreshold;
      if (gunThreshold.reloadPerRound) {
        return def.shotsPerReload + gunThreshold.reload;
      } else {
        return gunThreshold.reload;
      }
    case WeaponType.HandWeapons:
      // Hand weapons don't need to reload
      return 0;
  }
};

/**
 * Returns user-facing text representing the weapon's current status
 */
export const getWeaponTextStatus = (character: Character, weaponId: WeaponId, readyStatus: ReadyStatusUnion): string => {
  let weapon = character.inventory[weaponId] as Weapon;
  let weaponDef = weaponDefinitions[weaponId];
  let readyThreshold = getWeaponReadyThreshold(weaponDef);

  let slingedness = readyStatus.unsling < readyThreshold.unsling ? 'Slung, ' : '';
  let weaponStatus: string;

  switch (readyStatus.type) {
    case WeaponType.Guns:
      let gunReadyStatus = readyStatus as GunReadyStatus;
      let gunReadyThreshold = readyThreshold as GunReadyThreshold;
      let cockedness = gunReadyStatus.cock < gunReadyThreshold.cock ? 'Uncocked, ': '';

      let loadStatus: string;
      if (weapon.shotsLoaded < weaponDef.shotsPerReload) {
        let ammoStatus = `${weapon.shotsLoaded}/${weaponDef.shotsPerReload} Loaded`;
        let reloadStatus = gunReadyStatus.reload > 0 ? `, Reloading ${gunReadyStatus.reload}/${getWeaponTotalReloadReadyRequirement(weaponId)}` : '';
        loadStatus = `${ammoStatus}${reloadStatus}`;
      } else {
        loadStatus = 'Fully Loaded'
      }
      weaponStatus = `${cockedness}${loadStatus}`;
      break;
    case WeaponType.HandWeapons:
      let handReadyStatus = readyStatus as HandWeaponReadyStatus;
      let handReadyThreshold = readyThreshold as HandWeaponReadyThreshold;

      weaponStatus = handReadyStatus.backswing < handReadyThreshold.backswing ?
        `Backswing ${handReadyStatus.backswing}/${handReadyThreshold.backswing}` :
        'Ready';
      break;
    default:
      throw new Error(`Invalid ready status ${JSON.stringify(readyStatus)}`)
  }
  return `${slingedness}${weaponStatus}`;
};

