import React, {Component} from 'react';
import {Button, Form, FormControlProps, InputGroup} from 'react-bootstrap';
import {CombatPageProps} from "../../../CombatPage";
import {CenteredFlexDiv} from "../../../../../../lib/components/CenteredFlexDiv";
import {getAttributeUnmodifiedValue} from "../../../../../../lib/gameLogic/derivedCharacterStats";
import {AttributeId} from "../../../../../../lib/constants/attributeConstants";

interface Props extends CombatPageProps {
}

interface State {
    damageCounter: number;
    causesBleeding: boolean;
}

export class TakeDamage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            damageCounter: 0,
            causesBleeding: true,
        };
    }

    executeManeuver() {
        let {
            character,
            takeDamageSaga,
        } = this.props;
        let {
            damageCounter,
            causesBleeding,
        } = this.state;
        takeDamageSaga(character, damageCounter, causesBleeding);
    }

    render() {
        let {
            character,
        } = this.props;

        let {
            damageCounter,
        } = this.state;

        let characterHt = getAttributeUnmodifiedValue(AttributeId.Health, character);

        return <CenteredFlexDiv isRow={false}>
            <InputGroup className="mb-3">
                <InputGroup.Prepend>
                    <InputGroup.Checkbox
                        type="checkbox"
                        checked={this.state.causesBleeding}
                        onChange={() => {
                            this.setState({
                                causesBleeding: !this.state.causesBleeding,
                            });
                        }}
                    />
                </InputGroup.Prepend>
                <InputGroup.Text>
                    Causes Bleeding
                </InputGroup.Text>
            </InputGroup>
            <InputGroup className="mb-3">
                <InputGroup.Prepend>
                    <Form.Control
                        className='number-picker wide'
                        type="number"
                        // 5 * HT is instant death, so damage above that don't matter
                        max={`${characterHt * 5}`}
                        min='0'
                        value={damageCounter.toString(10)}
                        onChange={(e: React.FormEvent<FormControlProps>) => {
                            let rawVal = e.currentTarget.value;
                            if (rawVal) {
                                this.setState({
                                    damageCounter: parseInt(rawVal, 10),
                                });
                            }
                        }}
                    />
                </InputGroup.Prepend>
                <InputGroup.Text>
                    Damage
                </InputGroup.Text>
            </InputGroup>
            <Button variant='primary' onClick={this.executeManeuver.bind(this)}>Take Damage</Button>
        </CenteredFlexDiv>
    }
}
