import React, {ElementType, RefObject} from 'react';
import {Button, Col, Form, OverlayTrigger, Popover, Row,} from 'react-bootstrap';
import NumberIncrementer, {logTemplateValueToken} from "../../../../lib/components/NumberIncrementer";
import {CenteredFlexCol} from "../../../../lib/components/CenteredFlexCol";
import {
  allFatigueEvents,
  FatigueEvent,
  FatigueType,
  restingEvents,
  tiringEvents
} from "../../../../lib/constants/fatigueConstants";
import {Character} from "../../../../lib/constants/characterConstants";
import {StatusActionProps} from "../StatusPage";

interface Props extends StatusActionProps {
  character: Character,
}

export default class FatiguePanel extends React.Component<Props> {
  private tireSelectRef = React.createRef<HTMLSelectElement>();
  private restSelectRef = React.createRef<HTMLSelectElement>();

  fatigueEventClick(ref: RefObject<HTMLSelectElement>) {
    let {
      fatigueRollSaga,
      character,
    } = this.props;

    let fatigueEvent: FatigueEvent = allFatigueEvents[ref.current!.value];
    fatigueRollSaga(fatigueEvent, character);
    // let rollResult: SuccessRollResult | null = rollForFatigueEvent(fatigueEvent, character);
    // if (rollResult) {
    //   initStandardRoll(rollResult.rollSpec.title, rollResult.rollSpec.modifiers, rollResult.characteristic!);
    //   executeStandardRoll(rollResult);
    // }
    // let {fatigueValue, healthValue, fatigueType, log} = fatigueEvent.callback(character, fatigueEvent.id, rollResult ? rollResult.success : false);
    // setFatiguePoints(fatigueType, character.fatiguePoints[fatigueType] + fatigueValue, log);
  }

  infoPopover(ref: RefObject<HTMLSelectElement>, type: string, props: Props) {
    let fatigueEvent = allFatigueEvents[ref.current!.value];
    return <Popover {...props} id={`popover-${type}`} title={fatigueEvent.name}>{fatigueEvent.description}</Popover>;
  };

  render() {
    let {
      character,
      setFatiguePoints,
    } = this.props;

    return <Col xs={12} md={6} className='content-panel'>
      <Row className='justify-content-center'>
        <h4>Fatigue</h4>
      </Row>
      <Row>
        {Object.keys(character.fatiguePoints).map((fatigueType) => {
          let fatigueValue = character.fatiguePoints[fatigueType as FatigueType];
          return <NumberIncrementer
            key={`fatigue-incrementer-${fatigueType}`}
            title={fatigueType}
            value={fatigueValue}
            dispatchNewValue={setFatiguePoints.bind(this, fatigueType)}
            logTemplate={`Manual ${fatigueType} fatigue increment = ${logTemplateValueToken}`}
          />
        })}
      </Row>
      <Row>
        <CenteredFlexCol isRow={false}>
          <Form>
            <Form.Group>
              <Form.Control id='tire-select' as="select" ref={this.tireSelectRef as unknown as RefObject<Form<ElementType<any>>>}>
                {
                  Object.values(tiringEvents).map((fatigueEvent) => {
                    return <option key={fatigueEvent.id} value={fatigueEvent.id}>{fatigueEvent.name}</option>;
                  })
                }
              </Form.Control>
            </Form.Group>
          </Form>
          <div className='d-flex align-items-center'>
            <Button variant='outline-danger' onClick={this.fatigueEventClick.bind(this, this.tireSelectRef)}>Tire</Button>
            <OverlayTrigger trigger="click" placement='bottom' rootClose={true} overlay={this.infoPopover.bind(this, this.tireSelectRef, 'tire')}>
              <div>
                <i className="fas fa-question-circle"/>
              </div>
            </OverlayTrigger>
          </div>
        </CenteredFlexCol>
        <CenteredFlexCol isRow={false}>
          <Form>
            <Form.Group>
              <Form.Control id='rest-select' as="select" ref={this.restSelectRef as unknown as RefObject<Form<ElementType<any>>>}>
                {
                  Object.values(restingEvents).map((fatigueEvent) => {
                    return <option key={fatigueEvent.id} value={fatigueEvent.id}>{fatigueEvent.name}</option>;
                  })
                }
              </Form.Control>
            </Form.Group>
          </Form>
          <div className='d-flex align-items-center'>
            <Button variant='outline-success' onClick={this.fatigueEventClick.bind(this, this.restSelectRef)}>Rest</Button>
            <OverlayTrigger trigger="click" placement='bottom' rootClose={true} overlay={this.infoPopover.bind(this, this.restSelectRef, 'rest')}>
              <div>
                <i className="fas fa-question-circle"/>
              </div>
            </OverlayTrigger>
          </div>
        </CenteredFlexCol>
      </Row>
    </Col>
  }
}

