import React, {Component} from 'react';
import {Button, Form, FormControlProps, ListGroup} from 'react-bootstrap';
import {
  AllOutAttackDefinition,
  allOutAttackDefinitions,
  AllOutAttackId,
  attackerConditionDefinitions,
  AttackerConditionId,
  coverDefinitions,
  CoverId,
  getRangeAccuracyModifier,
  getSizeAccuracyModifier,
  getSpeedAccuracyModifier,
  positionDefinitions,
  PositionId
} from "../../../../../../lib/constants/combatConstants";
import {CombatPageProps} from "../../../CombatPage";
import {CenteredFlexCol} from "../../../../../../lib/components/CenteredFlexCol";
import Select from "react-select";
import {Option, validateSingleReactSelectValue} from "../../../../../../lib/utils";
import {ValueType} from "react-select/lib/types";
import {
  getAttackButtonDisabled,
  getAttackLabel,
  getCoverModifiers
} from "../../../../../../lib/gameLogic/derivedCombatStats";
import {Weapon} from "../../../../../../lib/constants/weaponConstants";
import {Modifier} from "../../../../../../lib/constants/rollConstants";

interface State {
  targetDistanceYards: number;
  targetSizeYards: number;
  targetSpeedYdsSec: number;
  targetIsHuman: boolean;
  attackerConditions: Set<AttackerConditionId>;
  selectedCover: CoverId;
  selectedPosition: PositionId;
  selectedAllOutOption: AllOutAttackDefinition;
}

interface Props extends CombatPageProps {
  isAllOutAttack: boolean;
}

const getOptionFromCoverId = (id: CoverId): Option<CoverId> => {
  let def = coverDefinitions[id];
  return {value: id as CoverId, label: def.description};
};

const coverArray = Object.keys(coverDefinitions);
const coverOptions = coverArray.map((id) => getOptionFromCoverId(id as CoverId));

const allOutOptions = Object.values(allOutAttackDefinitions);

const getOptionFromPositionId = (id: PositionId): Option<PositionId> => {
  let def = positionDefinitions[id];
  return {value: def.id as PositionId, label: def.name};
};
const positionArray = Object.keys(positionDefinitions);
const positionOptions = positionArray.map((id) => getOptionFromPositionId(id as PositionId));

const COLUMN_PROPS = {
  sm: 6,
  md: 4,
};

export class Attack extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    let defaultAllOutOption = allOutOptions[0];
    this.state = {
      selectedCover: CoverId.None,
      selectedPosition: PositionId.Standing,
      selectedAllOutOption: defaultAllOutOption,
      targetDistanceYards: 2,
      targetSizeYards: 2,
      targetSpeedYdsSec: 2,
      targetIsHuman: true,
      attackerConditions: new Set<AttackerConditionId>(),
    };
  }

  render() {
    let {
      character,
      combat,
      isAllOutAttack,
    } = this.props;
    let readyStatus = combat.weaponReadyStatus;
    if (!readyStatus) {
      throw new Error(`Can't attack without a readied weapon`)
    }
    let weaponId = readyStatus.weapon;
    let weaponInstance = character.inventory[weaponId] as Weapon;
    return <>
      <CenteredFlexCol {...COLUMN_PROPS} className='max-h-85' isRow={false}>
        <h5>Target</h5>
        {this.renderTargetDistanceAndSpeed()}
      </CenteredFlexCol>
      <CenteredFlexCol {...COLUMN_PROPS} className='max-h-85' isRow={false}>
        {this.renderAllOutOptions()}
        <h5>Target Position</h5>
        {this.renderTargetPosition()}
        <h5>Target Cover</h5>
        {this.renderTargetCover()}
        <Button variant='primary' disabled={getAttackButtonDisabled(combat, weaponInstance)} onClick={() => {
          let modifiers = this.getAttackModifiers();
          let allOutOption = this.state.selectedAllOutOption.value;
          if (allOutOption === AllOutAttackId.SkillBonus) {
            modifiers.push({
              value: 4,
              summary: `All-Out Bonus`,
              description: `All-Out Attack Skill Bonus`,
            });
          }
          this.props.attackManeuverSaga(
            this.props.character,
            modifiers,
            isAllOutAttack && allOutOption === AllOutAttackId.TwoAttacks,
            combat.position,
            this.state.targetDistanceYards,
          );
        }}>
          {getAttackLabel(combat, weaponId, 1)}
        </Button>
      </CenteredFlexCol>
      <CenteredFlexCol {...COLUMN_PROPS} className='max-h-85' isRow={false}>
        <h5>Attacker Conditions</h5>
        {this.renderAttackerCondition()}
      </CenteredFlexCol>
    </>
  }

  changeNumberField(fieldName: keyof State, e: React.FormEvent<FormControlProps>) {
    let stringVal = e.currentTarget.value;
    let number: number;
    if (stringVal) {
      number = parseInt(stringVal, 10);
    } else {
      number = 0;
    }
    this.setState((prevState) => ({
      ...prevState,
      [fieldName]: number,
    }));
  }

  renderTargetDistanceAndSpeed() {
    return <>
      <Form>
        <Form.Check
          type="checkbox"
          label='Is Human?'
          checked={this.state.targetIsHuman}
          onChange={() => {
            let targetIsHuman = !this.state.targetIsHuman;
            if (targetIsHuman) {
              this.setState({
                targetIsHuman,
                targetSizeYards: 2,
                targetSpeedYdsSec: 2,
              });
            } else {
              this.setState({
                targetIsHuman,
              });
            }
          }}
        />
        <Form.Group className='no-margin'>
          <Form.Label column={true}>Range (yd)</Form.Label>
          <Form.Control
            className='number-picker wide'
            type="number"
            value={this.state.targetDistanceYards.toString(10)}
            onChange={this.changeNumberField.bind(this, 'targetDistanceYards')}
          />
        </Form.Group>
        <Form.Group className='no-margin'>
          <Form.Label column={true}>Speed (yd/s)</Form.Label>
          <Form.Control
            className='number-picker wide'
            type="number"
            value={this.state.targetSpeedYdsSec.toString(10)}
            disabled={this.state.targetIsHuman}
            onChange={this.changeNumberField.bind(this, 'targetSpeedYdsSec')}
          />
        </Form.Group>
        <Form.Group className='no-margin'>
          <Form.Label column={true}>Size (yd)</Form.Label>
          <Form.Control
            className='number-picker wide'
            type="number"
            value={this.state.targetSizeYards.toString(10)}
            disabled={this.state.targetIsHuman}
            onChange={this.changeNumberField.bind(this, 'targetSizeYards')}
          />
        </Form.Group>
      </Form>
    </>
  }

  renderAllOutOptions() {
    if (!this.props.isAllOutAttack) {
      return null;
    }
    return <>
      <h5>All-Out Attack Option</h5>
      <Select
        className='react-select'
        value={this.state.selectedAllOutOption}
        onChange={(v: ValueType<Option<AllOutAttackId>>) => {
          let allOutAttackId = validateSingleReactSelectValue(v);
          this.setState({selectedAllOutOption: allOutAttackId})
        }}
        options={allOutOptions}
      />
    </>
  }

  renderTargetCover() {
    return <Select
      className='react-select'
      value={getOptionFromCoverId(this.state.selectedCover)}
      onChange={(v: ValueType<Option<CoverId>>) => {
          let coverOption = validateSingleReactSelectValue(v);
          this.setState({selectedCover: coverOption.value})
      }}
      options={coverOptions}
    />
  }

  renderTargetPosition() {
    return <Select
        className='react-select'
        isDisabled={!this.state.targetIsHuman}
        value={getOptionFromPositionId(this.state.selectedPosition)}
        onChange={(v: ValueType<Option<PositionId>>) => {
          let positionOption = validateSingleReactSelectValue(v);
          this.setState({selectedPosition: positionOption.value});
        }}
        options={positionOptions}
    />
  }

  renderAttackerCondition() {
    return <ListGroup className='list-picker scrollable'>
      {(Object.keys(attackerConditionDefinitions) as Array<AttackerConditionId>).map(conditionId => {
      let def = attackerConditionDefinitions[conditionId];
      return <ListGroup.Item
        action
        key={conditionId}
        active={this.state.attackerConditions.has(conditionId)}
        onClick={() => {
          let newSet = new Set(this.state.attackerConditions);
          let isActive = this.state.attackerConditions.has(conditionId);
          if (isActive) {
            newSet.delete(conditionId);
          } else {
            newSet.add(conditionId);
          }
          this.setState({attackerConditions: newSet})
        }}
      >
        {def.description}
      </ListGroup.Item>
    })}
    </ListGroup>
  }
  
  getAttackModifiers(): Array<Modifier> {
    let {
      targetDistanceYards,
      targetSizeYards,
      targetSpeedYdsSec,
      targetIsHuman,
      attackerConditions,
      selectedCover,
      selectedPosition,
    } = this.state;

    let out: Array<Modifier> = [];

    let rangeMod = getRangeAccuracyModifier(targetDistanceYards);
    if (rangeMod !== 0) {
      out.push({
        value: rangeMod,
        summary: "Target range",
        description: "Target range accuracy modifier"
      });
    }

    if (!targetIsHuman) {
      let sizeMod = getSizeAccuracyModifier(targetSizeYards);
      if (sizeMod !== 0) {
        out.push({
          value: sizeMod,
          summary: "Target size",
          description: "Target size accuracy modifier"
        });
      }

      let speedMod = getSpeedAccuracyModifier(targetSpeedYdsSec);
      if (speedMod !== 0) {
        out.push({
          value: speedMod,
          summary: "Target speed",
          description: "Target speed accuracy reduction"
        });
      }
    }

    out = out.concat([...attackerConditions].map(conditionId => {
      let condition = attackerConditionDefinitions[conditionId];
      return {
        value: condition.attackModifier,
        summary: `Attacker condition ${condition.description}`,
        description: `Attacker condition ${condition.description}`,
      };
    }));

    out = out.concat(getCoverModifiers(selectedPosition, selectedCover));

    return out;
  }
}
