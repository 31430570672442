import React from 'react';
import {connect} from 'react-redux';
import {Container, Row,} from 'react-bootstrap';
import {AppState} from "../../../modules";
import {bindActionCreators} from "redux";
import {CharacterEditorPanel} from "./panels/CharacterEditorPanel";
import {loadCharacter} from "../../../modules/character";

class CharacterEditorPage extends React.Component<EditorPageProps> {
    render() {
        let {
            character: {
                character,
            },
        } = this.props;

        if (Object.values(character).length === 0) {
            return <h2>No character loaded; create a blank character on the Data page to start from scratch.</h2>;
        }

        return <Container fluid={true} className='content-container'>
            <Row className='fullscreen-row'>
                <CharacterEditorPanel
                    {...this.props}
                />
            </Row>
        </Container>;
    }
}

export type EditorPageProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
const mapStateToProps = (state: AppState) => {
    // Consider using reselect to memoize derived values here if performance becomes an issue
    return {
        editor: state.characterEditorReducer,
        character: state.characterReducer,
    };
};

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    loadCharacter,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CharacterEditorPage)
