import React from 'react';
import {connect} from 'react-redux';
import {Container, Row,} from 'react-bootstrap';
import {AppState} from "../../../modules";
import {bindActionCreators} from "redux";
import OverviewPanel from "./panels/OverviewPanel";
import {
    clearCharacters,
    deleteCharacter,
    editCharacterSaga,
    loadNew,
    setGmEditing,
    updateCharacter
} from "../../../modules/gamemaster";

class OverviewPage extends React.Component<OverviewPageProps> {

    render() {
        return <Container fluid={true} className='content-container'>
            <Row className='fullscreen-row'>
                <OverviewPanel
                    {...this.props}
                />
            </Row>
        </Container>;
    }
}

export type OverviewPageProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
const mapStateToProps = (state: AppState) => {
    // Consider using reselect to memoize derived values here if performance becomes an issue
    return {
        gm: state.gamemasterReducer,
        data: state.dataReducer,
    };
};

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    loadNew,
    updateCharacter,
    deleteCharacter,
    clearCharacters,
    setGmEditing,
    editCharacterSaga,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OverviewPage)
