import {CombatState, getGunReadyStatus, GunReadyStatus, ReadyStatusUnion, StunStatus} from "../../modules/combat";
import {
  explosiveWeaponCategories,
  getWeaponReadyThreshold,
  Weapon,
  weaponCategories,
  WeaponCategoryId,
  WeaponDefinition,
  weaponDefinitions,
  WeaponId,
  WeaponType
} from "../constants/weaponConstants";
import {Character} from "../constants/characterConstants";
import {EquipmentType, getEquipmentDefinition, getEquipmentType} from "../constants/equipmentConstants";
import {GearDefinition} from "../constants/gearConstants";
import {
  getAttributeCurrentValue,
  getBestSkillForWeapon,
  getCharacteristicCurrentValue,
  getModifiedCharacteristicValue
} from "./derivedCharacterStats";
import {
  coverDefinitions,
  CoverId,
  getIndirectFireMissMargin,
  ManeuverId,
  positionDefinitions,
  PositionId
} from "../constants/combatConstants";
import {AttributeId} from "../constants/attributeConstants";
import {VantageId} from "../constants/vantageConstants";
import {getHitsInBurst} from "./AutomaticWeaponHitCheck";
import {Modifier} from "../constants/rollConstants";

export const SHOTS_PER_GROUP = 4;

export const getReadiedWeaponId = (combat: CombatState): WeaponId | null => {
  return combat.weaponReadyStatus ? combat.weaponReadyStatus.weapon : null;
};

export const getDefaultReadyStatus = (weaponId: WeaponId): ReadyStatusUnion => {
  switch (getWeaponType(weaponId)) {
    case WeaponType.Guns:
      return {
        type: WeaponType.Guns,
        weapon: weaponId,
        unsling: 0,
        reload: 0,
        cock: 0,
        aim: 0,
        braced: false,
      };
    case WeaponType.HandWeapons:
      return {
        type: WeaponType.HandWeapons,
        weapon: weaponId,
        unsling: 0,
        backswing: 0,
      };
  }
};

export const getWeaponType = (weaponId: WeaponId): WeaponType => {
  return weaponCategories[weaponDefinitions[weaponId].category].type;
};

export const isMultiTurnAttack = (rateOfFire: number): boolean => {
  return rateOfFire < 1;
};

// /**
//  * @param weaponId
//  * @param combatState Pre-attack maneuver combat state
//  */
// export const willMultiTurnAttackHappen = (weaponId: WeaponId, combatState: CombatState): boolean => {
//   let def = weaponDefinitions[weaponId];
//   if (!isMultiTurnAttack(def.rateOfFire)) {
//     throw new Error(`Invalid call to didMultiTurnAttackHappen for non multi-turn weapon ${weaponId}`);
//   }
//   return combatState.turnsThisAttack === 0;
// };

/**
 * @param weaponId
 * @param combatState Post-attack maneuver combat state
 */
export const didMultiTurnAttackHappen = (weaponId: WeaponId, combatState: CombatState): boolean => {
  let def = weaponDefinitions[weaponId];
  if (!isMultiTurnAttack(def.rateOfFire)) {
    throw new Error(`Invalid call to didMultiTurnAttackHappen for non multi-turn weapon ${weaponId}`);
  }
  return combatState.turnsThisAttack === 1;
};

export const getEffectiveRateOfFire = (def: WeaponDefinition, isDoubleAttack: boolean): number => {
  return isDoubleAttack ? def.rateOfFire * 2 : def.rateOfFire;
};

export const getRemainingAttackThisTurn = (weaponId: WeaponId): number => {
  let def = weaponDefinitions[weaponId];
  return def.rateOfFire
};

export const isAutomaticWeapon = (weaponId: WeaponId): boolean => {
  let def = weaponDefinitions[weaponId];
  return def.rateOfFire >= SHOTS_PER_GROUP;
};

export const isExplosiveWeapon = (weaponId: WeaponId): boolean => {
  let def = weaponDefinitions[weaponId];
  return explosiveWeaponCategories.indexOf(def.category) !== -1;
};

export const isShotgunWeapon = (weaponId: WeaponId): boolean => {
  let def = weaponDefinitions[weaponId];
  return def.category === WeaponCategoryId.Shotguns;
};

export const isArmorPiercingWeapon = (weaponId: WeaponId): boolean => {
  let def = weaponDefinitions[weaponId];
  return !!def.damage.armorDivisor;
};

export const getArmorDivisor = (weaponId: WeaponId): number => {
  let def = weaponDefinitions[weaponId];
  return def.damage.armorDivisor ? def.damage.armorDivisor : 1;
};

const concussiveRangeModifier = (range: number, multiplier: number): number => {
  if (range === 0) {
    // Manual: Explosives do concussion damage and fragmentation damage. Both types of damage are doubled for anyone
    //  in contact with the explosive when it goes off.
    return 2;
  } else if (range < 2) {
    // Manual: For blasts up to 6d×20, apply full damage to anyone within 2 yards. More distant
    //  targets divide damage by 4 per 2 yards range (1/4 at 2 yards, 1/16 at 4 yards, and so on). Each tenfold increase
    //  in the amount of concussion damage doubles the increment at which damage is quartered.
    return 1;
  } else {
    return (multiplier / Math.pow(4, range / 2));
  }
};

export const getConcussiveDamageMultiplierByRange = (weaponId: WeaponId, range: number): number => {
  // Manual: Damage due to the shock wave. This is applied to everything nearby. Most WWII grenades do about 2d concussion
  //  damage; TNT does 6d×2 per pound. For blasts up to 6d×20, apply full damage to anyone within 2 yards. More distant
  //  targets divide damage by 4 per 2 yards range (1/4 at 2 yards, 1/16 at 4 yards, and so on). Each tenfold increase
  //  in the amount of concussion damage doubles the increment at which damage is quartered.
  // Not sure if "tenfold" means the next increment is 6dx30 or 6dx200. There are aerial bombs that go up to 6dx19500
  //  damage, so I'm going to go with 6dx100 as the next increment.
  let def = weaponDefinitions[weaponId];
  let totalDice = def.damage.dice * def.damage.diceMultiplier;
  let initial = totalDice / (6 * 20);
  if (initial < 1) {
    return concussiveRangeModifier(range, 1);
  } else {
    return concussiveRangeModifier(range, Math.floor(initial / 100));
  }
};

/**
 * @param rollResultDifferential Same as AttackRollResult.differential
 * @param attackerDistanceToTarget
 */
export const getGrenadeImpactDistance = (rollResultDifferential: number, attackerDistanceToTarget: number): number => {
  return Math.min(Math.round(attackerDistanceToTarget / 2), Math.max(-rollResultDifferential, 0));
};

/**
 * @param rollResultDifferential Same as AttackRollResult.differential
 * @param attackerDistanceToTarget
 */
export const getIndirectFireImpactDistance = (rollResultDifferential: number, attackerDistanceToTarget: number): number => {
  // TODO: this doesn't take into account FO and situational modifiers, but should be valid once those are added to the
  //  attack roll itself
  return Math.min(Math.round(attackerDistanceToTarget / 10), getIndirectFireMissMargin(Math.max(-rollResultDifferential, 0)));
};

export const getFragmentationHitThreshold = (impactDistance: number): number => {
  // Manual: A hit is automatic at “ground zero.” At 1 yard from the blast, a hit occurs on a roll of 17 or less. At 2 yards,
  //  the roll is 16 or less, and so on. When this roll reaches 3, it stays at 3 to the limit of fragment range
  return Math.max(18 - impactDistance, 3);
};

export const getFragmentationRange = (weaponId: WeaponId): number => {
  let weaponDef = weaponDefinitions[weaponId];
  // Manual: An explosion projects fragments to a distance of 5 yards times the dice of concussion damage.
  return weaponDef.damage.dice * weaponDef.damage.diceMultiplier * 5;
};

/**
 *
 * @param effectiveSkill
 * @param weaponId
 * @param combatState Pre-attack maneuver combat state
 */
export const isPenalizedSnapShot = (effectiveSkill: number, weaponId: WeaponId, combatState: CombatState): boolean => {
  let def = weaponDefinitions[weaponId];
  if (!def.snapShotThreshold) {
    // If the weapon has no snap shot threshold (mines, grenades, etc), there's no penalty
    return false;
  }
  let isFirstShot = isMultiTurnAttack(def.rateOfFire) ? combatState.turnsThisAttack === 0 : combatState.attacksThisTurn === 0;
  // Ruling: only the first shot per turn gets a snap shot penalty
  // Otherwise multiple shots per turn are unworkable
  // Further discussion: https://rec.games.frp.gurps.narkive.com/JhowERX7/gurps-aim-and-recoil-questions
  return isFirstShot &&
  // Manual: Your attack is at -4 if you use a ranged weapon without aiming unless your effective skill is at least
  // equal to the weapon’s Snap Shot number.
   (!def.snapShotThreshold || effectiveSkill < def.snapShotThreshold);
};

/**
 *
 * From the manual:
 *  ST: The minimum ST to avoid recoil penalties (p. 201). “B” means a bipod is standard (if fired prone, +1 Acc, -2
 * ST).
 *  “T” means a tripod is used (if attached and weapon is fired from sitting or prone position, ignore ST minimum; if
 * not, -2 Acc).
 * @param character
 * @param combatState
 * @param weaponId
 * @param positionId
 */
export const isStrengthRequirementMet = (character: Character, combatState: CombatState, weaponId: WeaponId, positionId: PositionId): {
  requirementMet: boolean,
  accuracyModifier: number,
} => {
  let def = weaponDefinitions[weaponId];
  let req = def.strengthRequirement;
  let accuracyModifier = 0;
  if (!req) {
    // No requirement means we've met the requirement by default
    return {
      requirementMet: true,
      accuracyModifier,
    };
  }
  let strThreshold = req.strengthThreshold;
  if (req.bipodRequired && positionId === PositionId.Prone) {
    strThreshold -= 2;
    accuracyModifier = 1;
  }
  if (req.tripodRequired) {
    if ([PositionId.Prone, PositionId.Sitting].indexOf(positionId) !== -1) {
      strThreshold = 0;
    } else {
      accuracyModifier = -2;
    }
  }
  return {
    requirementMet: getModifiedCharacteristicValue(getAttributeCurrentValue(AttributeId.Strength, character, combatState)) >= strThreshold,
    accuracyModifier,
  };
};

export const getAttackButtonDisabled = (combat: CombatState, weapon: Weapon): boolean => {
  if (isThisAFinishAttackTurn(combat, weapon.id)) {
    // Finishing an attack doesn't actually use any ammo, so the weapon doesn't need to be loaded for this
    return false;
  }

  return weapon.shotsLoaded === 0;
};

export const getAttackLabel = (combat: CombatState, weaponId: WeaponId, modifier = 0): string => {
  let def = weaponDefinitions[weaponId];
  if (isMultiTurnAttack(def.rateOfFire)) {
    let descriptor = combat.turnsThisAttack === 0 ? 'Attack' : 'Finish Attack Action';
    return `${descriptor} (${combat.turnsThisAttack + modifier}/${getAttacksPerTurn(weaponId)} turns)`;
  } else {
    return `Attack (${combat.attacksThisTurn + modifier}/${getAttacksPerTurn(weaponId)} attacks)`;
  }
};

export const getPassiveDefense = (character: Character): number => {
  return Object.values(character.inventory).reduce((carry, equipment) => {
    if (!equipment) {
      return carry;
    }
    if (getEquipmentType(equipment.id) === EquipmentType.Gear && equipment.equipped) {
      let def = getEquipmentDefinition(equipment.id) as GearDefinition;
      return carry + def.passiveDefense;
    }
    return carry;
  }, 0);
};

export const getDamageResistance = (character: Character): number => {
  return Object.values(character.inventory).reduce((carry, equipment) => {
    if (!equipment) {
      return carry;
    }
    if (getEquipmentType(equipment.id) === EquipmentType.Gear && equipment.equipped) {
      let def = getEquipmentDefinition(equipment.id) as GearDefinition;
      return carry + def.damageResistance;
    }
    return carry;
  }, 0);
};

// Attack maneuver helper functions
const getEffectiveAttackSkill = (weaponId: WeaponId, character: Character, combatState: CombatState, modifiers: Array<Modifier>): number => {
  let bestSkill = getBestSkillForWeapon(weaponId, character, combatState);
  let characteristicValue = getModifiedCharacteristicValue(getCharacteristicCurrentValue(bestSkill.id, character, combatState));
  return modifiers.reduce((carry, modifier) => carry + modifier.value, characteristicValue);
};

export const getCoverModifiers = (targetPosition: PositionId, targetCover: CoverId): Array<Modifier> => {
  let out = [];
  // Airburst frag damage ignores prone cover, but we're going to leave that to the user to select as applicable
  if (targetCover !== CoverId.None) {
    let coverDef = coverDefinitions[targetCover];
    out.push({
      value: coverDef.attackModifier,
      summary: `Target cover ${coverDef.description}`,
      description: `Target cover ${coverDef.description}`,
    });
  }
  if (targetPosition !== PositionId.Standing) {
    let positionDef = positionDefinitions[targetPosition];
    out.push({
      value: positionDef.rangedDefenseModifier,
      summary: `Target position ${positionDef.name}`,
      description: `Target position ${positionDef.name}`,
    });
  }
  return out;
};

export const getAimModifiers = (modifiers: Array<Modifier>, weaponId: WeaponId, character: Character, combatState: CombatState, gunReadyStatus: GunReadyStatus, staticModifier: number = 0): Array<Modifier> => {
  let weaponDef = weaponDefinitions[weaponId];
  let preAimEffectiveSkill = getEffectiveAttackSkill(weaponId, character, combatState, modifiers);
  let hasAimed = gunReadyStatus.aim > 0;

  if (hasAimed) {
    // If aimed, we get the weapon's accuracy bonus
    let aimBonus = (weaponDef.accuracy ? weaponDef.accuracy : 0) + staticModifier;
    let summary = `Aim bonus`;
    // Aim bonus is +1 for every aim after the first
    aimBonus += (gunReadyStatus.aim - 1);
    if (gunReadyStatus.braced) {
      aimBonus += 1;
      summary = summary + ` (braced)`;
    }
    modifiers.push({
      value: aimBonus,
      summary,
      description: summary,
    });
  } else if (isPenalizedSnapShot(preAimEffectiveSkill, weaponId, combatState)) {
    // If the user hasn't aimed and this weapon or the user's skill doesn't qualify for a snap shot
    modifiers.push({
      value: -4,
      summary: `Snap shot penalty`,
      description: `Unaimed shot without meeting snap shot requirement`,
    });
  }
  return modifiers;
};

/**
 * @param modifiers
 * @param weaponId
 * @param character
 * @param combatState Pre-attack maneuver combat state
 * @param position
 */
export const getRecoilModifiers = (
    modifiers: Array<Modifier>,
    weaponId: WeaponId,
    character: Character,
    combatState: CombatState,
    position: PositionId
): {
  modifiers: Array<Modifier>,
  accuracyModifier: number,
} => {
  let weaponDef = weaponDefinitions[weaponId];
  let rcl = weaponDef.recoilModifier;
  if (!rcl) {
    return {
      modifiers,
      accuracyModifier: 0,
    };
  }
  let {requirementMet, accuracyModifier} = isStrengthRequirementMet(character, combatState, weaponId, position);

  if (isAutomaticWeapon(weaponId)) {
    // From the manual: Recoil: In automatic fire, apply Rcl as a penalty to effective skill on the attack roll for the first group,
    //  and again on the roll for each four-round group or partial group after the first. E.g., for a weapon with RoF 9
    //  and Rcl -3, the first 4 rounds are at -3, the second 4 at -6, and the final round at -9. This penalty continues
    //  to increase, even in subsequent turns, until the firer stops shooting for one full turn.

    // We haven't yet incremented attack state, so add one to get our current group
    let currentGroupCount = getGroupsFiredThisTurn(weaponId, combatState) + 1;
    rcl = currentGroupCount * rcl;
  } else {
    // If a gun attack was made on this turn or the last turn, there's recoil on this one.
    let isRecoilShot = combatState.lastGunAttackTurnId >= combatState.turnId;
    if (!isRecoilShot) {
      return {
        modifiers,
        accuracyModifier,
      };
    }
  }
  if (requirementMet) {
    modifiers.push({
      value: rcl,
      summary: `Recoil penalty`,
      description: `Recoil from consecutive shots`,
    });
  } else {
    modifiers.push({
      value: rcl * 2,
      summary: `Recoil penalty`,
      description: `Recoil from consecutive shots (understrength character)`,
    });
  }
  return {
    modifiers,
    accuracyModifier,
  };
};

export const getAttacksPerTurn = (weaponId: WeaponId): number => {
  let def = weaponDefinitions[weaponId];
  if (isAutomaticWeapon(weaponId)) {
    return Math.ceil(def.rateOfFire / SHOTS_PER_GROUP);
  }
  if (isMultiTurnAttack(def.rateOfFire)) {
    return 1 / def.rateOfFire;
  } else {
    return def.rateOfFire;
  }
};

// export const getFragDamageModifiers = (weaponId: WeaponId, character: Character, combatState: CombatState, gunReadyStatus: GunReadyStatus, staticModifier: number = 0): Array<Modifier> => {
//   let weaponDef = weaponDefinitions[weaponId];
//   let preAimEffectiveSkill = getEffectiveAttackSkill(weaponId, character, modifiers);
//   let hasAimed = gunReadyStatus.aim > 0;
//
//   if (hasAimed) {
//     // If aimed, we get the weapon's accuracy bonus
//     let aimBonus = (weaponDef.accuracy ? weaponDef.accuracy : 0) + staticModifier;
//     let summary = `Aim bonus`;
//     // Aim bonus is +1 for every aim after the first
//     aimBonus += (gunReadyStatus.aim - 1);
//     if (gunReadyStatus.braced) {
//       aimBonus += 1;
//       summary = summary + ` (braced)`;
//     }
//     modifiers.push({
//       value: aimBonus,
//       summary,
//       description: summary,
//     });
//   } else if (isPenalizedSnapShot(preAimEffectiveSkill, weaponId, combatState)) {
//     // If the user hasn't aimed and this weapon or the user's skill doesn't qualify for a snap shot
//     modifiers.push({
//       value: -4,
//       summary: `Snap shot penalty`,
//       description: `Unaimed shot without meeting snap shot requirement`,
//     });
//   }
//   return modifiers;
// };

/**
 *
 * @param weaponId
 * @param combatState Post-attack maneuver combat state
 */
export const getGroupsFiredThisTurn = (weaponId: WeaponId, combatState: CombatState): number => {
  if (!isAutomaticWeapon(weaponId)) {
    throw new Error(`Invalid automatic logic for non-automatic weapon ${weaponId}`);
  }
  return combatState.attacksThisTurn;
};

export const getShotsFiredThisAttack = (weaponId: WeaponId, attacksThisTurn: number): number => {
  let def = weaponDefinitions[weaponId];
  if (isMultiTurnAttack(def.rateOfFire)) {
    // This function should only be called on turns when an attack actually happened for a multi-turn attack weapon
    return 1;
  }
  if (!isAutomaticWeapon(weaponId)) {
    return 1;
  }
  let tentativeTotalFired = attacksThisTurn * SHOTS_PER_GROUP;
  // Last group, won't be a full group
  if (tentativeTotalFired > def.rateOfFire) {
    return def.rateOfFire % SHOTS_PER_GROUP;
  } else {
    // Full group
    return SHOTS_PER_GROUP;
  }
};

export const isThisAFinishAttackTurn = (combat: CombatState, weaponId: WeaponId): boolean => {
  let def = weaponDefinitions[weaponId];
  let isMultiTurn = isMultiTurnAttack(def.rateOfFire);
  return isMultiTurn && combat.turnsThisAttack !== 0;
};

export const getActiveDefenseModifiers = (character: Character, combatState: CombatState): Array<Modifier> => {
  let modifiers: Array<Modifier> = [];

  let passiveDefense = getPassiveDefense(character);
  if (passiveDefense !== 0) {
    modifiers.push({
      value: passiveDefense,
      summary: `Passive Defense`,
      description: `Passive Defense bonus to active defense`,
    });
  }

  let combatReflexes = character.vantages[VantageId.CombatReflexes];
  if (combatReflexes && combatReflexes.level > 0) {
    modifiers.push({
      value: 1,
      summary: `Combat Reflexes`,
      description: `Combat Reflexes bonus to active defense`,
    });
  }

  let positionDef = positionDefinitions[combatState.position];
  if (positionDef.activeDefenseModifier !== 0) {
    modifiers.push({
      value: positionDef.activeDefenseModifier,
      summary: `Position`,
      description: `Position modifier to active defense`,
    });
  }

  if (combatState.stunStatus !== StunStatus.Lucid) {
    modifiers.push({
      value: -4,
      summary: `Stunned`,
      description: `Stunned modifier to active defense`,
    });
  }
  return modifiers;
};

export const getDodgeModifiers = (character: Character, combatState: CombatState): Array<Modifier> => {
  return [];
};

export const getParryModifiers = (character: Character, combatState: CombatState): Array<Modifier> => {
  return [];
};

export const getActiveDefenseReadiness = (readyStatus: ReadyStatusUnion): {
  isReloading: boolean,
  isReady: boolean,
} => {
  let weaponDef = weaponDefinitions[readyStatus.weapon];
  let isReloading = false;
  let isReady: boolean;

  switch (readyStatus.type) {
    case WeaponType.Guns:
      let gunReadyStatus = readyStatus as GunReadyStatus;
      isReloading = gunReadyStatus.reload > 0;
      isReady = gunReadyStatus.unsling >= getWeaponReadyThreshold(weaponDef).unsling;
      break;
    case WeaponType.HandWeapons:
      // TODO: implement this
      isReady = false;
      break;
    default:
      throw new Error(`Undefined ready status type ${readyStatus.type}`);
  }

  return {
    isReloading,
    isReady,
  };
};

export const getActiveDefenseEligibility = (combat: CombatState): boolean => {
  let history = combat.activeDefenseHistory;
  if (combat.maneuverId === ManeuverId.AllOutDefense) {
    // All-out defense allows more options
    return history.length < 2;
  } else {
    // Otherwise we just get default defense options
    return history.length < 1;
  }
};

export const wasLastManeuverReload = (combatState: CombatState): boolean => {
  let gunReadyStatus = getGunReadyStatus(combatState);
  if (!gunReadyStatus) {
    return false;
  }
  return gunReadyStatus.reload > 0;
};

export const parseAttackHits = (
    weaponId: WeaponId,
    combat: CombatState,
    rollDifferential: number,
    success: boolean,
    criticalHit: boolean,
    maxDamageCriticalHit: boolean,
): {normalHits: number, maxDamageHits: number} => {
  // let weaponDef = weaponDefinitions[spec.weaponId];
  let normalHits: number;
  let maxDamageHits: number;

  if (isAutomaticWeapon(weaponId)) {
    // Automatic weapon hits take a bit more work

    // Ruling: the rules say "A critical hit with a group is a hit with all the rounds. Treat one round in the group as
    //  a critical hit, the remainder as normal hits." I'm going to also apply that to a "max damage" roll of 3, so
    //  we'll say the first round does max damage and we roll normally for the others
    maxDamageHits = maxDamageCriticalHit ? 1 : 0;
    normalHits = getHitsInBurst(rollDifferential, getShotsFiredThisAttack(weaponId, combat.attacksThisTurn), criticalHit) - maxDamageHits;
  } else {
    maxDamageHits = maxDamageCriticalHit ? 1 : 0;
    normalHits = (success ? 1 : 0) - maxDamageHits;
  }
  return {
    normalHits,
    maxDamageHits,
  };
};

export const getDamageModifiers = (weaponId: WeaponId): Array<Modifier> => {
  let modifiers: Array<Modifier> = [];
  let weaponDef = weaponDefinitions[weaponId];

  if (weaponDef.damage.modifier > 0) {
    modifiers.push({
      value: weaponDef.damage.modifier,
      summary: `Weapon Modifier`,
      description: `Weapon inherent static damage modifier`,
    });
  }

  return modifiers;
};
