import React, {Component} from 'react';
import {GunReadyStatus} from "../../../../../../modules/combat";
import {CenteredFlexDiv} from "../../../../../../lib/components/CenteredFlexDiv";
import {Button} from "react-bootstrap";
import {CombatPageProps} from "../../../CombatPage";
import {wasLastManeuverReload} from "../../../../../../lib/gameLogic/derivedCombatStats";
import {WeaponType} from "../../../../../../lib/constants/weaponConstants";

export class Dodge extends Component<CombatPageProps> {
    executeManeuver() {
        let {
            character,
            defendDodgeManeuverSaga,
        } = this.props;
        defendDodgeManeuverSaga(character);
    }

    render() {
        let {
            combat
        } = this.props;
        let readyStatus = combat.weaponReadyStatus as GunReadyStatus;
        return <CenteredFlexDiv isRow={false}>
            {this.renderReloadWarning()}
            {this.renderAimWarning()}
            <Button variant='primary' onClick={this.executeManeuver.bind(this)}>Roll for Dodge</Button>
        </CenteredFlexDiv>;
    }

    renderReloadWarning() {
        if (wasLastManeuverReload(this.props.combat)) {
            return <h4>Dodging will lose the last turn of reloading.</h4>
        }
        return null;
    }

    renderAimWarning() {
        let {
            combat
        } = this.props;
        if (combat.weaponReadyStatus !== null &&
            combat.weaponReadyStatus.type === WeaponType.Guns &&
            (combat.weaponReadyStatus as GunReadyStatus).aim > 0) {
            return <h4>Dodging will lose all aim.</h4>
        }
        return null;
    }
}
