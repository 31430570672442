import {Character} from "../constants/characterConstants";
import {getLocalStorageAutosaves, getLocalStorageFile} from "../localStorage/localStorageIo";
import {AUTOSAVE_COUNT, CharacterFile, DataFileType, SaveId, UUIDv1} from "../constants/dataConstants";
import {getObjectHash} from "../utils";

export const isCurrentCharacterDirty = (character: Character, file: CharacterFile | null, autosaves: CharacterFile[]) => {
    let currentCharacterHash = getObjectHash(character);

    // let file = getLocalStorageFile(id, DataFileType.Character);
    if (file && file.lastSavedHash === currentCharacterHash) {
        return false;
    }

    // let autosaves = getLocalStorageAutosaves(id, DataFileType.Character);
    let autosaveMatch = autosaves.some(autosave => {
        return autosave.lastSavedHash === currentCharacterHash;
    });

    if (autosaveMatch) {
        return false;
    }

    return true;
};

export const buildCharacterFile = (id: UUIDv1, character: Character): CharacterFile => {
    return {
        id,
        type: DataFileType.Character,
        lastSavedHash: getObjectHash(character),
        lastSavedTimestamp: Math.round(Date.now() / 1000),
        content: character,
    };
};

export const getNextAutosaveIndex = (autosaves: CharacterFile[]): number => {
    if (!autosaves) {
        return 0;
    }
    if (autosaves.length < AUTOSAVE_COUNT) {
        return autosaves.length
    }

    let lowestTimestamp = Number.MAX_SAFE_INTEGER;
    let targetIndex = -1;
    autosaves.forEach((autosave, index) => {
        let timestamp = autosave.lastSavedTimestamp;
        if (timestamp < lowestTimestamp) {
            lowestTimestamp = timestamp;
            targetIndex = index;
        }
    });
    if (targetIndex < 0) {
        throw new Error(`Failed to find oldest autosave to overwrite`);
    }
    return targetIndex;
};

export const getCharacterForSaveId = (saveId: SaveId): CharacterFile | null => {
    if (saveId.autosaveTimestamp) {
        let autosaves = getLocalStorageAutosaves(saveId.id, DataFileType.Character);
        let selectedAutosave = autosaves.find(autosave => autosave.id === saveId.id && autosave.lastSavedTimestamp === saveId.autosaveTimestamp);
        if (!selectedAutosave) {
            return null;
        }
        return selectedAutosave;
    } else {
        let file = getLocalStorageFile(saveId.id, DataFileType.Character);
        if (!file) {
            return null;
        }
        return file;
    }
};
