import {Equipment} from "./equipmentConstants";

export interface GearCategoryDefinition {
  name: string;
  id: GearCategoryId;
}

export enum GearCategoryId {
  Clothing = '@@gearCategory/Clothing',
  ArmorAndRelated = '@@gearCategory/ArmorAndRelated',
  FieldGear = '@@gearCategory/FieldGear',
  Food = '@@gearCategory/Food',
  WeaponAccessories = '@@gearCategory/WeaponAccessories',
  Storage = '@@gearCategory/Storage',
  ExplosivesAndRelated = '@@gearCategory/ExplosivesAndRelated',
  ToolsAndHeavyGear = '@@gearCategory/ToolsAndHeavyGear',
  // TODO: implement melee weapons
  MeleeWeapons = '@@gearCategory/MeleeWeapons',
  Medical = '@@gearCategory/Medical',
  AidKits = '@@gearCategory/AidKits',
  CommunicationsAndElectronics = '@@gearCategory/CommunicationsAndElectronics',
  PoliceAndSpyTools = '@@gearCategory/PoliceAndSpyTools',
}

export const gearCategories: Record<GearCategoryId, GearCategoryDefinition> = {
  [GearCategoryId.Clothing]: {
    id: GearCategoryId.Clothing,
    name: 'Clothing',
  },
  [GearCategoryId.ArmorAndRelated]: {
    id: GearCategoryId.ArmorAndRelated,
    name: 'Armor and Related',
  },
  [GearCategoryId.FieldGear]: {
    id: GearCategoryId.FieldGear,
    name: 'Field Gear',
  },
  [GearCategoryId.Food]: {
    id: GearCategoryId.Food,
    name: 'Food',
  },
  [GearCategoryId.WeaponAccessories]: {
    id: GearCategoryId.WeaponAccessories,
    name: 'Weapon Accessories',
  },
  [GearCategoryId.Storage]: {
    id: GearCategoryId.Storage,
    name: 'Storage',
  },
  [GearCategoryId.ExplosivesAndRelated]: {
    id: GearCategoryId.ExplosivesAndRelated,
    name: 'Explosives and Related',
  },
  [GearCategoryId.ToolsAndHeavyGear]: {
    id: GearCategoryId.ToolsAndHeavyGear,
    name: 'Tools and Heavy Gear',
  },
  // TODO: implement melee weapons
  [GearCategoryId.MeleeWeapons]: {
    id: GearCategoryId.MeleeWeapons,
    name: 'Melee Weapons',
  },
  [GearCategoryId.Medical]: {
    id: GearCategoryId.Medical,
    name: 'Medical',
  },
  [GearCategoryId.AidKits]: {
    id: GearCategoryId.AidKits,
    name: 'Aid Kits',
  },
  [GearCategoryId.CommunicationsAndElectronics]: {
    id: GearCategoryId.CommunicationsAndElectronics,
    name: 'Communications and Electronics',
  },
  [GearCategoryId.PoliceAndSpyTools]: {
    id: GearCategoryId.PoliceAndSpyTools,
    name: 'Police and Spy Tools',
  },
};

export interface Gear extends Equipment {
  id: GearId;
}

export interface GearDefinition {
  name: string;
  category: GearCategoryId;
  id: GearId;
  description: string;
  cost: number;
  weight: number;
  passiveDefense: number;
  damageResistance: number;
}

export const GEAR_ID_PREFIX = '@@gear';

export enum GearId {
  Belt = '@@gear/Belt',
  Boots = '@@gear/Boots',
  ClothingArctic = '@@gear/ClothingArctic',
  ClothingWinter = '@@gear/ClothingWinter',
  Fedora = '@@gear/Fedora',
  GlovesShooting = '@@gear/GlovesShooting',
  GlovesWork = '@@gear/GlovesWork',
  Goggles = '@@gear/Goggles',
  HatWomens = '@@gear/HatWomens',
  Shirt = '@@gear/Shirt',
  Shoes = '@@gear/Shoes',
  Socks = '@@gear/Socks',
  Sunglasses = '@@gear/Sunglasses',
  Sweater = '@@gear/Sweater',
  TrenchcoatLeather = '@@gear/TrenchcoatLeather',
  Underwear = '@@gear/Underwear',
  UniformMilitary = '@@gear/UniformMilitary',
  Windcheater = '@@gear/Windcheater',
  BreastplateConcealable = '@@gear/BreastplateConcealable',
  FlakJacket = '@@gear/FlakJacket',
  FlakVestAircrew = '@@gear/FlakVestAircrew',
  GasCape = '@@gear/GasCape',
  HeatedSuit = '@@gear/HeatedSuit',
  HelmetBritish = '@@gear/HelmetBritish',
  HelmetCrash = '@@gear/HelmetCrash',
  HelmetGerman = '@@gear/HelmetGerman',
  HelmetFlight = '@@gear/HelmetFlight',
  HelmetJapanese = '@@gear/HelmetJapanese',
  BatteryACell = '@@gear/BatteryACell',
  BatteryDCell = '@@gear/BatteryDCell',
  BlanketWool = '@@gear/BlanketWool',
  BlanketFlannel = '@@gear/BlanketFlannel',
  CigaretteLighter = '@@gear/CigaretteLighter',
  ClimbingGear = '@@gear/ClimbingGear',
  Converter = '@@gear/Converter',
  Cricket = '@@gear/Cricket',
  EntrenchingTool = '@@gear/EntrenchingTool',
  FlashlightSmall = '@@gear/FlashlightSmall',
  FlashlightLarge = '@@gear/FlashlightLarge',
  FlashlightLensFilters = '@@gear/FlashlightLensFilters',
  GeneratorHandcranked = '@@gear/GeneratorHandcranked',
  Grapnel = '@@gear/Grapnel',
  Handcuffs = '@@gear/Handcuffs',
  Lockpicks = '@@gear/Lockpicks',
  Maps = '@@gear/Maps',
  MessKitPersonal = '@@gear/MessKitPersonal',
  ARation = '@@gear/ARation',
  BreadandHam = '@@gear/BreadandHam',
  DRation = '@@gear/DRation',
  RiceUncooked = '@@gear/RiceUncooked',
  CartridgeBelt = '@@gear/CartridgeBelt',
  Condom = '@@gear/Condom',
  GunCleaningKit = '@@gear/GunCleaningKit',
  GunCleaningKitSmall = '@@gear/GunCleaningKitSmall',
  WebGear = '@@gear/WebGear',
  Parachute = '@@gear/Parachute',
  PersonalBasics = '@@gear/PersonalBasics',
  RappellingRope200 = '@@gear/RappellingRope200',
  RappellingHarness = '@@gear/RappellingHarness',
  RoadFlare = '@@gear/RoadFlare',
  SleepingBag = '@@gear/SleepingBag',
  ThermosBottle = '@@gear/ThermosBottle',
  Towel = '@@gear/Towel',
  Typewriter = '@@gear/Typewriter',
  WaterBasinFolding = '@@gear/WaterBasinFolding',
  WindUpAlarmClock = '@@gear/WindUpAlarmClock',
  WristwatchMilitary = '@@gear/WristwatchMilitary',
  AmmoCan = '@@gear/AmmoCan',
  DuffelBag = '@@gear/DuffelBag',
  FiftyGallonDrum = '@@gear/FiftyGallonDrum',
  IceBox = '@@gear/IceBox',
  JerryCan = '@@gear/JerryCan',
  WaterPail = '@@gear/WaterPail',
  BangaloreTorpedo = '@@gear/BangaloreTorpedo',
  ContactWire500 = '@@gear/ContactWire500',
  DetonatorCaps50 = '@@gear/DetonatorCaps50',
  DetonatorTrip = '@@gear/DetonatorTrip',
  DynamiteMilitary = '@@gear/DynamiteMilitary',
  Exploder10Cap = '@@gear/Exploder10Cap',
  SatchelCharge = '@@gear/SatchelCharge',
  CargoNet = '@@gear/CargoNet',
  Cot = '@@gear/Cot',
  FlashlightDynamo = '@@gear/FlashlightDynamo',
  HydraulicJack = '@@gear/HydraulicJack',
  ToolKitSmall = '@@gear/ToolKitSmall',
  Mattock = '@@gear/Mattock',
  SaddleandTack = '@@gear/SaddleandTack',
  Saddlebags = '@@gear/Saddlebags',
  Shovel = '@@gear/Shovel',
  Welder = '@@gear/Welder',
  Ammonia5Capsules = '@@gear/Ammonia5Capsules',
  Amphetamines30Pills = '@@gear/Amphetamines30Pills',
  FootPowder1Can = '@@gear/FootPowder1Can',
  Penicillin5Packets = '@@gear/Penicillin5Packets',
  SulfaPowder5Packets = '@@gear/SulfaPowder5Packets',
  Sulfa30Tablets = '@@gear/Sulfa30Tablets',
  Aspirin100Tablets = '@@gear/Aspirin100Tablets',
  MalarialPills30 = '@@gear/MalarialPills30',
  Morphine5Syrettes = '@@gear/Morphine5Syrettes',
  PlasmaKitSerum = '@@gear/PlasmaKitSerum',
  SaltTablets30 = '@@gear/SaltTablets30',
  WaterPurificationTablets50 = '@@gear/WaterPurificationTablets50',
  DoctorsAidKit = '@@gear/DoctorsAidKit',
  FirstAidKitBasic = '@@gear/FirstAidKitBasic',
  FirstAidKitMedics = '@@gear/FirstAidKitMedics',
  SurgicalKit = '@@gear/SurgicalKit',
  Camera35mm = '@@gear/Camera35mm',
  CameraSpy = '@@gear/CameraSpy',
  DeveloperKit = '@@gear/DeveloperKit',
  FieldPhone = '@@gear/FieldPhone',
  Megaphone = '@@gear/Megaphone',
  MovieCamera = '@@gear/MovieCamera',
  TelegraphKey = '@@gear/TelegraphKey',
  ClimbingSpikes = '@@gear/ClimbingSpikes',
  CriminologyKit = '@@gear/CriminologyKit',
}

export const gearDefinitions: Record<GearId, GearDefinition> = {
  [GearId.Belt]: {
    id: GearId.Belt,
    name: "Belt",
    category: GearCategoryId.Clothing,
    description: "Heavy leather",
    cost: 0.75,
    weight: 0.2,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.Boots]: {
    id: GearId.Boots,
    name: "Boots",
    category: GearCategoryId.Clothing,
    description: "PD 2, DR 2",
    cost: 10.0,
    weight: 4.0,
    passiveDefense: 2,
    damageResistance: 2,
  },
  [GearId.ClothingArctic]: {
    id: GearId.ClothingArctic,
    name: "Clothing Arctic",
    category: GearCategoryId.Clothing,
    description: "PD 0, DR 2, +5 to HT to resist cold",
    cost: 50.0,
    weight: 15.0,
    passiveDefense: 0,
    damageResistance: 2,
  },
  [GearId.ClothingWinter]: {
    id: GearId.ClothingWinter,
    name: "Clothing Winter",
    category: GearCategoryId.Clothing,
    description: "PD 0, DR 1, +2 to HT vs. cold",
    cost: 25.0,
    weight: 8.0,
    passiveDefense: 0,
    damageResistance: 1,
  },
  [GearId.Fedora]: {
    id: GearId.Fedora,
    name: "Fedora",
    category: GearCategoryId.Clothing,
    description: "Stylish felt hat",
    cost: 8.0,
    weight: 1.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.GlovesShooting]: {
    id: GearId.GlovesShooting,
    name: "Gloves Shooting",
    category: GearCategoryId.Clothing,
    description: "PD 1, DR 1",
    cost: 5.0,
    weight: 0.5,
    passiveDefense: 1,
    damageResistance: 1,
  },
  [GearId.GlovesWork]: {
    id: GearId.GlovesWork,
    name: "Gloves Work",
    category: GearCategoryId.Clothing,
    description: "PD 1, DR 1",
    cost: 2.0,
    weight: 0.5,
    passiveDefense: 1,
    damageResistance: 1,
  },
  [GearId.Goggles]: {
    id: GearId.Goggles,
    name: "Goggles",
    category: GearCategoryId.Clothing,
    description: "DR 1 for eyes,",
    cost: 5.0,
    weight: 0.0,
    passiveDefense: 0,
    damageResistance: 1,
  },
  [GearId.HatWomens]: {
    id: GearId.HatWomens,
    name: "Hat Women’ s",
    category: GearCategoryId.Clothing,
    description: "Many styles",
    cost: 3.0,
    weight: 1.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.Shirt]: {
    id: GearId.Shirt,
    name: "Shirt",
    category: GearCategoryId.Clothing,
    description: "Civilian or military",
    cost: 1.0,
    weight: 1.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.Shoes]: {
    id: GearId.Shoes,
    name: "Shoes",
    category: GearCategoryId.Clothing,
    description: "PD 1, DR 1",
    cost: 8.0,
    weight: 2.0,
    passiveDefense: 1,
    damageResistance: 1,
  },
  [GearId.Socks]: {
    id: GearId.Socks,
    name: "Socks",
    category: GearCategoryId.Clothing,
    description: "Six pairs",
    cost: 1.0,
    weight: 1.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.Sunglasses]: {
    id: GearId.Sunglasses,
    name: "Sunglasses",
    category: GearCategoryId.Clothing,
    description: "DR 1 for eyes",
    cost: 10.0,
    weight: 0.0,
    passiveDefense: 0,
    damageResistance: 1,
  },
  [GearId.Sweater]: {
    id: GearId.Sweater,
    name: "Sweater",
    category: GearCategoryId.Clothing,
    description: "Thick wool",
    cost: 10.0,
    weight: 3.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.TrenchcoatLeather]: {
    id: GearId.TrenchcoatLeather,
    name: "Trenchcoat Leather",
    category: GearCategoryId.Clothing,
    description: "PD 1, DR 1. Oiled leather serves as light rain gear and is +4 to Holdout skill",
    cost: 25.0,
    weight: 6.0,
    passiveDefense: 1,
    damageResistance: 1,
  },
  [GearId.Underwear]: {
    id: GearId.Underwear,
    name: "Underwear",
    category: GearCategoryId.Clothing,
    description: "Style can vary quite a bit",
    cost: 0.25,
    weight: 0.5,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.UniformMilitary]: {
    id: GearId.UniformMilitary,
    name: "Uniform Military",
    category: GearCategoryId.Clothing,
    description: "Cotton or wool pants and shirt, field or service cap, boots, socks, underwear",
    cost: 15.0,
    weight: 8.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.Windcheater]: {
    id: GearId.Windcheater,
    name: "Windcheater",
    category: GearCategoryId.Clothing,
    description: "Light jacket",
    cost: 20.0,
    weight: 3.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.BreastplateConcealable]: {
    id: GearId.BreastplateConcealable,
    name: "Breastplate Concealable",
    category: GearCategoryId.ArmorAndRelated,
    description: "PD 4, DR 10, covers the front only. A few Japanese infantry wore a nonconcealable version",
    cost: 75.0,
    weight: 12.0,
    passiveDefense: 4,
    damageResistance: 10,
  },
  [GearId.FlakJacket]: {
    id: GearId.FlakJacket,
    name: "Flak Jacket",
    category: GearCategoryId.ArmorAndRelated,
    description: "Some Japanese infantry units were issued these bulky vests. PD 2, DR 4",
    cost: 15.0,
    weight: 9.0,
    passiveDefense: 2,
    damageResistance: 4,
  },
  [GearId.FlakVestAircrew]: {
    id: GearId.FlakVestAircrew,
    name: "Flak Vest Aircrew",
    category: GearCategoryId.ArmorAndRelated,
    description: "U.S. issue. PD 4, DR 7",
    cost: 50.0,
    weight: 20.0,
    passiveDefense: 4,
    damageResistance: 7,
  },
  [GearId.GasCape]: {
    id: GearId.GasCape,
    name: "Gas Cape",
    category: GearCategoryId.ArmorAndRelated,
    description: "German-issue, a specially treated poncho that limits gas damage to wearer’s skin",
    cost: 20.0,
    weight: 2.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.HeatedSuit]: {
    id: GearId.HeatedSuit,
    name: "Heated Suit",
    category: GearCategoryId.ArmorAndRelated,
    description: "PD 1, DR 1. Worn under clothes and plugged into vehicle, adds up to 40o",
    cost: 40.0,
    weight: 4.0,
    passiveDefense: 1,
    damageResistance: 1,
  },
  [GearId.HelmetBritish]: {
    id: GearId.HelmetBritish,
    name: "Helmet British",
    category: GearCategoryId.ArmorAndRelated,
    description: "The wide brim offsets -1 in vision penalties for rain or bright light. PD 2, DR 4",
    cost: 7.0,
    weight: 4.0,
    passiveDefense: 2,
    damageResistance: 4,
  },
  [GearId.HelmetCrash]: {
    id: GearId.HelmetCrash,
    name: "Helmet Crash",
    category: GearCategoryId.ArmorAndRelated,
    description: "PD 2, DR 3 leather with goggles and headset, often worn by tank crews",
    cost: 40.0,
    weight: 4.0,
    passiveDefense: 2,
    damageResistance: 3,
  },
  [GearId.HelmetGerman]: {
    id: GearId.HelmetGerman,
    name: "Helmet German",
    category: GearCategoryId.ArmorAndRelated,
    description: "Line infantry wore this PD 4, DR 4 M1935 helm. (",
    cost: 6.0,
    weight: 4.0,
    passiveDefense: 4,
    damageResistance: 4,
  },
  [GearId.HelmetFlight]: {
    id: GearId.HelmetFlight,
    name: "Helmet Flight",
    category: GearCategoryId.ArmorAndRelated,
    description: "PD 2, DR 2 leather with goggles, headphones, and oxygen-mask fittings, worn by aviators of most nations",
    cost: 45.0,
    weight: 3.0,
    passiveDefense: 2,
    damageResistance: 2,
  },
  [GearId.HelmetJapanese]: {
    id: GearId.HelmetJapanese,
    name: "Helmet Japanese",
    category: GearCategoryId.ArmorAndRelated,
    description: "Much like U.S. helmet, but made of cheap, brittle metal. PD 3, DR 3",
    cost: 1.0,
    weight: 4.0,
    passiveDefense: 3,
    damageResistance: 3,
  },
  [GearId.BatteryACell]: {
    id: GearId.BatteryACell,
    name: "Battery A Cell",
    category: GearCategoryId.FieldGear,
    description: "Holds 10 kWs, nonrechargeable",
    cost: 0.1,
    weight: 0.2,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.BatteryDCell]: {
    id: GearId.BatteryDCell,
    name: "Battery D Cell",
    category: GearCategoryId.FieldGear,
    description: "Holds 13 kWs, nonrechargeable",
    cost: 0.15,
    weight: 0.3,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.BlanketWool]: {
    id: GearId.BlanketWool,
    name: "Blanket Wool",
    category: GearCategoryId.FieldGear,
    description: "Very warm",
    cost: 2.0,
    weight: 4.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.BlanketFlannel]: {
    id: GearId.BlanketFlannel,
    name: "Blanket Flannel",
    category: GearCategoryId.FieldGear,
    description: "Veterans use it and a body bag as a sleeping bag",
    cost: 0.35,
    weight: 2.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.CigaretteLighter]: {
    id: GearId.CigaretteLighter,
    name: "Cigarette Lighter",
    category: GearCategoryId.FieldGear,
    description: "A Zippo",
    cost: 1.0,
    weight: 0.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.ClimbingGear]: {
    id: GearId.ClimbingGear,
    name: "Climbing Gear",
    category: GearCategoryId.FieldGear,
    description: "Rock hammer (",
    cost: 2.0,
    weight: 4.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.Converter]: {
    id: GearId.Converter,
    name: "Converter",
    category: GearCategoryId.FieldGear,
    description: "Runs battery-driven devices off household current or vice versa",
    cost: 10.0,
    weight: 0.7,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.Cricket]: {
    id: GearId.Cricket,
    name: "Cricket",
    category: GearCategoryId.FieldGear,
    description: "A toy noise-maker used for identification",
    cost: 0.05,
    weight: 0.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.EntrenchingTool]: {
    id: GearId.EntrenchingTool,
    name: "Entrenching Tool",
    category: GearCategoryId.FieldGear,
    description: "Small folding shovel that also can serve as a pick. Uses Axe/Mace skill -2 in combat, doing swing-1 cutting",
    cost: 2.0,
    weight: 3.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.FlashlightSmall]: {
    id: GearId.FlashlightSmall,
    name: "Flashlight Small",
    category: GearCategoryId.FieldGear,
    description: "15’ beam, small battery lasts 2 hours",
    cost: 1.0,
    weight: 0.5,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.FlashlightLarge]: {
    id: GearId.FlashlightLarge,
    name: "Flashlight Large",
    category: GearCategoryId.FieldGear,
    description: "30’ beam, large battery lasts 5 hours",
    cost: 2.0,
    weight: 1.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.FlashlightLensFilters]: {
    id: GearId.FlashlightLensFilters,
    name: "Flashlight Lens Filters",
    category: GearCategoryId.FieldGear,
    description: "Change color of beam for signaling",
    cost: 1.0,
    weight: 0.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.GeneratorHandcranked]: {
    id: GearId.GeneratorHandcranked,
    name: "Generator Handcranked",
    category: GearCategoryId.FieldGear,
    description: "Provides 0.01 kWs per turn with one operator; can recharge batteries",
    cost: 5.0,
    weight: 10.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.Grapnel]: {
    id: GearId.Grapnel,
    name: "Grapnel",
    category: GearCategoryId.FieldGear,
    description: "Supports 300 lbs",
    cost: 5.0,
    weight: 4.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.Handcuffs]: {
    id: GearId.Handcuffs,
    name: "Handcuffs",
    category: GearCategoryId.FieldGear,
    description: "ST 18",
    cost: 10.0,
    weight: 1.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.Lockpicks]: {
    id: GearId.Lockpicks,
    name: "Lockpicks",
    category: GearCategoryId.FieldGear,
    description: "$5, neg., or +1 to skill for $50. Map Case – Waterproof",
    cost: 5.0,
    weight: 0.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.Maps]: {
    id: GearId.Maps,
    name: "Maps",
    category: GearCategoryId.FieldGear,
    description: "Military-issue set",
    cost: 50.0,
    weight: 2.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.MessKitPersonal]: {
    id: GearId.MessKitPersonal,
    name: "Mess Kit Personal",
    category: GearCategoryId.FieldGear,
    description: "Plate, can opener, fork, knife, cup, and spoon",
    cost: 1.0,
    weight: 2.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.ARation]: {
    id: GearId.ARation,
    name: "A-Ration",
    category: GearCategoryId.Food,
    description: "Includes meat, vegetables, fruit, etc",
    cost: 0.35,
    weight: 2.4,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.BreadandHam]: {
    id: GearId.BreadandHam,
    name: "Bread and Ham",
    category: GearCategoryId.Food,
    description: "1-lb. loaf of bread and 1 lb. ham. Goes bad quickly, but makes three meals",
    cost: 0.3,
    weight: 2.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.DRation]: {
    id: GearId.DRation,
    name: "D-  Ration",
    category: GearCategoryId.Food,
    description: "One concentrated meal (chocolate, oats, sausage, etc.) for emergency use",
    cost: 1.0,
    weight: 0.5,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.RiceUncooked]: {
    id: GearId.RiceUncooked,
    name: "Rice Uncooked",
    category: GearCategoryId.Food,
    description: "Each meal is",
    cost: 0.05,
    weight: 0.7,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.CartridgeBelt]: {
    id: GearId.CartridgeBelt,
    name: "Cartridge Belt",
    category: GearCategoryId.WeaponAccessories,
    description: "Holds 25 rounds, worn on waist or shoulder",
    cost: 1.5,
    weight: 0.5,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.Condom]: {
    id: GearId.Condom,
    name: "Condom",
    category: GearCategoryId.WeaponAccessories,
    description: "Keeps weapon muzzle dry, among other uses. Five for",
    cost: 1.0,
    weight: 0.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.GunCleaningKit]: {
    id: GearId.GunCleaningKit,
    name: "Gun Cleaning Kit",
    category: GearCategoryId.WeaponAccessories,
    description: "Fits any small arm",
    cost: 5.0,
    weight: 3.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.GunCleaningKitSmall]: {
    id: GearId.GunCleaningKitSmall,
    name: "Gun Cleaning Kit Small",
    category: GearCategoryId.WeaponAccessories,
    description: "Fits a specific model of small arm",
    cost: 2.0,
    weight: 1.5,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.WebGear]: {
    id: GearId.WebGear,
    name: "Web Gear",
    category: GearCategoryId.WeaponAccessories,
    description: "Belt and suspenders with plenty of pouches and fasteners for ammo clips, canteens, etc",
    cost: 3.0,
    weight: 5.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.Parachute]: {
    id: GearId.Parachute,
    name: "Parachute",
    category: GearCategoryId.WeaponAccessories,
    description: "Used to survive jumping out of an aircraft. It takes 25-DX seconds to exit the craft unless in an open or external seat. The chute opens after 80 yards of falling, then descends at 5 yards per second. U.S. version with reserve chute is",
    cost: 100.0,
    weight: 30.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.PersonalBasics]: {
    id: GearId.PersonalBasics,
    name: "Personal Basics",
    category: GearCategoryId.WeaponAccessories,
    description: "Soap, razor, toothbrush, notebook, pencil, handkerchief, wallet, ID disc or dog tags, paybook and other papers",
    cost: 5.0,
    weight: 1.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.RappellingRope200]: {
    id: GearId.RappellingRope200,
    name: "Rappelling Rope 200’",
    category: GearCategoryId.WeaponAccessories,
    description: "Holds 600 lbs",
    cost: 20.0,
    weight: 10.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.RappellingHarness]: {
    id: GearId.RappellingHarness,
    name: "Rappelling Harness",
    category: GearCategoryId.WeaponAccessories,
    description: "Allows quicker and easier climbing",
    cost: 10.0,
    weight: 4.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.RoadFlare]: {
    id: GearId.RoadFlare,
    name: "Road Flare",
    category: GearCategoryId.WeaponAccessories,
    description: "Burns for 30 minutes, +2 to Vision rolls to see it",
    cost: 1.0,
    weight: 1.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.SleepingBag]: {
    id: GearId.SleepingBag,
    name: "Sleeping Bag",
    category: GearCategoryId.WeaponAccessories,
    description: "Standard type",
    cost: 20.0,
    weight: 8.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.ThermosBottle]: {
    id: GearId.ThermosBottle,
    name: "Thermos Bottle",
    category: GearCategoryId.WeaponAccessories,
    description: "Keeps 1 pint hot for 24 hours, or cold for 72",
    cost: 1.0,
    weight: 2.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.Towel]: {
    id: GearId.Towel,
    name: "Towel",
    category: GearCategoryId.WeaponAccessories,
    description: "Always useful",
    cost: 0.35,
    weight: 1.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.Typewriter]: {
    id: GearId.Typewriter,
    name: "Typewriter",
    category: GearCategoryId.WeaponAccessories,
    description: "Manual",
    cost: 50.0,
    weight: 10.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.WaterBasinFolding]: {
    id: GearId.WaterBasinFolding,
    name: "Water Basin Folding",
    category: GearCategoryId.WeaponAccessories,
    description: "Rubberized canvas bowl holds two quarts",
    cost: 1.0,
    weight: 0.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.WindUpAlarmClock]: {
    id: GearId.WindUpAlarmClock,
    name: "Wind-Up Alarm Clock",
    category: GearCategoryId.WeaponAccessories,
    description: "Loud",
    cost: 2.0,
    weight: 1.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.WristwatchMilitary]: {
    id: GearId.WristwatchMilitary,
    name: "Wristwatch Military",
    category: GearCategoryId.WeaponAccessories,
    description: "Wind-up, waterresistant, luminous dial",
    cost: 5.0,
    weight: 0.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.AmmoCan]: {
    id: GearId.AmmoCan,
    name: "Ammo Can",
    category: GearCategoryId.Storage,
    description: "DR 2 metal, holds 1 cf or 50 lbs",
    cost: 0.2,
    weight: 5.0,
    passiveDefense: 0,
    damageResistance: 2,
  },
  [GearId.DuffelBag]: {
    id: GearId.DuffelBag,
    name: "Duffel Bag",
    category: GearCategoryId.Storage,
    description: "Heavy duck cloth, holds 4 cf or 120 lbs. of gear",
    cost: 3.0,
    weight: 2.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.FiftyGallonDrum]: {
    id: GearId.FiftyGallonDrum,
    name: "50-Gallon Drum",
    category: GearCategoryId.Storage,
    description: "DR 2, holds 7.5 cf or 450 lbs",
    cost: 2.0,
    weight: 54.0,
    passiveDefense: 0,
    damageResistance: 2,
  },
  [GearId.IceBox]: {
    id: GearId.IceBox,
    name: "Ice Box",
    category: GearCategoryId.Storage,
    description: "Holds 10 lbs. of ice and 1 cf of cargo; ice lasts one day",
    cost: 5.0,
    weight: 20.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.JerryCan]: {
    id: GearId.JerryCan,
    name: "Jerry Can",
    category: GearCategoryId.Storage,
    description: "DR 3, holds 0.8 cf (5 gallons) or 45 lbs",
    cost: 0.5,
    weight: 8.8,
    passiveDefense: 0,
    damageResistance: 3,
  },
  [GearId.WaterPail]: {
    id: GearId.WaterPail,
    name: "Water Pail",
    category: GearCategoryId.Storage,
    description: "DR 2, holds 0.5 cf (3 gallons) or 25 lbs",
    cost: 0.15,
    weight: 3.0,
    passiveDefense: 0,
    damageResistance: 2,
  },
  [GearId.BangaloreTorpedo]: {
    id: GearId.BangaloreTorpedo,
    name: "Bangalore Torpedo",
    category: GearCategoryId.ExplosivesAndRelated,
    description: "TNT-packed 5’ tube used to clear a 2’-wide path through barbed wire and antipersonnel mines; can be linked together and usually issued in lots of 10. Does 6d×18 per 5’ tube",
    cost: 35.0,
    weight: 13.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.ContactWire500]: {
    id: GearId.ContactWire500,
    name: "Contact Wire 500’",
    category: GearCategoryId.ExplosivesAndRelated,
    description: "Wire on a hand spool, used with plunger and detonator caps to set off explosives",
    cost: 5.0,
    weight: 3.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.DetonatorCaps50]: {
    id: GearId.DetonatorCaps50,
    name: "Detonator Caps 50",
    category: GearCategoryId.ExplosivesAndRelated,
    description: "Used to set off explosive charges, each does 1d-3 damage itself",
    cost: 10.0,
    weight: 2.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.DetonatorTrip]: {
    id: GearId.DetonatorTrip,
    name: "Detonator Trip",
    category: GearCategoryId.ExplosivesAndRelated,
    description: "Has 3-yard tripwire. Requires Vision -3 roll if Camouflage roll is made",
    cost: 0.25,
    weight: 0.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.DynamiteMilitary]: {
    id: GearId.DynamiteMilitary,
    name: "Dynamite Military",
    category: GearCategoryId.ExplosivesAndRelated,
    description: "A waterproof stick of TNT doing 6d×2",
    cost: 2.0,
    weight: 1.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.Exploder10Cap]: {
    id: GearId.Exploder10Cap,
    name: "Exploder 10-Cap",
    category: GearCategoryId.ExplosivesAndRelated,
    description: "Twisting handle sets off up to 10 detonator caps",
    cost: 10.0,
    weight: 8.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.SatchelCharge]: {
    id: GearId.SatchelCharge,
    name: "Satchel Charge",
    category: GearCategoryId.ExplosivesAndRelated,
    description: "A knapsack with 20 lbs. of TNT (6d×40) and a 15-second ring-pull fuse",
    cost: 60.0,
    weight: 24.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.CargoNet]: {
    id: GearId.CargoNet,
    name: "Cargo Net",
    category: GearCategoryId.ToolsAndHeavyGear,
    description: "10’ × 10’",
    cost: 3.0,
    weight: 13.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.Cot]: {
    id: GearId.Cot,
    name: "Cot",
    category: GearCategoryId.ToolsAndHeavyGear,
    description: "Folding Army issue",
    cost: 5.0,
    weight: 25.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.FlashlightDynamo]: {
    id: GearId.FlashlightDynamo,
    name: "Flashlight Dynamo",
    category: GearCategoryId.ToolsAndHeavyGear,
    description: "15’ beam, powered by hand pump",
    cost: 5.0,
    weight: 1.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.HydraulicJack]: {
    id: GearId.HydraulicJack,
    name: "Hydraulic Jack",
    category: GearCategoryId.ToolsAndHeavyGear,
    description: "Lifts 10 tons",
    cost: 15.0,
    weight: 60.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.ToolKitSmall]: {
    id: GearId.ToolKitSmall,
    name: "Tool Kit Small",
    category: GearCategoryId.ToolsAndHeavyGear,
    description: "Must specify as for Tool Kit. Major repairs take a -2 penalty. Electronics kits are",
    cost: 90.0,
    weight: 10.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.Mattock]: {
    id: GearId.Mattock,
    name: "Mattock",
    category: GearCategoryId.ToolsAndHeavyGear,
    description: "Improves digging speed",
    cost: 0.9,
    weight: 6.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.SaddleandTack]: {
    id: GearId.SaddleandTack,
    name: "Saddle and Tack",
    category: GearCategoryId.ToolsAndHeavyGear,
    description: "Outfits one cavalry horse",
    cost: 75.0,
    weight: 50.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.Saddlebags]: {
    id: GearId.Saddlebags,
    name: "Saddlebags",
    category: GearCategoryId.ToolsAndHeavyGear,
    description: "Hold 20 lbs",
    cost: 10.0,
    weight: 3.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.Shovel]: {
    id: GearId.Shovel,
    name: "Shovel",
    category: GearCategoryId.ToolsAndHeavyGear,
    description: "Speeds up digging",
    cost: 1.0,
    weight: 4.5,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.Welder]: {
    id: GearId.Welder,
    name: "Welder",
    category: GearCategoryId.ToolsAndHeavyGear,
    description: "Fuses steel or cast iron",
    cost: 200.0,
    weight: 350.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.Ammonia5Capsules]: {
    id: GearId.Ammonia5Capsules,
    name: "Ammonia 5 Capsules",
    category: GearCategoryId.Medical,
    description: "Inhalant gives HT roll to revive a stunned or unconscious person",
    cost: 0.25,
    weight: 0.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.Amphetamines30Pills]: {
    id: GearId.Amphetamines30Pills,
    name: "Amphetamines 30 Pills",
    category: GearCategoryId.Medical,
    description: "In fairly common use; see p. CII163",
    cost: 5.0,
    weight: 0.1,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.FootPowder1Can]: {
    id: GearId.FootPowder1Can,
    name: "Foot Powder 1 Can",
    category: GearCategoryId.Medical,
    description: "Gives +2 to resist trenchfoot or other fungus; lasts one week",
    cost: 0.85,
    weight: 0.3,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.Penicillin5Packets]: {
    id: GearId.Penicillin5Packets,
    name: "Penicillin 5 Packets",
    category: GearCategoryId.Medical,
    description: "As for Sulfa Powder but very rare until 1943. Also cures existing infections in 1d days (2d days if the patient critically fails a HT roll)",
    cost: 25.0,
    weight: 0.2,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.SulfaPowder5Packets]: {
    id: GearId.SulfaPowder5Packets,
    name: "Sulfa Powder 5 Packets",
    category: GearCategoryId.Medical,
    description: "Sprinkled on a wound, this prevents an infection unless the First Aid or Physician roll critically fails. Only available to Anglo-American forces",
    cost: 1.0,
    weight: 0.2,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.Sulfa30Tablets]: {
    id: GearId.Sulfa30Tablets,
    name: "Sulfa 30 Tablets",
    category: GearCategoryId.Medical,
    description: "As for Powder, but taken orally",
    cost: 2.0,
    weight: 0.1,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.Aspirin100Tablets]: {
    id: GearId.Aspirin100Tablets,
    name: "Aspirin 100 Tablets",
    category: GearCategoryId.Medical,
    description: "Good for minor pain and hangovers",
    cost: 0.45,
    weight: 0.4,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.MalarialPills30]: {
    id: GearId.MalarialPills30,
    name: "Malarial Pills 30",
    category: GearCategoryId.Medical,
    description: "One-month supply gives +2 to avoid malaria, but each morning roll vs. HT+2 or have nausea/vomiting",
    cost: 5.0,
    weight: 0.1,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.Morphine5Syrettes]: {
    id: GearId.Morphine5Syrettes,
    name: "Morphine 5 Syrettes",
    category: GearCategoryId.Medical,
    description: "See p. CII166",
    cost: 5.0,
    weight: 0.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.PlasmaKitSerum]: {
    id: GearId.PlasmaKitSerum,
    name: "Plasma Kit Serum",
    category: GearCategoryId.Medical,
    description: "Good for one use, with tubing and needle, provides +1 to First Aid all by itself",
    cost: 4.0,
    weight: 4.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.SaltTablets30]: {
    id: GearId.SaltTablets30,
    name: "Salt Tablets 30",
    category: GearCategoryId.Medical,
    description: "Two a day provide a +1 to HT rolls against dehydration or Fatigue loss due to heat",
    cost: 1.0,
    weight: 0.1,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.WaterPurificationTablets50]: {
    id: GearId.WaterPurificationTablets50,
    name: "Water Purification Tablets 50",
    category: GearCategoryId.Medical,
    description: "Purify 1 quart each",
    cost: 0.25,
    weight: 0.2,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.DoctorsAidKit]: {
    id: GearId.DoctorsAidKit,
    name: "Doctor’s Aid Kit",
    category: GearCategoryId.AidKits,
    description: "As Medic’s kit, plus +2 to Surgery, with Physician and Diagnosis at no penalty",
    cost: 30.0,
    weight: 15.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.FirstAidKitBasic]: {
    id: GearId.FirstAidKitBasic,
    name: "First-Aid Kit Basic",
    category: GearCategoryId.AidKits,
    description: "+1 to First Aid; includes two large compress bandages, five packs of sulfa powder, and bottle of 30 sulfa tablets",
    cost: 3.0,
    weight: 2.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.FirstAidKitMedics]: {
    id: GearId.FirstAidKitMedics,
    name: "First-Aid Kit Medic’s",
    category: GearCategoryId.AidKits,
    description: "+2 to First Aid; as two Basic kits, plus five each ammonia capsules and morphine syrettes, 30 salt tablets, and a plasma serum kit",
    cost: 10.0,
    weight: 10.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.SurgicalKit]: {
    id: GearId.SurgicalKit,
    name: "Surgical Kit",
    category: GearCategoryId.AidKits,
    description: "Allows Surgery skill at no penalty",
    cost: 7.0,
    weight: 2.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.Camera35mm]: {
    id: GearId.Camera35mm,
    name: "Camera 35mm",
    category: GearCategoryId.CommunicationsAndElectronics,
    description: "Extra film is 32 shots,",
    cost: 1.0,
    weight: 0.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.CameraSpy]: {
    id: GearId.CameraSpy,
    name: "Camera Spy",
    category: GearCategoryId.CommunicationsAndElectronics,
    description: "Takes 50 shots on microfilm, +4 to Holdout",
    cost: 250.0,
    weight: 0.2,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.DeveloperKit]: {
    id: GearId.DeveloperKit,
    name: "Developer Kit",
    category: GearCategoryId.CommunicationsAndElectronics,
    description: "Develops 1 photo per minute, needs complete darkness and red light",
    cost: 30.0,
    weight: 40.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.FieldPhone]: {
    id: GearId.FieldPhone,
    name: "Field Phone",
    category: GearCategoryId.CommunicationsAndElectronics,
    description: "Telephone for use with communications wires, which must be routed through a switchboard that runs the whole process",
    cost: 15.0,
    weight: 3.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.Megaphone]: {
    id: GearId.Megaphone,
    name: "Megaphone",
    category: GearCategoryId.CommunicationsAndElectronics,
    description: "+5 to hear user if pointed within 30o of listener",
    cost: 4.0,
    weight: 3.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.MovieCamera]: {
    id: GearId.MovieCamera,
    name: "Movie Camera",
    category: GearCategoryId.CommunicationsAndElectronics,
    description: "8mm, with an 8-minute reel (spares",
    cost: 5.0,
    weight: 1.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.TelegraphKey]: {
    id: GearId.TelegraphKey,
    name: "Telegraph Key",
    category: GearCategoryId.CommunicationsAndElectronics,
    description: "For using Morse code via phone line or radio",
    cost: 5.0,
    weight: 3.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.ClimbingSpikes]: {
    id: GearId.ClimbingSpikes,
    name: "Climbing Spikes",
    category: GearCategoryId.PoliceAndSpyTools,
    description: "+2 to some Climbing rolls. Also used by mountain troops and Japanese snipers",
    cost: 5.0,
    weight: 1.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
  [GearId.CriminologyKit]: {
    id: GearId.CriminologyKit,
    name: "Criminology Kit",
    category: GearCategoryId.PoliceAndSpyTools,
    description: "Portable forensics lab",
    cost: 300.0,
    weight: 6.0,
    passiveDefense: 0,
    damageResistance: 0,
  },
};
