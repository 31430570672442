import React from 'react';
import {Col, Table} from 'react-bootstrap';
import {Character} from "../../../../lib/constants/characterConstants";
import {CombatPageProps} from "../CombatPage";
import {positionDefinitions} from "../../../../lib/constants/combatConstants";
import {CombatState, GunReadyStatus, MAX_AIM, ReadyStatusUnion} from "../../../../modules/combat";
import {
  getAttributeCurrentValue,
  getModifiedCharacteristicValue
} from "../../../../lib/gameLogic/derivedCharacterStats";
import {AttributeId} from "../../../../lib/constants/attributeConstants";
import {getAttackLabel, getReadiedWeaponId} from "../../../../lib/gameLogic/derivedCombatStats";
import {
  getWeaponTextStatus,
  Weapon,
  WeaponDefinition,
  weaponDefinitions,
  WeaponType
} from "../../../../lib/constants/weaponConstants";
import {CharacterConditionArray} from "../../../../lib/components/CharacterConditionArray";

export class CombatStatusPanel extends React.Component<CombatPageProps> {
  render() {
    let {
      character,
      combat,
    } = this.props;

    return <Col md={4} className='content-panel scrollable full-height'>
      <Table responsive={'sm'} className='detail-table'>
        <thead>
          <tr>
            <th/>
            <th/>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Hit Points:</td>
            <td>{getModifiedCharacteristicValue(getAttributeCurrentValue(AttributeId.Health, character, combat))}</td>
          </tr>
          <tr>
            <td>Status:</td>
            <td><CharacterConditionArray character={character} combat={combat} /></td>
          </tr>
          <tr>
            <td>Position:</td>
            <td>{positionDefinitions[combat.position].name}</td>
          </tr>
          {this.renderWeaponStuff()}
        </tbody>
      </Table>
    </Col>
  }

  renderWeaponStuff() {
    let {
      character,
      combat,
    } = this.props;

    let weaponId = getReadiedWeaponId(combat);
    if (!combat.weaponReadyStatus || !weaponId) {
      return <>
        <tr>
          <td>Weapon:</td>
          <td>No Weapon Ready</td>
        </tr>
      </>
    }

    let readiedWeaponDefinition = weaponDefinitions[weaponId];
    let readiedWeapon = character.inventory[weaponId] as Weapon;

    return <>
      <tr>
        <td>Weapon:</td>
        <td>{readiedWeaponDefinition.name}</td>
      </tr>
      <tr>
        <td>Ammo:</td>
        <td>{readiedWeapon.shotsLoaded}/{readiedWeaponDefinition.shotsPerReload}</td>
      </tr>
      {/*If the weapon is jammed, display how long it will take to repair*/}
      {readiedWeapon.needsMalfunctionRoll &&
        <tr>
          <td>Jammed</td>
          <td></td>
        </tr>
      }
      {readiedWeapon.malfunctionTurns > 0 &&
          <tr>
            <td>Turns to Clear Jam:</td>
            <td>{readiedWeapon.malfunctionTurns}</td>
          </tr>
      }
      {this.renderWeaponReadyDetails(readiedWeaponDefinition, character, combat)}
    </>;
  }

  renderWeaponReadyDetails(readiedWeapon: WeaponDefinition, character: Character, combat: CombatState) {
    let readyStatusAbstract = combat.weaponReadyStatus;
    if (!readyStatusAbstract) {
      return null;
    }
    return <>
      <tr>
        <td>Ready Status:</td>
        <td>{getWeaponTextStatus(character, readiedWeapon.id, readyStatusAbstract)}</td>
      </tr>
      <tr>
        <td>Attacks:</td>
        <td>{getAttackLabel(combat, readiedWeapon.id)}</td>
      </tr>
      {this.renderTypeSpecificWeaponReadyDetails(readiedWeapon, readyStatusAbstract, character, combat)}
    </>;
  }

  renderTypeSpecificWeaponReadyDetails(readiedWeapon: WeaponDefinition, readyStatus: ReadyStatusUnion, character: Character, combat: CombatState) {
    switch (readyStatus.type) {
      case WeaponType.Guns:
        readyStatus = readyStatus as GunReadyStatus;
        return <>
          {readyStatus.aim > 0 &&
            <tr>
              <td>Aim:</td>
              <td>{readyStatus.aim}/{MAX_AIM}</td>
            </tr>
          }
        </>;
      case WeaponType.HandWeapons:
        return <>
        </>;
    }
  }
}

