import React from 'react';
import {Button, Col, Form, FormControlProps, InputGroup, ListGroup,} from 'react-bootstrap';
import {getObjectHash, ghettoCloneObject} from "../../../../lib/utils";
import {CenteredRow} from "../../../../lib/components/CenteredRow";
import {CharacteristicDefinition, CharacteristicId} from "../../../../lib/constants/characteristicConstants";
import {
    editCharacteristic,
    getCharacteristicFromId,
    getCharacteristicInstance,
    getCharacterTotalPoints,
    getLevelDetails
} from "../../../../lib/gameLogic/derivedCharacterStats";
import {EditorPageProps} from "../CharacterEditorPage";
import {attributeDefinitions, AttributeId} from "../../../../lib/constants/attributeConstants";
import {Character} from "../../../../lib/constants/characterConstants";
import {vantageDefinitions} from "../../../../lib/constants/vantageConstants";
import {skillDefinitions} from "../../../../lib/constants/skillConstants";
import {CenteredFlexCol} from "../../../../lib/components/CenteredFlexCol";
import {CenteredFlexDiv} from "../../../../lib/components/CenteredFlexDiv";
import {CharacteristicListItem} from "./subpanels/CharacteristicListItem";

interface State {
    selectedCharacteristic: CharacteristicId;
    workingCharacter: Character;
    searchTerm: string;
    onlyDisplayTaken: boolean;
}

const characteristicDefinitions: Array<CharacteristicDefinition> = [
    ...Object.values(attributeDefinitions),
    ...Object.values(vantageDefinitions),
    ...Object.values(skillDefinitions),
];

const HEADER_TEXT_COLUMN_PROPS = {
    sm: 6,
    lg: 3,
};

export class CharacterEditorPanel extends React.Component<EditorPageProps, State> {
    constructor(props: EditorPageProps) {
        super(props);
        this.state = {
            selectedCharacteristic: AttributeId.Health,
            workingCharacter: ghettoCloneObject(props.character.character),
            searchTerm: '',
            onlyDisplayTaken: false,
        };
    }

    characteristicClick(id: CharacteristicId) {
        this.setState({
            selectedCharacteristic: id,
        });
    }

    saveClick() {
        let {
            loadCharacter,
        } = this.props;
        let {
            workingCharacter,
        } = this.state;

        workingCharacter.totalPoints = getCharacterTotalPoints(workingCharacter);
        loadCharacter(ghettoCloneObject(workingCharacter));
    }

    revertClick() {
        let {
            character: {
                character,
            },
        } = this.props;
        this.setState({
            workingCharacter: ghettoCloneObject(character),
        });
    }

    render() {
        let {
            editor,
            character: {
                character,
            },
        } = this.props;
        let {
            workingCharacter,
            selectedCharacteristic,
            searchTerm,
            onlyDisplayTaken
        } = this.state;

        let lowerSearchTerm = searchTerm.toLowerCase();
        let filteredCharacteristics = characteristicDefinitions;
        if (searchTerm) {
            filteredCharacteristics = filteredCharacteristics.filter(def => def.name.toLowerCase().includes(lowerSearchTerm));
        }
        if (onlyDisplayTaken) {
            filteredCharacteristics = filteredCharacteristics.filter(def => !!getCharacteristicInstance(def.id, workingCharacter));
        }

        let workingCharacterHash = getObjectHash(workingCharacter);
        let propsCharacterHash = getObjectHash(character);

        return <Col className='content-panel'>
            <CenteredRow className='full-width header-row'>
                <CenteredFlexCol {...HEADER_TEXT_COLUMN_PROPS}>
                    <h4 style={{padding: '.5em'}}>Character:</h4>
                </CenteredFlexCol>
                <CenteredFlexCol {...HEADER_TEXT_COLUMN_PROPS}>
                    <InputGroup className="mb-3 flex-textbox">
                        <Form.Control
                            className=''
                            value={workingCharacter.name}
                            onChange={(e: React.FormEvent<FormControlProps>) => {
                                let rawVal = e.currentTarget.value;
                                if (rawVal) {
                                    this.setState({
                                        workingCharacter: {
                                            ...workingCharacter,
                                            name: rawVal,
                                        },
                                    });
                                }
                            }}
                        />
                    </InputGroup>
                </CenteredFlexCol>
                <CenteredFlexCol {...HEADER_TEXT_COLUMN_PROPS}>
                    <Button
                        variant='primary'
                        disabled={workingCharacterHash === propsCharacterHash}
                        onClick={this.saveClick.bind(this)}
                    >Save</Button>
                    <Button
                        variant='primary'
                        disabled={workingCharacterHash === propsCharacterHash}
                        onClick={this.revertClick.bind(this)}
                    >Revert</Button>
                </CenteredFlexCol>
            </CenteredRow>
            <CenteredRow className='main-row'>
                <CenteredFlexCol className='' isRow={false} sm={6}>
                    <CenteredFlexDiv className='full-width'>
                        <InputGroup className="mb-3 flex-textbox">
                            <Form.Control
                                className=''
                                placeholder='Search Characteristics...'
                                value={this.state.searchTerm}
                                onChange={(e: React.FormEvent<FormControlProps>) => {
                                    let rawVal = e.currentTarget.value;
                                    if (rawVal !== undefined && rawVal !== null) {
                                        this.setState({
                                            searchTerm: rawVal,
                                        });
                                    }
                                }}
                            />
                        </InputGroup>
                        <InputGroup className="mb-3" style={{width: '10em'}}>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    Only Taken
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <InputGroup.Checkbox
                                type="checkbox"
                                checked={this.state.onlyDisplayTaken}
                                onChange={() => {
                                    this.setState({
                                        onlyDisplayTaken: !this.state.onlyDisplayTaken,
                                    });
                                }}
                            />
                        </InputGroup>
                    </CenteredFlexDiv>
                    <ListGroup className='full-width scrollable'>
                        {filteredCharacteristics.map((def) => {
                            return <div
                                key={`characteristic-container-${def.id}`}
                                onClick={this.characteristicClick.bind(this, def.id)}
                            >
                                <CharacteristicListItem
                                    workingCharacter={workingCharacter}
                                    characteristicId={def.id}
                                    active={selectedCharacteristic === def.id}
                                />
                            </div>
                        })}
                    </ListGroup>
                </CenteredFlexCol>
                <CenteredFlexCol className='' isRow={false} sm={6}>
                    {this.renderCharacteristicDetails()}
                    {this.renderActions()}
                </CenteredFlexCol>
            </CenteredRow>
        </Col>
    }

    renderCharacteristicDetails() {
        let def = getCharacteristicFromId(this.state.selectedCharacteristic);
        return <CenteredFlexDiv className='main-content-height' isRow={false}>
            <CenteredFlexDiv>
                <h5>Total Points: {getCharacterTotalPoints(this.state.workingCharacter)}</h5>
            </CenteredFlexDiv>
            <h5>{def.name}</h5>
            <div className='main-content-height scrollable'>{def.description}</div>
        </CenteredFlexDiv>;
    }

    modifyCharacteristic(differential: number) {
        let {
            selectedCharacteristic,
            workingCharacter,
        } = this.state;

        this.setState({
            workingCharacter: editCharacteristic(selectedCharacteristic, workingCharacter, differential),
        });
    }

    renderActions() {
        let {
            selectedCharacteristic,
            workingCharacter,
        } = this.state;
        let details = getLevelDetails(selectedCharacteristic, workingCharacter);
        return <CenteredFlexDiv className='header-height'>
            <Button variant='danger' disabled={!details.previousLevelCost} onClick={this.modifyCharacteristic.bind(this, -1)}>
                {details.previousLevelCost ? `Reduce: ${details.previousLevelCost}` : `Not Taken`}
            </Button>
            <h5>{details.currentLevelLabel}</h5>
            <Button variant='success' disabled={!details.nextLevelCost} onClick={this.modifyCharacteristic.bind(this, 1)}>
                {details.nextLevelCost ? `Add: ${details.nextLevelCost}` : `Maxed Out`}
            </Button>
        </CenteredFlexDiv>;
    }
}
