import React from 'react';
import {SkillDefinition, skillDefinitions, SkillId} from "../../../../lib/constants/skillConstants";
import {Option} from "../../../../lib/utils";

interface Props {
}
interface State {
    selectedSkill: Option<SkillId>;
}

const getOptionFromSkillDef = (def: SkillDefinition): Option<SkillId> => {
    return { value: def.id as SkillId, label: def.name };
};

const skillOptions = Object.values(skillDefinitions).map((def) => getOptionFromSkillDef(def));

export default class EncounterPanel extends React.Component<Props, State> {

    render() {
        return null;
    }
}

