import React, {Component} from 'react';
import {Button, ListGroup} from 'react-bootstrap';
import {positionDefinitions, PositionId} from "../../../../../../lib/constants/combatConstants";
import {CombatPageProps} from "../../../CombatPage";

interface State {
  selectedPosition: PositionId;
}

export class ChangePosition extends Component<CombatPageProps, State> {
  constructor(props: CombatPageProps) {
    super(props);
    this.state = {
      selectedPosition: props.combat.position,
    };
  }

  executeManeuver() {
    let {
      character,
      changePositionManeuver,
      addToCharacterLog,
    } = this.props;
    let positionDef = positionDefinitions[this.state.selectedPosition];
    changePositionManeuver(character, this.state.selectedPosition);
    addToCharacterLog(`Changed position to ${positionDef.name}`);
  }

  render() {
    let {
      character,
      combat
    } = this.props;
    return <>
      <ListGroup className='list-picker scrollable'>
        {this.renderPositionOptions()}
      </ListGroup>
      <Button className='sub-scrollable' variant='primary' onClick={this.executeManeuver.bind(this)}>Change Position</Button>
    </>
  }

  renderPositionOptions() {
    let positionIdOptions: Array<PositionId> = [
      PositionId.Crawling,
      PositionId.Prone,
      PositionId.Crouching,
      PositionId.Kneeling,
      PositionId.Sitting
    ];

    switch (this.props.combat.position) {
      case PositionId.Crouching:
      case PositionId.Kneeling:
      case PositionId.Sitting:
      case PositionId.Standing:
        positionIdOptions.push(PositionId.Standing);
        break;
      case PositionId.Prone:
      case PositionId.Crawling:
      default:
        break;
    }
    return positionIdOptions.map(positionId => {
      let def = positionDefinitions[positionId];
      return <ListGroup.Item
        action
        key={positionId}
        active={positionId === this.state.selectedPosition}
        onClick={() => this.setState({selectedPosition: positionId})}
      >
        {def.name}
      </ListGroup.Item>
    });
  }
}
