import React from 'react';
import {RouteComponentProps} from 'react-router-dom'
import {connect} from 'react-redux';
import StatusPage from './components/StatusPage';
import CharacterNavbar from './components/CharacterNavbar';
import SkillPage from "./components/SkillsPage";
import CombatPage from "./components/CombatPage";
import EquipmentPage from "./components/EquipmentPage";
import CharacterEditorPage from "./components/CharacterEditorPage";
import DataPage from "./components/DataPage";
import {AppState} from "../../modules";
import {bindActionCreators} from "redux";
import {ModalContainer} from "../../lib/components/ModalContainer";
import {userClose} from "../../modules/modal";

interface MatchParams {
  selectedPanel: string;
}

type Props = CharacterDisplayProps & RouteComponentProps<MatchParams>;

class CharacterDisplay extends React.Component<Props> {
  render() {
    let {
      modal,
      userClose,
    } = this.props;
    let {
      selectedPanel,
    } = this.props.match.params;

    return <>
      <CharacterNavbar/>
      <ModalContainer
          modal={modal}
          userClose={userClose}
      />
      {this.renderSelectedPanel(selectedPanel)}
    </>;
  }

  renderSelectedPanel(selectedPanel: string) {
    if (!selectedPanel) {
      return null;
    }
    switch (selectedPanel.toLowerCase()) {
      case 'status':
        return <StatusPage/>;
      case 'skill':
        return <SkillPage/>;
      case 'combat':
        return <CombatPage/>;
      case 'equipment':
        return <EquipmentPage/>;
      case 'editor':
        return <CharacterEditorPage/>;
      case 'data':
        return <DataPage/>;
      default:
        throw Error(`Undefined panel ${selectedPanel}`);
    }
  }
}

export type CharacterDisplayProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    modal: state.modalReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  userClose,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CharacterDisplay)
