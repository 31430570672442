import React, {Component} from 'react';
import {Button} from 'react-bootstrap';
import {GunReadyStatus, HandWeaponReadyStatus, ReadyStatusUnion} from "../../../../../../modules/combat";
import {
  getWeaponReadyThreshold,
  GunReadyThreshold,
  HandWeaponReadyThreshold,
  Weapon,
  WeaponDefinition,
  weaponDefinitions,
  WeaponId,
  WeaponType
} from "../../../../../../lib/constants/weaponConstants";
import {doesCharacterHaveAWeapon, getWeapons} from "../../../../../../lib/gameLogic/derivedCharacterStats";
import {getDefaultReadyStatus, getReadiedWeaponId} from "../../../../../../lib/gameLogic/derivedCombatStats";
import {Option, validateSingleReactSelectValue} from "../../../../../../lib/utils";
import {Equipment, EquipmentType, getEquipmentType} from "../../../../../../lib/constants/equipmentConstants";
import Select from 'react-select'
import {CenteredFlexDiv} from "../../../../../../lib/components/CenteredFlexDiv";
import {ValueType} from "react-select/lib/types";
import {CombatPageProps} from "../../../CombatPage";

interface State {
  selectedWeapon: WeaponId;
}

const getOptionFromEquipDef = (def: WeaponDefinition): Option<WeaponId> => {
  return { value: def.id as WeaponId, label: def.name };
};

export class Ready extends Component<CombatPageProps, State> {
  weaponOptions: Array<Option<WeaponId>>;

  constructor(props: CombatPageProps) {
    super(props);
    this.weaponOptions = (Object.values(this.props.character.inventory) as Array<Equipment>)
      .filter((equip) => getEquipmentType(equip.id) === EquipmentType.Weapon)
      .map((equip) => getOptionFromEquipDef(weaponDefinitions[equip.id as WeaponId]));
    this.state = {
      selectedWeapon: getReadiedWeaponId(props.combat) || getWeapons(props.character)[0].id,
    };
  }

  executeReady() {
    let {
      character,
      readyManeuverSaga,
    } = this.props;
    readyManeuverSaga(character, this.state.selectedWeapon);
  }

  executeStow() {
    let {
      character,
      stowWeaponManeuver,
      addToCharacterLog,
    } = this.props;
    let weaponDef = weaponDefinitions[this.state.selectedWeapon];
    addToCharacterLog(`Stowed weapon ${weaponDef.name}`);
    stowWeaponManeuver();
    // Default the picker to the first weapon
    this.setState({
      selectedWeapon: getWeapons(character)[0].id,
    });
  }

  executeDrop() {
    let {
      character,
      dropWeaponManeuver,
      removeEquipment,
      addToCharacterLog,
    } = this.props;
    let weaponDef = weaponDefinitions[this.state.selectedWeapon];
    addToCharacterLog(`Dropped weapon ${weaponDef.name}`);
    dropWeaponManeuver(this.state.selectedWeapon);
    removeEquipment(weaponDef, 1, false);
    // Default the picker to the first weapon, if it exists
    if (doesCharacterHaveAWeapon(character)) {
      this.setState({
        selectedWeapon: getWeapons(character)[0].id,
      });
    }
  }

  render() {
    let {
      character,
      combat
    } = this.props;
    let readiedWeaponId = getReadiedWeaponId(combat);

    return <CenteredFlexDiv isRow={false}>
      {/* Stow weapon and drop weapon buttons*/}
      {readiedWeaponId && this.renderButtons(readiedWeaponId)}
      {/* Weapon picker if no weapon ready*/}
      {!readiedWeaponId && this.renderWeaponPicker()}
    </CenteredFlexDiv>;
  }

  renderButtons(readiedWeaponId: WeaponId) {
    let {
      character,
      combat,
    } = this.props;
    let readyStatus = combat.weaponReadyStatus;
    if (!readyStatus) {
      return null;
    }
    let readiedWeapon = character.inventory[readiedWeaponId] as Weapon;
    let readyButtonLabel = this.getReadyButtonLabel(readiedWeapon, readyStatus);
    return <CenteredFlexDiv isRow={true}>
      {this.renderReadyButton(readyButtonLabel)}
      <Button variant='primary' onClick={this.executeStow.bind(this)}>Stow Weapon</Button>
      <Button variant='primary' onClick={this.executeDrop.bind(this)}>Drop Weapon</Button>
    </CenteredFlexDiv>;
  }

  renderReadyButton(label: string | null) {
    if (!label) {
      return null;
    }
    return <>
      <Button variant='primary' onClick={this.executeReady.bind(this)}>{label}</Button>
    </>
  }

  renderWeaponPicker() {
    let {
      character,
      combat
    } = this.props;
    let selectedWeaponId = this.state.selectedWeapon;
    let selectedWeapon = character.inventory[selectedWeaponId] as Weapon;
    let readyButtonLabel = this.getReadyButtonLabel(selectedWeapon, getDefaultReadyStatus(selectedWeaponId));
    return <CenteredFlexDiv isRow={false}>
      <Select
        className='react-select'
        value={getOptionFromEquipDef(weaponDefinitions[this.state.selectedWeapon])}
        onChange={this.selectChange.bind(this)}
        options={this.weaponOptions}
      />
      {this.renderReadyButton(readyButtonLabel)}
    </CenteredFlexDiv>;
  }

  selectChange(v: ValueType<Option<WeaponId>>) {
    let weaponOption = validateSingleReactSelectValue(v);
    this.setState({selectedWeapon: weaponOption.value})
  }

  getReadyButtonLabel(weapon: Weapon, readyStatus: ReadyStatusUnion): string | null {
    let weaponDef = weaponDefinitions[weapon.id];
    let readyThreshold = getWeaponReadyThreshold(weaponDef);

    if (readyStatus.unsling < readyThreshold.unsling) {
      return `Unsling`;
    }
    switch (readyStatus.type) {
      case WeaponType.Guns:
        let gunReadyStatus = readyStatus as GunReadyStatus;
        let gunReadyThreshold = readyThreshold as GunReadyThreshold;
        if (gunReadyStatus.cock < gunReadyThreshold.cock) {
          return `Cock`;
        }
        if (weapon.needsMalfunctionRoll) {
          return `Unjam`;
        }
        if (weapon.shotsLoaded < weaponDef.shotsPerReload) {
          return `Reload`;
        }
        return null;
      case WeaponType.HandWeapons:
        let handReadyStatus = readyStatus as HandWeaponReadyStatus;
        let handReadyThreshold = readyThreshold as HandWeaponReadyThreshold;

        if (handReadyStatus.backswing < handReadyThreshold.backswing) {
          return `Backswing`;
        }
        return null;
    }
    return null;
  };
}
