import React from 'react';
import {Button, Col, OverlayTrigger, Popover,} from 'react-bootstrap';
import {StatusPageProps} from "../StatusPage";
import {CharacterConditionArray} from "../../../../lib/components/CharacterConditionArray";
import Select from "react-select";
import {objectKeysByCallback, Option, validateSingleReactSelectValue} from "../../../../lib/utils";
import {ValueType} from "react-select/lib/types";
import {CenteredRow} from "../../../../lib/components/CenteredRow";
import {
    characterConditionEventDefinitions,
    CharacterConditionEventId
} from "../../../../lib/constants/characterConditionConstants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";

interface State {
    selectedEvent: CharacterConditionEventId;
}

const getOptionFromEventId = (id: CharacterConditionEventId): Option<CharacterConditionEventId> => {
    let def = characterConditionEventDefinitions[id];
    return {value: id as CharacterConditionEventId, label: def.name};
};

export class ConditionPanel extends React.Component<StatusPageProps, State> {
    constructor(props: StatusPageProps) {
        super(props);
        this.state = {
            selectedEvent: CharacterConditionEventId.SimpleBandaging,
        };
    }
    executeEvent() {
        let {
            conditionEventSaga,
        } = this.props;
        let {
            selectedEvent,
        } = this.state;

        conditionEventSaga(selectedEvent);
    }

    infoPopover(props: StatusPageProps) {
        let {
            selectedEvent,
        } = this.state;
        let conditionEvent = characterConditionEventDefinitions[selectedEvent];
        return <Popover {...props} id={`popover-${selectedEvent}`} title={conditionEvent.name}>{conditionEvent.description}</Popover>;
    };

    render() {
        let {
            character,
            combat,
            conditionEventResult,
        } = this.props;

        let eventOptions = Object.values(characterConditionEventDefinitions)
            .filter((def) => {
                return def.filterCallback(character, combat);
            })
            .map((def) => {
                return getOptionFromEventId(def.id as CharacterConditionEventId)
            });
        let eventOptionIds = objectKeysByCallback(eventOptions, (option) => option.value);
        return <Col xs={12} sm={8} md={3} className='content-panel'>
            <CenteredRow>
                <h4>Conditions</h4>
            </CenteredRow>
            <CenteredRow>
                <CharacterConditionArray character={character} combat={combat} />
            </CenteredRow>
            <CenteredRow>
                <Select
                    className='react-select'
                    // Force a different selection if the old one isn't valid anymore
                    value={eventOptionIds[this.state.selectedEvent] ? getOptionFromEventId(this.state.selectedEvent) : eventOptions[0]}
                    onChange={(v: ValueType<Option<CharacterConditionEventId>>) => {
                        let eventOption = validateSingleReactSelectValue(v);
                        this.setState({selectedEvent: eventOption.value})
                    }}
                    options={eventOptions}
                />
                <OverlayTrigger rootClose={true} trigger="click" placement='bottom' overlay={this.infoPopover.bind(this)}>
                    <div>
                        <FontAwesomeIcon icon={faQuestionCircle} />
                    </div>
                </OverlayTrigger>
            </CenteredRow>
            <CenteredRow>
                <Button variant='primary' onClick={this.executeEvent.bind(this)}>Execute</Button>
            </CenteredRow>
            <CenteredRow>
                <h5>{conditionEventResult}</h5>
            </CenteredRow>
        </Col>
    }
}
