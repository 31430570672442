const automaticWeaponHitMatrix = [
// First dimension is shots in group, 1-4
// Roll made by:
//  -1  0  1  2  3  4  5+
    [0, 1, 1, 1, 1, 1, 1],
    [0, 1, 1, 1, 1, 1, 2],
    [1, 1, 1, 1, 2, 2, 3],
    [1, 2, 2, 3, 3, 3, 4],
];

export const getHitsInBurst = (rollDifferential: number, shotsInGroup: number, isCriticalHit: boolean): number => {
    if (isCriticalHit) {
        return shotsInGroup;
    }
    if (rollDifferential > 5) {
        rollDifferential = 5;
    }
    let shotsIndex = shotsInGroup - 1;
    let rollIndex = rollDifferential + 1;
    return automaticWeaponHitMatrix[shotsIndex][rollIndex];
};