import {SagaIterator} from "redux-saga";
import {log} from "../lib/logger";
import {put, select, take, takeLatest} from "redux-saga/effects";
import {AppState} from "../modules";
import {loadCharacter} from "../modules/character";
import {
    EditCharacterSagaAction,
    GamemasterActions,
    SagaGamemasterActions,
    setGmEditing,
    updateCharacter
} from "../modules/gamemaster";
import {history} from "../store";
import {loadState} from "../modules/combat";

export function* gamemasterSagas(): SagaIterator {
    log(`Launching GM sagas`);
    yield takeLatest(SagaGamemasterActions.EditCharacterSaga, editCharacterSaga);
}

function* editCharacterSaga(action: EditCharacterSagaAction): SagaIterator {
    log(`Edit character saga`);
    let state = (yield select()) as AppState;

    // Pull the currently loaded character so we can restore it later
    let originalCharacter = state.characterReducer.character;
    let originalCombat = state.combatReducer;

    // Load the selected GM character as the current character
    let editingCharacter = state.gamemasterReducer.characters[action.id];
    yield put(loadCharacter(editingCharacter.character));

    // Set the GM editing flag
    yield put(setGmEditing(true));

    // Navigate to the character page
    history.push('/character/status');

    // Wait for the editing end event
    log(`Waiting for GM editing to complete`);
    yield take(GamemasterActions.FinishGmEditing);
    log(`GM editing has completed`);
    state = (yield select()) as AppState;
    let updatedCharacter = state.characterReducer.character;
    let updatedCombat = state.combatReducer;

    // Restore original character as current character
    yield put(loadCharacter(originalCharacter));
    yield put(loadState(originalCombat));

    // Update the edited character
    yield put(updateCharacter(action.id, updatedCharacter, updatedCombat));

    // Clear GM editing flag
    yield put(setGmEditing(false));

    // Navigate back to GM screen
    history.push('/gamemaster/overview');
}
