import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import {
    faBiohazard,
    faBolt,
    faFlushed,
    faHandRock,
    faProcedures,
    faSkullCrossbones,
    faSocks,
    faTint,
    faUserInjured
} from "@fortawesome/free-solid-svg-icons";
import {CombatState, ShockStatus, StunStatus} from "../../modules/combat";
import {
    getAttributeCurrentValue,
    getAttributeUnmodifiedValue,
    getModifiedCharacteristicValue
} from "../gameLogic/derivedCharacterStats";
import {AttributeId} from "./attributeConstants";
import {Character} from "./characterConstants";

export interface CharacterStatusIcon {
    id: string;
    icon: IconDefinition;
    iconColor: string;
    name: string;
}

export enum InjuryId {
    Reeling = '@@injury/Reeling',
    UnconsciousnessRisk = '@@injury/UnconsiousnessRisk',
    Unconscious = '@@injury/Unconscious',
    Bleeding = '@@injury/Bleeding',
    DeathRisk = '@@injury/DeathRisk',
    Dead = '@@injury/Dead',
    Diseased = '@@injury/Diseased',
    CrippledRightArm = '@@injury/CrippledRightArm',
    CrippledLeftArm = '@@injury/CrippledLeftArm',
    CrippledRightFoot = '@@injury/CrippledRightFoot',
    CrippledLeftFoot = '@@injury/CrippledLeftFoot ',
    SeveredRightArm = '@@injury/SeveredRightArm',
    SeveredLeftArm = '@@injury/SeveredLeftArm',
    SeveredRightFoot = '@@injury/SeveredRightFoot',
    SeveredLeftFoot = '@@injury/SeveredLeftFoot',
}

// export type StatusId = CombatStatusId | InjuryId;

export interface InjuryDefinition extends CharacterStatusIcon {
    id: InjuryId;
}

// export enum CombatStatusId {
//   Shocked = '@@combatStatus/Shocked',
//   Stunned = '@@combatStatus/Stunned',
// }
//
// export interface CombatStatusDefinition extends CharacterStatusIcon {
//   id: CombatStatusId;
// }

const yellow = '#F1C40F';
const red = 'red';

export const shockIcon: CharacterStatusIcon = {
    id: '@@combatInjuryStatus/Shocked',
    name: 'Shocked',
    icon: faBolt,
    iconColor: red,
};

export const stunIcon: CharacterStatusIcon = {
    id: '@@combatInjuryStatus/Stunned',
    name: 'Stunned',
    icon: faFlushed,
    iconColor: red,
};

export const injuryDefinitions: Record<InjuryId, InjuryDefinition> = {
    [InjuryId.Reeling]: {
        id: InjuryId.Reeling,
        name: 'Reeling',
        icon: faUserInjured,
        iconColor: red,
    },
    [InjuryId.UnconsciousnessRisk]: {
        id: InjuryId.UnconsciousnessRisk,
        name: 'Risking Collapse',
        icon: faProcedures,
        iconColor: yellow,
    },
    [InjuryId.Unconscious]: {
        id: InjuryId.Unconscious,
        name: 'Unconscious',
        icon: faProcedures,
        iconColor: red,
    },
    [InjuryId.Bleeding]: {
        id: InjuryId.Bleeding,
        name: 'Bleeding',
        icon: faTint,
        iconColor: red,
    },
    [InjuryId.DeathRisk]: {
        id: InjuryId.DeathRisk,
        name: 'DeathRisk',
        icon: faSkullCrossbones,
        iconColor: yellow,
    },
    [InjuryId.Dead]: {
        id: InjuryId.Dead,
        name: 'Dead',
        icon: faSkullCrossbones,
        iconColor: red,
    },
    [InjuryId.Diseased]: {
        id: InjuryId.Diseased,
        name: 'Diseased',
        icon: faBiohazard,
        iconColor: red,
    },
    [InjuryId.CrippledRightArm]: {
        id: InjuryId.CrippledRightArm,
        name: 'CrippledRightArm',
        icon: faHandRock,
        iconColor: yellow,
    },
    [InjuryId.CrippledLeftArm]: {
        id: InjuryId.CrippledLeftArm,
        name: 'CrippledLeftArm',
        icon: faHandRock,
        iconColor: yellow,
    },
    [InjuryId.CrippledRightFoot]: {
        id: InjuryId.CrippledRightFoot,
        name: 'CrippledRightFoot',
        icon: faSocks,
        iconColor: yellow,
    },
    [InjuryId.CrippledLeftFoot]: {
        id: InjuryId.CrippledLeftFoot,
        name: 'CrippledLeftFoot',
        icon: faSocks,
        iconColor: yellow,
    },
    [InjuryId.SeveredRightArm]: {
        id: InjuryId.SeveredRightArm,
        name: 'SeveredRightArm',
        icon: faHandRock,
        iconColor: red,
    },
    [InjuryId.SeveredLeftArm]: {
        id: InjuryId.SeveredLeftArm,
        name: 'SeveredLeftArm',
        icon: faHandRock,
        iconColor: red,
    },
    [InjuryId.SeveredRightFoot]: {
        id: InjuryId.SeveredRightFoot,
        name: 'SeveredRightFoot',
        icon: faSocks,
        iconColor: red,
    },
    [InjuryId.SeveredLeftFoot]: {
        id: InjuryId.SeveredLeftFoot,
        name: 'SeveredLeftFoot',
        icon: faSocks,
        iconColor: red,
    },
};

export enum CharacterConditionEventId {
    SimpleBandaging = '@@characterConditionEvent/SimpleBandaging',
    FirstAid = '@@characterConditionEvent/FirstAid',
    StopBleedingCheck = '@@characterConditionEvent/StopBleedingCheck',
    BleedingCheck = '@@characterConditionEvent/BleedingCheck',
    CollapseCheck = '@@characterConditionEvent/CollapseCheck',
    PhysicalStunRecoveryCheck = '@@characterConditionEvent/PhysicalStunRecoveryCheck',
    MentalStunRecoveryCheck = '@@characterConditionEvent/MentalStunRecoveryCheck',
    ShockRecovery = '@@characterConditionEvent/ShockRecovery',
    OneDayNaturalRecovery = '@@characterConditionEvent/OneDayNaturalRecovery',
    OneDayNaturalRecoveryWithPhysician = '@@characterConditionEvent/OneDayNaturalRecoveryWithPhysician',
    UnconsciousWakeUpCheckOneHour = '@@characterConditionEvent/UnconsciousWakeUpCheckOneHour',
    UnconsciousWakeUpCheckFifteenMinutes = '@@characterConditionEvent/UnconsciousWakeUpCheckFifteenMinutes',
}

export interface ConditionEventFilterCallback { (character: Character, combatState: CombatState): boolean }
export interface CharacterConditionEventDefinition {
    id: CharacterConditionEventId;
    name: string;
    description: string;
    /** Return true for the event to be a displayed option to the user and vice versa **/
    filterCallback: ConditionEventFilterCallback;
}

const recoverConsciousnessTemplate = {
    description: 'If your HT is still positive, roll vs. HT every hour to awaken (or, if you have lost no more than 2 HT, roll every 15 minutes).\n' +
        'If your HT is negative, but not fully negative, you will become conscious in as many hours as your HT is negative, or a maximum of 12 hours. Example: Your HT is -8 after the battle. You will wake up (still with -8 HT) in 8 hours. When you awaken, you can call for help or even try to drag yourself to shelter.\n' +
        'If your HT has gone fully negative – e.g., HT of -10 or worse for someone with a basic HT of 10 – you are in bad shape. If you can make a roll on basic HT, you will awaken (as above) after 12 hours, and can try to help yourself. If you fail the roll, you stay in a coma and die unless you are helped within HT hours.',
};

export const characterConditionEventDefinitions: Record<CharacterConditionEventId, CharacterConditionEventDefinition> = {
    [CharacterConditionEventId.SimpleBandaging]: {
        id: CharacterConditionEventId.SimpleBandaging,
        name: 'Simple Bandaging (30 minutes)',
        description: 'Basic, unskilled bandaging will restore 1 lost hit point per fight – but no more, no matter how bad the injury. This takes 30 minutes per victim.',
        filterCallback: (character: Character, combatState: CombatState): boolean => {
            return !combatState.haveBandagedSinceLastFight;
        },
    },
    [CharacterConditionEventId.FirstAid]: {
        id: CharacterConditionEventId.FirstAid,
        name: 'First Aid (20 minutes)',
        description: 'A successful First Aid skill roll will restore 1d-1 lost hit points. On a critical success, the victim regains the maximum: 5 hit points. On a critical failure, the victim loses 2 hits, and bandaging will not help. Barring critical failure, a minimum of 1 point is always restored. First aid is not cumulative with simple bandaging. This takes 20 minutes per victim.',
        filterCallback: (character: Character, combatState: CombatState): boolean => {
            return !combatState.haveBandagedSinceLastFight;
        },
    },
    [CharacterConditionEventId.BleedingCheck]: {
        id: CharacterConditionEventId.BleedingCheck,
        name: 'Bleeding Check (each minute)',
        description: 'At the end of every minute after being wounded, the victim rolls against HT, at a -1 per full 5 points of damage he has taken. If he fails, he bleeds for 1 point of damage. On a critical failure, he bleeds for 3 points of damage. On a critical success, the bleeding stops. On an ordinary success, he does not bleed this minute, but must continue to roll every minute. If he does not bleed for three consecutive minutes, the bleeding stops for good.',
        filterCallback: (character: Character, combatState: CombatState): boolean => {
            return !!character.injuries[InjuryId.Bleeding];
        },
    },
    [CharacterConditionEventId.StopBleedingCheck]: {
        id: CharacterConditionEventId.StopBleedingCheck,
        name: 'Stop Bleeding (1 minute)',
        description: 'A First Aid roll to stop bleeding takes 1 minute, and comes before the bleeding roll. Once first aid has been successfully administered, no more bleeding rolls are made.',
        filterCallback: (character: Character, combatState: CombatState): boolean => {
            return !!character.injuries[InjuryId.Bleeding];
        },
    },
    [CharacterConditionEventId.CollapseCheck]: {
        id: CharacterConditionEventId.CollapseCheck,
        name: 'Collapse Check (each turn)',
        description: 'At the beginning of each turn, roll against your basic HT, plus or minus Strong or Weak Will. A success means you may take your turn normally. A failed roll means you fall unconscious.',
        filterCallback: (character: Character, combatState: CombatState): boolean => {
            return !!character.injuries[InjuryId.UnconsciousnessRisk];
        },
    },
    [CharacterConditionEventId.PhysicalStunRecoveryCheck]: {
        id: CharacterConditionEventId.PhysicalStunRecoveryCheck,
        name: 'Physical Stun Recovery Check (each turn)',
        description: 'If you are stunned, all your active defenses are at -4 until your next turn. At that time, roll against basic HT to see whether you recover. A successful roll means you can act normally on that turn. A failed roll means you are still stunned and stand there mindlessly . . . The “stunned” state continues until you can make your HT roll and snap out of it. You may act again on the turn you roll successfully and shake off the daze.',
        filterCallback: (character: Character, combatState: CombatState): boolean => {
            return combatState.stunStatus === StunStatus.PhysicallyStunned;
        },
    },
    [CharacterConditionEventId.MentalStunRecoveryCheck]: {
        id: CharacterConditionEventId.MentalStunRecoveryCheck,
        name: 'Mental Stun Recovery Check (each turn)',
        description: 'Someone who is surprised or shocked may also be mentally “stunned.” The effects of this sort of stunning are just the same, but you must make your IQ roll, rather than your HT roll, to snap out of it. You’re not hurt – you’re confused',
        filterCallback: (character: Character, combatState: CombatState): boolean => {
            return combatState.stunStatus === StunStatus.MentallyStunned;
        },
    },
    [CharacterConditionEventId.ShockRecovery]: {
        id: CharacterConditionEventId.ShockRecovery,
        name: 'Shock Recovery (end of turn after shocked)',
        description: 'When you are injured, your DX and IQ, and any skills based on DX and IQ, are reduced by that amount, on your next turn only. Example: If you take 3 hits of injury, your IQ, DX, and skills will be at -3 on your next turn. Active defenses are not reduced.\n' +
            'This subtraction will most often affect weapon attacks – but any use of IQ, DX, or skills is affected. Therefore, on the turn after you are badly hurt, it may be a good idea to try flight, All- Out Defense, or the like, rather than counterattacking instantly.\n' +
            'This is only a temporary effect due to shock. On your following turn, your skills are back to normal.',
        filterCallback: (character: Character, combatState: CombatState): boolean => {
            return combatState.shockStatus === ShockStatus.Shocked;
        },
    },
    [CharacterConditionEventId.OneDayNaturalRecovery]: {
        id: CharacterConditionEventId.OneDayNaturalRecovery,
        name: 'Natural Recovery (one day of rest)',
        description: 'Natural recovery will cure any number of hits. At the end of each day of rest and decent food, the victim may roll against his basic HT. A successful roll results in the recovery of 1 hit point. The GM may modify the roll downward if conditions are bad, or upward if conditions are very good.',
        filterCallback: (character: Character, combatState: CombatState): boolean => {
            return getModifiedCharacteristicValue(getAttributeCurrentValue(AttributeId.Health, character, combatState)) < getAttributeUnmodifiedValue(AttributeId.Health, character);
        },
    },
    [CharacterConditionEventId.OneDayNaturalRecoveryWithPhysician]: {
        id: CharacterConditionEventId.OneDayNaturalRecoveryWithPhysician,
        name: 'Natural Recovery (one day of rest cared for by a competent physician)',
        description: 'A victim under the care of a competent Physician (skill level 12+) gets +1 on all healing rolls.',
        filterCallback: (character: Character, combatState: CombatState): boolean => {
            return getModifiedCharacteristicValue(getAttributeCurrentValue(AttributeId.Health, character, combatState)) < getAttributeUnmodifiedValue(AttributeId.Health, character);
        },
    },
    [CharacterConditionEventId.UnconsciousWakeUpCheckOneHour]: {
        ...recoverConsciousnessTemplate,
        id: CharacterConditionEventId.UnconsciousWakeUpCheckOneHour,
        name: 'Regain Consciousness (every hour)',
        filterCallback: (character: Character, combatState: CombatState): boolean => {
            let currentHp = getModifiedCharacteristicValue(getAttributeCurrentValue(AttributeId.Health, character, combatState));
            let totalHp = getAttributeUnmodifiedValue(AttributeId.Health, character);
            return !!character.injuries[InjuryId.Unconscious] && currentHp > 0 && (totalHp - currentHp > 2);
        },
    },
    [CharacterConditionEventId.UnconsciousWakeUpCheckFifteenMinutes]: {
        ...recoverConsciousnessTemplate,
        id: CharacterConditionEventId.UnconsciousWakeUpCheckFifteenMinutes,
        name: 'Regain Consciousness (every 15 minutes)',
        filterCallback: (character: Character, combatState: CombatState): boolean => {
            let currentHp = getModifiedCharacteristicValue(getAttributeCurrentValue(AttributeId.Health, character, combatState));
            let totalHp = getAttributeUnmodifiedValue(AttributeId.Health, character);
            return !!character.injuries[InjuryId.Unconscious] && currentHp > 0 && (totalHp - currentHp <= 2);
        },
    },
};
