import React from 'react';
import {connect} from 'react-redux';
import {Container, Row,} from 'react-bootstrap';
import LogPanel from "./panels/LogPanel";
import RollPanel from "./panels/RollPanel";
import {executeRoll, initRoll} from "../../../modules/roll";
import {AppState} from "../../../modules";
import {
  aimManeuver,
  allOutAttackManeuver,
  allOutDefenseManeuver,
  attackManeuver,
  attackManeuverSaga,
  changePositionManeuver,
  completeManeuver,
  completeReload,
  completeTurn,
  defendDodgeManeuverSaga,
  defendParryManeuverSaga,
  dropWeaponManeuver,
  endCombat,
  finishLoadingDamageRoll,
  loadDamageRoll,
  longActionManeuver,
  moveManeuver,
  readyManeuver,
  readyManeuverSaga,
  rollForDamageSaga,
  setSelectedCombatAction,
  startCombat,
  startManeuver,
  startTurn,
  stowWeaponManeuver,
  takeDamageSaga
} from "../../../modules/combat";
import {ManeuverPanel} from "./panels/ManeuverPanel";
import {CombatStatusPanel} from "./panels/CombatStatusPanel";
import {addToCharacterLog, removeEquipment, setWeaponShotsLoaded} from '../../../modules/character';
import {bindActionCreators} from "redux";
import {AppDispatch} from "../../../lib/constants/appConstants";

class CombatPage extends React.Component<CombatPageProps> {
  render() {
    let {
      character,
      logs,
      initRoll,
      executeRoll,
    } = this.props;

    if (!character || Object.values(character).length === 0) {
      return <h2>No Character loaded</h2>;
    }

    return <Container fluid={true} className='content-container'>
      <Row className='top-row'>
        <ManeuverPanel
          {...this.props}
        />
        <CombatStatusPanel
          {...this.props}
        />
      </Row>
      <Row className='bottom-row'>
        <RollPanel/>
        <LogPanel
          logs={logs}
        />
      </Row>
    </Container>;
  }
}

export interface RollProps {
  initRoll: typeof initRoll;
  executeRoll: typeof executeRoll;
}

export interface CombatActionProps extends RollProps {
  setSelectedCombatAction: typeof setSelectedCombatAction;

  loadDamageRoll: typeof loadDamageRoll;
  finishLoadingDamageRoll: typeof finishLoadingDamageRoll;

  startCombat: typeof startCombat;
  endCombat: typeof endCombat;
  startManeuver: typeof startManeuver;
  completeManeuver: typeof completeManeuver;
  startTurn: typeof startTurn;
  completeTurn: typeof completeTurn;

  moveManeuver: typeof moveManeuver;
  changePositionManeuver: typeof changePositionManeuver;
  readyManeuver: typeof readyManeuver;
  dropWeaponManeuver: typeof dropWeaponManeuver;
  stowWeaponManeuver: typeof stowWeaponManeuver;
  aimManeuver: typeof aimManeuver;
  attackManeuver: typeof attackManeuver;
  allOutAttackManeuver: typeof allOutAttackManeuver;
  allOutDefenseManeuver: typeof allOutDefenseManeuver;
  longActionManeuver: typeof longActionManeuver;

  removeEquipment: typeof removeEquipment;
  setWeaponShotsLoaded: typeof setWeaponShotsLoaded;
  completeReload: typeof completeReload;

  asyncReadyManeuver: typeof readyManeuverSaga;
  attackManeuverSaga: typeof attackManeuverSaga;
  defendDodgeManeuverSaga: typeof defendDodgeManeuverSaga;
  defendParryManeuverSaga: typeof defendParryManeuverSaga;
  rollForDamageSaga: typeof rollForDamageSaga;
  takeDamageSaga: typeof takeDamageSaga;
}

const mapStateToProps = (state: AppState) => {
  // Consider using reselect to memoize derived values here if performance becomes an issue
  return {
    ...state.characterReducer,
    combat: state.combatReducer,
  };
};

export type CombatPageProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  setSelectedCombatAction,

  loadDamageRoll,
  finishLoadingDamageRoll,

  initRoll,
  executeRoll,
  addToCharacterLog,

  startCombat,
  endCombat,
  startManeuver,
  completeManeuver,
  startTurn,
  completeTurn,

  moveManeuver,
  changePositionManeuver,
  readyManeuver,
  dropWeaponManeuver,
  stowWeaponManeuver,
  aimManeuver,
  attackManeuver,
  allOutAttackManeuver,
  allOutDefenseManeuver,
  longActionManeuver,

  removeEquipment,
  setWeaponShotsLoaded,
  completeReload,

  readyManeuverSaga,
  attackManeuverSaga,
  defendDodgeManeuverSaga,
  defendParryManeuverSaga,
  rollForDamageSaga,
  takeDamageSaga,
  // changePage: () => push('/about-us')
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CombatPage)
