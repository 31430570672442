import {weaponCategories, weaponDefinitions, WeaponId} from "./weaponConstants";

export enum DamageTypeId {
    Impaling = '@@damageTypes/Impaling',
    Cutting = '@@damageTypes/Cutting',
    Crushing = '@@damageTypes/Crushing',
}

export interface DamageTypeDefinition {
    id: DamageTypeId;
    label: string;
    postDrDamageMultiplier: number;
    minimumDamage: number;
}

export const damageTypeDefinitions: Record<DamageTypeId, DamageTypeDefinition> = {
    [DamageTypeId.Impaling]: {
        id: DamageTypeId.Impaling,
        label: 'Impaling',
        postDrDamageMultiplier: 2.0,
        minimumDamage: 1,
    },
    [DamageTypeId.Cutting]: {
        id: DamageTypeId.Cutting,
        label: 'Cutting',
        postDrDamageMultiplier: 1.5,
        minimumDamage: 1,
    },
    [DamageTypeId.Crushing]: {
        id: DamageTypeId.Crushing,
        label: 'Crushing',
        postDrDamageMultiplier: 1.0,
        minimumDamage: 0,
    },
};

export const getWeaponDamageType = (weaponId: WeaponId): DamageTypeId => {
    let def = weaponDefinitions[weaponId];
    let category = weaponCategories[def.category];
    return category.damageType;
};

export const getWeaponDamageModifiedByType = (weaponId: WeaponId, postDrDamage: number): number => {
    let def = damageTypeDefinitions[getWeaponDamageType(weaponId)];
    postDrDamage *= def.postDrDamageMultiplier;
    return Math.max(def.minimumDamage, postDrDamage);
};

export const getDamageModifiedByType = (damageType: DamageTypeId, postDrDamage: number): number => {
    let def = damageTypeDefinitions[damageType];
    postDrDamage *= def.postDrDamageMultiplier;
    return Math.max(def.minimumDamage, postDrDamage);
};
