import React, {Component} from 'react';
import {positionDefinitions} from "../../../../../../lib/constants/combatConstants";
import {CenteredFlexDiv} from "../../../../../../lib/components/CenteredFlexDiv";
import {getModifiedCharacteristicValue, getSpeed} from "../../../../../../lib/gameLogic/derivedCharacterStats";
import {Button} from "react-bootstrap";
import {CombatPageProps} from "../../../CombatPage";

export class Move extends Component<CombatPageProps> {
  executeManeuver() {
    let {
      character,
      combat,
      moveManeuver,
      addToCharacterLog,
    } = this.props;
    moveManeuver(character);
    addToCharacterLog(`Moved up to ${getSpeed(character, combat, combat.position)} yards`);
  }

  render() {
    let {
      character,
      combat
    } = this.props;
    let positionDef = positionDefinitions[combat.position];
    return <CenteredFlexDiv>
      <h2>Move {getModifiedCharacteristicValue(getSpeed(character, combat, combat.position))} yards while {positionDef.name}</h2>
      <Button variant='primary' onClick={this.executeManeuver.bind(this)}>Move</Button>
    </CenteredFlexDiv>;
  }
}
