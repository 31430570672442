import {SkillId} from "../skillConstants";
import {Character} from "../characterConstants";
import {Weapon, WeaponId} from "../weaponConstants";
import {Gear, GearId} from "../gearConstants";
import {AttributeId} from "../attributeConstants";
import {VantageId} from "../vantageConstants";
import {FatigueType} from "../fatigueConstants";

export const rifleman: Character = {
  name: 'Rifleman Example',
  totalPoints: 100,
  money: 100,
  fatiguePoints: {
    [FatigueType.Normal]: 1,
    [FatigueType.Dehydration]: 1,
    [FatigueType.Starvation]: 1,
    [FatigueType.Sleep]: 1,
  },
  attributes: {
    [AttributeId.Strength]: {
      id: AttributeId.Strength,
      level: 11,
    },
    [AttributeId.Dexterity]: {
      id: AttributeId.Dexterity,
      level: 11,
    },
    [AttributeId.Intelligence]: {
      id: AttributeId.Intelligence,
      level: 11,
    },
    [AttributeId.Health]: {
      id: AttributeId.Health,
      level: 11,
    },
  },
  vantages: {
    [VantageId.AbsoluteDirection]: {
      id: VantageId.AbsoluteDirection,
      level: 1,
    },
    [VantageId.DiseaseResistantImmunityToDisease]: {
      id: VantageId.DiseaseResistantImmunityToDisease,
      level: 1,
    },
    [VantageId.Vow]: {
      id: VantageId.Vow,
      level: 3,
    },
    [VantageId.StrongWill]: {
      id: VantageId.StrongWill,
      level: 2,
    },
  },
  skills: {
    [SkillId.Acrobatics]: {
      id: SkillId.Acrobatics,
      level: 2,
    },
    [SkillId.SurvivalDesert]: {
      id: SkillId.SurvivalDesert,
      level: 5,
    },
    [SkillId.Throwing]: {
      id: SkillId.Throwing,
      level: 2,
    },
    [SkillId.GunsRifle]: {
      id: SkillId.GunsRifle,
      level: 3,
    },
    [SkillId.GunnerHeavyMachineGun]: {
      id: SkillId.GunnerHeavyMachineGun,
      level: 3,
    },
    [SkillId.FirstAid]: {
      id: SkillId.FirstAid,
      level: 4,
    },
  },
  inventory: {
    [WeaponId.M1Garand]: {
      id: WeaponId.M1Garand,
      quantity: 1,
      notes: 'Pimped out with a gilded barrel.',
      shotsLoaded: 4,
    } as Weapon,
    [WeaponId.MG34]: {
      id: WeaponId.MG34,
      quantity: 1,
      shotsLoaded: 35,
    } as Weapon,
    [WeaponId.MkIIPineapple]: {
      id: WeaponId.MkIIPineapple,
      quantity: 1,
      shotsLoaded: 1,
    } as Weapon,
    [GearId.Belt]: {
      id: GearId.Belt,
      quantity: 5,
      notes: 'Belts for days',
      equipped: false,
    } as Gear,
    [GearId.Boots]: {
      id: GearId.Boots,
      quantity: 1,
      equipped: true,
    } as Gear,
  },
  injuries: {},
  conditionEvents: {},
  damageTaken: 0,
  consecutiveBleedingCheckSuccesses: 0,
};