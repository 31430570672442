import React, {Component} from 'react';
import {Button, Form, FormControlProps, InputGroup} from 'react-bootstrap';
import {CombatPageProps} from "../../../CombatPage";
import {weaponDefinitions, WeaponId} from "../../../../../../lib/constants/weaponConstants";
import {
    getFragmentationRange,
    getReadiedWeaponId,
    isAutomaticWeapon,
    isExplosiveWeapon,
} from "../../../../../../lib/gameLogic/derivedCombatStats";
import {CenteredFlexDiv} from "../../../../../../lib/components/CenteredFlexDiv";
import Select from "react-select";
import {Option, validateSingleReactSelectValue} from "../../../../../../lib/utils";
import {ValueType} from "react-select/lib/types";

interface Props extends CombatPageProps {
    selectedWeaponId?: WeaponId;
}

interface State {
    weaponId: WeaponId;
    attackRollDifferential: number;
    targetDr: number;
    softTarget: boolean;
    // These will only ever be 1 or 0 for non-automatic weapons
    normalHits: number;
    maxDamageHits: number;
    // Only relevant for explosives
    impactDistanceToTarget: number;
    fragHit: boolean;
}

const getOptionFromWeaponId = (id: WeaponId): Option<WeaponId> => {
    let def = weaponDefinitions[id];
    return {value: def.id, label: def.name};
};
const weaponIds = Object.keys(weaponDefinitions);
const weaponOptions = weaponIds.map((id) => getOptionFromWeaponId(id as WeaponId));

export class RollDamage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        let {
            combat,
            selectedWeaponId,
        } = props;

        let attackRollResult = combat.attackRollResultToLoadAsDamageRoll;
        let readiedWeaponId = getReadiedWeaponId(combat);
        let weaponId: WeaponId;
        if (selectedWeaponId) {
            weaponId = selectedWeaponId;
        } else if (readiedWeaponId) {
            weaponId = readiedWeaponId;
        } else {
            weaponId = WeaponId.M1A1;
        }

        let attackRollDifferential: number;
        let fragHit: boolean;
        let impactDistanceToTarget: number;
        let normalHits: number;
        let maxDamageHits: number;
        
        if (attackRollResult) {
            // If this is an explicit roll result passed in, parse it and use it to set the UI elements
            attackRollDifferential = attackRollResult.differential;
            fragHit = attackRollResult.fragDamageHit || false;
            impactDistanceToTarget = attackRollResult.impactDistanceToTarget || 0;
            normalHits = attackRollResult.normalHits;
            maxDamageHits = attackRollResult.maxDamageHits;
        } else {
            // Otherwise just use sensible defaults to start with
            attackRollDifferential = 0;
            fragHit = false;
            impactDistanceToTarget = 0;
            normalHits = 1;
            maxDamageHits = 0;
        }
        
        this.state = {
            weaponId,
            attackRollDifferential,
            targetDr: 0,
            softTarget: true,
            normalHits,
            maxDamageHits,
            impactDistanceToTarget,
            fragHit,
        };
    }

    executeManeuver() {
        let {
            character,
            rollForDamageSaga,
        } = this.props;
        let {
            weaponId,
            attackRollDifferential,
            targetDr,
            softTarget,
            normalHits,
            maxDamageHits,
            impactDistanceToTarget,
            fragHit,
        } = this.state;
        rollForDamageSaga(
            weaponId,
            attackRollDifferential,
            targetDr,
            softTarget,
            normalHits,
            maxDamageHits,
            fragHit,
            impactDistanceToTarget,
        );
    }

    render() {
        let {
            weaponId,
            normalHits,
            maxDamageHits,
        } = this.state;
        let isExplosive = isExplosiveWeapon(weaponId);
        let isAutomatic = isAutomaticWeapon(weaponId);

        // Weapon picker with roll entry OR enter hits, specify shotgun, and max damage hits
        return <CenteredFlexDiv>
            <CenteredFlexDiv isRow={false}>
                <Select
                    className='react-select'
                    value={getOptionFromWeaponId(this.state.weaponId)}
                    onChange={(v: ValueType<Option<WeaponId>>) => {
                        let weaponOption = validateSingleReactSelectValue(v);
                        this.setState({weaponId: weaponOption.value})
                    }}
                    options={weaponOptions}
                />
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <Form.Control
                            className='number-picker'
                            type="number"
                            max={`${isAutomatic ? 4 - maxDamageHits : 1 - maxDamageHits}`}
                            min='0'
                            value={this.state.normalHits.toString(10)}
                            onChange={(e: React.FormEvent<FormControlProps>) => {
                                let rawVal = e.currentTarget.value;
                                if (rawVal) {
                                    this.setState({
                                        normalHits: parseInt(rawVal, 10),
                                    });
                                }
                            }}
                        />
                    </InputGroup.Prepend>
                    <InputGroup.Text>
                        Normal Hits
                    </InputGroup.Text>
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <Form.Control
                            className='number-picker'
                            type="number"
                            max={`${isAutomatic ? 4 - normalHits : 1 - normalHits}`}
                            min='0'
                            value={this.state.maxDamageHits.toString(10)}
                            onChange={(e: React.FormEvent<FormControlProps>) => {
                                let rawVal = e.currentTarget.value;
                                if (rawVal) {
                                    this.setState({
                                        maxDamageHits: parseInt(rawVal, 10),
                                    });
                                }
                            }}
                        />
                    </InputGroup.Prepend>
                    <InputGroup.Text>
                        Max Damage Hits
                    </InputGroup.Text>
                </InputGroup>
                {isExplosive &&
                <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <Form.Control
                                className='number-picker wide'
                                type="number"
                                max={`${getFragmentationRange(this.state.weaponId)}`}
                                min='0'
                                value={this.state.impactDistanceToTarget.toString(10)}
                                onChange={(e: React.FormEvent<FormControlProps>) => {
                                    let rawVal = e.currentTarget.value;
                                    if (rawVal) {
                                        this.setState({
                                            impactDistanceToTarget: parseInt(rawVal, 10),
                                        });
                                    }
                                }}
                            />
                        </InputGroup.Prepend>
                        <InputGroup.Text>
                            Impact Range from Target
                        </InputGroup.Text>
                    </InputGroup>
                }
            </CenteredFlexDiv>
            <CenteredFlexDiv isRow={false}>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <Form.Control
                            className='number-picker wide'
                            type="number"
                            max='15'
                            min='-15'
                            value={this.state.attackRollDifferential.toString(10)}
                            onChange={(e: React.FormEvent<FormControlProps>) => {
                                let rawVal = e.currentTarget.value;
                                if (rawVal) {
                                    this.setState({
                                        attackRollDifferential: parseInt(rawVal, 10),
                                    });
                                }
                            }}
                        />
                    </InputGroup.Prepend>
                    <InputGroup.Text>
                        Roll Differential
                    </InputGroup.Text>
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <Form.Control
                            className='number-picker wide'
                            type="number"
                            max={40}
                            min={0}
                            value={this.state.targetDr.toString(10)}
                            onChange={(e: React.FormEvent<FormControlProps>) => {
                                let rawVal = e.currentTarget.value;
                                if (rawVal) {
                                    this.setState({
                                        targetDr: parseInt(rawVal, 10),
                                    });
                                }
                            }}
                        />
                    </InputGroup.Prepend>
                    <InputGroup.Text>
                        Target DR
                    </InputGroup.Text>
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Checkbox
                            type="checkbox"
                            checked={this.state.softTarget}
                            onChange={() => {
                                this.setState({
                                    softTarget: !this.state.softTarget,
                                });
                            }}
                        />
                    </InputGroup.Prepend>
                    <InputGroup.Text>
                        Soft Target
                    </InputGroup.Text>
                </InputGroup>
                {isExplosive &&
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Checkbox
                                type="checkbox"
                                checked={this.state.fragHit}
                                onChange={() => {
                                    this.setState({
                                        fragHit: !this.state.fragHit,
                                    });
                                }}
                            />
                        </InputGroup.Prepend>
                        <InputGroup.Text>
                            Add Frag Damage
                        </InputGroup.Text>
                    </InputGroup>
                }
                <Button variant='primary' onClick={this.executeManeuver.bind(this)}>Roll For Damage</Button>
            </CenteredFlexDiv>
        </CenteredFlexDiv>
    }
}
