import React from 'react';
import {Col, Row,} from 'react-bootstrap';
import AttributeCell from "./subpanels/AttributeCell";
import {attributeDefinitions, AttributeId} from "../../../../lib/constants/attributeConstants";
import {StatusPageProps} from "../StatusPage";

export default class AttributePanel extends React.Component<StatusPageProps> {
  render() {
    let {
      character,
      combat,
    } = this.props;

    return <Col xs={12} sm={4} md={3} className='content-panel'>
      <Row className='justify-content-center'>
        <h4>Attributes</h4>
      </Row>
        {Object.keys(attributeDefinitions).map((attributeId) => {
          return <AttributeCell
            key={`attribute-cell-${attributeId}`}
            attributeId={attributeId as AttributeId}
            character={character}
            combat={combat}
          />
        })}
    </Col>
  }
}
