import React, {Component} from 'react';
import {Card, ProgressBar} from 'react-bootstrap';
import {Character} from "../constants/characterConstants";
import {GamemasterCharacterTypeId} from "../../modules/gamemaster";
import {getAttributeCurrentValue, getModifiedCharacteristicValue} from "../gameLogic/derivedCharacterStats";
import {CenteredRow} from "./CenteredRow";
import {CharacterConditionArray} from "./CharacterConditionArray";
import {CombatState} from "../../modules/combat";
import {attributeDefinitions, AttributeId} from "../constants/attributeConstants";
import {CenteredFlexCol} from "./CenteredFlexCol";

interface Props {
    character: Character;
    combat: CombatState;
    displayName: string;
    characterType: GamemasterCharacterTypeId;
    isSelected: boolean;
}

// Show health in a progress bar to make it snazzier
const textAttributeIds = Object.keys(attributeDefinitions)
    .filter(id => id !== AttributeId.Health);

export class CharacterCard extends Component<Props> {
    render() {
        let {
            character,
            combat,
            displayName,
            characterType,
            isSelected,
        } = this.props;

        let border: 'primary' | 'danger' = characterType === GamemasterCharacterTypeId.PC ? 'primary' : 'danger';

        let healthLevel = character.attributes[AttributeId.Health].level;
        let currentHealthValue = getModifiedCharacteristicValue(getAttributeCurrentValue(AttributeId.Health, character, combat));

        return <Card
            border={border}
            className={`${isSelected ? 'selected' : ''}`}
        >
            <Card.Body>
                <Card.Title>{displayName}</Card.Title>
                <Card.Subtitle>{`${character.name}: ${character.totalPoints} pts`}</Card.Subtitle>
                <Card.Text>{textAttributeIds.reduce((carry, attributeId) => {
                    let id = attributeId as AttributeId;
                    let abbreviation = attributeDefinitions[id].abbreviation;
                    let level = character.attributes[id].level;
                    let currentValue = getModifiedCharacteristicValue(getAttributeCurrentValue(id, character, combat));
                    return carry + ` ${abbreviation}: ${currentValue}/${level}`;
                }, '')}</Card.Text>
                <CenteredRow>
                    <CenteredFlexCol sm={4}>
                        <CharacterConditionArray character={character} combat={combat} />
                    </CenteredFlexCol>
                    <CenteredFlexCol sm={8}>
                        <ProgressBar className='full-width' now={currentHealthValue/healthLevel * 100} label={`${currentHealthValue}/${healthLevel} HT`} />
                    </CenteredFlexCol>
                </CenteredRow>
            </Card.Body>
        </Card>;
    }
}
