import {Character} from "../lib/constants/characterConstants";
import {CombatActionId, FreeActionId, ManeuverId, PositionId} from "../lib/constants/combatConstants";
import {WeaponId, WeaponType} from "../lib/constants/weaponConstants";
import {LOCAL_STORAGE_PREFIX} from "../lib/constants/constants";
import {AttackResultData, AttackRollResult, Modifier} from "../lib/constants/rollConstants";

// ------------------------------------
// Constants
// ------------------------------------
export const MAX_AIM = 4;
export const COMBAT_LOCAL_STORAGE_KEY = `${LOCAL_STORAGE_PREFIX}_combat`;
export enum CombatStatus {
  NotInCombat = '@@combatState/NotInCombat',
  WaitingOnManeuverRoll = '@@combatState/WaitingOnManeuverRoll',
  SelectingDefense = '@@combatState/SelectingDefense',
  WaitingOnDefenseRoll = '@@combatState/WaitingOnDefenseRoll',
  Idle = '@@combatState/ManeuversComplete',
}

export enum StunStatus {
  Lucid = '@@stunStatus/Lucid',
  PhysicallyStunned = '@@stunStatus/PhysicallyStunned',
  MentallyStunned = '@@stunStatus/MentallyStunned',
}

export enum ShockStatus {
  Lucid = '@@shockStatus/Lucid',
  Shocked = '@@shockStatus/Shocked',
}

// ------------------------------------
// Actions
// ------------------------------------
export enum CombatActions {
  SetSelectedCombatAction = '@@combat/SetSelectedCombatAction',
  LoadDamageRoll = '@@combat/LoadDamageRoll',
  FinishLoadingDamageRoll = '@@combat/FinishLoadingDamageRoll',
  StartCombat = '@@combat/StartCombat',
  EndCombat = '@@combat/EndCombat',
  // Todo: remove these start/end bookeneds
  StartManeuver = '@@combat/StartManeuver',
  CompleteManeuver = '@@combat/CompleteManeuver',
  StartTurn = '@@combat/StartTurn',
  CompleteTurn = '@@combat/CompleteTurn',
  ManeuverMove = '@@combat/ManeuverMove',
  ManeuverDefendDodge = '@@combat/ManeuverDefendDodge',
  ManeuverDefendParry = '@@combat/ManeuverDefendParry',
  ManeuverChangePosition = '@@combat/ManeuverChangePosition',
  ManeuverReady = '@@combat/ManeuverReady',
  CompleteReload = '@@combat/CompleteReload',
  ManeuverStowWeapon = '@@combat/ManeuverStowWeapon',
  ManeuverDropWeapon = '@@combat/ManeuverDropWeapon',
  ManeuverAim = '@@combat/ManeuverAim',
  SetAim = '@@combat/SetAim',
  SetReload = '@@combat/SetReload',
  ManeuverAttack = '@@combat/ManeuverAttack',
  ManeuverAllOutAttack = '@@combat/ManeuverAllOutAttack',
  ManeuverAllOutDefense = '@@combat/ManeuverAllOutDefense',
  ManeuverLongAction = '@@combat/ManeuverLongAction',
  LoadState = '@@combat/LoadState',
  SetStun = '@@combat/SetStun',
  SetShock = '@@combat/SetShock',
  // Same as ManeuverChangePosition, but not a maneuver and doesn't take a turn
  ChangePosition = '@@combat/ChangePosition',
  DoBandaging = '@@combat/DoBandaging',
}

interface CombatAction {
  type: CombatActions;
}

export enum SagaCombatActions {
  ManeuverAttack = '@@combat/saga/ManeuverAttackSaga',
  ManeuverReady = '@@combat/saga/ManeuverReady',
  ManeuverDefendDodge = '@@combat/saga/ManeuverDefendDodge',
  ManeuverDefendParry = '@@combat/saga/ManeuverDefendParry',
  RollForDamage = '@@combat/saga/RollForDamage',
  TakeDamage = '@@combat/saga/TakeDamage',
}

interface SagaCombatAction {
  type: SagaCombatActions;
}

interface SetSelectedCombatActionAction extends CombatAction {
  type: CombatActions.SetSelectedCombatAction;
  combatActionId: CombatActionId;
}
export const setSelectedCombatAction = (combatActionId: CombatActionId): SetSelectedCombatActionAction => {
  return {
    type: CombatActions.SetSelectedCombatAction,
    combatActionId,
  };
};

interface LoadDamageRollAction extends CombatAction {
  type: CombatActions.LoadDamageRoll,
  attackRollResult: AttackRollResult;
}
export const loadDamageRoll = (attackRollResult: AttackRollResult): LoadDamageRollAction => {
  return {
    type: CombatActions.LoadDamageRoll,
    attackRollResult,
  };
};

interface FinishLoadingDamageRollAction extends CombatAction {
  type: CombatActions.FinishLoadingDamageRoll,
}
export const finishLoadingDamageRoll = (): FinishLoadingDamageRollAction => {
  return {
    type: CombatActions.FinishLoadingDamageRoll,
  };
};

interface StartCombatAction extends CombatAction {
  type: CombatActions.StartCombat,
  character: Character;
}
export const startCombat = (character: Character): StartCombatAction => {
  return {
    type: CombatActions.StartCombat,
    character,
  };
};

interface EndCombatAction extends CombatAction {
  type: CombatActions.EndCombat,
  character: Character;
}
export const endCombat = (character: Character): EndCombatAction => {
  return {
    type: CombatActions.EndCombat,
    character,
  };
};

interface StartTurnAction extends CombatAction {
  type: CombatActions.StartTurn,
  character: Character;
}
export const startTurn = (character: Character): StartTurnAction => {
  return {
    type: CombatActions.StartTurn,
    character,
  };
};

interface CompleteTurnAction extends CombatAction {
  type: CombatActions.CompleteTurn,
  character: Character;
}
export const completeTurn = (character: Character): CompleteTurnAction => {
  return {
    type: CombatActions.CompleteTurn,
    character,
  };
};

interface StartManeuverAction extends CombatAction {
  type: CombatActions.StartManeuver,
  character: Character;
}
export const startManeuver = (character: Character): StartManeuverAction => {
  return {
    type: CombatActions.StartManeuver,
    character,
  };
};

interface CompleteManeuverAction extends CombatAction {
  type: CombatActions.CompleteManeuver,
  character: Character;
}
export const completeManeuver = (character: Character): CompleteManeuverAction => {
  return {
    type: CombatActions.CompleteManeuver,
    character,
  };
};

interface MoveManeuverAction extends CombatAction {
  type: CombatActions.ManeuverMove,
  character: Character;
}
export const moveManeuver = (character: Character): MoveManeuverAction => {
  return {
    type: CombatActions.ManeuverMove,
    character,
  };
};

interface ChangePositionManeuverAction extends CombatAction {
  type: CombatActions.ManeuverChangePosition,
  character: Character;
  newPosition: PositionId;
}
export const changePositionManeuver = (character: Character, newPosition: PositionId): ChangePositionManeuverAction => {
  return {
    type: CombatActions.ManeuverChangePosition,
    newPosition,
    character,
  };
};

interface ChangePositionAction extends CombatAction {
  type: CombatActions.ChangePosition,
  character: Character;
  newPosition: PositionId;
}
export const changePosition = (character: Character, newPosition: PositionId): ChangePositionAction => {
  return {
    type: CombatActions.ChangePosition,
    newPosition,
    character,
  };
};

interface ReadyManeuverAction extends CombatAction {
  type: CombatActions.ManeuverReady,
  readyStatus: ReadyStatusUnion;
}
export const readyManeuver = (readyStatus: ReadyStatusUnion): ReadyManeuverAction => {
  return {
    type: CombatActions.ManeuverReady,
    readyStatus,
  };
};

interface CompleteReloadAction extends CombatAction {
  type: CombatActions.CompleteReload,
  weapon: WeaponId;
}
export const completeReload = (weapon: WeaponId): CompleteReloadAction => {
  return {
    type: CombatActions.CompleteReload,
    weapon,
  };
};

interface StowWeaponManeuverAction extends CombatAction {
  type: CombatActions.ManeuverStowWeapon,
}
export const stowWeaponManeuver = (): StowWeaponManeuverAction => {
  return {
    type: CombatActions.ManeuverStowWeapon,
  };
};

interface DropWeaponManeuverAction extends CombatAction {
  type: CombatActions.ManeuverDropWeapon,
  weapon: WeaponId; }
export const dropWeaponManeuver = (weapon: WeaponId): DropWeaponManeuverAction => {
  return {
    type: CombatActions.ManeuverDropWeapon,
    weapon,
  };
};

interface AimManeuverAction extends CombatAction {
  type: CombatActions.ManeuverAim,
  character: Character;
  braced: boolean;
}
export const aimManeuver = (character: Character, braced: boolean): AimManeuverAction => {
  return {
    type: CombatActions.ManeuverAim,
    character,
    braced,
  };
};

interface SetAimAction extends CombatAction {
  type: CombatActions.SetAim,
  aim: number;
}
export const setAim = (aim: number): SetAimAction => {
  return {
    type: CombatActions.SetAim,
    aim,
  };
};

interface SetReloadAction extends CombatAction {
  type: CombatActions.SetReload,
  reload: number;
}
export const setReload = (reload: number): SetReloadAction => {
  return {
    type: CombatActions.SetReload,
    reload,
  };
};

export interface AttackManeuverAction extends CombatAction {
  type: CombatActions.ManeuverAttack;
  readyStatus: ReadyStatusUnion;
  turnsThisAttack: number;
  attacksThisTurn: number;
}
export const attackManeuver = (character: Character, readyStatus: ReadyStatusUnion, turnsThisAttack: number, attacksThisTurn: number): AttackManeuverAction => {
  return {
    type: CombatActions.ManeuverAttack,
    readyStatus,
    turnsThisAttack,
    attacksThisTurn,
  };
};

interface AllOutAttackManeuverAction extends CombatAction {
  type: CombatActions.ManeuverAllOutAttack,
  character: Character;
}
export const allOutAttackManeuver = (character: Character): AllOutAttackManeuverAction => {
  return {
    type: CombatActions.ManeuverAllOutAttack,
    character,
  };
};

interface AllOutDefenseManeuverAction extends CombatAction {
  type: CombatActions.ManeuverAllOutDefense,
  character: Character;
}
export const allOutDefenseManeuver = (character: Character): AllOutDefenseManeuverAction => {
  return {
    type: CombatActions.ManeuverAllOutDefense,
    character,
  };
};

interface DefendDodgeManeuverAction extends CombatAction {
  type: CombatActions.ManeuverDefendDodge,
  character: Character;
}
export const defendDodgeManeuver = (character: Character): DefendDodgeManeuverAction => {
  return {
    type: CombatActions.ManeuverDefendDodge,
    character,
  };
};

interface DefendParryManeuverAction extends CombatAction {
  type: CombatActions.ManeuverDefendParry,
  character: Character;
}
export const defendParryManeuver = (character: Character): DefendParryManeuverAction => {
  return {
    type: CombatActions.ManeuverDefendParry,
    character,
  };
};

interface LongActionManeuverAction extends CombatAction {
  type: CombatActions.ManeuverLongAction,
  character: Character;
}
export const longActionManeuver = (character: Character): LongActionManeuverAction => {
  return {
    type: CombatActions.ManeuverLongAction,
    character,
  };
};

interface LoadStateAction extends CombatAction {
  type: CombatActions.LoadState;
  combat: CombatState;
}
export const loadState = (combat: CombatState): LoadStateAction => {
  return {
    type: CombatActions.LoadState,
    combat,
  };
};

interface SetShockAction extends CombatAction {
  type: CombatActions.SetShock;
  shock: ShockStatus;
  shockDamage: number;
}
export const setShock = (shock: ShockStatus, shockDamage: number): SetShockAction => {
  return {
    type: CombatActions.SetShock,
    shock,
    shockDamage,
  };
};

interface SetStunAction extends CombatAction {
  type: CombatActions.SetStun;
  stun: StunStatus;
}
export const setStun = (stun: StunStatus): SetStunAction => {
  return {
    type: CombatActions.SetStun,
    stun,
  };
};

interface DoBandagingAction extends CombatAction {
  type: CombatActions.DoBandaging;
}
export const doBandaging = (): DoBandagingAction => {
  return {
    type: CombatActions.DoBandaging,
  };
};

export type CombatActionTypes =
  LoadDamageRollAction |
  FinishLoadingDamageRollAction |
  SetSelectedCombatActionAction |
  StartCombatAction |
  EndCombatAction |
  StartTurnAction |
  CompleteTurnAction |
  StartManeuverAction |
  CompleteManeuverAction |
  MoveManeuverAction |
  ChangePositionManeuverAction |
  ReadyManeuverAction |
  StowWeaponManeuverAction |
  DropWeaponManeuverAction |
  AimManeuverAction |
  SetAimAction |
  SetReloadAction |
  AttackManeuverAction |
  AllOutAttackManeuverAction |
  AllOutDefenseManeuverAction |
  DefendDodgeManeuverAction |
  DefendParryManeuverAction |
  LongActionManeuverAction |
  CompleteReloadAction |
  SetShockAction |
  SetStunAction |
  ChangePositionAction |
  DoBandagingAction |
  LoadStateAction;

// Actions that take a turn to execute, usually maneuvers
const nonAttackTurnActions: Array<CombatActions | SagaCombatActions> = [
  CombatActions.ManeuverMove,
  CombatActions.ManeuverChangePosition,
  CombatActions.ManeuverReady,
  CombatActions.ManeuverStowWeapon,
  CombatActions.ManeuverDropWeapon,
  CombatActions.ManeuverAim,
  CombatActions.ManeuverLongAction,
  // Attack maneuvers are not included here because they may not take an entire turn depending on the weapon
];

export interface ReadyManeuverSagaAction extends SagaCombatAction {
  type: SagaCombatActions.ManeuverReady,
  character: Character;
  weaponId: WeaponId;
}

export const readyManeuverSaga = (character: Character, weaponId: WeaponId): ReadyManeuverSagaAction => {
  return {
    type: SagaCombatActions.ManeuverReady,
    character,
    weaponId,
  };
};

export interface AttackManeuverSagaAction extends SagaCombatAction {
  type: SagaCombatActions.ManeuverAttack,
  character: Character;
  modifiers: Array<Modifier>;
  doubleAttack: boolean;
  attackPosition: PositionId;
  targetRange: number;
}

export const attackManeuverSaga = (character: Character, modifiers: Array<Modifier>, doubleAttack: boolean,
                                   attackPosition: PositionId, targetRange: number): AttackManeuverSagaAction => {
  return {
    type: SagaCombatActions.ManeuverAttack,
    character,
    modifiers,
    doubleAttack,
    attackPosition,
    targetRange,
  };
};

export interface DefendDodgeManeuverSagaAction extends SagaCombatAction {
  type: SagaCombatActions.ManeuverDefendDodge,
  character: Character;
}

export const defendDodgeManeuverSaga = (character: Character): DefendDodgeManeuverSagaAction => {
  return {
    type: SagaCombatActions.ManeuverDefendDodge,
    character,
  };
};

export interface DefendParryManeuverSagaAction extends SagaCombatAction {
  type: SagaCombatActions.ManeuverDefendParry,
  character: Character;
  parryingBarehandWithWeapon: boolean;
  parryingThreeTimesHeavierWeapon: boolean;
}

export const defendParryManeuverSaga = (
    character: Character,
    parryingBarehandWithWeapon: boolean,
    parryingThreeTimesHeavierWeapon: boolean
): DefendParryManeuverSagaAction => {
  return {
    type: SagaCombatActions.ManeuverDefendParry,
    character,
    parryingBarehandWithWeapon,
    parryingThreeTimesHeavierWeapon,
  };
};

export interface RollForDamageSagaAction extends SagaCombatAction, AttackResultData {
  type: SagaCombatActions.RollForDamage,
  weaponId: WeaponId;
  attackRollDifferential: number;
  targetDr: number;
  softTarget: boolean;
}

export const rollForDamageSaga = (
    weaponId: WeaponId,
    attackRollDifferential: number,
    targetDr: number,
    softTarget: boolean,
    normalHits: number,
    maxDamageHits: number,
    fragDamageHit?: boolean,
    impactDistanceToTarget?: number
): RollForDamageSagaAction => {
  return {
    type: SagaCombatActions.RollForDamage,
    weaponId,
    attackRollDifferential,
    targetDr,
    softTarget,
    normalHits,
    maxDamageHits,
    fragDamageHit,
    impactDistanceToTarget,
  };
};

export interface TakeDamageSagaAction extends SagaCombatAction {
  type: SagaCombatActions.TakeDamage,
  character: Character;
  damage: number;
  causesBleeding: boolean;
}

export const takeDamageSaga = (character: Character, damage: number, causesBleeding: boolean): TakeDamageSagaAction => {
  return {
    type: SagaCombatActions.TakeDamage,
    character,
    damage,
    causesBleeding,
  };
};

export interface CombatState {
  selectedCombatActionId: CombatActionId;
  attackRollResultToLoadAsDamageRoll: AttackRollResult | null;

  // Incremented every turn so we can track when events happened
  turnId: number;
  maneuverId: ManeuverId | null;
  activeDefenseHistory: Array<FreeActionId>;
  position: PositionId;
  status: CombatStatus;
  stunStatus: StunStatus;
  shockStatus: ShockStatus;
  shockDamage: number;
  // For RoF >= 1, an attack is over when attacksThisTurn = RoF
  // This counts number of shots fired for semiauto, or number of groups fired for full auto
  attacksThisTurn: number;
  // For RoF < 1, a attack is over when turnsThisAttack = 1/RoF
  turnsThisAttack: number;
  // The id of the last turn on which a gun attack took place. For tracking recoil.
  lastGunAttackTurnId: number;
  // Was the last gun attack with an automatic weapon. Recoil is handled differently for automatic weapons.
  lastGunAttackAutomatic: boolean;
  weaponReadyStatus: ReadyStatusUnion | null;

  haveBandagedSinceLastFight: boolean;
}

export interface BaseReadyStatus {
  type: WeaponType;
  weapon: WeaponId;
  unsling: number;
}

export interface GunReadyStatus extends BaseReadyStatus {
  reload: number;
  cock: number;
  aim: number;
  braced: boolean;
}

export interface HandWeaponReadyStatus extends BaseReadyStatus {
  backswing: number;
}

export type ReadyStatusUnion = GunReadyStatus | HandWeaponReadyStatus;

export const getGunReadyStatus = (combatState: CombatState): GunReadyStatus | null => {
  if (combatState.weaponReadyStatus === null || combatState.weaponReadyStatus.type !== WeaponType.Guns) {
    return null;
  }
  return combatState.weaponReadyStatus as GunReadyStatus;
};

const initialCombatLoad = JSON.parse(window.localStorage.getItem(COMBAT_LOCAL_STORAGE_KEY) as string) || {};
export const initialCombatState: CombatState = Object.values(initialCombatLoad).length > 0 ? initialCombatLoad : {
  selectedCombatActionId: ManeuverId.Move,
  attackRollResultToLoadAsDamageRoll: null,

  turnId: 0,
  maneuverId: null,
  activeDefenseHistory: [],
  position: PositionId.Standing,
  status: CombatStatus.NotInCombat,
  stunStatus: StunStatus.Lucid,
  shockStatus: ShockStatus.Lucid,
  shockDamage: 0,
  attacksThisTurn: 0,
  turnsThisAttack: 0,
  lastGunAttackTurnId: -1000,
  lastGunAttackAutomatic: false,
  weaponReadyStatus: null,
  haveBandagedSinceLastFight: true,
};

export function combatReducer(state: CombatState = initialCombatState, action: CombatActionTypes): CombatState {
  // If this is an action that requires a turn to execute, increment our turn counter
  if (nonAttackTurnActions.indexOf(action.type) !== -1) {
    state.turnId++;
    state.activeDefenseHistory = [];
    // If this action is not an attack, reset the multi-attack counter
    state.attacksThisTurn = 0;
  }
  switch (action.type) {
    case CombatActions.SetSelectedCombatAction:
      return {
        ...state,
        selectedCombatActionId: action.combatActionId,
      };
    case CombatActions.LoadDamageRoll:
      return {
        ...state,
        attackRollResultToLoadAsDamageRoll: action.attackRollResult,
      };
    case CombatActions.FinishLoadingDamageRoll:
      return {
        ...state,
        attackRollResultToLoadAsDamageRoll: null,
      };
    case CombatActions.StartCombat:
      return {
        ...state,
        maneuverId: null,
        status: CombatStatus.Idle,
        haveBandagedSinceLastFight: false,
      };
    case CombatActions.EndCombat:
      return {
        ...state,
        maneuverId: null,
        status: CombatStatus.NotInCombat,
        weaponReadyStatus: null,
      };
    case CombatActions.CompleteTurn:
      return {
        ...state,
        maneuverId: null,
        status: CombatStatus.NotInCombat,
      };
    case CombatActions.StartManeuver:
      return {
        ...state,
      };
    case CombatActions.CompleteManeuver:
      return {
        ...state,
        maneuverId: null,
        status: CombatStatus.Idle,
      };
    case CombatActions.ManeuverMove:
      return {
        ...state,
        maneuverId: ManeuverId.Move,
      };
    case CombatActions.ManeuverChangePosition:
    case CombatActions.ChangePosition:
      return {
        ...state,
        maneuverId: ManeuverId.ChangePosition,
        position: action.newPosition,
        status: CombatStatus.Idle,
      };
    case CombatActions.ManeuverReady:
      return {
        ...state,
        maneuverId: ManeuverId.Ready,
        status: CombatStatus.Idle,
        weaponReadyStatus: action.readyStatus,
      };
    case CombatActions.CompleteReload: {
      let readyStatus = state.weaponReadyStatus as GunReadyStatus;
      return {
        ...state,
        weaponReadyStatus: {
          ...readyStatus,
          reload: 0,
        }
      };
    }
    case CombatActions.ManeuverDropWeapon:
      return {
        ...state,
        weaponReadyStatus: null,
        maneuverId: ManeuverId.Ready,
        status: CombatStatus.Idle,
      };
    case CombatActions.ManeuverStowWeapon:
      if (!state.weaponReadyStatus) {
        return {
          ...state,
          maneuverId: ManeuverId.Ready,
          status: CombatStatus.Idle,
        };
      }
      let newUnsling = state.weaponReadyStatus.unsling - 1;
      // Weapon is now completely slung
      if (newUnsling <= 0) {
        return {
          ...state,
          maneuverId: ManeuverId.Ready,
          status: CombatStatus.Idle,
          weaponReadyStatus: null,
        };
      }
      return {
        ...state,
        maneuverId: ManeuverId.Ready,
        status: CombatStatus.Idle,
        weaponReadyStatus: {
          ...state.weaponReadyStatus,
          unsling: newUnsling,
        },
      };
    case CombatActions.ManeuverAim: {
      if (!state.weaponReadyStatus || state.weaponReadyStatus.type === WeaponType.HandWeapons) {
        throw new Error(`Can't aim an invalid weapon`);
      }
      let gunReadyStatus = state.weaponReadyStatus as GunReadyStatus;
      let newAim = Math.min(gunReadyStatus.aim + 1, MAX_AIM);
      return {
        ...state,
        maneuverId: ManeuverId.Aim,
        weaponReadyStatus: {
          ...state.weaponReadyStatus,
          aim: newAim,
          braced: action.braced,
        },
      };
    }
    case CombatActions.SetAim: {
      if (!state.weaponReadyStatus || state.weaponReadyStatus.type === WeaponType.HandWeapons) {
        throw new Error(`Can't aim an invalid weapon`);
      }
      return {
        ...state,
        weaponReadyStatus: {
          ...state.weaponReadyStatus,
          aim: action.aim,
        },
      };
    }
    case CombatActions.SetReload: {
      if (!state.weaponReadyStatus || state.weaponReadyStatus.type === WeaponType.HandWeapons) {
        throw new Error(`Can't reload an invalid weapon`);
      }
      return {
        ...state,
        weaponReadyStatus: {
          ...state.weaponReadyStatus,
          reload: action.reload,
        },
      };
    }
    case CombatActions.ManeuverAttack: {
      if (!state.weaponReadyStatus) {
        throw new Error(`Can't attack without a ready weapon`);
      }
      let turnId = state.turnId;
      let activeDefenseHistory = state.activeDefenseHistory;
      // Turn is complete if a multi-attack is done or if it's a multi-turn attack
      let isTurnComplete = action.attacksThisTurn === 0;
      if (isTurnComplete) {
        turnId++;
        activeDefenseHistory = [];
      }
      return {
        ...state,
        turnId,
        activeDefenseHistory,
        maneuverId: ManeuverId.Attack,
        weaponReadyStatus: action.readyStatus,
        attacksThisTurn: action.attacksThisTurn,
        turnsThisAttack: action.turnsThisAttack,
        lastGunAttackTurnId: state.turnId,
      };
    }
    case CombatActions.ManeuverAllOutDefense:
      return {
        ...state,
        maneuverId: ManeuverId.AllOutDefense,
      };
    case CombatActions.ManeuverLongAction:
      return {
        ...state,
        maneuverId: ManeuverId.LongAction,
      };
    case CombatActions.ManeuverDefendDodge:
      return {
        ...state,
        activeDefenseHistory: [...state.activeDefenseHistory, FreeActionId.DefendDodge],
      };
    case CombatActions.ManeuverDefendParry:
      return {
        ...state,
        activeDefenseHistory: [...state.activeDefenseHistory, FreeActionId.DefendParry],
      };
    case CombatActions.SetShock:
      return {
        ...state,
        shockStatus: action.shock,
        shockDamage: action.shockDamage,
      };
    case CombatActions.SetStun:
      return {
        ...state,
        stunStatus: action.stun,
      };
    case CombatActions.DoBandaging:
      return {
        ...state,
        haveBandagedSinceLastFight: true,
      };
    case CombatActions.LoadState: {
      window.localStorage.setItem(COMBAT_LOCAL_STORAGE_KEY, JSON.stringify(action.combat));
      return {...action.combat};
    }
    default:
      return state
  }
}
