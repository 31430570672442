import React from 'react';
import {Col, ListGroup, Row,} from 'react-bootstrap';
import {CenteredFlexCol} from "../../../../lib/components/CenteredFlexCol";

const getLogItemId = (index: number) => {
  return `log-item-${index}`;
};

interface Props {
  logs: Array<string>,
}

export default class LogPanel extends React.Component<Props> {
  // private listGroupRef = React.createRef<HTMLDivElement>();

  componentDidUpdate(prevProps: Props, prevState: {}) {
    let oldLogs = prevProps.logs;
    let newLogs = this.props.logs;
    // If the list of logs updated size
    if (newLogs.length > 0 && newLogs.length !== oldLogs.length) {
      // Scroll to the bottom
      let bottomNode = document.querySelector('.log.list-group>.list-group-item:last-child');
      if (bottomNode) {
        bottomNode.scrollIntoView(false);
      }
    }
  }

  render() {
    let {
      /** @type {string[]} */
      logs,
    } = this.props;

    return <Col xs={12} md={4} className='content-panel'>
      <Row className='justify-content-center'>
        <h4>Log</h4>
      </Row>
      <Row className='log-list'>
        <CenteredFlexCol isRow={false} className='h-100'>
          {/*<ListGroup className='log' ref={this.listGroupRef}>*/}
          <ListGroup className='log scrollable'>
            {logs.map((log, index) => {
              let id = getLogItemId(index);
              return <ListGroup.Item id={id} key={id}>
                {log}
              </ListGroup.Item>;
            })}
          </ListGroup>
        </CenteredFlexCol>
      </Row>
    </Col>
  }
}
