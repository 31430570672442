import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Character} from "../constants/characterConstants";
import {CombatState} from "../../modules/combat";
import {getCharacterStatusIcons} from "../gameLogic/derivedCharacterStats";

interface Props {
    character: Character;
    combat: CombatState;
    className?: string;
}

export const CharacterConditionArray: React.FunctionComponent<Props> = (props: Props) => {
    let {
        className = '',
        character,
        combat,
        ...rest
    } = props;
    return <>
        {getCharacterStatusIcons(character, combat).map(icon => {
            return <FontAwesomeIcon {...rest}
                key={icon.id}
                icon={icon.icon}
                title={icon.name}
                style={{color: icon.iconColor}}
                className='status-icon'
                size='2x'
            />
        })}
    </>;
};