import React from 'react';
import {connect} from 'react-redux';
import {Container, Row,} from 'react-bootstrap';
import AttributePanel from "./panels/AttributePanel";
import FatiguePanel from "./panels/FatiguePanel";
import {conditionEventSaga, setFatiguePoints} from "../../../modules/character";
import LogPanel from "./panels/LogPanel";
import RollPanel from "./panels/RollPanel";
import {executeRoll, fatigueRollSaga, initRoll} from "../../../modules/roll";
import {AppState} from "../../../modules";
import {ConditionPanel} from "./panels/ConditionPanel";
import {bindActionCreators} from "redux";
import {setConditionEventResult} from "../../../modules/characterUi";

class StatusPage extends React.Component<StatusPageProps> {
  render() {
    let {
      character,
      logs,
      setFatiguePoints,
      initRoll,
      executeRoll,
    } = this.props;
    // let coinsBySlot = getCoinsBySlot(coinSlots);
    // let sideErrors = this.getSideErrors(coinsBySlot, coinSides);
    // let colorErrors = this.getColorErrors(coinsBySlot);
    // Status
    // Combat
    // Skills
    // Inventory
    // Equipment
    // Customize

    if (Object.values(character).length === 0) {
      return <h2>No Character loaded</h2>;
    }

    return <Container fluid={true} className='content-container'>
      <Row className='top-row'>
        <AttributePanel {...this.props} />
        <ConditionPanel {...this.props} />
        <FatiguePanel
          {...this.props}
        />
      </Row>
      <Row className='bottom-row'>
        <RollPanel/>
        <LogPanel
          logs={logs}
        />
      </Row>
    </Container>;
  }
}

export interface RollProps {
  initRoll: typeof initRoll;
  executeRoll: typeof executeRoll;
  fatigueRollSaga: typeof fatigueRollSaga;
}

export interface StatusActionProps extends RollProps {
  setFatiguePoints: typeof setFatiguePoints;
}

const mapStateToProps = (state: AppState) => {
  // Consider using reselect to memoize derived values here if performance becomes an issue
  return {
    combat: state.combatReducer,
    ...state.characterUiReducer,
    ...state.characterReducer,
  };
};

export type StatusPageProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  setFatiguePoints,
  initRoll,
  executeRoll,
  fatigueRollSaga,
  conditionEventSaga,
  setConditionEventResult,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatusPage)
