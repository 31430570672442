// ------------------------------------
// Constants
// ------------------------------------
import {FatigueEvent} from "../lib/constants/fatigueConstants";
import {Character} from "../lib/constants/characterConstants";
import {RollResult, RollSpec} from "../lib/constants/rollConstants";

// ------------------------------------
// Actions
// ------------------------------------
export enum RollActions {
  InitRoll = '@@roll/InitRoll',
  ExecuteRoll = '@@roll/ExecuteRoll',
  ClearRollPanel = '@@roll/ClearRollPanel',
  FatigueRollSaga = '@@roll/FatigueRollSaga',
  LoadDamageRollSignal = '@@roll/LoadDamageRollSignal',
}

export type RollActionTypes = ClearRollPanelAction | InitRollAction | ExecuteRollAction | LoadDamageRollSignalAction;
export type RollSagaTypes = FatigueRollSagaAction;

interface RollAction {
  type: RollActions;
}

export interface InitRollAction extends RollAction {
  type: RollActions.InitRoll;
  spec: RollSpec;
}
export const initRoll = (spec: RollSpec): InitRollAction => {
  return {
    type: RollActions.InitRoll,
    spec,
  };
};

export interface ExecuteRollAction extends RollAction {
  type: RollActions.ExecuteRoll;
  result: RollResult;
}
export const executeRoll = (result: RollResult): ExecuteRollAction => {
  return {
    type: RollActions.ExecuteRoll,
    result,
  };
};

export interface ClearRollPanelAction extends RollAction {
  type: RollActions.ClearRollPanel;
}
export const clearRollPanel = (): ClearRollPanelAction => {
  return {
    type: RollActions.ClearRollPanel,
  };
};

export interface FatigueRollSagaAction extends RollAction {
  type: RollActions.FatigueRollSaga;
  event: FatigueEvent;
  character: Character;
}
export const fatigueRollSaga = (event: FatigueEvent, character: Character): FatigueRollSagaAction => {
  return {
    type: RollActions.FatigueRollSaga,
    event,
    character,
  };
};

export interface LoadDamageRollSignalAction extends RollAction {
  type: RollActions.LoadDamageRollSignal;
}
export const loadDamageRollSignal = (): LoadDamageRollSignalAction => {
  return {
    type: RollActions.LoadDamageRollSignal,
  };
};

export interface RollState {
  rollSpec: RollSpec | null,
  rollResult: RollResult | null,
}

const initialState: RollState = {
  rollSpec: null,
  rollResult: null,
};

export function rollReducer(state: RollState = initialState, action: RollActionTypes): RollState {
  switch (action.type) {
    case RollActions.InitRoll:
      return {
        ...initialState,
        rollSpec: action.spec,
      };
    case RollActions.ExecuteRoll:
      return {
        ...state,
        rollResult: action.result,
      };
    // case RollActions.InitAttackRoll:
    //   return {
    //     ...initialState,
    //     rollType: RollType.Attack,
    //     forTitle: action.forTitle,
    //     against: action.characteristic,
    //     modifiers: action.modifiers,
    //   };
    // case RollActions.ExecuteAttackRoll:
    //   return {
    //     ...state,
    //     rollResult: action.result,
    //   };
    case RollActions.ClearRollPanel:
      return initialState;
    default:
      return state
  }
}
