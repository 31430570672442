import {LOCAL_STORAGE_PREFIX} from "./constants";
import {Character} from "./characterConstants";

export const AUTOSAVE_INTERVAL_MILLISECONDS = 30000;
export const AUTOSAVE_COUNT = 5;

// export const DATA_INDEX_LOCAL_STORAGE_KEY = `${LOCAL_STORAGE_PREFIX}_data_index`;
export const DATA_FILE_LOCAL_STORAGE_PREFIX = `${LOCAL_STORAGE_PREFIX}.data_file_prefix`;
export const CURRENT_CHARACTER_ID_LOCAL_STORAGE_KEY = `${DATA_FILE_LOCAL_STORAGE_PREFIX}.current_character_id`;
export const CHARACTER_FILE_LOCAL_STORAGE_PREFIX = `${DATA_FILE_LOCAL_STORAGE_PREFIX}.character_file`;
export const CHARACTER_AUTOSAVES_LOCAL_STORAGE_PREFIX = `${DATA_FILE_LOCAL_STORAGE_PREFIX}.character_autosaves`;

// Doesn't actually do anything, but makes the code more readable
export type UUIDv1 = string;

export enum DataFileType {
    Character = '@@dataFileType/character',
}

export enum SaveType {
    File = '@@saveType/file',
    Autosaves = '@@saveType/autosaves',
}

export interface SaveId {
    id: UUIDv1;
    autosaveTimestamp: number | null;
}

export interface DataFile {
    id: UUIDv1;
    type: DataFileType;
    lastSavedHash: string;
    lastSavedTimestamp: number;
    content: Object;
}

export interface CharacterFile extends DataFile {
    type: DataFileType.Character;
    content: Character;
}

export const getFilePrefix = (type: DataFileType): string => {
    switch (type) {
        case DataFileType.Character:
            return CHARACTER_FILE_LOCAL_STORAGE_PREFIX;
    }
};

export const getAutosavePrefix = (type: DataFileType): string => {
    switch (type) {
        case DataFileType.Character:
            return CHARACTER_AUTOSAVES_LOCAL_STORAGE_PREFIX;
    }
};

export const getLocalStorageFileKey = (id: UUIDv1, type: DataFileType): string => {
    return `${getFilePrefix(type)}.${id}`;
};

export const getLocalStorageAutosavesKey = (id: UUIDv1, type: DataFileType): string => {
    return `${getAutosavePrefix(type)}.${id}`;
};
