import {Attribute, AttributeId} from "./attributeConstants";
import {Skill, SkillId} from "./skillConstants";
import {FatigueType} from "./fatigueConstants";
import {Vantage, VantageId} from "./vantageConstants";
import {Equipment, EquipmentId} from "./equipmentConstants";
import {RollResultTypeId} from "./gurpsConstants";
import {CharacterConditionEventId, InjuryId} from "./characterConditionConstants";

export interface Character {
  name: string;
  totalPoints: number;
  /** in dolla dolla bills, y'all */
  money: number;
  fatiguePoints: Record<FatigueType, number>;
  attributes: { [index in AttributeId]: Attribute };
  vantages: { [index in VantageId]?: Vantage };
  skills: { [index in SkillId]?: Skill };
  inventory: { [index in EquipmentId]?: Equipment };
  damageTaken: number;
  injuries: { [index in InjuryId]?: boolean };
  consecutiveBleedingCheckSuccesses: number;
  conditionEvents: { [index in CharacterConditionEventId]?: RollResultTypeId };
}

export const blankCharacter: Character = {
  name: 'New Character',
  totalPoints: 0,
  money: 0,
  vantages: {},
  attributes: {
    [AttributeId.Health]: {
      id: AttributeId.Health,
      level: 10,
    },
    [AttributeId.Strength]: {
      id: AttributeId.Strength,
      level: 10,
    },
    [AttributeId.Dexterity]: {
      id: AttributeId.Dexterity,
      level: 10,
    },
    [AttributeId.Intelligence]: {
      id: AttributeId.Intelligence,
      level: 10,
    }
  },
  skills: {},
  fatiguePoints: {
    [FatigueType.Normal]: 0,
    [FatigueType.Starvation]: 0,
    [FatigueType.Dehydration]: 0,
    [FatigueType.Sleep]: 0,
  },
  inventory: {},
  damageTaken: 0,
  injuries: {},
  conditionEvents: {},
  consecutiveBleedingCheckSuccesses: 0,
};

export interface CharacterTemplate  {
  pointCost: number;
  startingAttributes: Record<string, Attribute>;
}

export const TEMPLATE_RIFLEMAN = 'rifleman';

export const characterTemplates: Record<string, CharacterTemplate> = {
  // [TEMPLATE_RIFLEMAN]: {
  //   startingAttributes: {
  //     [AttributeId.Strength]: 11,
  //     [AttributeId.Dexterity]: 11,
  //     [AttributeId.Intelligence]: 11,
  //     [AttributeId.Health]: 11,
  //   }
  // },
};

export const validateCharacterJson = (input: Object): input is Character => {
  // TODO: more thorough validation
  for (let key in blankCharacter) {
    if (!input.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
};
