import React from 'react';
import {Button, Col, Table,} from 'react-bootstrap';
import {dateTimeFormatter, getObjectHash} from "../../../../lib/utils";
import {CenteredRow} from "../../../../lib/components/CenteredRow";
import {CenteredFlexCol} from "../../../../lib/components/CenteredFlexCol";
import {DataPageProps} from "../DataPage";
import {CharacterFile, SaveId, SaveType, UUIDv1} from "../../../../lib/constants/dataConstants";
import {getCharacterForSaveId} from "../../../../lib/gameLogic/dataLogic";
import {CenteredFlexDiv} from "../../../../lib/components/CenteredFlexDiv";

interface State {
    selectedSave: SaveId | null;
}

interface Props extends DataPageProps {
}

enum SaveTableColumn {
    CharacterName = '@@column/CharacterName',
    LastSaved = '@@column/LastSaved',
    IsLoadedCharacter = '@@column/IsCurrentCharacter',
    IsSaved = '@@column/IsSaved',
}

enum SaveTableId {
    Files = '@@saveTable/Files',
    Autosaves = '@@saveTable/Autosaves',
}

interface SaveTable {
    id: SaveTableId;
    description: string;
    columns: Array<TableColumn>;
    rowCallback: {(panel: DataPanel): JSX.Element | null};
}

interface ValueCallback {
    (file: CharacterFile, state: State, props: Props, currentCharacterHash: string): string
}

interface TableColumn {
    column: SaveTableColumn;
    displayHeader: string;
    valueCallback: ValueCallback;
}

const nameColumn: TableColumn = {
    column: SaveTableColumn.CharacterName,
    displayHeader: 'Name',
    valueCallback: (file, state, props, currentCharacterHash) => file.content.name,
};

const lastSavedColumn: TableColumn = {
    column: SaveTableColumn.LastSaved,
    displayHeader: 'Last Saved At',
    valueCallback: (file, state, props, currentCharacterHash) => dateTimeFormatter(file.lastSavedTimestamp),
};

const isLoadedColumn: TableColumn = {
    column: SaveTableColumn.IsLoadedCharacter,
    displayHeader: 'Loaded',
    valueCallback: (file, state, props, currentCharacterHash) => file.id === props.data.currentCharacterId ? '✓' : '',
};

const isSavedColumn: TableColumn = {
    column: SaveTableColumn.IsSaved,
    displayHeader: 'Current',
    valueCallback: (file, state, props, currentCharacterHash) => file.lastSavedHash === currentCharacterHash ? '✓' : '',
};

const renderSaveRows = (panel: DataPanel): JSX.Element | null => {
    let {
        data: {
            characterFiles,
        },
    } = panel.props;
    let {
        selectedSave,
    } = panel.state;
    let currentCharacterHash = getObjectHash(panel.props.character.character);

    return <tbody>
    {characterFiles.map(file => {
        return <tr key={`row-${file.id}`} style={{backgroundColor: file.id === selectedSave?.id ? 'cornflowerblue' : ''}}>
            {tables[SaveTableId.Files].columns.map((columnDef) => {
                return <td
                    key={`row-${file.id}-column-${columnDef.column}`}
                    onClick={panel.handleClickRow.bind(panel, SaveType.File, file.id, null)}
                >
                    {columnDef.valueCallback(file, panel.state, panel.props, currentCharacterHash)}
                </td>
            })}
        </tr>;
    })}
    </tbody>;
};

const renderAutosaveRows = (panel: DataPanel): JSX.Element | null => {
    let {
        data: {
            characterAutosaves,
        },
    } = panel.props;
    let {
        selectedSave,
    } = panel.state;
    let currentCharacterHash = getObjectHash(panel.props.character.character);

    let selected = selectedSave;
    if (!selected || !characterAutosaves[selected.id]) {
        return null;
    }

    return <tbody>
    {characterAutosaves[selected.id].map(file => {
        return <tr key={`autosave-${file.lastSavedTimestamp}`} style={{backgroundColor: file.lastSavedTimestamp === selectedSave?.autosaveTimestamp ? 'cornflowerblue' : ''}}>
            {tables[SaveTableId.Autosaves].columns.map((columnDef) => {
                return <td
                    key={`autosave-${file.lastSavedTimestamp}-column-${columnDef.column}`}
                    onClick={panel.handleClickRow.bind(panel, SaveType.Autosaves, file.id, file.lastSavedTimestamp)}
                >
                    {columnDef.valueCallback(file, panel.state, panel.props, currentCharacterHash)}
                </td>
            })}
        </tr>;
    })}
    </tbody>;
};

const tables: Record<SaveTableId, SaveTable> = {
    [SaveTableId.Files]: {
        id: SaveTableId.Files,
        description: 'Saved Characters',
        columns: [nameColumn, lastSavedColumn, isLoadedColumn, isSavedColumn],
        rowCallback: renderSaveRows,
    },
    [SaveTableId.Autosaves]: {
        id: SaveTableId.Autosaves,
        description: 'Autosaves',
        columns: [nameColumn, lastSavedColumn, isSavedColumn],
        rowCallback: renderAutosaveRows,
    },
};

export class DataPanel extends React.Component<DataPageProps, State> {
    constructor(props: DataPageProps) {
        super(props);
        this.state = {
            selectedSave: null,
        };
    }

    render() {
        return <Col className='content-panel'>
            <CenteredRow className='main-row'>
                <CenteredFlexCol className='h-100' isRow={false} md={8}>
                    {Object.values(tables).map(table => {
                        return <CenteredFlexDiv isRow={false} key={table.id} className='full-width'>
                            <h4 key={`header-${table.id}`}>{table.description}</h4>
                            <Table key={`table-${table.id}`} striped bordered hover size='sm'>
                                {this.renderHeader(table)}
                                {table.rowCallback(this)}
                            </Table>
                        </CenteredFlexDiv>;
                    })}
                </CenteredFlexCol>
                <CenteredFlexCol isRow={false} md={4}>
                    {this.renderActions()}
                </CenteredFlexCol>
            </CenteredRow>
        </Col>
    }

    renderHeader(table: SaveTable) {
        return <thead>
            <tr>
                {table.columns.map((columnDef) => {
                    return <th key={columnDef.column}>{columnDef.displayHeader}</th>
                })}
            </tr>
        </thead>;
    }

    handleClickRow(type: SaveType, id: UUIDv1, timestamp: number | null) {
        switch (type) {
            case SaveType.Autosaves:
                if (!timestamp) {
                    throw new Error(`Can't handle autosave click event without timestamp`);
                }
                this.setState({
                    selectedSave: {
                        id,
                        autosaveTimestamp: timestamp,
                    },
                });
                break;
            case SaveType.File:
                this.setState({
                    selectedSave: {
                        id,
                        autosaveTimestamp: null,
                    },
                });
                break;
        }
    }

    loadClick() {
        let {
            loadCharacterSaga,
        } = this.props;
        let {
            selectedSave,
        } = this.state;
        if (selectedSave) {
            loadCharacterSaga(selectedSave);
        }
    }

    saveClick() {
        let {
            data: {
                currentCharacterId,
            },
            saveCharacterSaga,
        } = this.props;
        saveCharacterSaga(currentCharacterId);
    }

    saveAsClick() {
        let {
            saveAsCharacterSaga,
        } = this.props;
        saveAsCharacterSaga();
    }

    deleteClick() {
        let {
            deleteCharacterSaga,
        } = this.props;
        let {
            selectedSave,
        } = this.state;
        if (selectedSave) {
            deleteCharacterSaga(selectedSave.id);
        }
        this.setState({
            selectedSave: null,
        });
    }

    importClick() {
        let {
            importCharacterSaga,
        } = this.props;
        importCharacterSaga();
    }

    exportClick() {
        let {
            exportCharacterSaga,
        } = this.props;
        let {
            selectedSave,
        } = this.state;
        if (selectedSave) {
            exportCharacterSaga(selectedSave);
        }
    }

    renderActions() {
        let {
            character: {
                character,
            },
            data: {
                currentCharacterId,
                characterFiles,
            },
        } = this.props;
        let {
            selectedSave,
        } = this.state;

        let currentCharacterHash = getObjectHash(character);
        let fileIndex = characterFiles.findIndex(file => file.id === currentCharacterId);
        let isDirty = fileIndex >= 0 ? characterFiles[fileIndex].lastSavedHash !== currentCharacterHash : true;
        let selectedFile = selectedSave ? getCharacterForSaveId(selectedSave) : null;

        return <>
            <Button variant='primary' disabled={!isDirty} onClick={this.saveClick.bind(this)}>Save Current Character</Button>
            <Button variant='primary' onClick={this.saveAsClick.bind(this)}>Save Current Character As New</Button>
            <Button
                variant='primary'
                disabled={!selectedSave || (!!selectedFile && selectedFile.lastSavedHash === currentCharacterHash)}
                onClick={this.loadClick.bind(this)}
            >Load Selected</Button>
            <Button variant='primary' disabled={!selectedSave} onClick={this.deleteClick.bind(this)}>Delete Selected</Button>
            <Button variant='primary' onClick={this.importClick.bind(this)}>Import as New</Button>
            <Button variant='primary' disabled={!selectedSave} onClick={this.exportClick.bind(this)}>Export Selected</Button>
        </>;
    }
}
