import {CharacterFile, DataFileType, SaveId, UUIDv1} from "../lib/constants/dataConstants";
import v1 from "uuid/v1";
import {
    getAllLocalStorageIds,
    getCurrentCharacterId,
    getLocalStorageAutosaves,
    getLocalStorageFile,
    writeCurrentCharacterId
} from "../lib/localStorage/localStorageIo";
import {arrayRemoveToNew, arrayReplaceToNew} from "../lib/utils";
import {Character} from "../lib/constants/characterConstants";
import {buildCharacterFile, getCharacterForSaveId} from "../lib/gameLogic/dataLogic";

// ------------------------------------
// Constants
// ------------------------------------

// ------------------------------------
// Actions
// ------------------------------------

export enum DataActions {
    Save = '@@data/Save',
    Delete = '@@data/Delete',
    Load = '@@data/Load',
    UpdateAutosaves = '@@data/UpdateAutosaves',
}

export enum SagaDataActions {
    Load = '@@dataSaga/Load',
    Save = '@@dataSaga/Save',
    SaveAs = '@@dataSaga/SaveAs',
    Import = '@@dataSaga/Import',
    Export = '@@dataSaga/Export',
    Delete = '@@dataSaga/Delete',
}

interface DataAction {
    type: DataActions;
}

interface DataSagaAction {
    type: SagaDataActions;
}

interface DeleteFileAction extends DataAction {
    type: DataActions.Delete;
    id: UUIDv1,
}
export const deleteFile = (id: UUIDv1): DeleteFileAction => {
    return {
        type: DataActions.Delete,
        id,
    };
};

interface SaveAction extends DataAction {
    type: DataActions.Save;
    id: UUIDv1;
    fileType: DataFileType;
    content: Object;
}
export const save = (id: UUIDv1, fileType: DataFileType, content: Object): SaveAction => {
    return {
        type: DataActions.Save,
        id,
        fileType,
        content,
    };
};

interface LoadAction extends DataAction {
    type: DataActions.Load;
    saveId: SaveId;
    fileType: DataFileType;
}
export const load = (saveId: SaveId, fileType: DataFileType): LoadAction => {
    return {
        type: DataActions.Load,
        saveId,
        fileType,
    };
};

interface UpdateAutosavesAction extends DataAction {
    type: DataActions.UpdateAutosaves;
    id: UUIDv1;
    autosaves: Array<CharacterFile>;
    fileType: DataFileType;
}
export const updateAutosaves = (id: UUIDv1, autosaves: Array<CharacterFile>, fileType: DataFileType): UpdateAutosavesAction => {
    return {
        type: DataActions.UpdateAutosaves,
        id,
        autosaves,
        fileType,
    };
};

export interface LoadCharacterSagaAction extends DataSagaAction {
    type: SagaDataActions.Load;
    id: SaveId;
}
export const loadCharacterSaga = (id: SaveId): LoadCharacterSagaAction => {
    return {
        type: SagaDataActions.Load,
        id,
    };
};

export interface SaveCharacterSagaAction extends DataSagaAction {
    type: SagaDataActions.Save;
    id: UUIDv1;
}
export const saveCharacterSaga = (id: UUIDv1): SaveCharacterSagaAction => {
    return {
        type: SagaDataActions.Save,
        id,
    };
};

export interface SaveAsCharacterSagaAction extends DataSagaAction {
    type: SagaDataActions.SaveAs;
}
export const saveAsCharacterSaga = (): SaveAsCharacterSagaAction => {
    return {
        type: SagaDataActions.SaveAs,
    };
};

export interface ImportCharacterSagaAction extends DataSagaAction {
    type: SagaDataActions.Import;
}
export const importCharacterSaga = (): ImportCharacterSagaAction => {
    return {
        type: SagaDataActions.Import,
    };
};

export interface ExportCharacterSagaAction extends DataSagaAction {
    type: SagaDataActions.Export;
    id: SaveId,
}
export const exportCharacterSaga = (id: SaveId): ExportCharacterSagaAction => {
    return {
        type: SagaDataActions.Export,
        id,
    };
};

export interface DeleteCharacterSagaAction extends DataSagaAction {
    type: SagaDataActions.Delete;
    id: UUIDv1,
}
export const deleteCharacterSaga = (id: UUIDv1): DeleteCharacterSagaAction => {
    return {
        type: SagaDataActions.Delete,
        id,
    };
};

export type DataActionTypes = SaveAction | DeleteFileAction | LoadAction | UpdateAutosavesAction;
export type CharacterAutosaves = Record<UUIDv1, CharacterFile[]>;

export interface DataState {
    currentCharacterId: string;
    characterFiles: CharacterFile[];
    characterAutosaves: CharacterAutosaves;
}

// TODO: extend this to other file types if we want
let storedLastCharacterId = getCurrentCharacterId();
if (!storedLastCharacterId) {
    storedLastCharacterId = v1();
    writeCurrentCharacterId(storedLastCharacterId);
}
const storedCharacterIds = getAllLocalStorageIds(DataFileType.Character);
const initialCharacterFiles: CharacterFile[] = storedCharacterIds
    .map(id => getLocalStorageFile(id, DataFileType.Character))
    .filter((file): file is CharacterFile => !!file);
const initialCharacterAutosaves: CharacterAutosaves = storedCharacterIds
    .map(id => getLocalStorageAutosaves(id, DataFileType.Character))
    .reduce((carry, autosaveArray) => {
        if (autosaveArray.length === 0) {
            return carry;
        }
        let id = autosaveArray[0].id;
        carry[id] = autosaveArray;
        return carry;
    }, {} as CharacterAutosaves);

const initialState: DataState = {
    currentCharacterId: storedLastCharacterId,
    characterFiles: initialCharacterFiles,
    characterAutosaves: initialCharacterAutosaves,
};

export function dataReducer(state = initialState, action: DataActionTypes): DataState {
    switch (action.type) {
        case DataActions.Save: {
            let saveTargetIndex = state.characterFiles.findIndex(file => file.id === action.id);
            let newFile = buildCharacterFile(action.id, action.content as Character);
            let newFiles: CharacterFile[];
            if (saveTargetIndex === -1) {
                newFiles = [newFile, ...state.characterFiles];
            } else {
                newFiles = arrayReplaceToNew(state.characterFiles, saveTargetIndex, newFile);
            }
            return {
                ...state,
                characterFiles: newFiles,
            }
        }
        case DataActions.Delete: {
            let deleteTargetIndex = state.characterFiles.findIndex(file => file.id === action.id);
            if (deleteTargetIndex === -1) {
                return state;
            }
            let newFiles = arrayRemoveToNew(state.characterFiles, deleteTargetIndex, deleteTargetIndex + 1);
            let newAutosaves = {...state.characterAutosaves};
            delete newAutosaves[action.id];
            return {
                ...state,
                characterFiles: newFiles,
                characterAutosaves: newAutosaves,
            }
        }
        case DataActions.Load: {
            let targetFile = getCharacterForSaveId(action.saveId);
            if (!targetFile) {
                return state;
            }
            let castedTargetFile = targetFile;
            let targetIndex = state.characterFiles.findIndex(file => file.id === castedTargetFile.id);
            let newFiles = arrayReplaceToNew(state.characterFiles, targetIndex, castedTargetFile);
            return {
                ...state,
                currentCharacterId: action.saveId.id,
                characterFiles: newFiles,
            }
        }
        case DataActions.UpdateAutosaves: {
            return {
                ...state,
                characterAutosaves: {
                    ...state.characterAutosaves,
                    [action.id]: action.autosaves,
                },
            };
        }
        default:
            return state;
    }
}
