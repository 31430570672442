import {blankCharacter, Character,} from "../lib/constants/characterConstants";
import {EquipmentDefinition, EquipmentType, getEquipmentType} from "../lib/constants/equipmentConstants";
import {Weapon, weaponDefinitions, WeaponId} from "../lib/constants/weaponConstants";
import {GearId} from "../lib/constants/gearConstants";
import {getAttributeUnmodifiedValue, getIsInstantlyDead} from "../lib/gameLogic/derivedCharacterStats";
import {AttributeId} from "../lib/constants/attributeConstants";
import {CharacterConditionEventId, InjuryId} from "../lib/constants/characterConditionConstants";
import {getCurrentCharacterId, getLocalStorageFile} from "../lib/localStorage/localStorageIo";
import {CharacterFile, DataFileType} from "../lib/constants/dataConstants";

// ------------------------------------
// Constants
// ------------------------------------

// ------------------------------------
// Actions
// ------------------------------------
export enum CharacterActions {
  LoadState = '@@character/LoadState',
  SetFatigue = '@@character/SetFatigue',
  SetCashMoney = '@@character/SetMoney',
  AddToLog = '@@character/AddToLog',
  AddEquipment = '@@character/AddEquipment',
  RemoveEquipment = '@@character/RemoveEquipment',
  SetWeaponShotsLoaded = '@@character/SetWeaponShotsLoaded',
  SetWeaponMalfunction = '@@character/SetWeaponMalfunction',
  EquipGear = '@@character/EquipGear',
  TakeDamage = '@@character/TakeDamage',
  HealDamage = '@@character/HealDamage',
  AddInjury = '@@character/AddInjury',
  RemoveInjury = '@@character/RemoveInjury',
  SetConsecutiveBleedCheckSuccesses = '@@character/SetConsecutiveBleedCheckSuccesses',
}

interface CharacterAction {
  type: CharacterActions;
}

export enum SagaCharacterActions {
  ConditionEvent = '@@character/saga/ConditionEvent',
}

interface SagaCharacterAction {
  type: SagaCharacterActions;
}

export interface ConditionEventSagaAction extends SagaCharacterAction {
  type: SagaCharacterActions.ConditionEvent,
  event: CharacterConditionEventId;
}
export const conditionEventSaga = (event: CharacterConditionEventId): ConditionEventSagaAction => {
  return {
    type: SagaCharacterActions.ConditionEvent,
    event,
  };
};

interface LoadCharacterAction extends CharacterAction {
  type: CharacterActions.LoadState,
  character: Character;
}
export const loadCharacter = (character: Character): LoadCharacterAction => {
  return {
    type: CharacterActions.LoadState,
    character,
  };
};

interface SetFatigueAction {
  type: CharacterActions.SetFatigue,
  points: number,
  fatigueType: string,
  log: string,
}
export const setFatiguePoints = (fatigueType: string, points: number, log: string): SetFatigueAction => {
  return {
    type: CharacterActions.SetFatigue,
    points,
    fatigueType,
    log,
  };
};

interface SetCashMoney {
  type: CharacterActions.SetCashMoney,
  newMoney: number,
  log: string | null,
}
export const setCashMoney = (newMoney: number, log: string | null = null): SetCashMoney => {
  return {
    type: CharacterActions.SetCashMoney,
    newMoney,
    log
  };
};

interface AddToLogAction {
  type: CharacterActions.AddToLog;
  log: string;
}
export const addToCharacterLog = (log: string): AddToLogAction => {
  return {
    type: CharacterActions.AddToLog,
    log,
  }
};

interface AddEquipmentAction {
  type: CharacterActions.AddEquipment;
  definition: EquipmentDefinition;
  quantity: number;
  payFor: boolean;
}
export const addEquipment = (definition: EquipmentDefinition, quantity: number, payFor: boolean): AddEquipmentAction => {
  return {
    type: CharacterActions.AddEquipment,
    definition,
    quantity,
    payFor,
  }
};

interface RemoveEquipmentAction {
  type: CharacterActions.RemoveEquipment;
  definition: EquipmentDefinition;
  quantity: number;
  payFor: boolean;
}
export const removeEquipment = (definition: EquipmentDefinition, quantity: number, payFor: boolean): RemoveEquipmentAction => {
  return {
    type: CharacterActions.RemoveEquipment,
    definition,
    quantity,
    payFor,
  }
};

interface SetWeaponShotsLoadedAction {
  type: CharacterActions.SetWeaponShotsLoaded;
  weaponId: WeaponId,
  shotsLoaded: number,
}
export const setWeaponShotsLoaded = (weaponId: WeaponId, shotsLoaded: number): SetWeaponShotsLoadedAction => {
  return {
    type: CharacterActions.SetWeaponShotsLoaded,
    weaponId,
    shotsLoaded,
  }
};

interface SetWeaponMalfunctionAction {
  type: CharacterActions.SetWeaponMalfunction;
  weaponId: WeaponId,
  malfunctionTurns: number,
  needsRoll: boolean;
}
export const setWeaponMalfunction = (weaponId: WeaponId, malfunctionTurns: number, needsRoll: boolean): SetWeaponMalfunctionAction => {
  return {
    type: CharacterActions.SetWeaponMalfunction,
    weaponId,
    malfunctionTurns,
    needsRoll,
  }
};

interface EquipGearAction {
  type: CharacterActions.EquipGear;
  gearId: GearId;
  equipped: boolean;
}
export const equipGear = (gearId: GearId, equipped: boolean): EquipGearAction => {
  return {
    type: CharacterActions.EquipGear,
    gearId,
    equipped,
  }
};

interface TakeDamageAction {
  type: CharacterActions.TakeDamage;
  oldHitPoints: number;
  newHitPoints: number;
}
export const takeDamage = (oldHitPoints: number, newHitPoints: number): TakeDamageAction => {
  return {
    type: CharacterActions.TakeDamage,
    oldHitPoints,
    newHitPoints,
  }
};

interface HealDamageAction {
  type: CharacterActions.HealDamage;
  damageHealed: number;
}
export const healDamage = (damageHealed: number): HealDamageAction => {
  return {
    type: CharacterActions.HealDamage,
    damageHealed,
  }
};

interface AddInjuryAction {
  type: CharacterActions.AddInjury;
  injury: InjuryId;
}
export const addInjury = (injury: InjuryId): AddInjuryAction => {
  return {
    type: CharacterActions.AddInjury,
    injury,
  }
};

interface RemoveInjuryAction {
  type: CharacterActions.RemoveInjury;
  injury: InjuryId;
}
export const removeInjury = (injury: InjuryId): RemoveInjuryAction => {
  return {
    type: CharacterActions.RemoveInjury,
    injury,
  }
};

interface SetConsecutiveBleedCheckSuccessesAction {
  type: CharacterActions.SetConsecutiveBleedCheckSuccesses;
  successCount: number;
}
export const setConsecutiveBleedCheckSuccesses = (successCount: number): SetConsecutiveBleedCheckSuccessesAction => {
  return {
    type: CharacterActions.SetConsecutiveBleedCheckSuccesses,
    successCount,
  }
};

export type CharacterActionTypes = LoadCharacterAction | SetFatigueAction | AddToLogAction | AddEquipmentAction |
  RemoveEquipmentAction | SetCashMoney | SetWeaponShotsLoadedAction | SetWeaponMalfunctionAction | EquipGearAction |
  TakeDamageAction | HealDamageAction | AddInjuryAction | RemoveInjuryAction | SetConsecutiveBleedCheckSuccessesAction;

export interface CharacterState {
  readonly character: Character;
  readonly logs: Array<string>;
}

const initialCharacterId = getCurrentCharacterId();
let initialCharacterFile: CharacterFile | null = null;
if (initialCharacterId) {
  initialCharacterFile = getLocalStorageFile(initialCharacterId, DataFileType.Character);
}
const initialCharacterLoad = !!initialCharacterFile ? initialCharacterFile.content : blankCharacter;
const initialState: CharacterState = {
  character: initialCharacterLoad,
  logs: [],
};

export function characterReducer(state = initialState, action: CharacterActionTypes): CharacterState {
  switch (action.type) {
    case CharacterActions.AddEquipment:
    case CharacterActions.RemoveEquipment: {
      let inventory = state.character.inventory;
      let entry = inventory[action.definition.id];
      let cashDiff, quantityDiff, actionVerb;
      switch (action.type) {
        case CharacterActions.AddEquipment:
          cashDiff = action.definition.cost;
          quantityDiff = action.quantity;
          actionVerb = action.payFor ? 'bought' : 'acquired';
          break;
        case CharacterActions.RemoveEquipment:
          cashDiff = -action.definition.cost;
          quantityDiff = -action.quantity;
          actionVerb = action.payFor ? 'sold' : 'ditched';
          break;
        default:
          throw new Error(`Invalid action type ${JSON.stringify(action)}`);
      }
      if (!entry) {
        if (quantityDiff < 0) {
          // If we're removing equipment we don't have, we don't care
          console.log(`Ignoring remove action ${JSON.stringify(action)}`);
          return state;
        }
        entry = {
          id: action.definition.id,
          quantity: 0,
          notes: "",
          equipped: false,
        };
        // If we're adding a weapon
        if (getEquipmentType(action.definition.id) === EquipmentType.Weapon) {
          let weaponDef = weaponDefinitions[action.definition.id as WeaponId];
          let weapon = entry as Weapon;
          // Add it fully loaded for now
          weapon.shotsLoaded = weaponDef.shotsPerReload;
        }
      }
      // Increase or decrease quantity
      entry.quantity += quantityDiff;
      if (entry.quantity > 0) {
        // If we end up with a positive count of this equipment, it should be in the store
        inventory = {
          ...inventory,
          [action.definition.id]: entry,
        }
      } else {
        // Otherwise, we should remove it
        delete inventory[action.definition.id];
      }
      let newState = {
        ...state,
        character: {
          ...state.character,
          inventory: {
            ...inventory,
          }
        }
      };
      return addToLogs(newState, `${actionVerb} ${action.quantity} ${action.definition.name}`);
    }
    case CharacterActions.LoadState: {
      return {
        ...state,
        character: action.character,
      };
    }
    case CharacterActions.SetFatigue:
      let points = action.points;
      return {
        logs: [...state.logs, action.log],
        character: {
          ...state.character,
          fatiguePoints: {
            ...state.character.fatiguePoints,
            [action.fatigueType]: Math.max(0, points),
          },
        },
      };
    case CharacterActions.SetCashMoney: {
      let newState = state;
      if (action.log) {
        newState = addToLogs(newState, action.log);
      }
      return {
        ...newState,
        character: {
          ...newState.character,
          money: action.newMoney,
        },
      };
    }
    case CharacterActions.SetWeaponShotsLoaded:
      let weapon = state.character.inventory[action.weaponId];
      if (!weapon) {
        throw new Error(`Can't set shots loaded on weapon id ${action.weaponId} not in character inventory`);
      }
      return {
        ...state,
        character: {
          ...state.character,
          inventory: {
            ...state.character.inventory,
            [weapon.id]: {
              ...weapon,
              shotsLoaded: action.shotsLoaded,
            },
          },
        },
      };
    case CharacterActions.SetWeaponMalfunction: {
      let weapon = state.character.inventory[action.weaponId];
      if (!weapon) {
        throw new Error(`Can't set malfunction on weapon id ${action.weaponId} not in character inventory`);
      }
      return {
        ...state,
        character: {
          ...state.character,
          inventory: {
            ...state.character.inventory,
            [weapon.id]: {
              ...weapon,
              malfunctionTurns: action.malfunctionTurns,
              needsMalfunctionRoll: action.needsRoll,
            },
          },
        },
      };
    }
    case CharacterActions.AddToLog:
      return addToLogs(state, action.log);
    case CharacterActions.EquipGear: {
      let gear = state.character.inventory[action.gearId];
      if (!gear) {
        throw new Error(`Can't equip gear id ${action.gearId} not in character inventory`);
      }
      return {
        ...state,
        character: {
          ...state.character,
          inventory: {
            ...state.character.inventory,
            [action.gearId]: {
              ...gear,
              equipped: action.equipped,
            },
          },
        },
      };
    }
    case CharacterActions.TakeDamage: {
      let unmodHealth = getAttributeUnmodifiedValue(AttributeId.Health, state.character);
      let oldHitPoints = action.oldHitPoints;
      let newHitPoints = action.newHitPoints;
      let damage = oldHitPoints - newHitPoints;
      let injuries = state.character.injuries;

      if (getIsInstantlyDead(state.character, newHitPoints)) {
        // Ded
        return {
          ...state,
          character: {
            ...state.character,
            injuries: {
              [InjuryId.Dead]: true,
            },
          }
        };
      }

      return {
        ...state,
        character: {
          ...state.character,
          damageTaken: unmodHealth - newHitPoints,
        }
      };
    }
    case CharacterActions.HealDamage: {
      return {
        ...state,
        character: {
          ...state.character,
          damageTaken: Math.max(state.character.damageTaken - action.damageHealed, 0),
        }
      };

    }
    case CharacterActions.AddInjury: {
      let injuries = state.character.injuries;
      injuries[action.injury] = true;
      return {
        ...state,
        character: {
          ...state.character,
          injuries,
        }
      };
    }
    case CharacterActions.RemoveInjury: {
      let injuries = state.character.injuries;
      delete injuries[action.injury];
      return {
        ...state,
        character: {
          ...state.character,
          injuries,
        }
      };
    }
    case CharacterActions.SetConsecutiveBleedCheckSuccesses: {
      return {
        ...state,
        character: {
          ...state.character,
          consecutiveBleedingCheckSuccesses: action.successCount,
        }
      };
    }
    default:
      return state
  }
}

const addToLogs = (state: CharacterState, log: string): CharacterState => {
  return {
    ...state,
    logs: [...state.logs, log],
  };
};
