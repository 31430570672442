import React from 'react';
import Select from 'react-select'
import CryptoJs from 'crypto-js';
import {Button, Col, OverlayTrigger, Popover} from 'react-bootstrap';
import {Character} from "../../../../lib/constants/characterConstants";
import {ValueType} from "react-select/lib/types";
import {Option, validateSingleReactSelectValue} from "../../../../lib/utils";
import {CenteredRow} from '../../../../lib/components/CenteredRow';
import {CombatPageProps} from "../CombatPage";
import {
  combatActionDefinitions,
  CombatActionId,
  FreeActionId,
  getCombatActionSelectLabel,
  ManeuverId
} from "../../../../lib/constants/combatConstants";
import {CombatState, CombatStatus} from "../../../../modules/combat";
import {ChangePosition} from "./subpanels/actions/ChangePosition";
import {Move} from "./subpanels/actions/Move";
import {addToCharacterLog} from "../../../../modules/character";
import {doesCharacterHaveAWeapon} from "../../../../lib/gameLogic/derivedCharacterStats";
import {Ready} from "./subpanels/actions/Ready";
import {Weapon, WeaponType} from "../../../../lib/constants/weaponConstants";
import {Aim} from "./subpanels/actions/Aim";
import {Attack} from "./subpanels/actions/Attack";
import {Dodge} from "./subpanels/actions/Dodge";
import {Parry} from "./subpanels/actions/Parry";
import {RollDamage} from "./subpanels/actions/RollDamage";
import {TakeDamage} from "./subpanels/actions/TakeDamage";

interface Props extends CombatPageProps {
}

interface State {
  statusMessage: string;
}

const getOptionFromActionId = (id: CombatActionId): Option<CombatActionId> => {
  return {value: id, label: getCombatActionSelectLabel(id)};
};

const actionArray = Object.keys(combatActionDefinitions);
const combatActionOptions = actionArray.map((id) => getOptionFromActionId(id as CombatActionId));

export interface ExecuteManeuverCallbackProps {
  character: Character,
  combat: CombatState,
  addToCharacterLog: typeof addToCharacterLog;
}

export class ManeuverPanel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      statusMessage: 'Click to start your combat turn'
    };
  }

  render() {
    return <Col md={8} className='content-panel'>
      {this.renderBody()}
    </Col>
  }

  selectChange(v: ValueType<Option<CombatActionId>>) {
    let {
      setSelectedCombatAction,
    } = this.props;
    let combatActionOption = validateSingleReactSelectValue(v);
    setSelectedCombatAction(combatActionOption.value);
  }

  renderBody() {
    let {
      combat,
    } = this.props;

    switch (combat.status) {
      case CombatStatus.Idle: {
        return <>
          <CenteredRow className='header-row'>
            <Select
              className='react-select'
              value={getOptionFromActionId(combat.selectedCombatActionId)}
              onChange={this.selectChange.bind(this)}
              options={combatActionOptions}
            />
            <Button variant='primary' onClick={() => {
              this.props.endCombat(this.props.character)
            }}>
              End Combat
            </Button>
            <OverlayTrigger trigger="click" placement='bottom' rootClose={true} overlay={(props: Props) => {
              return <Popover id={`popover-combatAction-info`} title='Combat Actions'>
                A list of actions you can take in combat.
                Actions that require a turn are denoted with (T). Actions that are free are denoted with (F).
              </Popover>;
            }}>
              <div>
                <i className="fas fa-question-circle"/>
              </div>
            </OverlayTrigger>
          </CenteredRow>
          <CenteredRow className='main-row full-width'>
            {this.renderCombatActionSubpanel(combat.selectedCombatActionId)}
          </CenteredRow>
        </> }
        case CombatStatus.NotInCombat:
          return <>
            <CenteredRow className='header-row'>
            <Button variant='primary' onClick={() => {
              this.props.startCombat(this.props.character);
            }}>
              Start Combat
            </Button>
          </CenteredRow>
          </>;
      default:
        return null;
    }
  }

  renderCombatActionSubpanel(id: CombatActionId) {
    let {
      character,
      combat,
    } = this.props;
    switch (id) {
      case ManeuverId.ChangePosition:
        return <ChangePosition {...this.props} />;
      case ManeuverId.Move:
        return <Move {...this.props} />;
      case ManeuverId.Ready:
        if (!doesCharacterHaveAWeapon(character)) {
          return noWeaponWarning();
        }
        return <Ready {...this.props} />;
      case ManeuverId.Aim:
        if (!combat.weaponReadyStatus || combat.weaponReadyStatus.type === WeaponType.HandWeapons) {
          return cantAimWarning();
        }
        return <Aim {...this.props} />;
      case ManeuverId.Attack:
        return this.renderAttackPanel(false);
      case ManeuverId.AllOutAttack:
        return this.renderAttackPanel(true);
      case FreeActionId.DefendDodge:
        return <Dodge {...this.props} />;
      case FreeActionId.DefendParry:
        return <Parry {...this.props} />;
      case FreeActionId.RollForDamage:
        // Handle loading damage rolls by changing this component's key to force it to recreate and load the props on creation only
        // https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
        return <RollDamage
            key={CryptoJs.SHA1(JSON.stringify(this.props.combat.attackRollResultToLoadAsDamageRoll)).toString(CryptoJs.enc.Hex)}
            {...this.props}
        />;
      case FreeActionId.TakeDamage:
        return <TakeDamage {...this.props} />;
      default:
        throw new Error(`Undefined combat action ${id}`);
    }
  }

  renderAttackPanel(isAllOutAttack: boolean) {
    let {
      combat,
      character,
    } = this.props;

    let readyStatus = combat.weaponReadyStatus;
    if (!readyStatus) {
      return cantAttackWarning();
    }
    let weapon = character.inventory[readyStatus.weapon] as Weapon;
    if (!weapon) {
      return cantAttackWarning();
    }

    if (weapon.needsMalfunctionRoll) {
      return jammedWeaponWarning();
    }

    let props = {
      ...this.props,
      isAllOutAttack,
    };
    return <Attack {...props} />;
  }
}

const noWeaponWarning = () => {
  return <h2>You need a weapon to use this maneuver</h2>
};

const cantAimWarning = () => {
  return <h2>You need a readied ranged weapon to aim</h2>
};

const cantAttackWarning = () => {
  return <h2>You need a readied weapon to attack</h2>
};

const jammedWeaponWarning = () => {
    return <h2>You need to unjam your weapon to attack</h2>
};
