import React from 'react';
import {Button, Col, Form, FormControlProps, ListGroup} from "react-bootstrap";
import {CenteredRow} from "../../../../lib/components/CenteredRow";
import {CenteredFlexCol} from "../../../../lib/components/CenteredFlexCol";
import {OverviewPageProps} from "../OverviewPage";
import {
    GAMEMASTER_LOCAL_STORAGE_KEY,
    GamemasterCharacterTypeId,
    gamemasterCharacterTypes
} from "../../../../modules/gamemaster";
import {CharacterCard} from "../../../../lib/components/CharacterCard";
import {CharacterFile, UUIDv1} from "../../../../lib/constants/dataConstants";
import {objectKeysByCallback, Option, validateSingleReactSelectValue} from "../../../../lib/utils";
import Select from "react-select";
import {ValueType} from "react-select/lib/types";

interface Props extends OverviewPageProps {
}

interface State {
    selectedCharacterId: UUIDv1 | null;
    selectedCharacterLoadId: UUIDv1;
    selectedCharacterType: GamemasterCharacterTypeId;
    loadAlias: string;
}

const getOptionFromCharacterDef = (def: CharacterFile): Option<UUIDv1> => {
    return {value: def.id as UUIDv1, label: def.content.name};
};

export default class OverviewPanel extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            selectedCharacterId: null,
            selectedCharacterLoadId: props.data.characterFiles[0].id,
            selectedCharacterType: GamemasterCharacterTypeId.NPC,
            loadAlias: '',
        };
    }

    render() {
        let {
            loadNew,
            updateCharacter,
            deleteCharacter,
            clearCharacters,
            setGmEditing,
            editCharacterSaga,
            gm: {
                characters,
                combatStates,
            },
            data: {
                characterFiles,
            },
        } = this.props;
        let {
            loadAlias,
            selectedCharacterLoadId,
            selectedCharacterId,
            selectedCharacterType,
        } = this.state;

        let pcs = Object.values(characters).filter(character => character.characterType === GamemasterCharacterTypeId.PC);
        let npcs = Object.values(characters).filter(character => character.characterType === GamemasterCharacterTypeId.NPC);

        let characterLoadOptions = characterFiles.map((file) => getOptionFromCharacterDef(file));
        let characterFilesById = objectKeysByCallback(characterFiles, file => file.id);

        let isCharacterSelected = !!selectedCharacterId;

        return <Col className='content-panel'>
            <CenteredRow style={{minHeight: '70vh'}}>
                <CenteredFlexCol isRow={false} md={6}>
                    <ListGroup className='full-width scrollable'>
                        {pcs.map((gmCharacter) => {
                            return <div
                                key={`click-wrapper-pc-${gmCharacter.id}`}
                                onClick={() => this.setState({selectedCharacterId: gmCharacter.id})}
                            >
                                <CharacterCard
                                    key={`pc-${gmCharacter.id}`}
                                    character={gmCharacter.character}
                                    combat={combatStates[gmCharacter.id]}
                                    displayName={gmCharacter.alias}
                                    characterType={gmCharacter.characterType}
                                    isSelected={gmCharacter.id === this.state.selectedCharacterId}
                                />
                            </div>
                        })}
                    </ListGroup>
                </CenteredFlexCol>
                <CenteredFlexCol isRow={false} md={6}>
                    <ListGroup className='full-width scrollable'>
                        {npcs.map((gmCharacter) => {
                            return <div
                                key={`click-wrapper-npc-${gmCharacter.id}`}
                                onClick={() => this.setState({selectedCharacterId: gmCharacter.id})}
                            >
                                <CharacterCard
                                    key={`npc-${gmCharacter.id}`}
                                    character={gmCharacter.character}
                                    combat={combatStates[gmCharacter.id]}
                                    displayName={gmCharacter.alias}
                                    characterType={gmCharacter.characterType}
                                    isSelected={gmCharacter.id === this.state.selectedCharacterId}
                                />
                            </div>;
                        })}
                    </ListGroup>
                </CenteredFlexCol>
            </CenteredRow>
            <CenteredRow style={{minHeight: '10vh'}}>
                <CenteredFlexCol isRow={false} md={6}>
                    <Form>
                        <Form.Group style={{margin: 0}}>
                            <Select
                                className='react-select'
                                value={getOptionFromCharacterDef(characterFilesById[this.state.selectedCharacterLoadId])}
                                onChange={(v: ValueType<Option<UUIDv1>>) => {
                                    let idOption = validateSingleReactSelectValue(v);
                                    this.setState({selectedCharacterLoadId: idOption.value})
                                }}
                                options={characterLoadOptions}
                            />
                            <Form.Control
                                as="select"
                                value={selectedCharacterType}
                                onChange={(e: React.FormEvent<FormControlProps>) => {
                                    let rawVal = e.currentTarget.value;
                                    if (rawVal) {
                                        this.setState({
                                            selectedCharacterType: rawVal as GamemasterCharacterTypeId,
                                        });
                                    }
                                }}
                            >
                                {
                                    Object.values(gamemasterCharacterTypes).map((def) => {
                                        return <option key={def.id} value={def.id}>{def.description}</option>;
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Control
                                className=''
                                placeholder='Character Alias'
                                value={this.state.loadAlias}
                                onChange={(e: React.FormEvent<FormControlProps>) => {
                                    let rawVal = e.currentTarget.value;
                                    this.setState({
                                        loadAlias: rawVal || '',
                                    });
                                }}
                            />
                            <Button onClick={() => {
                                let file = characterFilesById[selectedCharacterLoadId];
                                loadNew(loadAlias, file.content, selectedCharacterType);
                            }}>Load Character</Button>
                        </Form.Group>
                    </Form>
                </CenteredFlexCol>
                <CenteredFlexCol isRow={false} md={6}>
                    <Button
                        disabled={!isCharacterSelected}
                        onClick={() => {
                            let {
                                editCharacterSaga,
                            } = this.props;
                            let {
                                selectedCharacterId,
                            } = this.state;
                            if (selectedCharacterId) {
                                editCharacterSaga(selectedCharacterId);
                            }
                        }}
                    >
                        Edit Character
                    </Button>
                    <Button
                        disabled={!isCharacterSelected}
                        onClick={() => {
                            let {
                                deleteCharacter,
                            } = this.props;
                            let {
                                selectedCharacterId,
                            } = this.state;
                            if (selectedCharacterId) {
                                deleteCharacter(selectedCharacterId);
                            }
                        }}
                    >
                        Delete Character
                    </Button>
                    <Button onClick={() => {
                        let {
                            clearCharacters,
                        } = this.props;
                        clearCharacters(GamemasterCharacterTypeId.NPC);
                    }}>Clear All NPCs</Button>
                    <Button onClick={() => {
                        let {
                            gm,
                        } = this.props;
                        window.localStorage.setItem(GAMEMASTER_LOCAL_STORAGE_KEY, JSON.stringify(gm));
                    }}>Save State</Button>
                </CenteredFlexCol>
            </CenteredRow>
        </Col>
    }
}

