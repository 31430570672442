
// ------------------------------------
// Constants
// ------------------------------------

// ------------------------------------
// Actions
// ------------------------------------
export enum CharacterUiActions {
    SetConditionEventResult = '@@characterUi/SetConditionEventResult',
}

interface CharacterUiAction {
    type: CharacterUiActions;
}


interface SetConditionEventResultAction extends CharacterUiAction {
    type: CharacterUiActions.SetConditionEventResult,
    resultText: string;
}
export const setConditionEventResult = (resultText: string): SetConditionEventResultAction => {
    return {
        type: CharacterUiActions.SetConditionEventResult,
        resultText
    };
};

export type CharacterUiActionTypes = SetConditionEventResultAction;

export interface CharacterUiState {
    readonly conditionEventResult: string | null;
}

const initialState: CharacterUiState = {
    conditionEventResult: null,
};

export function characterUiReducer(state = initialState, action: CharacterUiActionTypes): CharacterUiState {
    switch (action.type) {
        case CharacterUiActions.SetConditionEventResult: {
            return {
                ...state,
                conditionEventResult: action.resultText,
            };
        }
        default:
            return state
    }
}
