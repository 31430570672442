import React from 'react';
import Select from 'react-select'
import {Button, Col, Form, ListGroup, Row} from 'react-bootstrap';
import {FormControlProps,} from 'react-bootstrap/FormControl'
import {CenteredFlexCol} from "../../../../lib/components/CenteredFlexCol";
import {setCashMoney} from "../../../../modules/character";
import {ValueType} from "react-select/lib/types";
import {Option, validateSingleReactSelectValue} from "../../../../lib/utils";
import {CenteredRow} from '../../../../lib/components/CenteredRow';
import {getEncumbranceLevel, getWeightCarried} from "../../../../lib/gameLogic/derivedCharacterStats";
import {EquipmentProps} from "../EquipmentPage";
import {weaponDefinitions} from "../../../../lib/constants/weaponConstants";
import {EquipmentDefinition, EquipmentId, getEquipmentDefinition} from "../../../../lib/constants/equipmentConstants";
import {gearDefinitions} from "../../../../lib/constants/gearConstants";
import {EquipmentListItem} from "./subpanels/EquipmentListItem";
import {CenteredFlexDiv} from "../../../../lib/components/CenteredFlexDiv";
import {getDamageResistance, getPassiveDefense} from "../../../../lib/gameLogic/derivedCombatStats";

interface State {
  selectedEquipFromSupply: Option<EquipmentId>;
  selectedEquipFromInventory: EquipmentId | null;
  lastSelectEquip: EquipmentId;
  quantity: number;
  payFor: boolean;
}

interface Props extends EquipmentProps {
  setCashMoney: typeof setCashMoney;
  displayEquipmentDetail: (id: EquipmentId) => void;
}

const getOptionFromEquipDef = (def: EquipmentDefinition): Option<EquipmentId> => {
  return { value: def.id as EquipmentId, label: def.name };
};

const equipArray = [...Object.values(weaponDefinitions), ...Object.values(gearDefinitions)];
const equipOptions = equipArray.map((def) => getOptionFromEquipDef(def));

const HEADER_TEXT_COLUMN_PROPS = {
  sm: 6,
  lg: 3,
};

export class EquipmentSelectPanel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    let defaultSkill = equipArray[0];
    let inventoryArray = Object.values(props.character.inventory);
    let selectedInventory = null;
    if (inventoryArray && inventoryArray.length > 0) {
      selectedInventory = inventoryArray[0]!.id;
    }
    this.state = {
      selectedEquipFromSupply: getOptionFromEquipDef(defaultSkill),
      selectedEquipFromInventory: selectedInventory,
      lastSelectEquip: defaultSkill.id,
      quantity: 1,
      payFor: false,
    };
  }

  addEquipClick() {
    let {
      character,
      addEquipment,
      setCashMoney,
    } = this.props;
    let {
      quantity,
      payFor,
    } = this.state;
    let equipDef = getEquipmentDefinition(this.state.lastSelectEquip);
    addEquipment(equipDef, quantity, payFor);
    if (payFor) {
      setCashMoney(character.money - equipDef.cost);
    }
  }

  removeEquipClick() {
    let {
      character,
      removeEquipment,
      setCashMoney,
    } = this.props;
    let {
      quantity,
      payFor,
    } = this.state;
    let equipDef = getEquipmentDefinition(this.state.lastSelectEquip);
    removeEquipment(equipDef, quantity, payFor);
    if (payFor) {
      setCashMoney(character.money + equipDef.cost);
    }
  }

  inventoryItemClick(id: EquipmentId) {
    this.props.displayEquipmentDetail(id);
    this.setState({
      selectedEquipFromInventory: id,
      lastSelectEquip: id,
    });
  }

  changeQuantity(e: React.FormEvent<FormControlProps>) {
    let stringVal = e.currentTarget.value;
    let quantity;
    if (stringVal) {
      quantity = parseInt(stringVal, 10);
    } else {
      quantity = 0;
    }
    this.setState({
      quantity,
    });
  }

  selectChange(v: ValueType<Option<EquipmentId>>) {
    let option = validateSingleReactSelectValue(v);
    this.props.displayEquipmentDetail(option.value);
    this.setState({
      selectedEquipFromSupply: option,
      lastSelectEquip: option.value,
    });
  }

  render() {
    let {
      character,
      combat,
    } = this.props;
    let equipDef = getEquipmentDefinition(this.state.selectedEquipFromSupply.value as EquipmentId);

    return <Col sm className='content-panel'>
      <Row className='justify-content-center info-header'>
        <CenteredFlexCol {...HEADER_TEXT_COLUMN_PROPS}>
          <h6>Cash: {character.money}</h6>
        </CenteredFlexCol>
        <CenteredFlexCol {...HEADER_TEXT_COLUMN_PROPS}>
          <h6>Weight: {getWeightCarried(character)}: {getEncumbranceLevel(character, combat).description}</h6>
        </CenteredFlexCol>
        <CenteredFlexCol {...HEADER_TEXT_COLUMN_PROPS}>
          <h6>PD: {getPassiveDefense(character)}</h6>
        </CenteredFlexCol>
        <CenteredFlexCol {...HEADER_TEXT_COLUMN_PROPS}>
          <h6>DR: {getDamageResistance(character)}</h6>
        </CenteredFlexCol>
      </Row>
      <CenteredRow className=''>
        <CenteredFlexCol sm={9} isRow={false} className='full-width'>
          <CenteredFlexDiv isRow={false} className='full-width'>
            <Select
              className='react-select'
              value={this.state.selectedEquipFromSupply}
              onChange={this.selectChange.bind(this)}
              options={equipOptions}
            />
            <ListGroup className='full-width scrollable'>
              {Object.values(character.inventory).map((equipment) => {
                if (equipment) {
                  return <div
                    key={`inventory-equipment-container-${equipment.id}`}
                    onClick={this.inventoryItemClick.bind(this, equipment.id)}
                  >
                    <EquipmentListItem
                      equipGear={this.props.equipGear}
                      active={this.state.lastSelectEquip === equipment.id}
                      key={`inventory-equipment-${equipment.id}`}
                      equipment={equipment}
                    />
                  </div>
                } else {
                  return null;
                }
              })}
            </ListGroup>
          </CenteredFlexDiv>
        </CenteredFlexCol>
        <CenteredFlexCol sm={3} isRow={false} className='full-width'>
          <Button variant='primary' onClick={this.addEquipClick.bind(this)}>Add</Button>
          <Form>
            <Form.Group>
              <CenteredFlexDiv isRow={false}>
                <Form.Label>Quantity</Form.Label>
                <Form.Control
                  className='number-picker'
                  type="number"
                  value={this.state.quantity.toString(10)}
                  onChange={this.changeQuantity.bind(this)}
                />
              </CenteredFlexDiv>
            </Form.Group>
            <Form.Group>
              <Form.Check
                type="checkbox"
                label='Pay For?'
                checked={this.state.payFor}
                onChange={() => {
                  this.setState({
                    payFor: !this.state.payFor,
                  });
                }}
              />
            </Form.Group>
          </Form>
          <Button variant='primary' onClick={this.removeEquipClick.bind(this)}>Remove</Button>
        </CenteredFlexCol>
      </CenteredRow>
    </Col>
  }
}

