import React from 'react';
import {InputResult, ModalCloseResult, ModalState, ModalType, userClose, YesNoResult} from "../../modules/modal";
import {Button, Form, FormControlProps, Modal} from "react-bootstrap";
import {log} from "../logger";
import {selectText} from "../utils";
import {CenteredFlexDiv} from "./CenteredFlexDiv";

interface Props {
    modal: ModalState;
    userClose: typeof userClose;
}

interface State {
    textInput: string | null;
}

interface ResultTemplate {
    cancelled: boolean;
    confirmed: boolean;
}

const confirmedResultTemplate = {
    cancelled: false,
    confirmed: true,
};

const cancelledResultTemplate = {
    cancelled: true,
    confirmed: false,
};

export class ModalContainer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            textInput: null,
        };
    }

    handleConfirm(arg?: string) {
        log(`Confirm: ${arg}`);
        let {
            userClose,
            modal: {
                isOpen,
                modalType,
                title,
                descriptionText,
                confirmButtonLabel,
                yesButtonLabel,
                noButtonLabel,
                outputText,
            },
        } = this.props;

        userClose(this.parseResult(confirmedResultTemplate, modalType));
    }
    
    handleNo() {
        log(`Cancel: no click`);
        let {
            userClose,
            modal: {
                isOpen,
                modalType,
                title,
                descriptionText,
                confirmButtonLabel,
                yesButtonLabel,
                noButtonLabel,
                outputText,
            },
        } = this.props;
        
        let result: YesNoResult = {
            ...confirmedResultTemplate,
            type: ModalType.YesNo,
            confirmed: false,
            no: true,
        };
        
        userClose(result);
    }

    handleCancel(arg?: string) {
        log(`Cancel: ${arg}`);
        let {
            userClose,
            modal: {
                isOpen,
                modalType,
                title,
                descriptionText,
                confirmButtonLabel,
                yesButtonLabel,
                noButtonLabel,
                outputText,
            },
        } = this.props;

        userClose(this.parseResult(cancelledResultTemplate, modalType));
    }
    
    parseResult(template: ResultTemplate, modalType: ModalType): ModalCloseResult {
        switch (modalType) {
            case ModalType.Confirm:
                return {
                    ...template,
                    type: ModalType.Confirm,
                };
            case ModalType.Output:
                return {
                    ...template,
                    type: ModalType.Output,
                };
            case ModalType.Input:
                return {
                    ...template,
                    type: ModalType.Input,
                    input: this.state.textInput,
                } as InputResult;
            case ModalType.YesNo:
                return {
                    ...template,
                    type: ModalType.YesNo,
                    no: false,
                } as YesNoResult;
        }
    }

    render() {
        let {
            modal: {
                isOpen,
                modalType,
                title,
                descriptionText,
                confirmButtonLabel,
                yesButtonLabel,
                noButtonLabel,
                outputText,
            },
        } = this.props;

        return <Modal
            show={isOpen}
            scrollable={true}
            centered={true}
            onHide={this.handleCancel.bind(this, 'modalHide')}
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{this.renderBody()}</Modal.Body>
            <Modal.Footer>{this.renderButtons()}</Modal.Footer>
        </Modal>
    }

    renderBody() {
        let {
            modal: {
                modalType,
                descriptionText,
                outputText,
            },
        } = this.props;

        switch (modalType) {
            case ModalType.Confirm:
            case ModalType.YesNo:
                return <div>{descriptionText}</div>;
            case ModalType.Input:
                return <>
                    <Form>
                        <Form.Group controlId="modal.InputTextarea">
                            <Form.Label>{descriptionText}</Form.Label>
                            <Form.Control
                                value={this.state.textInput || ''}
                                onChange={(e: React.FormEvent<FormControlProps>) => {
                                    let rawVal = e.currentTarget.value;
                                    this.setState({
                                        textInput: rawVal || null,
                                    });
                                }}
                                as="textarea"
                                rows="4"
                            />
                        </Form.Group>
                    </Form>
                </>;
            case ModalType.Output:
                return <>
                    <CenteredFlexDiv>
                        <div>{descriptionText}</div>
                        <Button
                            onClick={() => {
                                selectText('modal-output-element');
                                document.execCommand("copy");
                            }}
                        >Copy to Clipboard</Button>
                    </CenteredFlexDiv>
                    <pre><code id='modal-output-element'>{outputText}</code></pre>
                </>;
        }
    }

    renderButtons() {
        let {
            modal: {
                modalType,
                confirmButtonLabel,
                yesButtonLabel,
                noButtonLabel,
            },
        } = this.props;

        switch (modalType) {
            case ModalType.Confirm:
            case ModalType.Input:
            case ModalType.Output:
                return <Button variant="primary" onClick={this.handleConfirm.bind(this, 'confirmclick')}>{confirmButtonLabel}</Button>;
            case ModalType.YesNo:
                return <>
                    <Button variant="primary" onClick={this.handleConfirm.bind(this, 'yesclick')}>{yesButtonLabel}</Button>
                    <Button variant="secondary" onClick={this.handleNo.bind(this)}>{noButtonLabel}</Button>
                </>;
        }
    }
}
