import {put, race, select, take, takeLatest} from 'redux-saga/effects';
import {SagaIterator} from 'redux-saga';
import {
  addInjury as addCharacterInjury,
  addToCharacterLog,
  setWeaponMalfunction,
  setWeaponShotsLoaded,
  takeDamage as takeCharacterDamage,
} from "../modules/character";
import {log} from "../lib/logger";
import {
  attackManeuver,
  AttackManeuverSagaAction,
  changePosition,
  CombatState,
  completeReload,
  DefendDodgeManeuverSagaAction,
  DefendParryManeuverSagaAction,
  getGunReadyStatus,
  GunReadyStatus,
  HandWeaponReadyStatus,
  loadDamageRoll,
  readyManeuver,
  ReadyManeuverSagaAction,
  ReadyStatusUnion,
  RollForDamageSagaAction,
  SagaCombatActions,
  setAim,
  setReload,
  setSelectedCombatAction,
  setShock,
  setStun,
  ShockStatus,
  StunStatus,
  TakeDamageSagaAction,
} from "../modules/combat";
import {AppState} from "../modules";
import {
  getWeaponReadyThreshold,
  getWeaponTotalReloadReadyRequirement,
  GunReadyThreshold,
  HandWeaponReadyThreshold,
  Weapon,
  weaponCategories,
  WeaponCategoryId,
  weaponDefinitions,
  WeaponType
} from "../lib/constants/weaponConstants";
import {
  didMultiTurnAttackHappen,
  getActiveDefenseEligibility,
  getActiveDefenseModifiers,
  getActiveDefenseReadiness,
  getAimModifiers,
  getDamageModifiers,
  getDefaultReadyStatus,
  getDodgeModifiers,
  getEffectiveRateOfFire,
  getFragmentationRange,
  getGrenadeImpactDistance,
  getIndirectFireImpactDistance,
  getParryModifiers,
  getRecoilModifiers,
  getShotsFiredThisAttack,
  isAutomaticWeapon,
  isMultiTurnAttack,
  isThisAFinishAttackTurn,
} from "../lib/gameLogic/derivedCombatStats";
import {getRollTotal, rollMany} from "../lib/gameLogic/rolls";
import {
  getAttributeCurrentValue,
  getIsReeling,
  getIsRiskingUnconsciousness,
  getModifiedCharacteristicValue,
  getNeedsToMakeDeathRoll,
  getNeedsToMakeKnockdownRoll
} from "../lib/gameLogic/derivedCharacterStats";
import {AttributeId} from "../lib/constants/attributeConstants";
import {Character} from "../lib/constants/characterConstants";
import {clearRollPanel, ExecuteRollAction, initRoll, RollActions} from "../modules/roll";
import {
  AttackRollResult,
  CharacteristicRollResult,
  FragHitRollResult,
  Modifier,
  RollSpec,
  rollSpecForAttack,
  rollSpecForAttribute,
  rollSpecForDamage,
  rollSpecForDodge,
  rollSpecForFragHit,
  rollSpecForMalfunction,
  rollSpecForParry
} from "../lib/constants/rollConstants";
import {FreeActionId, PositionId} from "../lib/constants/combatConstants";
import {InjuryId} from "../lib/constants/characterConditionConstants";

export function* maneuverSagas(): SagaIterator {
  log(`Taking latest maneuvers for saga`);
  yield takeLatest(SagaCombatActions.ManeuverAttack, maneuverAttack);
  yield takeLatest(SagaCombatActions.ManeuverReady, maneuverReady);
  yield takeLatest(SagaCombatActions.ManeuverDefendDodge, maneuverDefendDodge);
  yield takeLatest(SagaCombatActions.ManeuverDefendParry, maneuverDefendParry);
  yield takeLatest(SagaCombatActions.RollForDamage, rollForDamage);
  yield takeLatest(SagaCombatActions.TakeDamage, takeDamage);
}

function* maneuverReady(action: ReadyManeuverSagaAction): SagaIterator {
  let weaponId = action.weaponId;
  let character = action.character;
  let updatedState = (yield select()) as AppState;

  let readyStatus = updatedState.combatReducer.weaponReadyStatus || getDefaultReadyStatus(weaponId);
  let weaponDef = weaponDefinitions[weaponId];
  let readyThreshold = getWeaponReadyThreshold(weaponDef);
  if (!readyStatus) {
    throw new Error(`Failed to find ready status; can't execute ready maneuver.`);
  }
  if (readyStatus.unsling < readyThreshold.unsling) {
    // Weapon is not unslung yet, so work on that
    yield put(readyManeuver({
      ...readyStatus,
      unsling: readyStatus.unsling + 1,
    }));
    yield put(addToCharacterLog(`Unsling ${weaponDef.name}`));
    return;
  }

  switch (readyStatus.type) {
    case WeaponType.Guns:
      let reloadRequirement = getWeaponTotalReloadReadyRequirement(weaponId);
      let gunReadyStatus = readyStatus as GunReadyStatus;
      let gunReadyThreshold = getWeaponReadyThreshold(weaponDef) as GunReadyThreshold;
      let weapon = updatedState.characterReducer.character.inventory[weaponId] as Weapon;
      if (weapon.malfunctionTurns > 0) {
        // First priority: clear the jam
        let newMalfTurns = weapon.malfunctionTurns - 1;
        yield put(addToCharacterLog(`Clearing jam: ${newMalfTurns} turns remaining`));
        yield put(setWeaponMalfunction(weaponId, newMalfTurns, true));
        return;
      } else if (weapon.needsMalfunctionRoll) {
        // Need a successful roll to clear the jam
        yield put(addToCharacterLog(`Clearing jam: rolling to finish clearing`));
        let rollSpec = rollSpecForMalfunction(weaponId, character, updatedState.combatReducer, []);
        let rollAction = yield* waitForRoll(rollSpec, 'jam clearing');
        let rollResult = rollAction.result as CharacteristicRollResult;

        yield put(addToCharacterLog(`Clearing jam: final skill roll ${rollResult.success ? 'succeeded' : 'failed'}`));
        yield put(setWeaponMalfunction(weaponId, 0, !rollResult.success));
        return;
      } else if (gunReadyStatus.cock < gunReadyThreshold.cock) {
        // Weapon isn't cocked, so cock it
        yield put(readyManeuver({
          ...gunReadyStatus,
          cock: gunReadyStatus.cock + 1,
        }));
        yield put(addToCharacterLog(`Cock ${weaponDef.name}`));
        return;
      } else if (gunReadyStatus.reload < reloadRequirement) {
        let newReload = gunReadyStatus.reload + 1;
        if (newReload < reloadRequirement) {
          // Still in the process of reloading the weapon
          yield put(readyManeuver({
            ...gunReadyStatus,
            reload: newReload,
          }));
          yield put(addToCharacterLog(`Working on reloading ${weaponDef.name}: ${newReload}/${reloadRequirement}`));
          return;
        } else {
          // Reloading is done, let's update the shots loaded status
          let currentShotsLoaded = weapon.shotsLoaded;
          let tentativeShotsLoaded: number;
          if (gunReadyThreshold.reloadPerRound) {
            tentativeShotsLoaded = currentShotsLoaded + 1;
          } else {
            tentativeShotsLoaded = currentShotsLoaded + weaponDef.shotsPerReload;
          }
          // If we're done reloading, update the weapon stats
          yield put(setWeaponShotsLoaded(weaponId, Math.min(weaponDef.shotsPerReload, tentativeShotsLoaded)));
          yield put(completeReload(weaponId));
          yield put(addToCharacterLog(`Reload completed for ${weaponDef.name}`));
          return;
        }
      }
      break;
    case WeaponType.HandWeapons:
      let handReadyStatus = readyStatus as HandWeaponReadyStatus;
      let handReadyThreshold = readyThreshold as HandWeaponReadyThreshold;

      yield put(readyManeuver({
        ...handReadyStatus,
        backswing: handReadyStatus.backswing + 1,
      }));
      yield put(addToCharacterLog(`Backswing ${weaponDef.name}`));
      return;
  }
}

function* maneuverAttack(action: AttackManeuverSagaAction): SagaIterator {
  log(`Attack saga started`);
  let state = (yield select()) as AppState;
  if (!state.combatReducer.weaponReadyStatus) {
    throw new Error(`Can't attack without a ready weapon`);
  }
  let character = action.character;
  let weaponId = state.combatReducer.weaponReadyStatus.weapon;
  let weaponDef = weaponDefinitions[weaponId];
  let weaponCategory = weaponCategories[weaponDef.category];
  let weaponInstance = action.character.inventory[weaponId] as Weapon;
  let hasFragDamage = !!weaponDef.damage.fragmentation;

  switch (weaponCategory.type) {
    case WeaponType.Guns:
      let gunReadyStatus = state.combatReducer.weaponReadyStatus as GunReadyStatus;

      let {modifiers, accuracyModifier} = getRecoilModifiers(action.modifiers, weaponId, action.character, state.combatReducer, action.attackPosition);
      modifiers = getAimModifiers(modifiers, weaponId, action.character, state.combatReducer, gunReadyStatus, accuracyModifier);
      break;
    case WeaponType.HandWeapons:
      // Nothing to do here. Hand weapons don't have recoil or aim.
      break;
    default:
      throw new Error(`Unsupported weapon type ${weaponCategory.type}`);
  }

  // --------
  // Actually execute the attack roll, if applicable
  // --------
  let attackRollResult: AttackRollResult | null = null;
  if (isThisAFinishAttackTurn(state.combatReducer, weaponId)) {
    // If we're just finishing an attack turn, clear out the roll results
    yield put(clearRollPanel())
  } else {
    // Actually execute the attack roll, if we're not just finishing a multi-turn attack action
    let rollSpec = rollSpecForAttack(weaponId, action.character, state.combatReducer, action.modifiers);
    let attackRollAction = (yield* waitForRoll(rollSpec, 'attack roll')) as ExecuteRollAction;
    attackRollResult = attackRollAction.result as AttackRollResult;
    state = (yield select()) as AppState;
  }

  // Determine the state changes that result from the attack
  let oldReadyStatus = state.combatReducer.weaponReadyStatus;
  let newReadyStatus: ReadyStatusUnion;
  switch (weaponCategory.type) {
    case WeaponType.Guns:
      oldReadyStatus = oldReadyStatus as GunReadyStatus;
      newReadyStatus = {
        ...oldReadyStatus,
        // Attacking interrupts any reload or aim action
        reload: 0,
        aim: 0,
      };
      break;
    case WeaponType.HandWeapons:
      oldReadyStatus = oldReadyStatus as HandWeaponReadyStatus;
      newReadyStatus = {
        ...oldReadyStatus,
        backswing: 0,
      };
      break;
    default:
      throw new Error(`Unsupported weapon type ${weaponCategory.type}`);
  }

  // All-Out Attack can function as a doubled rate of fire
  let rateOfFire = getEffectiveRateOfFire(weaponDef, action.doubleAttack);
  let isAttackComplete: boolean;

  // --------
  // Dispatch attack action to update attacksThisTurn/turnsThisAttack
  // --------
  if (isMultiTurnAttack(rateOfFire)) {
    // Attack that requires multiple turns to complete
    let turns = state.combatReducer.turnsThisAttack + 1;
    isAttackComplete = turns >= (1 / rateOfFire);
    yield put(attackManeuver(character, newReadyStatus, isAttackComplete ? 0 : turns, 0));
  } else {
    let attacks = state.combatReducer.attacksThisTurn + 1;
    if (isAutomaticWeapon(weaponId)) {
      // Automatic weapon burst fire
      isAttackComplete = attacks >= rateOfFire / 4;
    } else {
      // >= 1 attacks per turn
      isAttackComplete = attacks >= rateOfFire;
    }
    yield put(attackManeuver(character, newReadyStatus, 0, isAttackComplete ? 0 : attacks));
  }

  state = (yield select()) as AppState;
  let attackHappened = !isMultiTurnAttack(rateOfFire) || didMultiTurnAttackHappen(weaponId, state.combatReducer);
  if (!attackHappened) {
    log(`Multi-turn attack action state update complete; exiting attack saga.`);
    return;
  }
  let rollResult = state.rollReducer.rollResult as AttackRollResult;
  if (!rollResult) {
    throw new Error(`Attack execution failed: roll action produced null roll result.`)
  }

  // --------
  // Check for malfunctions
  // --------
  if (rollResult.malfunction) {
    // Check for a malfunction
    let clearTurns = getRollTotal(rollMany(2));
    yield put(addToCharacterLog(`Weapon ${weaponDef.name} malfunctioned. Requires ${clearTurns} turns to clear.`));
    yield put(setWeaponMalfunction(weaponId, clearTurns, true));
    // If a malfunction happened, we're done with this saga
    return;
  } else {
    // --------
    // Update weapon shots loaded and add to character log
    // --------

    if (attackHappened) {
      yield put(setWeaponShotsLoaded(weaponId, weaponInstance.shotsLoaded - getShotsFiredThisAttack(weaponId, state.combatReducer.attacksThisTurn)));
      if (!attackRollResult) {
        throw new Error(`Attack happened, but no attack roll result?`);
      }
      let logText = attackRollResult.maxDamageHits > 0 ? 'max damage critical success' :
          attackRollResult.automaticHit ? 'automatic hit critical success' :
          attackRollResult.automaticMiss ? 'automatic miss critical failure' :
          attackRollResult.success ? 'hit' : 'miss';
      yield put(addToCharacterLog(`Attack with ${weaponDef.name} ${logText}`));
    } else {
      yield put(addToCharacterLog(`Finished attack action with ${weaponDef.name}`));
    }
  }

  // --------
  // Roll for frag damage if necessary
  // --------
  let fragHitRollResult: FragHitRollResult | null = null;
  // If this weapon has frag damage, we need to roll separately for that too
  // Skip this if it's a multi-turn attack and we didn't attack this turn
  if (hasFragDamage && attackRollResult) {
    let fragHit = false;
    let impactDistanceToTarget: number;
    switch (weaponDef.category) {
      case WeaponCategoryId.HandGrenades:
        impactDistanceToTarget = attackRollResult.success ? 0 : getGrenadeImpactDistance(attackRollResult.differential, action.targetRange);
        break;
      case WeaponCategoryId.Mortars:
        impactDistanceToTarget = attackRollResult.success ? 0 : getIndirectFireImpactDistance(attackRollResult.differential, action.targetRange);
        break;
      case WeaponCategoryId.Mines:
        impactDistanceToTarget = 0;
        break;
      default:
        throw new Error(`Frag calculations for weapon category type ${weaponDef.category} are currently not implemented.`);
    }
    let fragRange = getFragmentationRange(weaponId);

    if (impactDistanceToTarget <= 0) {
      log(`Direct hit; no frag hit roll required.`);
      fragHit = true;
    } else if (impactDistanceToTarget > fragRange) {
      log(`Impact distance ${impactDistanceToTarget} exceeded fragmentation range of ${fragRange}; no frag hit roll required.`);
      fragHit = false;
    } else {
      // Only roll for hit if it's not a direct hit and not out of frag range
      let rollSpec = rollSpecForFragHit(weaponId, action.modifiers, impactDistanceToTarget);
      let fragHitRollAction = (yield* waitForRoll(rollSpec, 'fragmentation hit roll')) as ExecuteRollAction;
      state = (yield select()) as AppState;
      fragHitRollResult = fragHitRollAction.result as FragHitRollResult;
      fragHit = fragHitRollResult.success;
      let logText = fragHitRollResult.success ? 'hit' : 'miss';
      yield put(addToCharacterLog(`Fragmentation roll for ${weaponDef.name} ${logText}`));
      log(`Fragmentation roll for ${weaponDef.name} ${logText}`);
    }
    rollResult.fragDamageHit = fragHit;
    rollResult.impactDistanceToTarget = impactDistanceToTarget;
  }

  // --------
  // Wait for a click to load weapon damage here so we have all the attack and frag data in context
  // --------
  // Also wait for any events that would indicate we're moving on and should cancel
  let raceResult = yield race({
    loadDamage: take(RollActions.LoadDamageRollSignal),
    init: take(RollActions.InitRoll),
    clear: take(RollActions.ClearRollPanel),
  });

  // Load the data from this attack into the RollDamage view and navigate to it
  // Only act if this is a loadDamageSignal action
  // We don't care about the other actions, we're just exiting if they win
  if (raceResult.loadDamage) {
    log(`Load damage saga triggered`);
    yield put(setSelectedCombatAction(FreeActionId.RollForDamage));
    // This action will set state that will cause the RollDamage component to be recreated with the attack roll result props
    //  used to set its internal state. See ManeuverPanel.renderCombatActionSubpanel for details.
    yield put(loadDamageRoll(rollResult));
  } else {
    log(`Cancelling wait for load damage button`);
  }
}

function* maneuverDefendDodge(action: DefendDodgeManeuverSagaAction): SagaIterator {
  log(`Defend dodge saga started`);
  let state = (yield select()) as AppState;

  // Calculate modifiers
  let modifiers: Array<Modifier> = getDodgeModifiers(action.character, state.combatReducer);
  modifiers = [...modifiers, ...getActiveDefenseModifiers(action.character, state.combatReducer)];

  // Create the dodge roll spec
  let rollSpec = rollSpecForDodge(action.character, modifiers);
  yield* waitForRoll(rollSpec, 'defend dodge');
  state = (yield select()) as AppState;

  // Dodge effects
  let gunReadyStatus = getGunReadyStatus(state.combatReducer);
  if (gunReadyStatus) {
    //  Dodging while reloading loses a turn of reloading
    if (gunReadyStatus.reload > 0) {
      yield put(setReload(gunReadyStatus.reload - 1));
      yield put(addToCharacterLog(`Lost reload progress due to dodge.`));
    }
    //  Any active defense spoils aim
    if (gunReadyStatus.aim > 0) {
      yield put(setAim(0));
      yield put(addToCharacterLog(`Lost aim progress due to dodge.`));
    }
  }
}

function* maneuverDefendParry(action: DefendParryManeuverSagaAction): SagaIterator {
  log(`Defend parry saga started`);
  let state = (yield select()) as AppState;
  let combat = state.combatReducer;
  let gunReadyStatus = getGunReadyStatus(combat);
  //  No parrying while reloading a gun
  if (gunReadyStatus && gunReadyStatus.reload > 0) {
    throw new Error(`Parrying while reloading a gun is not allowed.`);
  }
  if (!combat.weaponReadyStatus) {
    throw new Error(`Parrying without a ready weapon is not allowed.`);
  }
  let {isReady, isReloading} = getActiveDefenseReadiness(combat.weaponReadyStatus);
  if (isReloading) {
    throw new Error(`Parrying while reloading a gun is not allowed.`);
  }
  if (!isReady) {
    throw new Error(`Parrying without an unslung and ready weapon is not allowed.`);
  }
  let isEligible = getActiveDefenseEligibility(combat);
  if (!isEligible) {
    throw new Error(`You have already hit your limit for parrying this turn.`);
  }

  // Calculate modifiers
  let modifiers = getParryModifiers(action.character, state.combatReducer);
  modifiers = [...modifiers, ...getActiveDefenseModifiers(action.character, state.combatReducer)];
  // Create the parry roll spec
  let rollSpec = rollSpecForParry(action.character, modifiers, combat.weaponReadyStatus.weapon);
  // Execute the roll

  yield* waitForRoll(rollSpec, 'parry roll');
  state = (yield select()) as AppState;
  gunReadyStatus = getGunReadyStatus(state.combatReducer);

  //  Any active defense spoils aim
  if (gunReadyStatus && gunReadyStatus.aim > 0) {
    yield put(setAim(0));
    yield put(addToCharacterLog(`Lost aim progress due to parry.`));
  }
}

function* rollForDamage(action: RollForDamageSagaAction): SagaIterator {
  log(`Starting damage roll saga`);

  let modifiers = getDamageModifiers(action.weaponId);
  let rollSpec = rollSpecForDamage(
      modifiers,
      action.weaponId,
      action.targetDr,
      action.softTarget,
      action.normalHits,
      action.maxDamageHits,
      action.fragDamageHit,
      action.impactDistanceToTarget
  );

  // Execute the damage roll
  let damageRollResult = yield* waitForRoll(rollSpec, 'damage roll');
  let state = (yield select()) as AppState;
}

function* takeDamage(action: TakeDamageSagaAction): SagaIterator {
  log(`Starting take damage saga`);
  if (action.damage === 0) {
    return;
  }

  let state = (yield select()) as AppState;
  let initialInjuries = state.characterReducer.character.injuries;
  let oldHitPoints = getModifiedCharacteristicValue(getAttributeCurrentValue(AttributeId.Health, state.characterReducer.character, state.combatReducer));

  // Fire action updating character state with damage impact
  yield put(takeCharacterDamage(oldHitPoints, oldHitPoints - action.damage));
  state = (yield select()) as AppState;
  let updatedCharacter = state.characterReducer.character;
  let newHitPoints = getModifiedCharacteristicValue(getAttributeCurrentValue(AttributeId.Health, updatedCharacter, state.combatReducer));

  let injuryResults = yield* doInjuryRolls(updatedCharacter, state.combatReducer, oldHitPoints, newHitPoints, action.causesBleeding);
}

function* doInjuryRolls(character: Character, combatState: CombatState, oldHitPoints: number, newHitPoints: number, causesBleeding: boolean) {
  let damage = oldHitPoints - newHitPoints;
  // If the character is dead, we don't need to make any more rolls
  if (character.injuries[InjuryId.Dead]) {
    yield put(addToCharacterLog(`You've taken over 6 times your starting HT in damage, you're very dead.`));
    log(`Character is dead; ignoring further injury.`);
    return;
  }

  log(`Character is not dead yet; checking for injuries.`);
  // Shock always happens
  yield put(addToCharacterLog(`You are shocked for one turn: -${damage} to non-defense IQ and DX.`));
  yield put(setShock(ShockStatus.Shocked, damage));

  // todo: If injured while aiming, make a will roll or lose your aim

  // Determine what rolls we need to make and execute them
  if (getNeedsToMakeDeathRoll(oldHitPoints, newHitPoints, character)) {
    log(`Character needs to make death roll to avoid dying`);
    let deathRollSpec = rollSpecForAttribute(AttributeId.Health, character, combatState, [], 'Death saving roll', true);
    let deathRollAction = yield* waitForRoll(deathRollSpec, 'death roll');
    let deathRollResult = deathRollAction.result as CharacteristicRollResult;

    if (deathRollResult.success) {
      // Live to fight another day
      log(`Death roll success`);
      yield put(addToCharacterLog(`Death roll succeeded: you hold on a bit longer.`));
      yield put(addCharacterInjury(InjuryId.DeathRisk));
    } else {
      // Ded
      log(`Death roll failure`);
      yield put(addToCharacterLog(`Death roll failed: he's dead, Jim.`));
      yield put(addCharacterInjury(InjuryId.Dead));
      return;
    }
  }

  if (!getIsReeling(character, oldHitPoints) && getIsReeling(character, newHitPoints)) {
    yield put(addToCharacterLog(`You are reeling from your wounds: your Move and Dodge are halved.`));
    yield put(addCharacterInjury(InjuryId.Reeling));
  }

  if (!getIsRiskingUnconsciousness(character, oldHitPoints) && getIsRiskingUnconsciousness(character, newHitPoints)) {
    yield put(addToCharacterLog(`You are in immediate danger of collapse: roll against HT each turn or fall unconscious.`));
    yield put(addCharacterInjury(InjuryId.UnconsciousnessRisk));
  }

  if (causesBleeding) {
    yield put(addToCharacterLog(`Took cutting, impaling, or bullet damage; you are now bleeding: roll against HT every minute to stop the bleeding.`));
    yield put(addCharacterInjury(InjuryId.Bleeding));
  }

  if (getNeedsToMakeKnockdownRoll(oldHitPoints, newHitPoints, character)) {
    log(`Character needs to make knockdown roll to avoid being knocked prone`);
    let knockdownRollSpec = rollSpecForAttribute(AttributeId.Health, character, combatState, [], 'Knockdown', true);
    let knockdownRollAction = yield* waitForRoll(knockdownRollSpec, 'death roll');
    let knockdownRollResult = knockdownRollAction.result as CharacteristicRollResult;

    if (knockdownRollResult.success) {
      yield put(addToCharacterLog(`Knockdown roll succeeded: you keep your footing but are still stunned.`));
    } else {
      yield put(addToCharacterLog(`Knockdown roll failed: you are knocked down and stunned.`));
      yield put(changePosition(character, PositionId.Prone));
    }
    yield put(setStun(StunStatus.PhysicallyStunned));
  }
}

export function* waitForRoll(rollSpec: RollSpec, sagaName: string) {
  // Execute the roll
  log(`Initializing roll spec for ${sagaName} saga`);
  yield put(initRoll(rollSpec));
  log(`Waiting for user auto or manual roll for ${sagaName} saga`);
  let rollAction = (yield take(RollActions.ExecuteRoll)) as ExecuteRollAction;
  log(`User auto or manual roll complete for ${sagaName} saga, processing result`);
  return rollAction;
}
