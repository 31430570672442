import React from 'react';
import {Link} from 'react-router-dom'
import {connect} from 'react-redux';
import {Nav, Navbar,} from 'react-bootstrap';
import {loadCharacter} from "../../../modules/character";
import {AppState} from "../../../modules";
import {bindActionCreators} from "redux";
import {confirmSaga} from "../../../modules/modal";
import {AppDispatch} from "../../../lib/constants/appConstants";

class GamemasterNavbar extends React.Component<Props> {
  render() {
    let {
    } = this.props;

    return <Navbar bg='light' expand='lg' className='character-nav'>
      <Link to='/'>
        <Navbar.Brand className='stencil-font header'>GURPS WW2</Navbar.Brand>
      </Link>
      <Navbar.Toggle/>
      <Navbar.Collapse>
        <Nav className='mr-auto' variant='tabs' id='character-navbar'>
          <Link className='stencil-font' to='/gamemaster/overview'>Overview</Link>
          {/*<Link className='stencil-font' to='/gamemaster/encounter'>Encounter</Link>*/}
        </Nav>
      </Navbar.Collapse>
    </Navbar>;
  }
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    character: state.characterReducer,
    combat: state.combatReducer,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({
  loadCharacter,
  confirmSaga,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GamemasterNavbar)
